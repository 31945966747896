import { Injectable, Injector, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel
} from "@angular/forms";
import { AutoDestroy } from "projects/lib-shared-common/src/lib/decorators/auto-destroy";
import { Subject, takeUntil, tap } from "rxjs";

@Injectable()
export class SvcControl implements ControlValueAccessor, OnInit, OnChanges {

  public control!: FormControl;
  public disabled = false;
  private isChangingValue = false;

  @AutoDestroy
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected injector?: Injector
  ) {
  }

  ngOnInit(): void {
    this.setComponentControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setComponentControl();
  }

  /**
   * Call when value has changed programatically
   */
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public value: any;
  public writeValue(obj: any): void { this.value = obj; }
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

  protected onValueChanged(value){}

  private setComponentControl(): void {
    let injectedControl: NgControl = null;

    try{
      injectedControl = this.injector.get(NgControl);
    }catch {
      return;
    }

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control } = injectedControl as NgModel;
        control.valueChanges.pipe(
          takeUntil(this.destroy$),
          tap((value) => {
            if (!this.isChangingValue) {
              this.isChangingValue = true;
              this.onValueChanged(value);
              setTimeout(() => (this.isChangingValue = false));
            }
          })
        ).subscribe();
        this.control = control;
        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as FormControl;
        break;
      }
    }
  }
}
