import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-multiple-choice-answer',
  templateUrl: './multiple-choice-answer.component.html',
  styleUrls: ['./multiple-choice-answer.component.scss'],
})
export class MultipleChoiceAnswerComponent implements OnInit {
  @ViewChild('description') description: ElementRef;

  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public isSave = false;

  #fb = inject(FormBuilder);
  #modalService = inject(SurveyModalService);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.form = this.#fb.group({
      options: this.#fb.array(this.question?.metadata?.options?.map(({ description, Description, selected }) => this.#fb.group({
        description: [description ?? Description],
        selected: [selected ?? false],
      })) ?? []),
    });

    this.changesAnswered();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  private changesAnswered() {
    this.form?.get('options')?.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      tap((response) => {
        this.question.answered = this.checkIfAnySelected(response);
      })
    ).subscribe();
  }

  private checkIfAnySelected(response): boolean {
    return response.some((response) => response.selected === true);
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    const options = (this.form.get('options') as FormArray).value;
    const selectedOptions = options
      .filter((option) => option.selected)
      .map(({ description }) => ({ Description: description }));
    if (selectedOptions.length > 0) {
      this.doSave.emit({ Options: selectedOptions });
    }
  }
}
