<div class="flex flex-col flex-auto p-4">
  <div class="flex flex-row items-start">
    <div class="md:ml-10 text-3xl font-bold tracking-tight leading-6">
      {{ chartInfo?.title }}
    </div>
  </div>
  <div class="flex flex-col md:flex-row items-center justify-center">
    <div class="flex flex-auto w-full"
      echarts
      [style.height]="height"
      [options]="chartOptions"
      (chartInit)="onChartInit($event)"
      (chartClick)="onChartClick($event)"
    ></div>
  </div>
  <div *ngIf="minDate && maxDate" class="flex pt-2 px-2">
    <svc-chart-month-filter class="flex-auto"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (onRangeChange)="onSelectedRangeChange($event)">
    </svc-chart-month-filter>
  </div>
</div>
