import { ActivatedRouteSnapshot, Data, Resolve, RouterStateSnapshot } from "@angular/router";
import { Injectable, Type } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, of, take, tap } from "rxjs";
import { SvcDialogService } from "./svc-dialog.service";
import { SvcAppSettings } from "projects/lib-shared-core/src/lib/settings/svc-app-settings";
import { SvcModule } from "projects/lib-shared-core/src/lib/settings/enums/svc-module.enum";

@Injectable({
    providedIn: 'root',
})
export class SvcDialogResolver implements Resolve<any> {

    constructor(
        public _dialog: SvcDialogService,
        public _appSettings: SvcAppSettings,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<MatDialogRef<any>> | Observable<any> {
        const data = route.data;
        const dialog = data.resolveDialog ? data.resolveDialog(route) : data.dialog;

        if (dialog instanceof Observable) {
            return dialog.pipe(
                tap(((dialog) => {
                    this._openDialog(
                        route.routeConfig.component,
                        data,
                        dialog
                    );
                }))
            );
        }

        this._openDialog(
            route.routeConfig.component,
            data,
            dialog
        );

        return of();

    }

    private _openDialog(component: Type<any>, data: Data, dialog: any) {
        const dialogRef = this._dialog.open(component, {
            ...data,
            data: dialog,
            closeOnNavigation: true,
            disableClose: data.disableClose ?? false,
            backdropClass: data.backdropClass,
            size: data.size,
        });

        dialogRef.afterClosed().pipe(
            tap(() => {
                const closeFunctionName = {
                    [SvcModule.Notification]: 'closeAngularNotification',
                    [SvcModule.ExternalFeatures]: 'closeAngularExternalFeatures',
                }[this._appSettings.module];

                if (closeFunctionName && (parent || top)?.window?.[closeFunctionName]) {
                    ((parent || top)?.window?.[closeFunctionName] as any)();
                }
            }),
            take(1)
        ).subscribe();
    }
}
