import {Component, EventEmitter, forwardRef, Inject, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SvcControl} from "../svc-controls/svc-control";
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatSlideToggle, MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'svc-slide-toggle',
  templateUrl: './svc-slide-toggle.component.html',
  styleUrls: ['./svc-slide-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcSlideToggleComponent),
    multi: true
  }]
})
export class SvcSlideToggleComponent extends SvcControl implements OnInit, OnChanges {

  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() inputId: string;
  @Output() change = new EventEmitter<MatSlideToggleChange>();
  @ViewChild(MatSlideToggle) slideToggle: MatSlideToggle;

  constructor(@Inject(Injector) injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  
  onChanged(event: MatSlideToggleChange) {
    this.checked = event.checked;
    this.change.emit(event);
  }

  toggle() {
    this.slideToggle.toggle();
    this.control.setValue(this.slideToggle.checked);
  }

}
