import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SvcModalImageEditorData {
  image: string | Blob;
}

@Component({
  selector: 'svc-modal-image-editor',
  template: `<svc-image-editor
    [image]="data.image"
    (onSave)="save($event)"
    (onCancel)="close()"
  ></svc-image-editor>
`,
})
export class SvcModalImageEditorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SvcModalImageEditorData,
    private _dialogRef: MatDialogRef<SvcModalImageEditorComponent>,
  ) {
  }

  public ngOnInit(): void {
  }

  public save(blob: Blob) {
    this._dialogRef.close(blob);
  }

  public close() {
    this._dialogRef.close();
  }
}
