import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SvcPivotTableComponent } from './svc-pivot-table.component';

const routes: Routes = [
  {
    path: '',
    component: SvcPivotTableComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SvcPivotTableRoutingModule { }
