import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ExternalUrlResolver } from 'projects/lib-shared-common/src/public-api';
import { LayoutComponent } from 'projects/lib-shared-feature/src/lib/ui/layout/layout.component';

const routes: Routes = [
  // Auth rotes are defined at auth-module import
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    component: LayoutComponent,
    resolve: {
      ExternalUrlResolver
    },
    data: {
      externalUrl: '/myworkspace/'
    }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  },
  {
    path: 'signin',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
