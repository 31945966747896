import { Layout } from '../layout.types';

// Types
export type Screens = { [key: string]: string };
export type Theme = 'svc-default' | string;
export type Themes = { id: string; name: string }[];

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  screens: Screens;
  theme: Theme;
  themes: Themes;
}

export const appConfig: AppConfig = {
  layout: 'svc-custom-layout',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
  theme: 'svc-default',
  themes: [
    {
      id: 'svc-default',
      name: 'Default',
    },
    {
      id: 'svc-00274C',
      name: 'Solvace #00274C',
    },
    {
      id: 'svc-003366',
      name: 'Solvace #003366',
    },
    {
      id: 'svc-003399',
      name: 'Solvace #003399',
    },
    {
      id: 'svc-005F86',
      name: 'Solvace #005F86',
    },
    {
      id: 'svc-0F58D6',
      name: 'Solvace #0F58D6',
    },
    {
      id: 'svc-161718',
      name: 'Solvace #161718',
    },
    {
      id: 'svc-3A8DD2',
      name: 'Solvace #3A8DD2',
    },
    {
      id: 'svc-512072',
      name: 'Solvace #512072',
    },
    {
      id: 'svc-7B7521',
      name: 'Solvace #7B7521',
    },
    {
      id: 'svc-830051',
      name: 'Solvace #830051',
    },
    {
      id: 'svc-E4002B',
      name: 'Solvace #E4002B',
    },
    {
      id: 'svc-E5A812',
      name: 'Solvace #E5A812',
    },
    {
      id: 'svc-F3F3F3',
      name: 'Solvace #F3F3F3',
    },
    {
      id: 'svc-FAF5EB',
      name: 'Solvace #FAF5EB',
    },
    {
      id: 'svc-FEFEFE',
      name: 'Solvace #FEFEFE',
    },
    {
      id: 'svc-FF7900',
      name: 'Solvace #FF7900',
    },
    {
      id: 'svc-FFD30F',
      name: 'Solvace #FFD30F',
    }
  ],
};
