import { Directive, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective {

  @Output() scrollEnd = new EventEmitter();

  @HostListener('scroll', ['$event'])
  public onScroll(event: Event): void {
    const element = event.target as HTMLElement;

    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 10)
      this.scrollEnd.emit();
  }
}
