<div
  *ngIf="!chartInfo?.error"
  class="flex flex-col flex-auto"
  [ngStyle]="chartInfo?.loading ? { 'filter': 'blur(2px)' } : null"
>
  <div
    echarts
    class="flex flex-auto "
    [style.height]="height"
    [options]="chartOptions"
    (chartInit)="onChartInit($event)"
    (chartClick)="onChartClick($event)"
  ></div>
  <ng-content select="svc-chart-buttons"></ng-content>
</div>
<div *ngIf="chartInfo?.error" class="absolute flex justify-center items-center left-0 top-0 w-full h-full" (click)="refresh($event)">
  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1" [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>
  <span class="text-primary text-sm underline">
    {{ errorMsg }}
  </span>
</div>
<div *ngIf="!chartInfo?.error && chartInfo?.loading" class="absolute flex justify-center items-center left-0 top-0 w-full h-full">
  <mat-spinner diameter="30"></mat-spinner>
</div>
