import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcAlertComponent } from './alert.component';
import { SvcAlertService } from './alert.service';

@NgModule({
  declarations: [
    SvcAlertComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatButtonModule,
    MatIconModule,
    LibSharedIconsModule,
  ],
  exports: [
    SvcAlertComponent,
  ],
  providers: [
    SvcAlertService,
  ]
})
export class SvcAlertModule { }
