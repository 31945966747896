import {
  BaseCommonIndicatorChart,
  CommonIndicator,
  CommonIndicatorChart,
  CommonIndicatorChartInfo
} from "projects/lib-shared-model/src/public-api";

export enum InspectionIndicatorCategory{
  LATE = 1,
  TODAY = 2
}
export enum InspectionIndicatorCategoryNames {
  'Atrasado' = 1,
  'Hoje' = 2
}

export enum InspectionIndicatorStatus {
  PERFORMED = 1,
  PENDING = 3
}
export enum InspectionIndicatorStatusNames {
  'Realizado' = 1,
  'Pendente' = 3
}
export enum InspectionIndicatorStatusColors {
  '#4D4D4D' = 1,
  '#9BA0A3' = 3
}

export enum InspectionStatus {
  OPEN = 10,
  IN_APPROVAL = 20,
  COMPLETED = 30
}

export class InspectionIndicator extends BaseCommonIndicatorChart {
  data: CommonIndicator;
  chartInfo: CommonIndicatorChartInfo;
  chartOrder = [2, 1];
  seriesOrder = [3, 1];

  constructor(data: CommonIndicator){
    super({
      categories: InspectionIndicatorCategory,
      categoriesNames: InspectionIndicatorCategoryNames,
      statuses: InspectionIndicatorStatus,
      statusesNames: InspectionIndicatorStatusNames,
      colors: InspectionIndicatorStatusColors
    });
    this.data = data;
    this.chartInfo = this.generateChartInfo();
  }
}
