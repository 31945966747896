import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService, UserService } from 'projects/lib-shared-core/src/public-api';
import { CognitoUser, EnvironmentInfo, SsoItem } from 'projects/lib-shared-core/src/lib/auth/model/environment.info.model';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { svcAnimations } from 'projects/lib-shared-component/src/public-api';
import { AuthStatus, UserStatusAuth } from 'projects/lib-shared-core/src/lib/auth/model/user-auth.model';

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: svcAnimations,
})
export class AuthSignInComponent implements OnInit, AfterViewInit, OnDestroy {
  alert: { type: string; message: string, appearance: string } = {
    type: 'success',
    message: '',
    appearance: 'outline'
  };
  signInForm: UntypedFormGroup;
  userEnviromentSingInForm: UntypedFormGroup;
  passwordExpire: 'expired' | 'expiring' | null;

  privateEnvInfo: EnvironmentInfo;
  privateAlert = false;
  privateEnvUserInfoList: { text: string, value: string }[];
  privateHasEnviromentInfoByOriginPath = false;
  privateHasSSORedirecting = false;
  privateHasCurrentUser = false;
  privateCurrentUser: CognitoUser | null;
  currentAuthingUser: CognitoUser | null;
  loading: boolean;
  daysMax = 1;
  userStatus: UserStatusAuth;

  quickSignInInProcess = false;
  public solvaceLogo$ = this._authService.solvaceLogo$;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  get showAlert() {
    return this.privateAlert;
  }

  get hasEnviromentInfoByOriginPath() {
    return this.privateHasEnviromentInfoByOriginPath;
  }

  get environmentInfo(): EnvironmentInfo {
    return this._authService.environmentInfo ?? null;
  }

  get environmentUserInfoList(): { text: string, value: string }[] {
    return this.privateEnvUserInfoList?.length
      ? this.privateEnvUserInfoList
      : null;
  }

  get backgroundImg() {
    return this._sanitizer.bypassSecurityTrustStyle(
      this._authService.getCoverImage()
    );
  }

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _authService: AuthService,
    private _userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private _sanitizer: DomSanitizer,
    private _router: Router
  ) { }

  async ngAfterViewInit(): Promise<void> {
    const urlCurrent: string = this._authService.getEnvironmentUrlCurrent('');

    if (urlCurrent !== 'https://www.solvacelabs.com')
      this.privateHasEnviromentInfoByOriginPath = await this.requestEnvironmentInfo();
  }

  private async requestEnvironmentInfo(urlEnv?: string, isGlobal?: boolean): Promise<boolean> {
    let hasEnvironmentInfo = false;
    this.privateEnvInfo = null;
    await this._authService.postEnvironmentInfo(urlEnv, isGlobal).then((c) => {
      hasEnvironmentInfo = true;
      this.privateEnvInfo = c;
      this._authService.applyCongnitoConfig(c);

      this.privateHasSSORedirecting = this._authService.ssoRedirecting;
      this._authService.getCurrentUser().then((user) => {
        this.privateHasCurrentUser = !!user;
        this.privateCurrentUser = user;
      });
    });

    return hasEnvironmentInfo;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.signInForm = this._formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.userEnviromentSingInForm = this._formBuilder.group({
      user: ['', [Validators.required]],
      environment: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.userEnviromentSingInForm.controls.environment.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((env) => {
        this.requestEnvironmentInfo(env, true);
      }),
    ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */

  private setEnviromentUserInfo() {
    if (this.userEnviromentSingInForm.controls['user'].invalid) {
      return;
    }
    this.privateAlert = false;
    this.userEnviromentSingInForm.disable();

    const user = this.userEnviromentSingInForm.value.user;

    this._authService
      .postEnvironmentListByUserName(user)
      .then((envList) => {
        if (envList) {
          this.privateEnvUserInfoList = envList.map(item => ({
            text: item.environmentName,
            value: item.environmentURL,
          }));
        } else {
          throw envList;
        }
      })
      .catch(() => {
        this.alert = {
          type: 'error',
          appearance: 'outline',
          message: this._translocoService.translate(
            'Usuário inválido'
          ),
        };
        this.privateAlert = true;
      })
      .finally(() => {
        this.userEnviromentSingInForm.enable();
      });
  }

  private signIn(form: UntypedFormGroup): void {
    this._authService
      .signIn(form.value, this.environmentInfo.url)
      .then((userStatus) => {
        this.userStatus = userStatus;
        this.checkUserExpiredPassword(userStatus.user).then((expired) => {
          if (!expired) this.doSignInFlowCheck(userStatus);
        });
      })
      .catch((err) => {
        this.raiseLoginError(form);
      });
  }

  private signInCurrentUser() {
    this._authService
      .signInCurrentUser(this.environmentInfo.url)
      .then((userStatus) => {
        this.userStatus = userStatus;
        this.checkUserExpiredPassword(userStatus.user).then((expired) => {
          if (!expired) this.doSignInFlowCheck(userStatus);
        })
      })
      .catch((err) => {
        this.privateCurrentUser = null;
        this.raiseLoginError();
      });
  }

  private doSignInFlowCheck(userStatus: UserStatusAuth) {
    switch (userStatus.status) {
      case AuthStatus.TemporaryPasswordUser:
        this._router.navigate(['reset-password']);
        break;

      case AuthStatus.NotFoundUser:
        this._router.navigate(['user-locked/new']);
        break;

      case AuthStatus.PermissionPendingUser:
        this._router.navigate(['user-locked/waiting']);
        break;

      case AuthStatus.PendingGDPRUser:
        this._router.navigate(['user-locked/gdpr'], {
          queryParamsHandling: 'preserve'
        });
        break;

      case AuthStatus.RejectedUser:
        this._router.navigate(['user-locked/rejected']);
        break;

      case AuthStatus.MandatoryFederatedUser:
        this._router.navigate(['user-locked/deprecated']);
        break;

      case AuthStatus.EmployeeUser:
      case AuthStatus.SupplierUser:
      case AuthStatus.ContractorUser:
      default:
        this._userService.get().subscribe((user) => {
          this._authService.doValidatedUserRedirecting(user, userStatus);
        });
        break;
    }
  }

  signInWithCurrentUser() {
    if (this.passwordExpire === 'expiring') {
      this.loading = true;
      this.doSignInFlowCheck(this.userStatus);
      return;
    }

    this.quickSignInInProcess = true;
    this.signInCurrentUser();
  }

  signInWithEnviromentInfo() {
    if (this.passwordExpire === 'expiring') {
      this.loading = true;
      this.doSignInFlowCheck(this.userStatus);
      return;
    }
    this.privateAlert = false;
    this.signInForm.markAllAsTouched();
    if (this.signInForm.invalid || this.passwordExpire === 'expired') {
      return;
    }

    this.signInForm.disable();
    this.loading = true;

    this.signIn(this.signInForm);
  }

  signInEnviromentUserInfo() {
    this.privateAlert = false;
    if (this.environmentUserInfoList) {
      this.userEnviromentSingInForm.markAllAsTouched();
      if (this.userEnviromentSingInForm.invalid) {
        return;
      }

      this.userEnviromentSingInForm.disable();
      this.signIn(this.userEnviromentSingInForm);
    } else {
      this.setEnviromentUserInfo();
    }
  }

  loginSSO(sso: SsoItem) {
    this.privateAlert = false;
    this._authService.ssoSignIn(sso);
  }

  private raiseLoginError(form?: UntypedFormGroup) {
    if (form)
      form.enable();

    this.alert = {
      type: 'error',
			appearance: 'outline',
      message: this._translocoService.translate(
        'Usuário ou senha inválidos'
      ),
    };

    this.privateAlert = true;
    this.loading = false;
  }

  onRefresh() {
    this._authService.signOut(true);
    document.location.reload();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private async checkUserExpiredPassword(user: CognitoUser) : Promise<boolean> {
    return this._authService.checkUserExpiredPassword()
      .then(result => {
        if (result?.status === 'expiring') {
          this.privateAlert = true;
          this.passwordExpire = 'expiring';
          this.alert = { type: 'info', appearance: 'fill', message: this._translocoService.translate(`Sua senha vai expirar em {0} dias, atualize no link ao lado ou clique em entrar para fazer o login.`).replace('{0}', result.days.toString())};
          this.currentAuthingUser = user;
          this._authService.removeExpiredToken();
          this.loading = false;
          return true;
        }
        if (result?.status === 'expired') {
          this.privateAlert = true;
          this.passwordExpire = 'expired';
          this.alert = { type: 'error', appearance: 'fill', message: this._translocoService.translate('Sua senha expirou, atualize agora para fazer o login')};
          this._authService.setExpiredToken();
          this.loading = false;
          return true;
        }
        this._authService.removeExpiredToken();
        return false;
      });
  }

}
