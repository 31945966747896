import { InjectionToken } from '@angular/core';
import { SvcModule } from './enums/svc-module.enum';

export const SVC_APP_SETTINGS = new InjectionToken<SvcAppSettingsValue>('SVC_APP_SETTINGS');

export interface ISvcRootAppSettingsValue {
    get applicationId(): string;
    get applicationName(): string;
    get module(): SvcModule;
}

export interface ISvcAppSettingsValue extends ISvcRootAppSettingsValue {
    get dateFormat(): string;
    get delimiterSymbol(): string;
    get currencyAcronym(): string;
}

export class SvcAppSettingsValue implements ISvcAppSettingsValue {
    public applicationId: string;
    public applicationName: string;
    public module: SvcModule;
    public dateFormat: string = 'dd/MM/yyyy';
    public currencyAcronym: string = 'LCY$';
    public delimiterSymbol: string = ',';

    constructor(obj: ISvcRootAppSettingsValue) {
        Object.assign(this, obj ?? {});
    }
}
