<div
  class="flex items-center gap-2 mx-6 mb-4"
  *ngIf="showSearchControl || showOpenAllAccordions || showDownloadOptions"
  [class.justify-end]="!showSearchControl"
  [class.justify-between]="showSearchControl"
>

  <mat-form-field class="svc-mat-no-subscript svc-mat-dense w-[228px] max-w-[228px]" *ngIf="showSearchControl">
    <input
      type="search"
      matInput
      (keyup.enter)="searchItems()"
      [formControl]="searchControl"
      [placeholder]="'Pesquisar' | transloco"
    />

    <mat-icon
      class="icon-size-4 text-default cursor-pointer"
      svgIcon="mat_solid:search"
      (click)="searchItems()"
    >
    </mat-icon>
  </mat-form-field>

  <div class="flex justify-self-end gap-2" *ngIf="showOpenAllAccordions || showDownloadOptions">
    <svc-button
      class="button-action"
      icon-only
      color="primary"
      size="sm"
      [mode]="isAllExpanded ? 'flat' : 'stroked'"
      (click)="toggleIsAllExpanded()"
      *ngIf="showOpenAllAccordions"
    >
      <mat-icon class="icon-size-6" color="primary" svgIcon="heroicons_solid:bars-3-center-left"></mat-icon>
    </svc-button>

    <button
      class="button-download text-primary font-bold hidden sm:flex"
      mat-button
      color="primary"
      [matMenuTriggerFor]="exportMatMenu"
      [disabled]="isExporting"
      *ngIf="showDownloadOptions"
    >
      <mat-icon class="text-primary" *ngIf="!isExporting" svgIcon="heroicons_solid:ellipsis-vertical">
      </mat-icon>

      <mat-progress-spinner class="text-primary" *ngIf="isExporting" mode="indeterminate" [diameter]="24">
      </mat-progress-spinner>
    </button>

    <mat-menu #exportMatMenu>
      <button mat-menu-item class="text-default" (click)="exportFile.emit('excel')">
        <mat-icon class="text-current" svgIcon="heroicons_solid:document-text"></mat-icon>
        <span>{{ 'Export XLS' | transloco }}</span>
      </button>

      <button mat-menu-item class="text-default" (click)="exportFile.emit('pdf')">
        <mat-icon class="text-current" svgIcon="mat_solid:picture_as_pdf"></mat-icon>
        <span>{{ 'Export PDF' | transloco }}</span>
      </button>
    </mat-menu>

  </div>
</div>

<table mat-table multiTemplateDataRows matSort [dataSource]="dataSource">

  <ng-template #columnData let-type="type" let-property="property" let-row="row" let-cssClass="cssClass" let-propertyBarPercentage="propertyBarPercentage" let-propertyTooltip="propertyTooltip">
    <ng-container [ngSwitch]="type">

      <p *ngSwitchCase="'string'" [ngClass]="cssClass" [matTooltip]="row?.[propertyTooltip]">
        {{ row?.[property] }}
      </p>

      <p *ngSwitchCase="'number'" [ngClass]="cssClass" [matTooltip]="row?.[propertyTooltip]">
        {{ row?.[property] | formatNumberDefault }}
      </p>

      <svc-status-badge
        *ngSwitchCase="'status'"
        class="justify-center"
        [ngClass]="cssClass"
        [badgeData]="row?.[property]">
      </svc-status-badge>

      <div class="flex justify-center" *ngSwitchCase="'numberRounded'" [ngClass]="cssClass" [matTooltip]="row?.[propertyTooltip]">
        <div
          class="flex items-center justify-center w-6 h-6 rounded-full bg-primary text-on-primary-800 line-clamp-1 break-words text-center"
          *ngIf="row?.[property] > 0; else showNumber"
        >
          {{ row?.[property] | formatNumberDefault }}
        </div>

        <ng-template #showNumber>
          {{ row?.[property] | formatNumberDefault }}
        </ng-template>
      </div>

      <div *ngSwitchCase="'numberBorderRadius'" [ngClass]="cssClass" [matTooltip]="row?.[propertyTooltip]">
        <div class="flex items-center justify-center bg-primary rounded-[80px] py-0.5 px-2 h-[23px] min-w-[34px]" *ngIf="row?.[property]">
          <p class="text-white text-base font-normal">
            {{ row?.[property] | formatNumberDefault }}
          </p>
        </div>

        <p *ngIf="!row?.[property]" class="text-default text-base font-normal">
          {{ row?.[property] }}
        </p>
      </div>

      <div class="flex items-center gap-2" *ngSwitchCase="'bar'" [ngClass]="cssClass">
        <div
          class="bg-primary rounded-3xl h-4 min-w-[31px] cursor-pointer"
          *ngIf="row?.[propertyBarPercentage]"
          [style.width]="'calc(' + row?.[propertyBarPercentage] + '% + 31px)'"
          [matTooltip]="row?.[propertyTooltip]"
          (click)="barClick($event, row)"
        >
        </div>

        <p class="text-xs font-semibold text-default" [matTooltip]="row?.[propertyBarPercentage] === 0 ? row?.[propertyTooltip] : null">
          {{ row?.[property] | formatNumberDefault }}
        </p>
      </div>

    </ng-container>
  </ng-template>

  <ng-container *ngFor="let column of dataTable?.columns; index as i">
    <ng-container [matColumnDef]="column?.label + i">

      <ng-container *ngIf="column?.sort">
        <th
          class="header-sort"
          mat-header-cell
          [mat-sort-header]="column?.property"
          *matHeaderCellDef
          [ngClass]="column?.cssClass"
        >
          {{ column?.label | transloco }}

          <mat-icon class="icon-hover ml-1 icon-size-4 text-default hidden" svgIcon="heroicons_solid:chevron-down" *ngIf="sort?.active !== column?.property || (sort?.direction !== 'desc' && sort?.direction !== 'asc')">
          </mat-icon>

          <mat-icon class="ml-1 icon-size-4 text-default" svgIcon="heroicons_solid:chevron-down" *ngIf="sort?.active === column?.property && sort?.direction === 'desc'">
          </mat-icon>

          <mat-icon class="ml-1 icon-size-4 text-default" svgIcon="heroicons_solid:chevron-up" *ngIf="sort?.active === column?.property && sort?.direction === 'asc'">
          </mat-icon>
        </th>

      </ng-container>

      <ng-container *ngIf="!column?.sort">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="column?.cssClass"
        >
          {{ column?.label | transloco }}
        </th>
      </ng-container>

      <td
        mat-cell
        *matCellDef="let row"
        [ngClass]="column?.cellCssClass"
        [class.is-first-cell]="i === 0"
      >
        <div [ngClass]="i === 0 ? 'flex items-center gap-0.5' : ''">
          <button
            class="expand-button"
            *ngIf="i === 0"
            mat-icon-button
            aria-label="expand row"
            (click)="toggleExpanded(row)"
            [disabled]="isAllExpanded"
          >
            <mat-icon
              class="text-default icon-size-5"
              [svgIcon]="expandedRows.has(row) || isAllExpanded ? 'mat_solid:expand_more' : 'mat_solid:chevron_right'"
            >
            </mat-icon>
          </button>

          <ng-template
            *ngTemplateOutlet="columnData; context {
              type: column?.type,
              property: column?.property,
              row,
              cssClass: column?.cellCssClass,
              propertyBarPercentage: column?.propertyBarPercentage,
              propertyTooltip: column?.propertyTooltip
            }"
          >
          </ng-template>
        </div>
      </td>

    </ng-container>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="dataTable?.columns?.length">

      <div [hidden]="!(expandedRows.has(row) || isAllExpanded)">
        <table
          class="sub-items"
          mat-table
          multiTemplateDataRows
          [class.show-header-row]="dataTable?.showHeaderRowSubItems"
          [dataSource]="row?.[dataTable?.subItemsPropertyName]"
        >
          <ng-container *ngFor="let column of dataTable?.columns; index as i">
            <ng-container
              [matColumnDef]="dataTable?.showHeaderRowSubItems && column?.accordion?.label ? column?.accordion?.label : column.label + (i + dataTable?.columns?.length)"
            >
              <th
                *matHeaderCellDef
                mat-header-cell
                [hidden]="!dataTable?.showHeaderRowSubItems"
                [ngClass]="column?.accordion?.cssClass"
              >
                <ng-container *ngIf="dataTable?.showHeaderRowSubItems && column?.accordion?.label">
                  {{ column?.accordion?.label | transloco }}
                </ng-container>
              </th>

              <td
                mat-cell
                *matCellDef="let subItem"
                [ngClass]="column?.accordion?.cellCssClass"
                [class.is-first-cell]="i === 0"
              >
                <p>
                  <ng-template
                    *ngTemplateOutlet="columnData; context {
                      type: column?.accordion?.type,
                      property: column?.accordion?.property,
                      propertyBarPercentage: column?.accordion?.propertyBarPercentage,
                      row: subItem,
                      cssClass: column?.accordion?.cellCssClass,
                      propertyTooltip: column?.accordion?.propertyTooltip
                    }"
                  >
                  </ng-template>
                </p>
              </td>

            </ng-container>

          </ng-container>

          <tr mat-row *matRowDef="let row; columns: getColumnLabelsAccordion()" class="cursor-pointer" (click)="onSubRowClicked.emit(row)"></tr>
          <tr mat-header-row *matHeaderRowDef="getColumnLabelsAccordion();"></tr>

        </table>
      </div>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: getColumnLabels()" [class.row-selected]="expandedRows.has(row) || isAllExpanded"></tr>
  <tr mat-header-row *matHeaderRowDef="getColumnLabels()"></tr>

  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" [hidden]="!(expandedRows.has(row) || isAllExpanded)">
  </tr>

</table>

<ng-container *ngIf="isLoading">
  <svc-common-loader></svc-common-loader>
</ng-container>

<div class="svc-data-table-accordion-empty" *ngIf="!isLoading && dataSource?.data?.length <= 0">
	<span [innerHTML]="'Não há resultados' | transloco"></span>
</div>
