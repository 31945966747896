<div class="flex flex-col items-center flex-shrink-0 basis-1/4 ">
  <div class="flex -space-x-7">

    <svc-user-avatar
      *ngFor="let avatar of avatars | slice:0 : maxSize"
      [info]="avatar"
      [matTooltip]="avatar.name"
      border-color="borderColor"
    ></svc-user-avatar>

    <div
      *ngIf="avatars.length > maxSize"
      class="flex flex-column rounded-full items-center justify-center border-2 border-white bg-primary"
      [style.width.px]="info.size"
      [style.height.px]="info.size"
      [matTooltip]="(avatars | slice:maxSize) | getProperty:'name' | join"
    >
      <div style="line-height: 24px; font-size: 24px; color: #ffffff;">
        +{{avatars.length - maxSize}}
      </div>
    </div>

  </div>
</div>
