import { NgModule } from '@angular/core';
import { HighlightModule } from 'ngx-highlightjs';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcDialogsModule } from 'projects/lib-shared-component/src/public-api';
import { ModalEmbedHtmlComponent } from './modal-embed-html.component';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [
    ModalEmbedHtmlComponent,
  ],
  exports: [
    ModalEmbedHtmlComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDialogsModule,
    HighlightModule,
  ]
})
export class ModalEmbedHtmlModule { }
