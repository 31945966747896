<div class="flex flex-col flex-auto">
  <!-- Form -->
  <form *ngIf="showInput" [formGroup]="chatForm">
    <div class="flex items-start pb-3" [ngClass]="smallSize ? 'gap-1' : 'gap-10'">
      <div class="flex flex-col flex-auto">
        <svc-comments-input
          [placeholder]="placeholder | transloco"
          formControlName="message"
          [loading]="isCreatingCommentLoading"
          [buttonDisabled]="!commentIsValidToSend"
          (onTextChange)="validateSendButton()"
          (onButtonClicked)="sendMessage()"
        ></svc-comments-input>
        <div class="flex flex-wrap">
          <div class="attachment-item text-default" *ngFor="let attachment of attachmentFiles">
            <div *ngIf="attachment.type != AttachmentFileType.RECORDED_AUDIO" class="attachment-file">
              <mat-icon class="text-current icon-size-4" svgIcon="mat_solid:attach_file"></mat-icon>
            </div>
            <div class="attachment-content">
              <span *ngIf="attachment.type != AttachmentFileType.RECORDED_AUDIO">
                {{ attachment.file.name }}
              </span>
              <audio *ngIf="attachment.type == AttachmentFileType.RECORDED_AUDIO" controls>
                <source [src]="attachment.file | blobToUrl">
              </audio>
            </div>
            <div class="attachment-remove">
              <mat-icon
                class="text-current icon-size-4 cursor-pointer"
                svgIcon="mat_solid:close"
                [matTooltip]="'Remover' | transloco"
                (click)="removeAttachment(attachment.file)"
              ></mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="flex" [ngClass]="smallSize ? 'gap-1' : 'gap-2.5'">
        <button type="button" class="mat-elevation-z1 no-hover-shadow mt-2" color="primary" [ngClass]="{ 'small-button': smallSize }" mat-mini-fab (click)="openAttachmentWindow()" [disabled]="isCreatingCommentLoading">
          <mat-icon svgIcon="mat_solid:attach_file"></mat-icon>
        </button>

        <svc-audio-to-text-button
          class="mt-2"
          [small]="smallSize"
          [disabled]="isCreatingCommentLoading"
          [canChooseRecording]="true"
          (onText)="chatForm.controls.message.setValue($event)"
          (onAudioRecorded)="addFileToAttachments($event.file, AttachmentFileType.RECORDED_AUDIO)"
        ></svc-audio-to-text-button>
      </div>
    </div>
  </form>

  <!-- Messages -->
  <div class="flex flex-col flex-auto w-full" [class.pl-4]="!smallSize">
    <svc-comments-message
      *ngFor="let message of messages"
      class="page-avoid-break pb-4"
      [message]="message"
      [configTypeId]="configTypeId"
      [parentMessage]="parentMessage"
      [applicationId]="applicationId"
      [smallSize]="smallSize"
      [siteId]="siteId"
      [external]="external"
      (onCommentRemoved)="commentRemoved(message)"
      (onCommentAdded)="onCommentAdded.emit($event)"
    ></svc-comments-message>
  </div>

  <div *ngIf="!messagesAreGone" class="flex pb-2 mt-0" [ngClass]="{ 'justify-center': !isChild || isLoading }">
    <svc-button *ngIf="!isLoading" mode="simple" [size]="isChild ? 'sm' : 'md'" (click)="nextPage()">
      {{ (isChild ? 'Carregar mais respostas' : 'Carregar mais comentários') | transloco }}
    </svc-button>
    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
  </div>
</div>
