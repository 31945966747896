import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcToastModule } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.module';
import {
  LibSharedComponentModule,
  SvcBreadcrumbModule,
  SvcButtonsModule,
  SvcControlsModule,
  SvcDataTableModule,
  SvcSearchFieldModule
} from 'projects/lib-shared-component/src/public-api';
import { SvcSmartUserAvatarModule } from '../../general/svc-smart-user-avatar/svc-smart-user-avatar.module';
import { SvcAccessPermissionFormComponent } from './svc-access-permission-form/svc-access-permission-form.component';
import { SvcAccessPermissionComponent } from './svc-access-permission.component';
import { accessPermissionRoutes } from './svc-access-permission.routing';


@NgModule({
  declarations: [
    SvcAccessPermissionComponent,
    SvcAccessPermissionFormComponent
  ],
  exports: [
    SvcAccessPermissionComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDataTableModule,
    SvcBreadcrumbModule,
    SvcButtonsModule,
    SvcControlsModule,
    TranslocoModule,
    SvcToastModule,
    SvcSearchFieldModule,
    RouterModule.forChild(accessPermissionRoutes),
    SvcSmartUserAvatarModule
  ]
})
export class SvcAccessPermissionModule { }
