import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcDialogsModule } from 'projects/lib-shared-component/src/public-api';
import { SvcPrinterModalComponent } from './svc-printer-modal.component';
import { SvcPrinterMiniatureComponent } from './svc-printer-miniature/svc-printer-miniature.component';

@NgModule({
  declarations: [
    SvcPrinterModalComponent,
    SvcPrinterMiniatureComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    SvcButtonsModule,
    TranslocoModule,
  ],
  exports: [
    SvcPrinterModalComponent,
    SvcPrinterMiniatureComponent,
  ],
})
export class SvcPrinterModule { }
