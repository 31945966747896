<ng-container *ngIf="summary; else refresh">
  <div *ngIf="summary && !disable" class="flex bg-primary-100 rounded-lg p-2 gap-2">

    <div class="flex items-center">
      <svg class="idea-icon" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.25 2.25C20.25 1.00736 19.2426 0 18 0C16.7574 0 15.75 1.00736 15.75 2.25V4.5C15.75 5.74264 16.7574 6.75 18 6.75C19.2426 6.75 20.25 5.74264 20.25 4.5V2.25Z"/>
        <path d="M30.7278 8.45394C31.6065 7.57526 31.6065 6.15064 30.7278 5.27196C29.8492 4.39328 28.4245 4.39328 27.5459 5.27196L25.9549 6.86295C25.0762 7.74163 25.0762 9.16625 25.9549 10.0449C26.8335 10.9236 28.2582 10.9236 29.1368 10.0449L30.7278 8.45394Z"/>
        <path d="M36 18C36 19.2426 34.9926 20.25 33.75 20.25H31.5C30.2574 20.25 29.25 19.2426 29.25 18C29.25 16.7574 30.2574 15.75 31.5 15.75H33.75C34.9926 15.75 36 16.7574 36 18Z"/>
        <path d="M6.86292 10.045C7.7416 10.9236 9.16623 10.9236 10.0449 10.045C10.9236 9.16629 10.9236 7.74167 10.0449 6.86299L8.45391 5.272C7.57524 4.39332 6.15061 4.39332 5.27193 5.272C4.39325 6.15068 4.39325 7.5753 5.27193 8.45398L6.86292 10.045Z"/>
        <path d="M6.75 18C6.75 19.2426 5.74264 20.25 4.5 20.25H2.25C1.00736 20.25 -2.68221e-07 19.2426 0 18C0 16.7574 1.00736 15.75 2.25 15.75H4.5C5.74264 15.75 6.75 16.7574 6.75 18Z"/>
        <path d="M13.5 31.5V29.25H22.5V31.5C22.5 33.9853 20.4853 36 18 36C15.5147 36 13.5 33.9853 13.5 31.5Z"/>
        <path d="M22.502 27C22.5348 26.2343 22.9671 25.5458 23.5728 25.0676C25.6602 23.4193 27 20.8661 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 20.8661 10.3398 23.4193 12.4272 25.0676C13.0329 25.5458 13.4652 26.2343 13.498 27H22.502Z"/>
      </svg>
    </div>

    <div class="flex flex-col text-primary-600">
      <span class="text-xs truncate">{{'Idéias Kaizen'| transloco}}</span>
      <span class="text-xl font-medium truncate">{{ summary.totalIdeas }}</span>
    </div>

    <div class="flex flex-col text-primary-600 grow overflow-hidden">
      <span class="text-xs truncate">{{'Economias Entregues' | transloco}}</span>
      <span *ngIf="!summary.lcy"> - </span>
      <span
        *ngIf="summary.lcy"
        class="text-xl font-medium truncate"
        [title]="summary.lcy | formatNumberDefault"
      >{{ currencyAcronym }} {{summary.lcy | formatNumberDefault}}</span>
    </div>

  </div>
</ng-container>
<ng-template #refresh>
  <div class="flex justify-center items-center border shadow rounded-lg h-16" [ngClass]="{ 'bg-white': !isLoading }" (click)="$event.stopImmediatePropagation()">
    <div *ngIf="!isLoading" class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="getKaizenSummary($event)">
      <mat-icon class="icon-size-4 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
      <span class="text-base text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
    </div>
    <mat-spinner diameter="18" *ngIf="isLoading"></mat-spinner>
  </div>
</ng-template>
