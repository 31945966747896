import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcSearchFieldComponent } from './svc-search-field.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';

@NgModule({
  declarations: [
    SvcSearchFieldComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule,
  ],
  exports: [
    SvcSearchFieldComponent
  ]
})
export class SvcSearchFieldModule { }
