import { AfterViewInit, Component, ViewChild, OnInit, Inject } from '@angular/core';

import { Subject } from 'rxjs';

import { SvcDrilldownService } from './shared/services/svc-drilldown.service';
import { SvcDrilldownConfig } from './shared/interfaces/svc-drilldown-config.interface';
import { SvcDataTableComponent, SvcDataTableEvent, SvcFilterType } from '../svc-data-table/public-api';
import { SVC_DRILLDOWN_CONFIG_DATA } from './shared/utils/svc-drilldown-config-data';

@Component({
  selector: 'svc-drilldown',
  templateUrl: './svc-drilldown.component.html',
  styleUrls: ['./svc-drilldown.component.scss']
})
export class SvcDrilldownComponent implements OnInit, AfterViewInit {

  @ViewChild('dataTable') public dataTable: SvcDataTableComponent;

  public svcFilterTypes = SvcFilterType;

  public isLoading$: Subject<boolean>;
  public items$: Subject<any>;
  public dataLength$: Subject<number>;
  public onGetData$: Subject<SvcDataTableEvent>;

  constructor (
    private _svcDrilldownService: SvcDrilldownService,
    @Inject(SVC_DRILLDOWN_CONFIG_DATA) public config: SvcDrilldownConfig
  ) { }

  public ngOnInit(): void {
    this.isLoading$ = this._svcDrilldownService.isLoading$;
    this.items$ = this._svcDrilldownService.items$;
    this.dataLength$ = this._svcDrilldownService.dataLength$;
    this.onGetData$ = this._svcDrilldownService.onGetData$;
  }

  public ngAfterViewInit(): void {
    this._addDataInputsDataTable();
  }

  private _addDataInputsDataTable(): void {
    if (this.config?.noPagination)
      this.dataTable.noPagination = this.config.noPagination;
  }

  public closeDrilldown(): void {
    this._svcDrilldownService.closeDrilldown(true);
  }

  public clickItemTypeId(item: any): void {
    this._svcDrilldownService.clickItemTypeId$.next(item);
  }

  public onGetData(dataTableEvent: SvcDataTableEvent): void {
    this.onGetData$.next(dataTableEvent);
  }
}
