import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../../../../lib-shared-core/src/lib/auth/auth.interceptor';
import { RouterModule } from "@angular/router";
import { authRoutes } from "./auth-routing";
import { NotFound404Module } from 'projects/lib-shared-core/src/public-api';

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule.forChild(authRoutes),
        NotFound404Module
    ],
    providers: [

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    declarations: []
})
export class AuthModule { }
