import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'svc-priority-button',
  templateUrl: './svc-priority-button.component.html',
  styleUrls: ['./svc-priority-button.component.scss']
})
export class SvcPriorityButtonComponent implements OnInit {
  @Output()
  onPriorityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() readonly = false;
  @Input() priority = false;
  @Input() iconSizeClass: string;
  @Input() buttonId: string;

  constructor() {}

  ngOnInit() {}
  async changePriority(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.priority = !this.priority;
    this.onPriorityChange.emit(this.priority);
  }
}
