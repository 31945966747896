import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { SurveyModalService } from '../../services/survey-modal.service';
import { FormControl } from '@angular/forms';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { tap } from 'rxjs';

@Component({
  selector: 'app-dropdown-list-answer',
  templateUrl: './dropdown-list-answer.component.html',
  styleUrls: ['./dropdown-list-answer.component.scss'],
})
export class DropdownListAnswerComponent implements OnInit {
  @ViewChildren('description') descriptions: QueryList<ElementRef>;

  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  #cdr = inject(ChangeDetectorRef);
  #modalService = inject(SurveyModalService);

  public control = new FormControl();
  public options: ISvcSelectOption[] = [];
  public isDuplicateDescription = false;
  public duplicateIndices: unknown[] = [];
  public selectedOptionValue: string | null = null;
  public isSave = false;

  ngOnInit(): void {
    this.control.valueChanges.pipe(
      tap((value) => {
        this.onSelectionChange(value);
      }),
    ).subscribe();

    if (this.question) {
      this.options = [];
      this.question.metadata?.options?.forEach((element, index) => {
        const value = `option-${index + 1}`;
        this.options.push({ value: value, text: element.description });
      });

      this.#cdr.detectChanges();
    }

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    if (this.selectedOptionValue) {
      const selectedOption = this.options.find(
        (option) => option.value === this.selectedOptionValue
      );
      const payload = { Option: selectedOption.text };
      this.doSave.emit(payload);
    }
  }

  public onSelectionChange(value: any) {
    this.selectedOptionValue = value;
    this.question.answered = true;
  }
}
