<svc-dialog #dialog fullscreen="XS">
  <div svc-dialog-title class="flex gap-2 items-center">
    <mat-icon svgIcon="heroicons_solid:printer" class="icon-size-5 text-current"></mat-icon>
    <div class="flex-auto">
      {{ 'Imprimir' | transloco }}
    </div>
  </div>
  <div svc-dialog-content class="p-3">
    <div class="printer-container flex flex-col gap-6" [class.hidden]="isLoading">
      <ng-container *ngFor="let option of (data?.options ?? []); let optionIndex = index">
        <div *ngIf="option.show ?? true" class="printer-option flex flex-col">
          <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option, disabled: option.disabled ?? false }"></ng-container>
          <div *ngFor="let childOption of (option.options ?? [])" class="printer-option flex flex-col gap-2 pt-2 pl-9">
            <ng-container *ngIf="childOption.show ?? true" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option: childOption, disabled: option.disabled || !option.selected || childOption.disabled ?? false }"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="isLoading" class="flex items-center justify-center py-50">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
  <div svc-dialog-actions class="flex gap-2 justify-end">
    <svc-button mode="stroked" type="button" (click)="dialog?.closeDialog()" [disabled]="isLoading">
      {{ 'Cancelar' | transloco }}
    </svc-button>
    <svc-button mode="flat" color="primary" type="button" cdkFocusInitial [disabled]="isLoading || !someOptionSeleted" (click)="!isLoading && someOptionSeleted && print()">
      {{ 'Imprimir' | transloco }}
    </svc-button>
  </div>
</svc-dialog>

<ng-template #optionTemplate let-option="option" let-disabled="disabled">
  <div class="flex items-center gap-2">
    <div>
      <mat-checkbox class="flex -mr-2" [checked]="option.selected ?? false" (change)="!disabled && changeOptionSelected(option)" [disabled]="disabled"></mat-checkbox>
    </div>
    <div #elementContainer *ngIf="option.element" [ngClass]="{ 'cursor-pointer': !disabled, 'opacity-50': disabled }" (click)="!disabled && changeOptionSelected(option)">
      <svc-printer-miniature [option]="option" [printAreaWidth]="data.printAreaWidth"></svc-printer-miniature>
    </div>
    <div class="flex-auto" [ngClass]="{ 'cursor-pointer': !disabled, 'opacity-50': disabled }" (click)="!disabled && changeOptionSelected(option)">
      {{ option.name }}
    </div>
  </div>
</ng-template>