import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { AuthForgotPasswordComponent } from './forgot-password.component';
import { authForgotPasswordRoutes } from './forgot-password.routing';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcAlertModule, SvcControlsModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
    declarations: [
        AuthForgotPasswordComponent
    ],
    imports     : [
        RouterModule.forChild(authForgotPasswordRoutes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        LibSharedCommonModule,
        TranslocoModule,
        SvcControlsModule,
        SvcAlertModule,
    ]
})
export class AuthForgotPasswordModule
{
}
