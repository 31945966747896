<form [formGroup]="form" class="max-w-4xl" [ngClass]="{ 'pointer-events-none': readonly }">
  <div class="flex flex-col gap-2">
    <div formArrayName="optionHeaders" class="grid grid-cols-[115px_repeat(5,.5fr)_45px] gap-4 items-center min-h-[45px] bg-table-header px-2.5">
      <!-- Espaço vazio alinhado com o botão de remoção de instrução -->
      <div></div>

      <!-- Cabeçalhos das opções editáveis -->
      <div *ngFor="let headerControl of optionHeaders?.controls; let j = index" class="flex items-center justify-center gap-x-1">
        <input
          type="text"
          [formControlName]="j"
          class="text-base text-default font-semibold w-full px-2 bg-transparent"
          [readonly]="true"
        />
      </div>
    </div>

    <!-- Conteúdo do formulário -->
    <div formArrayName="instructions" class="flex flex-col gap-2 px-2.5">
      <ng-container *ngFor="let instructionFormGroup of instructions.controls;let i = index" [formGroupName]="i">
        <div class="grid grid-cols-[115px_repeat(5,.5fr)_45px] gap-4 my-2 items-center">
          <!-- Botão de remoção de instrução e label editável -->
          <div class="flex items-center gap-x-1 align-middle">
            <input
              type="text"
              class="text-base text-default font-semibold bg-transparent"
              formControlName="instructionLabel"
              [readonly]="true"
            />
          </div>

          <div class="col-span-5 grid grid-cols-5 gap-4">
            <!-- Loop para criar os radio buttons com base no número de opções -->
            <ng-container  *ngFor="let _ of [].constructor(selectedOption); let j = index">
              <div class="text-center">
                <input
                  type="radio"
                  [name]="'option' + i"
                  [value]="j"
                  [checked]="instructionFormGroup?.controls?.option?.value === j"
                  (change)="instructionFormGroup?.controls?.option?.setValue(j)"
                  class="w-5 h-5 cursor-pointer"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
