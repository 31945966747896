import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcUiModule } from './ui/svc-ui.module';
import { LibSharedCoreModule } from 'projects/lib-shared-core/src/public-api';

@NgModule({
  declarations: [],
  imports: [
    LibSharedCommonModule,
    LibSharedCoreModule,
  ],
  exports: [
    SvcUiModule,
  ],
})
export class LibSharedFeatureModule {}
