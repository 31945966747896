import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';

export enum UserAvatarType {
  user = 1,
  team = 2
}

export interface ISvcAvatarMultiple {

  size: UserAvatarSize;
  initialsColor?: string;
  type?: UserAvatarType;
  data: ISvcAvatar[];

}

export interface ISvcAvatar {
  name: string;
  picture?: string;
  initialsColor: string;
  type: number;
}
