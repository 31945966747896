import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcDrawerComponent } from './svc-drawer.component';

@NgModule({
  declarations: [
    SvcDrawerComponent,
  ],
  imports: [
    LibSharedCommonModule,
  ],
  exports: [
    SvcDrawerComponent
  ]
})
export class SvcDrawerModule { }
