import { Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA} from "@angular/material/dialog";
import { Post} from "../../models/post";
import { UserService} from 'projects/lib-shared-core/src/public-api';
import {
  ISvcUserAvatar,
  SvcDialogComponent,
  SvcToastService,
  UserAvatarSize
} from 'projects/lib-shared-component/src/public-api';
import { PostUser} from "../../models/post-user";
import { TranslocoService} from "@ngneat/transloco";
import { PostService} from "../../services/post.service";
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import {debounceTime, Subject, switchMap, tap} from "rxjs";

export interface ShareItem {
  user: PostUser;
  avatar: ISvcUserAvatar;
  role: string;
}
@Component({
  selector: 'svc-modal-share',
  templateUrl: './svc-modal-share.component.html',
  styleUrls: ['./svc-modal-share.component.scss']
})
export class SvcModalShareComponent implements OnInit {

  @Input() post: Post;

  @ViewChild('panelList') panelList: ElementRef;
  @ViewChild('panelSelected') panelSelected: ElementRef;
  @ViewChild('dialog') dialog: SvcDialogComponent;

  text: string;
  searching: boolean;
  list: ShareItem[] = [];
  listSelected: ShareItem[] = [];
  loading: boolean;

  searchText$ = new Subject<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _toastService: SvcToastService,
    private _postService: PostService,
    private _httpErrorService: HttpErrorService
  ) {
    this.post = data;

    this.searchText$
      .pipe(
        debounceTime(800),
        tap(() => { this.searching = true }),
        switchMap((term: string) => {
          return this._userService.getUsersInfo({
            pageSize: 10,
            pageIndex: 0,
            active: true,
            name: term
          })
        })
      ).subscribe(r => {
      this.appendUsers(r.users);
      this.searching = false;
    });
  }

  ngOnInit(): void {
  }

  selectItem(event: any, item: ShareItem) {

    if (event.checked) {

      this.list = this.list
        .filter((i => i.user.userId !== item.user.userId));
      this.listSelected.push(item);

    } else {

      this.listSelected = this.listSelected
        .filter((i => i.user.userId !== item.user.userId));
      this.list.push(item);

    }
  }


  setFilterText(text: string) {
    this.list = [];
    if (text.length < 3) return;
    this.searchText$.next(text);
  }

  shareWithSelectedUsers() {

    if (this.listSelected.length === 0) {
      this._toastService.warning(this._translocoService.translate('Selecione pelo menos um funcionário.'))
      return;
    }

    this.loading = true;
    this._postService.sharePost({
      id: this.post.id,
      userShares: this.listSelected.map(i => ({
        userId: i.user.userId
      })),
      teamShares: []
    }).subscribe({
      next: () => {

        this.post.sharedTimes++;
        this._toastService.success(this._translocoService.translate('Post compartilhado com Sucesso!'))
        this.loading = false;
        this.dialog.closeDialog();
      },
      error: (error) => {
        this.loading = false;
        this._toastService.error(this._translocoService.translate(this._httpErrorService.getErrorMessage(error)))
      },
    })

  }


  private appendUsers(users: PostUser[]) {

    users.map(user => {
      if (this.listSelected.find(i => i.user.userId === user.userId))
        return;

      this._userService.getAvatar(user.userId).subscribe(userAvatar => {
        this.panelList.nativeElement.scrollTop = this.panelSelected?.nativeElement?.scrollHeight || 0;
        this.list.push({
          user,
          role: userAvatar.role,
          avatar: {
            name: userAvatar.firstLastName,
              size: UserAvatarSize.Posts,
              picture: userAvatar.pictureUrl
          }
        });

      })
    })
  }


}
