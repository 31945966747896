import { Component, Input } from '@angular/core';

@Component({
  selector: 'svc-button-neutral',
  templateUrl: './svc-button-neutral.component.html',
  styleUrls: ['./svc-button-neutral.component.scss']
})
export class SvcButtonnNeutralComponent {
  @Input() label: string;
  @Input() type: 'button' | 'file' | 'submit' = 'button';
  @Input() processing: boolean = false;
}
