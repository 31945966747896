import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcAudioToTextButtonComponent } from './svc-audio-to-text-button.component';
import { SvcAudioToTextButtonService } from './svc-audio-to-text-button.service';
import { SvcAudioToTextWrapperComponent } from './wrapper/svc-audio-to-text-wrapper.component';
import { LibSharedComponentModule, SvcButtonsModule } from 'projects/lib-shared-component/src/public-api';



@NgModule({
  declarations: [
    SvcAudioToTextButtonComponent,
    SvcAudioToTextWrapperComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcButtonsModule,
  ],
  providers: [
    SvcAudioToTextButtonService
  ],
  exports: [
    SvcAudioToTextButtonComponent,
    SvcAudioToTextWrapperComponent,
  ],
})
export class SvcAudioToTextButtonModule { }
