import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { SvcViewPanelContentDirective } from './svc-view-panel-content.directive';
import { SvcViewPanelButtonsData } from './interfaces/svc-view-panel-buttons-data.interface';
import { FormControl } from '@angular/forms';
import {Subject, debounceTime, takeUntil } from 'rxjs';
import {SvcViewPanelExportInterface} from "./interfaces/svc-view-panel-export.interface";

@Component({
  selector: 'svc-view-panel',
  templateUrl: './svc-view-panel.component.html',
  styleUrls: ['./svc-view-panel.component.scss']
})
export class SvcViewPanelComponent implements OnChanges, OnDestroy, AfterViewInit {

  @Input('buttons')
  viewButtons: SvcViewPanelButtonsData[] = [];

  @Input()
  showFilter = true;

  @Input()
  hasFilter: boolean;

  @Input()
  showSearch = true;

  @Input()
  showFilterFavorites: boolean;

  @Input()
  showExport: boolean = true;

  @Input()
  filterFavorites: boolean;

  @Output()
  onViewChanged: EventEmitter<SvcViewPanelButtonsData> = new EventEmitter<SvcViewPanelButtonsData>();

  @Output()
  onSearchEventClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onFilterFavoritesEventClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onSearchTermChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  export: SvcViewPanelExportInterface;

  @ContentChildren(SvcViewPanelContentDirective) views: QueryList<SvcViewPanelContentDirective>;

  protected activeView: string = '';
  protected searchCtrl = new FormControl();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private wasInitialized = false;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
    const views = this.views.toArray();

    if (views.length === 1) {
      this.activeView = views[0].id;
      this.cdr.detectChanges();
    }

    if(this.showSearch) {
      this.searchCtrl.valueChanges
        .pipe(
          debounceTime(500),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((value) => this.onSearchTermChanged.next(value));
    }

    this.wasInitialized = true;
  }

  public onActiveViewChanged(activeViewButton: SvcViewPanelButtonsData) {
    this.activeView = activeViewButton.id;
    if (this.wasInitialized) this.onViewChanged.emit(activeViewButton);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
