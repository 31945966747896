<svc-dialog
  #dialog
  dialogStyle="form"
>

  <div svc-dialog-title class="text-xl font-bold">
    {{ "Compartilhar" | transloco }}
  </div>

  <div svc-dialog-content class="flex flex-col divide-y">

    <div class="flex flex-row w-2/3 px-2 py-3 self-end">

      <mat-form-field class="flex-auto svc-mat-no-subscript svc-mat-dense">
        <input
          matInput
          [placeholder]="'Digite ao menos 3 caracteres' | transloco"
          type="text"
          [disabled]="searching"
          [(ngModel)]="text"
          (ngModelChange)="setFilterText($event)"
          id="post-share-search"
        />
        <mat-icon
          matSuffix
          class="text-current icon-size-5"
          [svgIcon]="'heroicons_solid:magnifying-glass'"
        ></mat-icon>
      </mat-form-field>

    </div>

    <div #panelList class="flex flex-col gap-2 p-3 max-h-[50vh] overflow-y-auto">

      <div #panelSelected>
        <div  *ngFor="let item of listSelected" class="flex flex-row">
          <ng-container
            [ngTemplateOutlet]="userTemplate"
            [ngTemplateOutletContext]="{ $implicit: item, selected: true }"
          ></ng-container>
        </div>
      </div>

      <div *ngFor="let item of list" class="flex flex-row">
        <ng-container
          [ngTemplateOutlet]="userTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, selected: false }"
        ></ng-container>
      </div>

    </div>

  </div>

  <div svc-dialog-actions class="flex flex-row gap-2">
    <svc-button
      mode="stroked"
      type="button"
      [disabled]="loading"
      (click)="dialog.closeDialog()"
    >{{ 'Cancelar' | transloco }}</svc-button>

    <svc-button
      mode="flat"
      type="button"
      color="primary"
      [processing]="loading"
      (click)="shareWithSelectedUsers()"
    >{{'Compartilhar' | transloco}}</svc-button>

  </div>
</svc-dialog>


<ng-template #userTemplate let-item let-selected="selected">
  <div class="flex flex-row items-center w-full">

    <svc-user-avatar
      class="relative mr-2"
      [info]="item.avatar"
    ></svc-user-avatar>

    <div class="grow flex flex-col">
      <span class="font-bold leading-tight">{{ item.user.name }}</span>
      <span *ngIf="item.role?.length">{{ item.role }}</span>
    </div>

    <div class="">
      <mat-checkbox
        color="primary"
        [id]="'post-share-' + item.user.userId"
        (change)="selectItem($event, item)"
        [checked]="selected"
      ></mat-checkbox>
    </div>

  </div>
</ng-template>
