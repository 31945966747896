import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ImageEditor from 'tui-image-editor';
import * as colorPicker from 'tui-color-picker';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'svc-image-editor',
  templateUrl: './svc-image-editor.component.html',
  styleUrls: ['./svc-image-editor.component.scss'],
})
export class SvcImageEditorComponent implements OnInit, AfterViewInit {

  @Input() public image: string | Blob;
  @Output() public onSave = new EventEmitter<Blob>();
  @Output() public onCancel = new EventEmitter();

  public isLoading = false
  private instance: ImageEditor;
  private rImageType = /data:(image\/.+);base64,/;
  private brushColorpicker: any;
  private textColorpicker: any;
  private activeObjectId: number;
  private displayingSubMenu: HTMLElement;

  public get el(): HTMLElement { return this._elRef.nativeElement; }

  // Buttons
  public get btns(): NodeListOf<HTMLElement> { return this.el.querySelectorAll('.menu-item'); }
  public get btnsActivatable(): NodeListOf<HTMLElement> { return this.el.querySelectorAll('.menu-item.activatable'); }

  public get btnUndo(): HTMLElement { return document.querySelector('#btn-undo') };
  public get btnRedo(): HTMLElement { return document.querySelector('#btn-redo') };
  public get btnCrop(): HTMLElement { return document.querySelector('#btn-crop') };
  public get btnFlip(): HTMLElement { return document.querySelector('#btn-flip') };
  public get btnRotation(): HTMLElement { return document.querySelector('#btn-rotation') };
  public get btnDrawLine(): HTMLElement { return document.querySelector('#btn-draw-line') };
  public get btnApplyCrop(): HTMLElement { return document.querySelector('#btn-apply-crop') };
  public get btnFlipX(): HTMLElement { return document.querySelector('#btn-flip-x') };
  public get btnFlipY(): HTMLElement { return document.querySelector('#btn-flip-y') };
  public get btnResetFlip(): HTMLElement { return document.querySelector('#btn-reset-flip') };
  public get btnRotateClockwise(): HTMLElement { return document.querySelector('#btn-rotate-clockwise') };
  public get btnRotateCounterClockWise(): HTMLElement { return document.querySelector('#btn-rotate-counter-clockwise') };
  public get btnText(): HTMLElement { return document.querySelector('#btn-text') };
  public get btnTextStyle(): NodeListOf<HTMLButtonElement> { return this.el.querySelectorAll('.btn-text-style') };
  public get btnsClose(): NodeListOf<HTMLButtonElement> { return this.el.querySelectorAll('.menu-item.close') };

  // Range Input
  public get inputBrushWidthRange(): HTMLInputElement { return document.querySelector('#input-brush-width-range') };
  public get inputFontSizeRange(): HTMLInputElement { return document.querySelector('#input-font-size-range') };

  // Sub menu
  public get cropSubMenu(): HTMLInputElement { return document.querySelector('#crop-sub-menu'); }
  public get flipSubMenu(): HTMLInputElement { return document.querySelector('#flip-sub-menu'); }
  public get rotationSubMenu(): HTMLInputElement { return document.querySelector('#rotation-sub-menu'); }
  public get drawLineSubMenu(): HTMLInputElement { return document.querySelector('#draw-line-sub-menu'); }
  public get textSubMenu(): HTMLInputElement { return document.querySelector('#text-sub-menu'); }

  // Select blend type
  public get selectBlendType(): HTMLInputElement { return this.el.querySelector('[name="select-blend-type"]'); }

  constructor(
    private _elRef: ElementRef<HTMLElement>,
    private _translocoService: TranslocoService,
  ) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.instance = new ImageEditor('.tui-image-editor', {
      cssMaxWidth: 700,
      cssMaxHeight: 500,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
    });

    this.setImage();

    this.brushColorpicker = colorPicker.create({
      container: document.querySelector('#tui-brush-color-picker'),
      detailTxt: this._translocoService.translate('Alterar'),
      color: '#000000',
    });
    this.textColorpicker = colorPicker.create({
      container: document.querySelector('#tui-text-color-picker'),
      detailTxt: this._translocoService.translate('Alterar'),
      color: '#000000',
    });

    this.brushColorpicker.on('selectColor', (event) => {
      this.instance.setBrush({
        color: this.hexToRGBa(event.color),
        width: 1,
      });
    });

    this.instance.on('undoStackChanged', (length) => {
      if (length) {
        this.btnUndo.classList.remove('disabled');
      } else {
        this.btnUndo.classList.add('disabled');
      }
      this.resizeEditor();
    });
    this.instance.on('redoStackChanged', (length) => {
      if (length) {
        this.btnRedo.classList.remove('disabled');
      } else {
        this.btnRedo.classList.add('disabled');
      }
      this.resizeEditor();
    });
    this.instance.on('objectScaled', (obj) => {
      if (obj.type === 'text') {
        this.inputFontSizeRange.value = obj.fontSize;
      }
    });
    this.instance.on('addText', (pos) => {
      this.instance
        .addText('Text', {
          position: pos.originPosition,
        });
    });
    this.instance.on('objectActivated', (obj) => {
      this.activeObjectId = obj.id;
      if (obj.type === 'text') {
        this.showSubMenu('text');
        this.setTextToolbar(obj);
        this.activateTextMode();
      }
    });

    this.btns.forEach((btn) => btn.addEventListener('click', () => {
      btn.classList.remove('active');
    }));

    this.btnsActivatable.forEach((btn) => addEventListener('click', () => {
      btn.classList.add('active');
    }));

    this.btnUndo.addEventListener('click', () => {
      if (!this.btnUndo.classList.contains('disabled')) {
        this.instance.discardSelection();
        this.instance.undo();
      }
    });

    this.btnRedo.addEventListener('click', () => {
      if (!this.btnRedo.classList.contains('disabled')) {
        this.instance.discardSelection();
        this.instance.redo();
      }
    });

    this.btnCrop.addEventListener('click', () => {
      this.instance.startDrawingMode('CROPPER');
      this.displayingSubMenu?.classList.add('hidden');
      this.cropSubMenu.classList.remove('hidden');
      this.displayingSubMenu = this.cropSubMenu;
    });

    this.btnFlip.addEventListener('click', () => {
      this.instance.stopDrawingMode();
      this.displayingSubMenu?.classList.add('hidden');
      this.flipSubMenu.classList.remove('hidden');
      this.displayingSubMenu = this.flipSubMenu;
    });

    this.btnRotation.addEventListener('click', () => {
      this.instance.stopDrawingMode();
      this.displayingSubMenu?.classList.add('hidden');
      this.rotationSubMenu.classList.remove('hidden');
      this.displayingSubMenu = this.rotationSubMenu;
    });

    this.btnsClose.forEach(btn => btn.addEventListener('click', () => {
      this.instance.stopDrawingMode();
      this.displayingSubMenu?.classList.add('hidden');
    }));

    this.btnApplyCrop.addEventListener('click', () => {
      this.instance.crop(this.instance.getCropzoneRect()).then(() => {
        this.displayingSubMenu?.classList.add('hidden');
        this.instance.stopDrawingMode();
        this.resizeEditor();
      });
    });

    this.btnFlipX.addEventListener('click', () => {
      this.instance.flipX();
    });

    this.btnFlipY.addEventListener('click', () => {
      this.instance.flipY();
    });

    this.btnResetFlip.addEventListener('click', () => {
      this.instance.resetFlip();
    });

    this.btnRotateClockwise.addEventListener('click', () => {
      this.instance.rotate(90);
    });

    this.btnRotateCounterClockWise.addEventListener('click', () => {
      this.instance.rotate(-90);
    });

    this.inputBrushWidthRange.addEventListener('change', () => {
      this.instance.setBrush({ width: parseInt(this.inputBrushWidthRange.value, 10) } as any);
    });

    // control draw line mode
    this.btnDrawLine.addEventListener('click', () => {
      this.instance.stopDrawingMode();
      this.displayingSubMenu?.classList.add('hidden');
      this.drawLineSubMenu.classList.remove('hidden');
      this.displayingSubMenu = this.drawLineSubMenu;
      this.instance.startDrawingMode(
        'FREE_DRAWING',
        this.getBrushSettings(),
      );
    });

    this.brushColorpicker.on('selectColor', (event) => {
      this.instance.setBrush({
        ...this.getBrushSettings(),
        color: this.hexToRGBa(event.color),
      } as any);
    });

    // control text mode
    this.btnText.addEventListener('click', () => {
      this.showSubMenu('text');
      this.activateTextMode();
    });

    this.inputFontSizeRange.addEventListener('change', () => {
      this.instance.changeTextStyle(this.activeObjectId, {
        fontSize: parseInt(this.inputFontSizeRange.value, 10),
      });
    });

    this.btnTextStyle.forEach(btn => btn.addEventListener('click', (e) => {
      let styleType = btn.getAttribute('data-style-type');
      let styleObj;

      e.stopPropagation();

      switch (styleType) {
        case 'b':
          styleObj = { fontWeight: 'bold' };
          break;
        case 'i':
          styleObj = { fontStyle: 'italic' };
          break;
        case 'u':
          styleObj = { underline: true };
          break;
        case 'l':
          styleObj = { textAlign: 'left' };
          break;
        case 'c':
          styleObj = { textAlign: 'center' };
          break;
        case 'r':
          styleObj = { textAlign: 'right' };
          break;
        default:
          styleObj = {};
      }

      this.instance.changeTextStyle(this.activeObjectId, styleObj);
    }));

    this.textColorpicker.on('selectColor', (event) => {
      this.instance.changeTextStyle(this.activeObjectId, {
        fill: event.color,
      });
    });
  }

  public save() {
    let dataURL = this.instance.toDataURL();
    let blob = this.base64ToBlob(dataURL);
    this.onSave.emit(blob);
  }

  public cancel() {
    this.onCancel.emit();
  }

  private setImage() {
    this.isLoading = true;
    if (typeof this.image === 'string') {
      this.instance.loadImageFromURL(this.image, 'image').then((sizeValue) => {
        this.isLoading = false;
        this.instance.clearUndoStack();
      });
    }
    else {
      this.instance.loadImageFromFile(new File([this.image], 'image')).then((sizeValue) => {
        this.isLoading = false;
        this.instance.clearUndoStack();
      });
    }
  }

  private resizeEditor() {
    const editor = this.el.querySelector('.tui-image-editor') as HTMLElement;
    const container = this.el.querySelector('.tui-image-editor-canvas-container') as HTMLElement;
    const height = container.style.maxHeight;

    editor.style.height = height;
  };

  private hexToRGBa(hex: string, alpha?: number) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    let a = alpha || 1;

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
  }

  private base64ToBlob(data) {
    let mimeString = '';
    let raw, uInt8Array, i, rawLength;

    raw = data.replace(this.rImageType, (header, imageType) => {
      mimeString = imageType;
      return '';
    });

    raw = atob(raw);
    rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength);

    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
  }

  private getBrushSettings() {
    var brushWidth = parseInt(this.inputBrushWidthRange.value);
    var brushColor = this.brushColorpicker.getColor();

    return {
      width: brushWidth,
      color: this.hexToRGBa(brushColor),
    };
  }

  private activateTextMode() {
    if (this.instance.getDrawingMode() !== 'TEXT') {
      this.instance.stopDrawingMode();
      this.instance.startDrawingMode('TEXT');
      this.instance.changeTextStyle(this.activeObjectId, {
        fontFamily: 'Roboto',
      });
    }
  }

  private setTextToolbar(obj) {
    var fontSize = obj.fontSize;
    var fontColor = obj.fill;

    this.inputFontSizeRange.value = fontSize;
    this.textColorpicker.setColor(fontColor);
  }

  private showSubMenu(type) {
    let submenu;

    switch (type) {
      case 'text':
        submenu = this.textSubMenu;
        break;
      default:
        return;
    }

    this.displayingSubMenu?.classList.add('hidden');
    submenu.classList.remove('hidden');
    this.displayingSubMenu = submenu;
  }
}
