import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable } from "rxjs";
import * as screenSizes from 'tailwind/screenSizes';

export enum MediaQueryBreakpointStart {
	SM = screenSizes.SM,
	MD = screenSizes.MD,
	LG = screenSizes.LG,
	XL = screenSizes.XL,
}

export interface MediaQuerySize {
	isXS: boolean;
	isSM: boolean;
	isMD: boolean;
	isLG: boolean;
	isXL: boolean;
	isAboveXS: boolean;
	isAboveSM: boolean;
	isAboveMD: boolean;
	isBelowMD: boolean;
	isAboveLG: boolean;
	isBelowLG: boolean;
	isBelowXL: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class SvcMediaQuery {

	private _size = new BehaviorSubject<MediaQuerySize>(this._getMediaQuerySize());
	public get size$(): Observable<MediaQuerySize> {
		return this._size.asObservable();
	}
	public get currentSize(): MediaQuerySize {
		return this._size.value;
	}

	constructor() {
    fromEvent(window, 'resize').subscribe(() => this._onWindowResize());
	}

	private _onWindowResize() {
		const size = this._getMediaQuerySize();
		if (JSON.stringify(size) !== JSON.stringify(this._size.value)) {
			this._size.next(size);
		}
	}

	private _getMediaQuerySize(): MediaQuerySize {
		const width = document.body?.parentElement?.clientWidth ?? window.innerWidth;
		return {
			isXS: width < MediaQueryBreakpointStart.SM,
			isSM: width >= MediaQueryBreakpointStart.SM && width < MediaQueryBreakpointStart.MD,
			isMD: width >= MediaQueryBreakpointStart.MD && width < MediaQueryBreakpointStart.LG,
			isLG: width >= MediaQueryBreakpointStart.LG && width < MediaQueryBreakpointStart.XL,
			isXL: width >= MediaQueryBreakpointStart.XL,
			isAboveXS: width >= MediaQueryBreakpointStart.SM,
			isAboveSM: width >= MediaQueryBreakpointStart.MD,
			isAboveMD: width >= MediaQueryBreakpointStart.LG,
			isBelowMD: width < MediaQueryBreakpointStart.MD,
			isAboveLG: width >= MediaQueryBreakpointStart.XL,
			isBelowLG: width < MediaQueryBreakpointStart.LG,
			isBelowXL: width < MediaQueryBreakpointStart.XL,
		};
	}
}