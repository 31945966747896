<div class="grid">
  <div class="flex flex-col mb-4 w-full">
    <mat-slider
      color="primary"
      id="ratingSlider"
      class="slider"
      [disabled]="false"
      [min]="1"
      [max]="options.length"
      [step]="1"
      [showTickMarks]="false"
    >
      <input
        [ngClass]="{ 'pointer-events-none': readonly }"
        class="p-0 left-0 opacity-0"
        #sliderRef
        matSliderThumb
        [(ngModel)]="value"
        (input)="onRatingChange()"
      />
    </mat-slider>

    <div class="labels" [ngClass]="{ 'pointer-events-none': readonly }">
      <label
        #labels
        class="text-xs font-normal cursor-pointer"
        *ngFor="let option of options; let i = index"
        [id]="'label' + (i + 1)"
        (click)="value = i + 1; onRatingChange()"
        [matTooltip]="
          option.controls.description?.value?.length > 10
            ? option.controls.description?.value
            : ''
        "
      >
        {{ option.controls.description?.value | ellipsis : 10 }}
      </label>
    </div>
  </div>
</div>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
