import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { SitesService, SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Subscription } from 'rxjs';
import { SvcTotalsStatusService } from './svc-totals-status.service';
import { environment } from 'projects/environments/environment';

@Component({
  selector: 'svc-totals-status',
  templateUrl: './svc-totals-status.component.html',
  styleUrls: ['./svc-totals-status.component.scss'],
})
export class SvcTotalsStatusComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public referenceId: number | string;
  @Input() public applicationId: string;
  @Input() public configTypeId: string;
  @Input() public featureName: string;
  @Input() public siteId: number;
  @Input() public external: boolean = false;
  @Input() public type: 'text' | 'icon' = 'icon';
  @Input() public mockMode: boolean = false;
  @Input() public hideIfEmpty: boolean = true;
  @Input() public available = {
    reactions: true,
    comments: true,
    views: true,
  };
  @Input() public classColor = 'text-default';
  @Input() public classLoading: string;

  @Output() public reactionsClick = new EventEmitter<void>();
  @Output() public commentsClick = new EventEmitter<void>();
  @Output() public viewsClick = new EventEmitter<void>();

  private _wasInitialized = false;
  private _requestSubscription: Subscription;

  public env = environment;
  public isLoading = false;
  public totals = {
    reactions: 0,
    comments: 0,
    views: 0,
  };

  constructor(
    private _appSettings: SvcAppSettings,
    private _sitesService: SitesService,
    private _totalsStatusService: SvcTotalsStatusService,
  ) {
  }

  public ngOnInit(): void {
    this._get();
    this._wasInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._wasInitialized && ('referenceId' in changes || 'applicationId' in  changes || 'featureName' in  changes || 'configTypeId' in  changes)) {
      this._get();
    }
  }

  private _get(only?: { reactions?: boolean, comments?: boolean, views?: boolean }) {
    if (!this.mockMode) {
      only = only ?? this.available;
      this.isLoading = true;
      this._requestSubscription?.unsubscribe();
      this._requestSubscription = this._totalsStatusService.getTotals({
        applicationId: this.applicationId ?? this._appSettings.applicationId,
        siteId: this.siteId ?? this._sitesService.currentSite.siteId,
        referenceId: this.referenceId,
        configTypeId: this.configTypeId,
        featureName: this.featureName,
        avoid: {
          reactions: !(only?.reactions ?? true) || !(this.available?.reactions ?? true),
          comments: !(only?.comments ?? true) || !(this.available?.comments ?? true),
          views: !(only?.views ?? true) || !(this.available?.views ?? true),
        }
      }, this.external).pipe(
        tap((response) => {
          this.totals.reactions = response.reactions ?? this.totals.reactions;
          this.totals.comments = response.comments ?? this.totals.comments;
          this.totals.views = response.views ?? this.totals.views;
        }),
        finalize(() => this.isLoading = false),
      ).subscribe();
    }
    else {
      this.totals = {
        reactions: 68,
        comments: 26,
        views: 250,
      }
    }
  }

  public refresh(only?: { reactions?: boolean, comments?: boolean, views?: boolean }) {
    this._get(only);
  }

  ngOnDestroy(): void {
    this._requestSubscription?.unsubscribe();
  }
}
