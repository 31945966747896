<div class="flex space-x-2">
  <div *ngIf="(available?.reactions) ?? true && (!hideIfEmpty || totals?.reactions > 0)" class="flex text-default font-medium items-center" [class.cursor-pointer]="reactionsClick.observed" [matTooltip]="'Reações' | transloco" matTooltipPosition="above" [matTooltipDisabled]="type !== 'icon'" [class]="classColor" (click)="!isLoading && reactionsClick.emit()">
    <mat-icon *ngIf="type == 'icon'" class="icon-size-3.5 text-current mr-2" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
    <span [ngClass]="type === 'text' ? 'text-sm' : 'text-xs'">{{ totals?.reactions }}</span>
    <div *ngIf="type == 'text'" class="ml-1 text-sm">{{ totals?.reactions > 1 ? ('Reações' | transloco) : ('Reação' | transloco) }}</div>
  </div>
  <div *ngIf="(available?.comments) ?? true && (!hideIfEmpty || totals?.comments > 0)" class="flex text-default font-medium items-center" [class.cursor-pointer]="commentsClick.observed" [matTooltip]="'Comentários' | transloco" matTooltipPosition="above" [matTooltipDisabled]="type !== 'icon'" [class]="classColor" (click)="!isLoading && commentsClick.emit()">
    <mat-icon *ngIf="type == 'icon'" class="icon-size-3.5 text-current mr-2" svgIcon="mat_solid:chat"></mat-icon>
    <span [ngClass]="type === 'text' ? 'text-sm' : 'text-xs'">{{ totals?.comments }}</span>
    <div *ngIf="type == 'text'" class="ml-1 text-sm">{{ totals?.comments > 1 ? ('Comentários' | transloco) : ('Comentário' | transloco) }}</div>
  </div>
  <div *ngIf="(available?.views ?? true) && (!hideIfEmpty || totals?.views > 0)" class="flex text-default font-medium items-center" [class.cursor-pointer]="viewsClick.observed" [matTooltip]="'Visualizações' | transloco" matTooltipPosition="above" [matTooltipDisabled]="type !== 'icon'" [class]="classColor" (click)="!isLoading && viewsClick.emit()">
    <mat-icon *ngIf="type == 'icon'" class="icon-size-3.5 text-current mr-2" svgIcon="mat_solid:visibility"></mat-icon>
    <span [ngClass]="type === 'text' ? 'text-sm' : 'text-xs'">{{ totals?.views }}</span>
    <div *ngIf="type == 'text'" class="ml-1 text-sm">{{ totals?.views > 1 ? ('Visualizações' | transloco) : ('Visualização' | transloco) }}</div>
  </div>
</div>
<div *ngIf="isLoading" class="totals-status-loading shimmer" [class]="classLoading"></div>
