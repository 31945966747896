import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcDrilldownComponent } from './svc-drilldown.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcDataTableModule } from '../svc-data-table/svc-data-table.module';
import { SvcButtonsModule } from '../svc-buttons/svc-buttons.module';
import { SvcStatusBadgeModule } from '../svc-status-badge/svc-status-badge.module';

@NgModule({
  declarations: [
    SvcDrilldownComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDataTableModule,
    SvcButtonsModule,
    SvcStatusBadgeModule,
  ],
  exports: [
    SvcDrilldownComponent,
  ]
})
export class SvcDrilldownModule { }
