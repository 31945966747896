<div class="flex-auto" *screenSizes="let size">
  <div class="flex flex-row justify-between items-center gap-4">
    <div class="flex flex-auto gap-2 items-center">
      <mat-form-field *ngIf="showSearch" class="svc-mat-no-subscript svc-mat-dense w-full" [style.maxWidth.px]="size.isAboveSM ? 230 : null">
        <input [formControl]="searchCtrl" matInput [placeholder]="'Pesquisar' | transloco" type="search"  id="view-panel-search-text"/>
        <mat-icon class="icon-size-4" svgIcon="heroicons_solid:magnifying-glass"></mat-icon>
      </mat-form-field>
      <div class="flex flex-row gap-2">
        <svc-button *ngIf="showFilter" icon-only color="primary" size="sm" [mode]="hasFilter ? 'flat' : 'stroked'" (click)="onSearchEventClicked.emit()" buttonId="view-panel-search-button">
          <mat-icon class="icon-size-4" color="primary" svgIcon="heroicons_solid:funnel"></mat-icon>
        </svc-button>

        <svc-button *ngIf="showFilterFavorites" icon-only color="primary" size="sm" [mode]="filterFavorites ? 'flat' : 'stroked'" (click)="filterFavorites = !filterFavorites; onFilterFavoritesEventClicked.emit(filterFavorites)" buttonId="view-panel-favorite">
          <mat-icon [ngClass]="[filterFavorites ? 'text-on-primary' : 'text-primary' ]" class="icon-size-4" svgIcon="heroicons_solid:star"></mat-icon>
        </svc-button>
      </div>
    </div>

    <div class="h-fit empty:hidden">
      <svc-view-toggle *ngIf="viewButtons?.length" class="inline-flex align-middle" [view]="activeView" [viewButtons]="viewButtons" (onViewChanged)="onActiveViewChanged($event)"></svc-view-toggle>
      <svc-view-panel-export *ngIf="showExport && size.isAboveSM" [config]="export" class="inline-flex align-middle" [class.ml-1]="viewButtons?.length"></svc-view-panel-export>
    </div>
  </div>

  <div class="flex flex-col relative pt-6">
    <div *ngFor="let view of views;" [ngClass]="view.customClass">
      <ng-container *ngIf="view.id === activeView" [ngTemplateOutlet]="view.templateRef"></ng-container>
    </div>
  </div>
</div>
