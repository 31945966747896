export function cookieExist(cookieName: string){
  const a = `; ${document.cookie}`.match(`;\\s*${cookieName}=([^;]+)`);
  return !(a == null);
}

export function getCookie(cookieName: string){
  const a = `; ${document.cookie}`.match(`;\\s*${cookieName}=([^;]+)`);
  return a ? a[1] : '';
}

export function deleteCookie(cookieName: string) {
  const expireDate = new Date();
  expireDate.setTime(expireDate.getTime() - (24*60*60*1000));
  document.cookie = `${cookieName}='';expires=${expireDate};path=/`;
}

export function setCookie(cookieName: string, cookieValue: string | number) {
  document.cookie = `${cookieName}=${cookieValue};path=/`;
}

export function setCookieWithOptions(cookieName: string, cookieValue: string | number, cookieExpiryTime: string, cookieDomain: string) {
  document.cookie = `${cookieName}=${cookieValue};expires=${new Date(cookieExpiryTime)};domain=${cookieDomain};path=/`;
}

