import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcElementsBoardComponent } from './svc-elements-board.component';
import { SvcElementsBoardItemDirective } from './svc-elements-board-item.directive';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcButtonsModule } from '../svc-buttons/svc-buttons.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    SvcElementsBoardComponent,
    SvcElementsBoardItemDirective,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcButtonsModule,
    TranslocoModule,
  ],
  exports: [
    SvcElementsBoardComponent,
    SvcElementsBoardItemDirective,
  ]
})
export class SvcElementsBoardModule { }
