import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvcControl } from "../svc-control";

@Component({
  selector: 'svc-text-area',
  templateUrl: './svc-text-area.component.html',
  styleUrls: ['./svc-text-area.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcTextAreaComponent),
    multi: true
  }]
})
export class SvcTextAreaComponent extends SvcControl implements OnInit {
  constructor(
    @Inject(Injector) injector: Injector,
    private _cd: ChangeDetectorRef
  ) {
    super(injector);
  }

  @Input() label: string | { text: string, icon: string };
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() autosize: boolean = true;
  @Input() minRows: number = 1;
  @Input() maxRows: number = 10;
  @Input() placeholder: string;
  @Input() charCount: boolean = false;
  @Input() maxLength: number;
  @Input() inputId: string;
  @Input() fallbackTextValue: string;
  @Input() autoFocus: boolean;

  @Output() ngModelChange = new EventEmitter<any>();
  @Output() blur = new EventEmitter<void>();

  @ViewChild('textArea') set textArea(element: ElementRef) {
    if (element && this.autoFocus) {
      element?.nativeElement?.focus();
      this._cd.detectChanges();
    }
  };

  ngOnInit() {
    super.ngOnInit();
    this.charCount = typeof this.charCount == 'string' ? !this.charCount || this.charCount == 'true' : this.charCount;
  }

  protected onValueChanged(value): void {
    this.ngModelChange.emit(value);
  }
}
