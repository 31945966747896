<ng-container *ngIf="_iconItem.lib === 'heroIcons'">
  <mat-icon
    [ngClass]="_iconSizeClass"
    [svgIcon]="type === 'solid' ? _iconItem.libIconName.solid : _iconItem.libIconName.outline"
  ></mat-icon>
</ng-container>

<ng-container *ngIf="_iconItem.lib === 'material'">

</ng-container>

<ng-container *ngIf="_iconItem.lib === 'fontawesome'">

</ng-container>


