import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { HttpClient } from '@angular/common/http';
import { PraiseEligibility } from './models/praise-eligibility';
import { PraiseItem } from '../../models/praise-item.model';

@Injectable()
export class PraiseItemService {

  private get baseUrl() {
    return this._appConfig.APIs.apiUrlPraise;
  }

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _httpClient: HttpClient,
  ) { }

  getPraiseById(praiseId: number): Observable<PraiseItem> {
    return this._httpClient.get<PraiseItem>(`${this.baseUrl}/Praise/${praiseId}`);
  }

  deletePraise(id: string) {
    return this._httpClient.delete(`${this.baseUrl}/Praise/${id}`);
  }

  getEligibilityById(id: number) {
    return this._httpClient.get(`${this.baseUrl}/Praise/${id}/user/eligibility`).pipe(
      map((response: any) => response.eligibility as PraiseEligibility)
    );
  }
}
