import {Component, Input, OnInit} from '@angular/core';
import {ISvcAvatar, ISvcAvatarMultiple} from "./interfaces/svc-avatar-multiple.interface";

@Component({
  selector: 'svc-avatar-multiple',
  templateUrl: './svc-avatar-multiple.component.html',
  styleUrls: ['./svc-avatar-multiple.component.scss']
})
export class SvcAvatarMultipleComponent implements OnInit {

  @Input() info: ISvcAvatarMultiple;
  @Input('max-size') maxSize: number = 1;
  @Input('border-color') borderColor: string = 'white';

  public avatars: ISvcAvatar[];

  ngOnInit() {
    this.avatars = this.info.data.map((d) => ({
      name: d.name,
      picture: d.picture,
      size: this.info.size,
      type: d.type,
      initialsColor: d.initialsColor ?? this.info.initialsColor
    }));
  }
}
