import { Component, ViewChild } from '@angular/core';
import { SvcDataTableEvent } from '../models/svc-filter-data.model';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { OrderByPipe, SvcFunctionsHelper } from 'projects/lib-shared-common/src/public-api';
import { DatePipe } from '@angular/common';

/* 
	INSTRUCTIONS TO USE:

	To use this component, make sure your module:
		1. imports SvcDataTableModule
		2. provides SvcDataPrepareService
		3. provides SvcDataVirtualScrollService
*/
@Component({
	selector: 'app-svc-data-table-example',
	templateUrl: './svc-data-table-example.component.html',
	styleUrls: ['./svc-data-table-example.component.scss'],
})
export class SvcDataTableExampleComponent {
	@ViewChild('dt') public table: SvcDataTableComponent;

	public examples: any[] = [
		{ id: 1, name: 'Data client-side (paginate)' },
		{ id: 2, name: 'Data server-side (paginate)' },
		{ id: 3, name: 'Data server-side (infinite)' },
	];
	public example: any = this.examples[0];

	private allDataTeste: any[] = [];
	public items: any[] = [];

	public types = ['Tipo 1', 'Tipo 2', 'Tipo 3'];
	public dataAreOver: boolean = false;
	public dataLength: number = 0;
	public loading: boolean = false;

	public pageSize: number = 25;
	public currentPage: number = 1;

	private lastTableEvent: SvcDataTableEvent;
	public selectAllValue: boolean;

	constructor(
		private helper: SvcFunctionsHelper,
		private datePipe: DatePipe,
	) {
		this.prepareExampleData();
	}

	prepareExampleData() {
		if (!this.example) this.example = this.examples[0];

		let type = 1;
		let date = new Date();

		if (this.example.id === 1) {
			this.allDataTeste = [];
			this.loading = true;
			setTimeout(() => {
				this.items = Array.from({ length: 1000 }).map((x, i) => {
					let number = i + 1;
					if (type < 3) type++;
					else type = 1;

					date = date.addDays(-1);
					return {
						id: `${number}`,
						name: `Teste ${number}`,
						dateRef: this.datePipe.transform(date, 'yyyy-MM-dd'),
						type: `Tipo ${type}`,
						height: Math.floor(Math.random() * 50),
					};
				});
				this.loading = false;
			}, 1200);
		} else {
			this.items = [];
			this.dataAreOver = false;
			this.dataLength = 0;
			this.allDataTeste = Array.from({ length: 105 }).map((x, i) => {
				let number = i + 1;
				if (type < 3) type++;
				else type = 1;

				date = date.addDays(-1);
				return {
					id: `${number}`,
					name: `Teste ${number}`,
					dateRef: this.datePipe.transform(date, 'yyyy-MM-dd'),
					type: `Tipo ${type}`,
					height: Math.floor(Math.random() * 100),
				};
			});
		}
	}

	setExample(id: number) {
		this.example = this.examples.find(x => x.id === id);
		this.prepareExampleData();
	}

	getData(event: SvcDataTableEvent) {
		this.lastTableEvent = event;
		if (!this.loading) {
			if (this.example.id === 2) {
				this.loading = true;
				setTimeout(() => {
					let _testes = this.allDataTeste.filter(x => {
						let ok = true;
						for (let field in event.filters) {
							let filterValue = event.filters[field];
							if (filterValue) {
								filterValue = (filterValue.toString() as string).toUpperCase();
								ok = x[field] && (x[field].toString() as string).toUpperCase().includes(filterValue);
							}

							if (!ok) break;
						}

						return ok;
					});
					this.dataLength = this.allDataTeste.length;

					if (event.order) _testes = new OrderByPipe().transform(_testes, event.order.field, event.order.asc);

					let begin = (event.page - 1) * event.pageSize;
					let end = begin + event.pageSize;

					this.items = _testes.slice(begin, end);

					this.loading = false;
				}, 1500);
			}

			if (this.example.id === 3) {
				if (!this.dataAreOver || event.resetData) {
					this.loading = true;
					if (event.resetData) this.items = [];
					setTimeout(() => {
						let _testes = this.allDataTeste.filter(x => {
							let ok = true;
							for (let field in event.filters) {
								let filterValue = event.filters[field];
								if (filterValue) {
									filterValue = (filterValue.toString() as string).toUpperCase();
									ok =
										x[field] && (x[field].toString() as string).toUpperCase().includes(filterValue);
								}

								if (!ok) break;
							}

							return ok;
						});
						this.dataLength = _testes.length;

						if (event.order)
							_testes = new OrderByPipe().transform(_testes, event.order.field, event.order.asc);

						let begin = (event.page - 1) * event.pageSize;
						let end = begin + event.pageSize;

						_testes = _testes.slice(begin, end);

						this.dataAreOver = !_testes.length;

						this.items = [...this.items, ..._testes];

						this.loading = false;
					}, 1500);
				}
			}
		}
	}

	public removeItem(item: any) {
		this.loading = true;
		setTimeout(() => {
			this.loading = false;
			this.allDataTeste = this.allDataTeste.filter(x => x !== item);
			this.getData(this.lastTableEvent);
		}, 200);
	}

	public tableSelectAll(value: boolean): void {
		this.selectAllValue = value;
	}

	public onDragReorder(event) {
		console.log(event);
	}
}
