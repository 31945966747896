<div class="date-picker-range-calendar">
  <div class="calendar-header bg-primary flex flex-col gap-9">
    <p class="text-sm font-medium text-white uppercase tracking-[1.5px] leading-4">
      {{ 'Data selecionada' | transloco }}
    </p>

    <p class="text-white text-[34px] capitalize leading-9">
      {{ (startDate ?? matCalendar?.activeDate) | date: 'MMMM' | transloco }}
      <ng-container *ngIf="startDate">
        {{ startDate | date: 'yy' }}
      </ng-container>
    </p>
  </div>

  <mat-calendar #matCalendar
    [minDate]="minDate"
    [maxDate]="maxDate"
    [(selected)]="currentDate"
    [startAt]="currentDate"
    [headerComponent]="customCalendarHeader"
    (selectedChange)="onSelect.emit($event)"
  >
  </mat-calendar>

  <div class="flex justify-end gap-2 px-4 pt-2 pb-2.5">
    <button mat-button class="action-button text-sm" (click)="closeCalendar.emit(true)">
      {{ 'Cancelar' | transloco }}
    </button>

    <button mat-button class="action-button" (click)="closeCalendar.emit(false)">
      {{ 'Ok' | transloco }}
    </button>
  </div>
</div>
