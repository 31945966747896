import {EChartsOption, TooltipComponentOption} from 'echarts';

export interface SvcChartBarConfig extends Partial<EChartsOption> {}
export interface SvcChartTooltipOption extends TooltipComponentOption {}

export interface ISvcChartBarInfo {
  loading: boolean;
  error: any;
}

export interface ISvcChartBarDefinitions {
  categories: { label: string, error?: boolean }[];
  series: ISvcChartBarSerie[];
  showLegend?: boolean;
  tooltip?: SvcChartTooltipOption;
}

export interface ISvcChartBarSerie {
  name: string;
  color: string;
  values: any[];
  minHeight?: number;
  maxWidth?: number;
  minWidth?: number;
  label?: any;
}

export enum ISvcChartBarOrientation {
  HORIZONTAL,
  VERTICAL
}



