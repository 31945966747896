import {Routes} from "@angular/router";
import { LayoutComponent } from "../ui/layout/layout.component";
import { AmplifyGuard, AuthGuard, NoAuthGuard, NotFound404Component } from "projects/lib-shared-core/src/public-api";


export const authRoutes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        canActivate: [NoAuthGuard, AmplifyGuard],
        path: 'forgot-password',
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then(
            (m) => m.AuthForgotPasswordModule
          ),
      },
      {
        canActivate: [NoAuthGuard, AmplifyGuard],
        path: 'reset-password',
        loadChildren: () =>
          import('./reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        canActivate: [NoAuthGuard],
        path: 'sign-in',
        loadChildren: () =>
          import('./sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'user-locked',
        loadChildren: () =>
          import('./user-locked/user-locked.module')
            .then((m) => m.UserLockedModule)
      }
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('./sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'expired-password',
        loadChildren: () =>
          import('./expired-password/expired-password.module').then(
            (m) => m.AuthExpiredPasswordModule
          ),
      },
    ],
  },
  {
    path: '**',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: '**', component: NotFound404Component },
    ],
  }
];
