import { Directive, EmbeddedViewRef, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaQuerySize, SvcMediaQuery } from '../services/svc-media-query.service';
import { Subject, takeUntil, tap } from 'rxjs';

class ScreenSizesContext {
	public $implicit: MediaQuerySize = null!;
}

@Directive({
	selector: '[screenSizes]',
})
export class ScreenSizesDirective implements OnDestroy {
	private size: MediaQuerySize = <MediaQuerySize>{};
	private view: EmbeddedViewRef<ScreenSizesContext> | undefined;
    private _unsubscribe: Subject<any> = new Subject<any>();

	@Input() screenSizes: string;

	static ngTemplateGuard_screenSizes: 'binding';

	constructor(
		private _viewContainer: ViewContainerRef,
		private _templateRef: TemplateRef<ScreenSizesContext>,
		private _mediaQuery: SvcMediaQuery,
	) {
		this._mediaQuery.size$.pipe(
            takeUntil(this._unsubscribe),
			tap((size) => {
				this.size = size;
				if (this.view) {
					this.view.context.$implicit = this.size;
				}
				else {
					this.view = this._viewContainer.createEmbeddedView(this._templateRef!, {
						$implicit: this.size,
					});
				}
			}),
		).subscribe();
	}

	static ngTemplateContextGuard(dir: ScreenSizesDirective, ctx: any): ctx is ScreenSizesContext {
		return true;
	}

    public ngOnDestroy(): void {
        this._unsubscribe.next(null);
        this._unsubscribe.complete();
    }
}