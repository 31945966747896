<svc-dialog dialogStyle="empty" [hasActions]="false">
	<div class="text-xl font-semibold" svc-dialog-title>
		{{ 'Reações' | transloco }}
	</div>

	<div svc-dialog-content class="text-sm text-secondary px-5 py-2 border-b">
		<div class="flex">
			<div class="text-sm mr-3">
				<a href="javascript:void(0)" class="font-semibold text-primary cursor-pointer" (click)="clearFilter()">{{ 'Tudo' | transloco }}</a>
			</div>

      <div *ngFor="let reactionType of reactionsTypeReacted"
        class="flex flex-row text-xs mr-3 align-items-center cursor-pointer" 
        [ngClass]="{ 'active': reactionType.reactionUniqueId === currentReactionType?.reactionUniqueId }" 
        (click)="selectReactionType(reactionType)"
      >
        <img [src]="reactionType.staticImageUrl" class="w-4 h-4 flex-auto" />
        <span class="ml-1">{{ reactionType.users.length }}</span>
      </div>
		</div>
	</div>

  <div svc-dialog-content class="py-5 px-3 reactions-list space-y-2">
    <ng-container *ngFor="let reaction of filteredReactions">
      <div *ngFor="let user of reaction.users">
        <div class="flex space-x-3">
          <div class="flex relative">
            <svc-user-avatar
              [info]="{ name: user.firstLastName, picture: user.pictureUrl, size: 48, initialsColor: user.preferenceColor ?? initialsColor }"
            ></svc-user-avatar>

            <div class="reaction-avatar">
              <img [src]="reaction.staticImageUrl" />
            </div>
          </div>

          <div class="flex flex-col flex-auto justify-center pt-1">
            <p class="text-base font-semibold">{{ user.firstLastName }}</p>
            <p class="text-base">{{ user.role }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</svc-dialog>
