<div>
  <div>
    <quill-editor
      #editor
      class="mentions-editor w-full"
      [class.readonly]="readOnly"
      [class.cursor-force]="cursorForce"
      [class.line-clamp]="lineClamp"
      classes="mentions-container"
      [modules]="modules"
      [(ngModel)]="value"
      [readOnly]="readOnly"
      [placeholder]="!readOnly ? placeholder : null"
      (ngModelChange)="onChange(value)"
      (onContentChanged)="contentChanged($event)"
      [id]="inputId"
    ></quill-editor>
    <!-- <div
      *ngIf="lineClamp && _showMore"
      class="-mt-1 leading-none text-primary font-bold cursor-pointer"
    >{{'Ver mais' | transloco}}</div> -->
  </div>

</div>
