import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService, getCustomThemeColor } from 'projects/lib-shared-common/src/public-api';
import { ISvcAvatarMultiple, SvcDialogService, SvcToastService, UserAvatarSize, UserAvatarType } from 'projects/lib-shared-component/src/public-api';
import { SettingsService, StaticApplicationId, SvcViewsService, UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcReactionComponent } from '../../../general/svc-reaction/svc-reaction.component';
import { SvcCommentsChatComponent } from '../../../general/svc-comments/components/svc-comments-chat/svc-comments-chat.component';
import { SvcCommentsTotalComponent } from '../../../general/svc-comments/components/svc-comments-total/svc-comments-total.component';
import { SvcTotalsStatusComponent } from '../../../general/svc-totals-status/svc-totals-status.component';
import { environment } from 'projects/environments/environment';
import { PraiseItemCard } from './models/praise-item-card.model';
import { PraiseEligibility } from './models/praise-eligibility';
import { PraiseItemService } from './praise-item.service';

@Component({
  selector: 'app-modal-praise-item-detail',
  templateUrl: './modal-praise-item-detail.component.html',
  styleUrls: ['./modal-praise-item-detail.component.scss'],
})
export class ModalPraiseDetailComponent implements OnInit {
  @ViewChild(SvcReactionComponent) public svcReaction: SvcReactionComponent;
  @ViewChild(SvcCommentsChatComponent) public svcCommentsChatComponent: SvcCommentsChatComponent;
  @ViewChild(SvcCommentsTotalComponent) public svcCommentsTotal: SvcCommentsTotalComponent;
  @ViewChild(SvcTotalsStatusComponent) public svcTotalsStatus: SvcTotalsStatusComponent;

  public form: FormGroup;
  public item: PraiseItemCard;
  public env = environment;

  public praiseId: number;
  public loading = false;
  public isReactionLoading = false;
  public isReactionDone = false;
  public showComments = false;
  public isSettingView = true;

  public isUserLoggedPraise = false;
  public eligibility: PraiseEligibility;

  public applicationId = StaticApplicationId.praise;
  public configType = '26B0FC9D-AE9D-477F-BC14-E4D68D6E4DAC';

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _dialogRef: MatDialogRef<ModalPraiseDetailComponent>,
    private _settingsService: SettingsService,
    private _praiseItemService: PraiseItemService,
    private _userService: UserService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
    private _dialogService: SvcDialogService,
    private _httpErrorService: HttpErrorService,
    private _svcViewsService: SvcViewsService,
  ) {
    this.praiseId = +(this.dialogData?.praise?.praiseId ?? this.dialogData?.id);
  }

  ngOnInit(): void {
    this.loading = true;
    this._praiseItemService.getEligibilityById(this.praiseId).pipe(
      tap((eligibility) => {
        this.eligibility = eligibility;
        this._getPraise();
      }),
      catchError((error) => {
        this._getPraise();
        return error;
      }),
      takeUntil(this.destroy$),
    ).subscribe();

    if (this.dialogData?.openComments) {
      this.showCommentsChat();
    }
  }

  private _getPraise() {
    if (typeof this.dialogData === 'object' && 'praise' in this.dialogData) {
      this.loading = false;
      this.processPraiseData(this.dialogData.praise);
      this.setView();
    } else if (typeof this.dialogData === 'object' && 'id' in this.dialogData) {
      this.loading = true;
      this._praiseItemService.getPraiseById(this.praiseId)
        .pipe(
          tap(praise => {
            this.processPraiseData(praise);
          }),
          catchError((error) => {
            this._toastService.error(this._translocoService.translate(this._httpErrorService.getErrorMessage(error)));
            return error;
          }),
          takeUntil(this.destroy$),
          finalize(() => this.loading = false),
        )
        .subscribe();
      this.setView();
    }
  }

  public removePraise() {
    const text = this._translocoService.translate('Tem certeza que deseja remover este elogio?');
    this._dialogService.openConfirm(text).subscribe((confirmed) => {
      if (confirmed) {
        this.loading = true;
        this._praiseItemService.deletePraise(this.dialogData.praise.praiseId).pipe(
          tap(() => {
            this._dialogRef.close({ deleted: true });
          }),
          finalize(() => this.loading = false)
        ).subscribe();
      }
    });
  }

  public showCommentsChat() {
    this.showComments = true;
    setTimeout(() => {
      this.svcCommentsChatComponent?.focus();
    });
  }

  public closeDialog() {
    this._dialogRef.close();
  }

  private processPraiseData(praise: any) {
    // Processamento de recipientUsers e recipientTeams para avatar data
    const avatarData = [
      ...(praise.recipientUsers?.map((i) => ({
        name: i.userName,
        picture: i.userPictureFile,
      })) || []),
      ...(praise.recipientTeams?.map((i) => ({
        name: i.groupName,
        picture: i.pictureFile,
      })) || []),
    ];

    const color = getCustomThemeColor(this._settingsService.theme$, 500);

    // Construção do objeto item
    this.item = {
      // Propriedades do praise original
      ...praise,
      // Propriedades adicionais ou modificadas
      id: praise.praiseId,
      title: praise.titleDescription,
      description: praise.text,
      user: {
        name: praise.originatorName,
        id: praise.userOriginatorId,
      },
      avatar: {
        type: UserAvatarType.user,
        size: UserAvatarSize.Card,
        initialsColor: praise.recipientUsers?.length > 0 ? praise.recipientUsers[0].initialColor : color,
        data: avatarData,
      } as ISvcAvatarMultiple,
      date: praise.createDate,
      hasReactions: false,
      hasComments: false,
      comments: 0,
    };
  }

  private setView() {
    if (this.env.isQA || this.env.isDEV) {
      this.isSettingView = true;
      this._svcViewsService.setViews({
        registryId: this.praiseId.toString(),
        applicationId: this.applicationId,
        featureName: 'views-praise',
      }).pipe(
        tap(() => this.dialogData.praise.wasMarkedAsSeen = true),
        finalize(() => this.isSettingView = false)
      ).subscribe();
    }
  }
}
