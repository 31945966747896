import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'user-password-validator',
  templateUrl: './user-password-validator.component.html',
  styleUrls: ['./user-password-validator.component.scss']
})
export class UserPasswordValidatorComponent {
  @Input() control: AbstractControl;

  validators = [
    { name: 'minLength', text: 'Mínimo 8 caracteres'},
    { name: 'hasUpperCase', text: 'Uma letra maíuscula',},
    { name: 'hasNumber', text: 'Um número'},
    { name: 'hasSpecialChar', text: 'Um caractere especial'}
  ];

}
