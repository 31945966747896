import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcDialogsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcReactionModule } from '../../../general/svc-reaction/svc-reaction.module';
import { SvcCommentsModule } from '../../../general/svc-comments/svc-comments.module';
import { SvcTotalsStatusModule } from '../../../general/svc-totals-status/svc-totals-status.module';
import { ModalPraiseDetailComponent } from './modal-praise-item-detail.component';
import { PraiseItemService } from './praise-item.service';

@NgModule({
  declarations: [
    ModalPraiseDetailComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcUserAvatarModule,
    SvcReactionModule,
    SvcButtonsModule,
    SvcCommentsModule,
    SvcDialogsModule,
    TranslocoModule,
    SvcTotalsStatusModule
  ],
  providers: [
    PraiseItemService,
  ],
  exports: [
    ModalPraiseDetailComponent,
  ]
})
export class ModalPraiseDetailModule { }
