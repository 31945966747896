<div #headerShadowEl class="top-shadow animated fadeIn" [ngClass]="{ 'to-drop': topShadow }"></div>

<svc-data-paginate
	*ngIf="!itDoesntPaginate"
	[ngClass]="{ 'to-drop': bottomShadow }"
	[totalRegisters]="dataLength"
	[totalPages]="isPaging && numPages ? numPages : 1"
	[current]="isPaging && numPages ? currentPage : 1"
	[pageSize]="paginate"
	[loading]="loading"
	[canChangePageSize]="canChangePageSize"
	[disabled]="loading"
	(onPageChanged)="onPageChange($event)"
	(onPageSizeChanged)="onPageSizeChange($event)"
>
</svc-data-paginate>

<ng-content></ng-content>

<div *ngIf="loading && (!infinite || currentPage === 1)"
	class="svc-data-loading"
	[style.left.px]="elRef?.nativeElement?.scrollLeft"
	[style.right.px]="elRef?.nativeElement?.scrollLeft ? -elRef.nativeElement.scrollLeft : null"
>
	<mat-spinner diameter="35"></mat-spinner>
</div>
