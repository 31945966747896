import {
	Component,
	OnInit,
	HostBinding,
	ContentChildren,
	QueryList,
	Input,
	ElementRef,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	ViewChild,
	EventEmitter,
	Output,
	Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TRANSLATION } from './svc-data-body.component.trans';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcDataVirtualScrollModel } from '../models/svc-data-virtual-scroll.model';
import { SvcDataVirtualScrollService } from '../services/svc-data-virtual-scroll.service';
import { SvcDraggingReorderEvent } from '../models/svc-dragging-reorder-event.model';
import { SvcFunctionsHelper, isNullOrUndefined, offsetToParent, srollBottom } from 'projects/lib-shared-common/src/public-api';
import { SvcDataRowComponent } from '../svc-data-row/svc-data-row.component';

@Component({
	selector: 'svc-data-body',
	templateUrl: './svc-data-body.component.html',
	styleUrls: ['./svc-data-body.component.scss'],
	host: {
		'(window:mousedown)': 'onMouseDown($event)',
		'(window:mousemove)': 'onMouseMove($event)',
		'(window:mouseup)': 'onMouseUp($event)',
	},
})
export class SvcDataBodyComponent implements OnInit, OnChanges, OnDestroy {
	@HostBinding('class.svc-data-body') public class: boolean = true;
	@HostBinding('class.clickable') public clickable: boolean = false;
  @HostBinding('class.hoverable') public hoverable: boolean = false;
	@HostBinding('class.height-is-auto') public get heightIsAuto() {
		return this.parent && this.parent.height === 'auto';
	}

	@ViewChild('itemsContainer', { static: false }) public itemsContainerElRef: ElementRef<HTMLElement>;

	@ContentChildren(SvcDataRowComponent) private queryRows: QueryList<SvcDataRowComponent>;

	public minHeight: number = 40;

	@Input('row-height') rowHeight: string = this.minHeight + 'px';
	@Input('drag-and-reorder') dragAndReorder: boolean = false;

	@Output() public onDragReorder = new EventEmitter<any>();

	public get rows() {
		return this.queryRows ? this.queryRows.toArray() : [];
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	public parent: SvcDataTableComponent;

	public dictionary = TRANSLATION;

	private subscription: Subscription;

	public scrollInBottom: boolean = false;
	public scrollEnabled: boolean = false;
	public isListeningScroll: boolean = false;
	public finishListenersFn: (() => void)[] = [];

	public virtualScroll: SvcDataVirtualScrollModel;

	private lastScrollTop: number = -1;
	public expandAllRows = false;

	public dragging: {
		state: 'START' | 'DRAGGING' | 'NONE';
		rowIndex: number;
		row: SvcDataRowComponent;
		targetRow: SvcDataRowComponent;
		lastMouseY: number;
		initMouseY: number;
		initElementY: number;
		initScrollTop: number;
		initScrollPadding: number;
		fakeElement: HTMLElement;
		toDown: boolean;
	};

	constructor(
		private renderer: Renderer2,
		private helper: SvcFunctionsHelper,
		public elRef: ElementRef<HTMLElement>,
		private dataVirtualScrollService: SvcDataVirtualScrollService
	) {
		this.resetDragging();
	}

	public ngOnInit() {
		this.virtualScroll = new SvcDataVirtualScrollModel({
			containerSrollElRef: () => {
				if (this.parent?.useParentScroll) {
					const element = this.parent?.parentScrollingElement;
					if (element) {
						return new ElementRef(element);
					}
				}
				return this.elRef;
			},
		});
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('dragAndReorder' in changes) {
			const value = this.dragAndReorder;
			this.dragAndReorder = typeof value === 'string' && (value === '' || value === 'true') ? true : value;
			this.rows.forEach(x => {
				x.onDraggableChange();
				x.checkCanDragAndReorder();
			});
		}

		if ('rowHeight' in changes) {
			if (this.helper.onlyNumbers(this.rowHeight) === this.rowHeight) this.rowHeight += 'px';

			if (isNullOrUndefined(this.rowHeight) || this.rowHeight === '') {
				this.rowHeight = this.minHeight + 'px';
				return;
			} else {
				let number = this.helper.onlyNumbers(this.rowHeight);
				let unit = this.rowHeight.replace(number, '');

				if (unit !== 'px') {
					this.rowHeight = this.minHeight + 'px';
					console.warn(`The row-height is invalid. The height must be informed in "px".`);
					return;
				}
			}

			let number = parseInt(this.helper.onlyNumbers(this.rowHeight));
			if (number < this.minHeight) {
				this.rowHeight = this.minHeight + 'px';
				console.warn(`The row-height is invalid because the minimum height should be "${this.minHeight}px".`);
				return;
			}
		}
	}

	public onRender(parent: SvcDataTableComponent) {
		this.parent = parent;
		this.configureChildrens();
		this.applyListeners();
		this.scrollEnabled = this.parent.loading;

		this.parent.checkMinWidthCanApply();

		if (this.dataVirtualScrollService.canApplyVirtualScroll(this)) this.dataVirtualScrollService.defineItens(this);
		else this.parent.items = this.parent.filteredItems;

		this.subscription?.unsubscribe();
		this.subscription = this.queryRows.changes.subscribe(this.configureChildrens.bind(this));
	}

	public configureChildrens() {
		this.clickable = this.parent.clickable;
    this.hoverable = this.parent.hoverable;
		this.rows.forEach((row, virtualIndex) => {
			row.onRender(this, virtualIndex);
			if (this.dragging.state !== 'NONE' && row.index === this.dragging.rowIndex) {
				this.dragging.row = row;
				row.el.style.opacity = '0';
			}
		});
	}

	public applyListeners() {
		let element = this.virtualScroll.containerSrollElRef()?.nativeElement;
		if (!this.isListeningScroll && element && (!this.parent?.useParentScroll || element !== this.el)) {
			if (element.tagName === 'HTML') {
				element = window as any;
			}
			this.finishListenersFn.forEach(x => x());
			this.finishListenersFn = [
				this.renderer.listen(element, 'scroll', this.onScroll.bind(this)),
				this.renderer.listen(element, 'mousewheel', this.onMouseWheel.bind(this)),
				this.renderer.listen(element, 'DOMMouseScroll', this.onMouseWheel.bind(this)),
			];
			this.isListeningScroll = true;
		}
	}

	public dataTableInBottom() {
		const elementScrolling = this.virtualScroll.containerSrollElRef()?.nativeElement;

		if (!elementScrolling || elementScrolling === this.el) {
			return this.el ? this.el.clientHeight + this.el.scrollTop >= this.el.scrollHeight : false;
		} else {
			const bodyToParentOffset = offsetToParent(this.el, elementScrolling);
			const heightToScroll = this.el.clientHeight + bodyToParentOffset;
			const scrolled = elementScrolling.scrollTop + elementScrolling.clientHeight;
			const inBottom = scrolled >= heightToScroll;

			return inBottom;
		}
	}

	public onLoading() {
		if (this.parent.infinite > 0 && this.parent.currentPage !== 1 && this.parent.loading) {
			const elementRef = this.virtualScroll.containerSrollElRef();
			if (elementRef && elementRef.nativeElement) {
				let element = elementRef.nativeElement;
				let stepTime = 10;
				let countTime = 0;
				let interval = setInterval(() => {
					try {
						if (this.parent.loading) {
							countTime += stepTime;
							srollBottom(element);
							if (countTime >= 150) clearInterval(interval);
						} else clearInterval(interval);
					} catch {
						clearInterval(interval);
					}
				}, stepTime);
			}
		}
	}

	private onScrollFinished() {
		if (this.parent.infinite && this.parent && !this.parent.dataAreOver) {
			this.parent.currentPage++;
			this.parent.emitGetData();
		}
	}

	public onScroll(ev?: any, forceChange: boolean = false) {
		if (!this.parent.loading) {
			if (this.dataTableInBottom()) {
				if (!this.scrollInBottom) {
					this.scrollInBottom = true;
					this.onScrollFinished();
				}
			} else this.scrollInBottom = false;
		}

		if (this.dataVirtualScrollService.canApplyVirtualScroll(this))
			if (forceChange || this.lastScrollTop !== this.virtualScroll.currentSrollTop) {
				this.lastScrollTop = this.virtualScroll.currentSrollTop;
				this.dataVirtualScrollService.onScrollChange(this);
			}

		if (this.dragging.state === 'DRAGGING') {
			const posY = this.dragging.lastMouseY - this.dragging.initMouseY;
			const scrollTop = this.virtualScroll.currentSrollTop - this.dragging.initScrollTop;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			this.dragging.fakeElement.style.top = `${this.dragging.initElementY + scrollTop + posY - scrollPedding}px`;
		}
	}

	public backToTheTop() {
		const element = this.virtualScroll.containerSrollElRef()?.nativeElement;
		if (element) {
			const bodyToParentOffset = this.parent?.useParentScroll
				? offsetToParent(this.parent.el, element)
				: 0;
			if (bodyToParentOffset > 0) {
				if (element.scrollTop > bodyToParentOffset) {
					element.scrollTo({
						left: element.scrollLeft,
						top: bodyToParentOffset,
						behavior: 'smooth',
					});
				}
			} else {
				element.scrollTop = 0;
			}
		}
	}

	public getRowIndexBasedVirtual(virtualIndex: number) {
		return this.virtualScroll.currentStartIndex + virtualIndex;
	}

	private onMouseWheel() {
		if (!this.dataVirtualScrollService.canApplyVirtualScroll(this)) this.onScroll();
	}

	public onBodyWidthChange() {
		let currentWidth = this.el ? this.el.clientWidth + 'px' : null;
		if (currentWidth && this.el) {
			this.rows.forEach(row => {
				row.cells.forEach(cell => cell.setWidth());
			});
		}
	}

	public onMouseDown(event: MouseEvent) {
		if (this.dragAndReorder) {
			if (event.button === 0 && this.dragging.state === 'NONE' && this.dragging.row === null) {
				const targetEl = event.target as HTMLElement;
				const row = this.getRowIfMatchToDrag(targetEl);
				if (row) {
					event.preventDefault();
					this.afterStartRowDragging();
					this.dragging.fakeElement = row.el.cloneNode(true) as HTMLElement;
					this.dragging.state = 'START';
					this.dragging.rowIndex = row.index;
					this.dragging.row = row;
					this.dragging.lastMouseY = event.pageY;
					this.dragging.initMouseY = event.pageY;
					this.dragging.initElementY = row.el.offsetTop;
					this.dragging.initScrollTop = this.virtualScroll.currentSrollTop;
					this.dragging.initScrollPadding = this.virtualScroll.paddingScroll;
					this.dragging.fakeElement.classList.add('row-fake-dragging');
					this.dragging.fakeElement.style.top = `${this.dragging.initElementY}px`;
					this.itemsContainerElRef.nativeElement.appendChild(this.dragging.fakeElement);
					this.dragging.row.el.style.opacity = '0';
				}
			}
		}
	}

	public onMouseMove(event: MouseEvent) {
		if (this.dragging.state !== 'NONE') {
			this.dragging.state = 'DRAGGING';
			this.dragging.lastMouseY = event.pageY;
			const posY = event.pageY - this.dragging.initMouseY;
			const scrollTop = this.virtualScroll.currentSrollTop - this.dragging.initScrollTop;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			this.dragging.fakeElement.style.top = `${this.dragging.initElementY + scrollTop + posY - scrollPedding}px`;
			this.dragging.toDown = posY + scrollTop > 0;

			this.checkMatchAndPreReorder();
		}
	}

	public onMouseUp(event: MouseEvent) {
		if (this.dragging.state !== 'NONE') {
			this.dragging.state = 'NONE';
			this.afterStopRowDragging();

			const targetRow = this.dragging.targetRow;
			const rowIsVisible = !!this.dragging.row?.el.parentElement;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			let top = this.dragging.initElementY - scrollPedding;
			if (!rowIsVisible && !targetRow) {
				top = parseFloat(this.dragging.fakeElement.style.top) - this.dragging.fakeElement.clientHeight;
			} else if (targetRow) {
				top = targetRow.el.offsetTop;
			}

			this.dragging.fakeElement.style.transitionDuration = '200ms';
			this.dragging.fakeElement.style.top = `${top}px`;

			if (targetRow) {
				setTimeout(() => {
					if (rowIsVisible && this.dragging.row?.el != null) this.dragging.row.el.style.opacity = '';
					this.rows.forEach(row => {
						row.el.style.transform = '';
					});
					this.reorderItem(targetRow.index, this.dragging.rowIndex);
					this.resetDragging();
				}, 200);
			} else {
				this.dragging.fakeElement.style.animationDuration = rowIsVisible ? '100ms' : '200ms';
				this.dragging.fakeElement.style.animationDelay = rowIsVisible ? '100ms' : '0';

				setTimeout(() => {
					if (rowIsVisible && this.dragging.row?.el != null) this.dragging.row.el.style.opacity = '';
					this.resetDragging();
				}, 200);
			}
			this.dragging.fakeElement.classList.add('fadeOut');
		}
	}

	private checkMatchAndPreReorder() {
		const draggingEl = this.dragging.fakeElement;
		const draggingTopPos =
			draggingEl.offsetTop +
			this.virtualScroll.paddingScroll +
			(this.dragging.toDown ? draggingEl.clientHeight : 0);

		let draggingRowTopPos = 0;
		if (this.dragging.row) {
			draggingRowTopPos =
				this.dragging.row.el.offsetTop +
				this.virtualScroll.paddingScroll +
				(this.dragging.toDown ? 0 : this.dragging.row.el.clientHeight);
		}

		const ratioToMatch = 0.4;

		let rowTarget: SvcDataRowComponent = null;

		this.rows.forEach(row => {
			if (this.dragging.rowIndex !== row.index) {
				let canBeTarget = false;
				const height = row.el.clientHeight;
				const ratioHeight = this.dragging.toDown ? ratioToMatch * height : (1 - ratioToMatch) * height;
				const rowTopPos =
					row.el.offsetTop +
					this.virtualScroll.paddingScroll +
					(this.dragging.toDown ? 0 : row.el.clientHeight);
				const rowTopPosToGoDownOrUp = row.el.offsetTop + this.virtualScroll.paddingScroll + ratioHeight;
				if (this.dragging.toDown) {
					// console.log('DOWN', draggingTopPos, rowTopPos);
					canBeTarget = rowTopPos > draggingRowTopPos && draggingTopPos > rowTopPosToGoDownOrUp;
					row.el.style.transform = canBeTarget ? `translateY(-${draggingEl.clientHeight}px)` : '';
					if (canBeTarget) {
						rowTarget = row;
					}
				} else {
					// console.log('UP', draggingTopPos, rowTopPos);
					canBeTarget = rowTopPos < draggingRowTopPos && draggingTopPos < rowTopPosToGoDownOrUp;
					row.el.style.transform = canBeTarget ? `translateY(${draggingEl.clientHeight}px)` : '';
					if (canBeTarget && !rowTarget) {
						rowTarget = row;
					}
				}
			}
		});

		if (this.dragging.targetRow !== rowTarget) {
			this.dragging.targetRow = rowTarget;
		}
	}

	private resetDragging(): void {
		this.dragging?.fakeElement.remove();
		this.dragging = {
			state: 'NONE',
			rowIndex: null,
			row: null,
			targetRow: null,
			lastMouseY: 0,
			initMouseY: 0,
			initElementY: 0,
			initScrollTop: 0,
			initScrollPadding: 0,
			fakeElement: null,
			toDown: true,
		};
	}

	private getRowIfMatchToDrag(targetEl: HTMLElement): SvcDataRowComponent {
		const row = this.rows.find(row => {
			return row.draggable && row.dragButtonEl.contains(targetEl);
		});
		return row;
	}

	private reorderItem(itemCurrentIndex: number, itemPreviousIndex: number): void {
		const directionIsDown = itemCurrentIndex > itemPreviousIndex;
		const item = this.parent.allItems[itemPreviousIndex];

		const itemsBefore = this.parent.allItems;
		let itemsAfter = this.parent.allItems.filter((x, i) => i !== itemPreviousIndex);
		itemsAfter.splice(itemCurrentIndex + (directionIsDown ? 0 : 0), 0, item);

		this.parent.allItems = itemsAfter;
		this.parent.prepareItemsPagingAndFilter(false);

		this.onDragReorder.emit(
			new SvcDraggingReorderEvent({
				currentIndex: itemCurrentIndex,
				previousIndex: itemPreviousIndex,
				items: {
					before: itemsBefore,
					after: itemsAfter,
				},
				cancelFn: () => {
					this.parent.allItems = itemsBefore;
					this.parent.prepareItemsPagingAndFilter(false);
				},
			})
		);
	}

	private afterStartRowDragging(): void {
		this.el?.classList.add('is-dragging');
	}

	private afterStopRowDragging(): void {
		this.el?.classList.remove('is-dragging');
	}

	ngOnDestroy() {
		this.finishListenersFn.forEach(x => x());
		this.subscription?.unsubscribe();
	}
}
