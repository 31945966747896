<div class="absolute top-0 bottom-0 left-0 right-0 z-20">
  <div class="relative top-1/3 w-fit mx-auto">
    <mat-progress-spinner
      [color]="'primary'"
      [diameter]="28"
      [mode]="'indeterminate'"
    ></mat-progress-spinner>
  </div>
</div>
<div
  class="absolute top-0 bottom-0 left-0 right-0 opacity-80 z-10"
  style="backdrop-filter: blur(2px);"
></div>
