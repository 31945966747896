import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { environment } from "projects/environments/environment";
import { Observable, finalize, map, tap } from "rxjs";

interface GetReactionTotalsParams {
	applicationId: string;
	registryUniqueId: number | string;
	siteId: number;
}

interface GetCommentsTotalsParams {
	referenceId: number | string;
	configTypeId: string;
	applicationId: string;
	externalSiteId?: number;
}

interface GetViewsTotalsParams {
	referenceId: number | string;
	applicationId: string;
	featureName: string;
}

interface GetTotalsParams {
	applicationId: string;
	siteId: number;
	referenceId: number | string;
	configTypeId: string;
	featureName: string;
	avoid?: {
		reactions: boolean,
		comments: boolean,
		views: boolean,
	}
}

@Injectable()
export class SvcTotalsStatusService {

	constructor(
		private _appConfig: AppEnvironmentConfig,
		private _httpClient: HttpClient,
	) {
	}

	public getTotals(params: GetTotalsParams, external: boolean = false): Observable<{ reactions: number, comments: number, views: number }> {
		return new Observable((subscriber) => {
			const requests = {};
			if (!(params.avoid?.reactions)) {
				requests['reactions'] = {
					loading: true,
					subscription: null,
					request: this.getReactionsTotals({
						applicationId: params.applicationId,
						registryUniqueId: params.referenceId,
						siteId: params.siteId,
					}),
				};
			}
			if (!(params.avoid?.comments)) {
				requests['comments'] = {
					loading: true,
					subscription: null,
					request: this.getCommentsTotals({
						applicationId: params.applicationId,
						referenceId: params.referenceId,
						configTypeId: params.configTypeId,
						externalSiteId: params?.siteId,
					}, external),
				};
			}
			if (!(params.avoid?.views)) {
				requests['views'] = {
					loading: true,
					subscription: null,
					request: this.getViewsTotals({
						referenceId: params.referenceId,
						applicationId: params.applicationId,
						featureName: params.featureName,
					}),
				};
			}
			const response = {
				reactions: 0,
				comments: 0,
				views: 0,
			};

			if (Object.keys(requests).length <= 0) {
				subscriber.next(response);
				subscriber.complete();
				return;
			}
			for(const key of Object.keys(requests)) {
				requests[key].subscription = requests[key].request.pipe(
					tap((total) => response[key] = total),
					finalize(() => {
						requests[key].loading = false;
						if (Object.keys(requests).every(key => !requests[key].loading)) {
							subscriber.next(response);
							subscriber.complete();
						}
					})
				).subscribe();
			}

			return () => Object.keys(requests).forEach(key => requests[key].subscription?.unsubscribe());
		});
	}

	public getReactionsTotals(params: GetReactionTotalsParams): Observable<number> {
		const id = params.registryUniqueId;
		const url = `${this._appConfig.APIs.apiUrlReaction}/${typeof id === 'string' ? 'CountersUid' : 'CountersId'}`;
		let queryParams = {
			applicationId: params.applicationId,
			siteId: params.siteId,
		} as any;
		if (typeof id === 'string') {
			queryParams.registryUniqueUId = id;
		}
		else {
			queryParams.registryUniqueId = id;
		}
		return this._httpClient.get<{ count: number }[]>(`${url}`, {
			params: queryParams,
		}).pipe(
			map((response) => {
				return response.reduce((prev, curr) => {
					return prev + curr.count;
				}, 0)
			}),
		);
	}

	public getCommentsTotals(params: GetCommentsTotalsParams, external: boolean): Observable<number> {
		const id = params.referenceId;
		const url = `${this._appConfig.APIs.apiUrlComments}/${typeof id === 'string' ? 'CommentByReferenceUId' : 'CommentByReferenceId'}${external ? '/external' : ''}/counter`;
		return this._httpClient.get<number>(`${url}`, {
			params: {
				referenceId: id,
				referenceUid: id,
				commentConfigTypeId: params.configTypeId,
        externalSiteId: params?.externalSiteId,
			},
		});
	}

	public getViewsTotals(params: GetViewsTotalsParams): Observable<number> {
		const url = `${this._appConfig.APIs.apiUrlViews}/View/count`;
		return this._httpClient.get<{ count: number }>(`${url}`, {
			params: {
				applicationId: params.applicationId,
				registryId: params.referenceId,
				featureName: params.featureName,
			},
		}).pipe(
			map((response) => response?.count ?? 0),
		);
	}
}
