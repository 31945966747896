import { NgModule } from '@angular/core';
import { SvcCloudWordsComponent } from './svc-cloud-words.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';

@NgModule({
  imports: [
    LibSharedCommonModule
  ],
  declarations: [SvcCloudWordsComponent],
  exports: [SvcCloudWordsComponent],
})
export class SvcCloudWordsModule {}
