import { NgModule } from '@angular/core';
import {RouterModule} from "@angular/router";
import {postsRoutes} from "./svc-posts.routing";
import { SvcPostsStandaloneModule } from './svc-posts-standalone.module';

@NgModule({
  imports: [
    SvcPostsStandaloneModule,
    RouterModule.forChild(postsRoutes),
  ],
  providers: [
  ]
})
export class SvcPostsModule { }
