import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { LoadingBarService } from './loading.service';

@Component({
  selector: 'loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  @HostBinding('class.showing-loading')
  public show: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _loadingService: LoadingBarService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._loadingService.show$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        setTimeout(() => this.show = value, 0);
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
