import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcButtonnNeutralComponent } from './components/svc-button-neutral/svc-button-neutral.component';
import { SvcButtonComponent } from './components/svc-button/svc-button.component';
import {SvcButtonConfirmComponent} from "./components/svc-button-confirm/svc-button-confirm.component";
import { SvcButtonCircularComponent } from './components/svc-button-circular/svc-button-circular.component';
import { SvcButtonFloatComponent } from './components/svc-button-float/svc-button-float.component';

@NgModule({
  declarations: [
    SvcButtonnNeutralComponent,
    SvcButtonConfirmComponent,
    SvcButtonComponent,
    SvcButtonCircularComponent,
    SvcButtonFloatComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
  ],
  exports: [
    SvcButtonnNeutralComponent,
    SvcButtonConfirmComponent,
    SvcButtonComponent,
    SvcButtonCircularComponent,
    SvcButtonFloatComponent
  ]
})
export class SvcButtonsModule { }
