import * as customPalettes from "tailwindcss/config";

export function getCustomThemeColor(theme: string, level: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900): string {
    theme = theme.replace('-', '_');
    return customPalettes[theme][level];
}
export function themeColorIsValid(theme: string): boolean {
    theme = theme.replace('-', '_');
    return !!(customPalettes[theme]);
}
