import {Injectable} from "@angular/core";
import { UserIndicators, UserIndicator } from "./user.types";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UserIndicatorsService {

  private _userIndicators = new BehaviorSubject<UserIndicators>({
    actionPlan: {
      description: 'Plano de Ação',
      amount: 0,
      loading: true,
      error: null,
    },
    defectTag: {
      description: 'Etiqueta de Defeito',
      amount: 0,
      loading: true,
      error: null,
    },
    lil: {
      description: 'LIL',
      amount: 0,
      loading: true,
      error: null,
    },
    checkList: {
      description: 'Checklist',
      amount: 0,
      loading: true,
      error: null,
    },
  });
  _userIndicators$ = this._userIndicators.asObservable();

  updateChecklistIndicators(indicator: Partial<UserIndicator>){
    this._userIndicators.next({
      ...this._userIndicators.value,
      checkList: {
        ...this._userIndicators.value.checkList,
        ...indicator
      },
    });
  }

  updateLilIndicators(indicator: Partial<UserIndicator>){
    this._userIndicators.next({
      ...this._userIndicators.value,
      lil: {
        ...this._userIndicators.value.lil,
        ...indicator
      },
    });
  }

  updateActionPlanIndicators(indicator: Partial<UserIndicator>){
    this._userIndicators.next({
      ...this._userIndicators.value,
      actionPlan: {
        ...this._userIndicators.value.actionPlan,
        ...indicator
      },
    });
  }

  updateDefectTagIndicators(indicator: Partial<UserIndicator>){
    this._userIndicators.next({
      ...this._userIndicators.value,
      defectTag: {
        ...this._userIndicators.value.defectTag,
        ...indicator
      },
    });
  }
}
