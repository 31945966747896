<div class="flex items-center justify-center h-full">
  <ng-container *ngIf="!info?.picture">
    <div *ngIf="info?.size === UserAvatarSize.Workspace" class="flex flex-auto justify-center" [style.backgroundColor]="info.initialsColor ?? '#d0d0d0'">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <ng-container *ngIf="info?.size !== UserAvatarSize.Workspace">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
    <ng-template #content>
      <div
        class="flex flex-column rounded-full items-center justify-center"
        [class.border-2]="info?.size !== UserAvatarSize.CustomLayout"
        [ngClass]="'border-' + borderColor"
        [style.fontSize.px]="fontSize"
        [style.height.px]="size" 
        [style.width.px]="size"
        [style.min-height.px]="size" 
        [style.min-width.px]="size"
        [style.backgroundColor]="info?.initialsColor ?? '#d0d0d0'"
      >
        <div *ngIf="!info?.type || info?.type == 1"  class="text-white" [style.lineHeight.px]="fontSize" [style.fontSize.px]="fontSize">
          {{initials}}
        </div>
        <mat-icon *ngIf="info?.type == 2" svgIcon="mat_solid:place" class="teams-areas-icon text-white"></mat-icon>
        <mat-icon *ngIf="info?.type == 3" svgIcon="mat_solid:groups" class="teams-areas-icon text-white"></mat-icon>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="info?.picture">
    <img
      class=""
      [src]="info?.picture"
      [class.rounded-full]="info?.size !== UserAvatarSize.Workspace"
      [class.border-2]="info?.size !== UserAvatarSize.Workspace && info?.size !== UserAvatarSize.CustomLayout"
      [ngClass]="'border-' + (borderColor ?? 'white')"
      [style.height]="(info?.size === UserAvatarSize.Workspace ? '100%' : (size + 'px')) + ' !important'"
      [style.width]="(info?.size === UserAvatarSize.Workspace ? '100%' : (size + 'px')) + ' !important'"
      [style.min-height]="(info?.size === UserAvatarSize.Workspace ? '100%' : (size + 'px')) + ' !important'" 
      [style.min-width]="(info?.size === UserAvatarSize.Workspace ? '100%' : (size + 'px')) + ' !important'"
      (error)="info.picture = undefined"
    />
  </ng-container>
</div>
<ng-content></ng-content>
