import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { ISvcChipGroupedOption, SvcFilterDialogService, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { StaticApplicationId, SvcFeatureToggleService, UserPreferencesService } from 'projects/lib-shared-core/src/public-api';
import { MyworkspaceUserPreferencesFeaturesNames } from 'projects/myworkspace/src/app/board/main/models/myworkspace-preferences';
import { catchError, distinctUntilChanged, finalize, Subject, takeUntil, tap } from "rxjs";
import { SvcUserPreferencesEvents } from '../models/svc-user-preferences-events.model';
import { SvcUserPreferenceFeatureCustomPorpertyKey, SvcUserPreferencesFeatures } from '../models/svc-user-preferences.model';
import { AddHashtagFormComponent } from './form/add-hashtag/add-hashtag-form.component';
import { AllHashtagComponent } from "./form/all-hashtag/all-hashtag.component";
import { EditHashtagComponent } from "./form/edit-hashtag/edit-hashtag.component";
import { Hashtag, HashtagTypeEnum } from "./services/user-hashtags.types";
import { UserHashtagsService } from './services/user-hastags.service';

@Component({
  selector: 'user-hashtags',
  templateUrl: './user-hashtags.component.html',
  styleUrls: ['./user-hashtags.component.scss']
})
export class UserHashtagsComponent extends SvcUserPreferencesEvents<'hashtag'> implements OnInit {

  @ViewChild('containerHashtags') containerHashtags: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refreshSeeMore()
  }

  toggleType: HashtagTypeEnum = HashtagTypeEnum.Local;
  filteredHashtags: Hashtag[];
  hashtags: Hashtag[];
  hashtagsByType: Hashtag[];
  hashtagTypeEnum = HashtagTypeEnum;
  hashtagSearch: string;
  seeMore: boolean;
  seeMoreRevealed: boolean;
  filteredhashtagByPreference = false;
  hashtagFiltered: { availableHashtags: ISvcChipGroupedOption[] };
  feedIsEnabled = false;
  cleanFilter = false;
  isLoading = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _dialog: MatDialog,
    private _userHashtagService: UserHashtagsService,
    private _svcFilterDialogService: SvcFilterDialogService,
    private _userPreferencesService: UserPreferencesService,
    private _httpErrorService: HttpErrorService,
    private _toast: SvcToastService,
    private _transloco: TranslocoService,
    private _featureToggleService: SvcFeatureToggleService,
    private _cd: ChangeDetectorRef,
  ) {
    super({
      featureName: MyworkspaceUserPreferencesFeaturesNames.MyWorkspaceHashtagFilterPostsTab,
      customPropertyKey: SvcUserPreferenceFeatureCustomPorpertyKey.List,
      customPropertyType: 'string',
      customPropertyValue: ['hashtag'],
      activeCustomProperty: 'hashtag',
      applicationId: StaticApplicationId.myworkspace,
      customFilter: 'HomeHashtagFilterPostsTab'
    });

    this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
  }

  ngOnInit() {
    this.getUserHashtags();

    this._userHashtagService.hashtags$.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      takeUntil(this._unsubscribeAll),
    ).subscribe(res => {
      this.hashtags = res;
      this.onToggleTypeEventClicked(this.toggleType, false);
    });

    this._userHashtagService.clearHashtagPreferenceFilter$.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((filter => {
      if (filter) this.#clearFilterHashtagByPreference();
    }));
  }

  ngOnDestroy(): void {
    //Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getUserHashtags() {
    this.isLoading = true;
    this._userHashtagService.getUserHashtags()
      .pipe(
        catchError((err) => {
          this._httpErrorService.showErrorInToast(err);
          return err;
        }),
        takeUntil(this._unsubscribeAll),
        finalize(() => (this.isLoading = false))
      ).subscribe();
  }

  onAddEventClicked() {
    this._dialog.open(AddHashtagFormComponent, { width: '450px' });
  }

  onSeeAllEventClicked() {
    this._dialog.open(AllHashtagComponent, { width: '450px' });
  }

  onEditEventClicked() {
    this._dialog.open(EditHashtagComponent, { width: '450px' }).afterClosed().subscribe(response => {
      this.cleanFilter = response?.cleanFilter;
      if (this.cleanFilter) {
        this.#clearFilterHashtagByPreference();
      }
    });
  }

  onToggleTypeEventClicked(type: HashtagTypeEnum, clicked: boolean = false) {
    this.toggleType = type;
    const isGlobal = this.toggleType == HashtagTypeEnum.Global;
    this.hashtagsByType = this.hashtags.filter((x) => x.isGlobal == isGlobal);
    if (this.hashtagSearch) {
      this.filter(this.hashtagSearch)
    }
    else {
      this.filteredHashtags = this.hashtagsByType;
      this.refreshSeeMore();
    }

    if (this.feedIsEnabled) {
      if (clicked) this.#clearFilterHashtagByPreference();
      if (!this.cleanFilter) this.#getPreferences();
    }

    this._cd.detectChanges();
  }

  filter(value: string) {
    if (!value?.trim()) {
      this.filteredHashtags = this.hashtagsByType;
    } else {
      this.filteredHashtags = this.hashtagsByType.filter(x =>
        x.name.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
    }
    this.refreshSeeMore();
  }

  refreshSeeMore() {
    setTimeout(() => {
      if (!this.containerHashtags && !this.seeMoreRevealed) return;
      this.seeMore = this.containerHashtags?.nativeElement.clientHeight < (this.containerHashtags.nativeElement.scrollHeight);
    });
  }

  filterByHashtagClicked(value: string) {
    this.filteredhashtagByPreference = true;

    if (!value?.trim()) {
      this.#clearFilterHashtagByPreference();
    } else {
      this.filteredHashtags = this.hashtagsByType.filter(x => x.name.trim().toLowerCase() === value.trim().toLowerCase());
      this._userHashtagService.filteredHashtagClicked(this.filteredHashtags);
      const filtered = { hashTagIds: this.filteredHashtags.map(f => f.hashtagId) };
      this.setPreferenceFilter({ filterName: this.preferencesConfig.customFilter, filter: filtered });
    }
    this.refreshSeeMore();
  }

  #clearFilterHashtagByPreference() {
    this.filteredhashtagByPreference = false;
    this.filteredHashtags = this.hashtagsByType.filter(h => this.hashtags.some(ht => ht.hashtagId === h.hashtagId));
    this._userHashtagService.filteredHashtagClicked([]);
    this.clearPreferenceFilter(this.preferencesConfig.customFilter);
    this.refreshSeeMore();
    this._cd.detectChanges();
  }

  #getPreferences() {
    this._svcFilterDialogService.setLoading(true);
    this._userPreferencesService.getByFeatureName(StaticApplicationId.myworkspace, MyworkspaceUserPreferencesFeaturesNames.MyWorkspaceHashtagFilterPostsTab).pipe(
      tap((feature: SvcUserPreferencesFeatures) => {
        if (feature) {
          const ff = (feature.featureFilters ?? []).find(x => x.filterName == 'HomeHashtagFilterPostsTab');
          const filter = ff?.filters?.find(x => x.filterKey === 'hashTagIds')?.filterValue.map(x => parseInt(x));
          if (filter) {
            this.filteredhashtagByPreference = true;
            this.seeMore = false;
            this.filteredHashtags = this.filteredHashtags?.filter(item => filter?.includes(item.hashtagId));
            this._cd.detectChanges();
          }
        }
      }),
      catchError((error) => {
        this._toast.error(this._transloco.translate(this._httpErrorService.getErrorMessage(error)));
        return error;
      }),
      finalize(() => this._svcFilterDialogService.setLoading(false))
    ).subscribe();
  }
}
