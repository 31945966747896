import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from 'projects/lib-shared-core/src/public-api';
import { getCustomThemeColor } from 'projects/lib-shared-common/src/public-api';

import { Reacted } from '../../models/reacted.model';

@Component({
  selector: 'modal-total-reactions',
  templateUrl: './modal-total-reactions.component.html',
  styleUrls: ['./modal-total-reactions.component.scss'],
})
export class ModalTotalReactionsComponent implements OnInit {
  public reactionsTypeReacted: Reacted[] = [];
  public currentReactionType: Reacted | null = null;
  public initialsColor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _settingsService: SettingsService,
    private _dialogRef?: MatDialogRef<ModalTotalReactionsComponent>
  ) {
    this.initialsColor = getCustomThemeColor(this._settingsService.theme$, 500);
    this.reactionsTypeReacted = data.filter(x => x.users.length > 0);
  }

  public ngOnInit(): void {
  }
  
  public get filteredReactions(): Reacted[] {
    if (!this.currentReactionType) {
      return this.reactionsTypeReacted;
    } else {
      return this.reactionsTypeReacted.filter(
        (reaction) => reaction.reactionUniqueId === this.currentReactionType.reactionUniqueId
      );
    }
  }

  public selectReactionType(reactionType: Reacted | null) {
    if(this.currentReactionType?.reactionUniqueId === reactionType?.reactionUniqueId) {
      this.currentReactionType = null;
    } else {
      this.currentReactionType = reactionType;
    }
  }

  public clearFilter() {
    this.currentReactionType = null;
  }

  public closeDialog() {
    this._dialogRef.close();
  }
}
