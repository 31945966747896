<!-- Toast manager -->
<svc-toast></svc-toast>

<!-- Loading bar -->
<loading-bar></loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full h-full">

    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>

</div>
