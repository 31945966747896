import { Component, ViewEncapsulation } from '@angular/core';
import { SettingsService } from 'projects/lib-shared-core/src/lib/features/settings/services/settings.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styles: [
    `
      settings {
        position: static;
        display: block;
        flex: none;
        width: auto;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent {
  constructor(private _settingsService: SettingsService) {}
  selectedTheme = '';
  selectedMode = '';
  themes = [
    'default',
    'teal',
    'rose',
    'purple',
    'amber',
    'blue'
  ];

  getThemeName(theme: string) {
    return `theme-${theme}`;
  }

  setTheme(theme: string) {
    this.selectedTheme = theme;
    this._settingsService.handleThemeChange(this.getThemeName(theme));
  }
}
