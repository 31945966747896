import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

const PAGINATE_OPTIONS = [5, 10, 30, 50, 150, 250, 500];

export interface SvcPaginateEvent {
	page: number;
	pageSize: number;
}

@Component({
	selector: 'svc-paginate',
	templateUrl: './svc-paginate.component.html',
	styleUrls: ['./svc-paginate.component.scss'],
})
export class SvcPaginateComponent implements OnInit, OnChanges {

	public options: number[] = PAGINATE_OPTIONS;

	@Input() public totalRegisters: number = 0;
	@Input() public current: number = 1;
	@Input() public pageSize: number = PAGINATE_OPTIONS[1];
	@Input() public disabled: boolean = false;
	@Input() public loading: boolean = false;
	@Output() public onPageChanged = new EventEmitter<SvcPaginateEvent>();
	@Output() public onPageSizeChanged = new EventEmitter<SvcPaginateEvent>();

	public totalPages: number = 0;
	public hasPrevPage: boolean;
	public hasNextPage: boolean;
	public startFrom: number;
	public endFrom: number;
	public from: number;

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	constructor(private elRef: ElementRef<HTMLElement>) {
	}

	ngOnInit() { }

	ngOnChanges() {
		this.prepareProperties();
	}

	private prepareProperties() {
		this.totalRegisters = this.totalRegisters ?? 0;
		this.current = this.current ?? 1;
		this.pageSize = this.pageSize ?? PAGINATE_OPTIONS[1];
		this.totalPages = Math.floor(this.totalRegisters / this.pageSize) + (this.totalRegisters % this.pageSize > 0 ? 1 : 0);
		this.hasPrevPage = this.current > 1;
		this.hasNextPage = this.current < this.totalPages;
		this.from = this.totalRegisters ?? (this.totalPages * this.pageSize);
		this.startFrom = (this.current - 1) * this.pageSize + 1;
		this.endFrom = Math.min(((this.current - 1) * this.pageSize) + this.pageSize, this.from);
	}

	public optionChanged(event: MatSelectChange) {
		if (!this.disabled && !this.loading && this.current <= this.totalPages) {
			this.onPageSizeChanged.emit({
				page: this.current,
				pageSize: event.value,
			});
		}
	}

	nextPage() {
		if (!this.disabled && !this.loading && this.hasNextPage) {
			this.current += 1;
			this.prepareProperties();
			this.onPageChanged.emit({
				page: this.current,
				pageSize: this.pageSize,
			});
		}
	}
	prevPage() {
		if (!this.disabled && !this.loading && this.hasPrevPage) {
			this.current -= 1;
			this.prepareProperties();
			this.onPageChanged.emit({
				page: this.current,
				pageSize: this.pageSize,
			});
		}
	}
}
