import { Component, ElementRef, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, forwardRef, Output, EventEmitter, Optional, ContentChild, TemplateRef, ViewContainerRef, HostListener, AfterViewChecked } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { ISvcChipOption } from '../interfaces/svc-chip.interface';
import { SvcControl } from '../../svc-control';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatChipGrid } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';


@Component({
  selector: 'svc-chip',
  templateUrl: './svc-chip.component.html',
  styleUrls: ['./svc-chip.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcChipComponent),
    multi: true
  }]
})
export class SvcChipComponent extends SvcControl implements OnInit, AfterViewChecked, OnChanges, OnDestroy {

  _options: ISvcChipOption[];

  filteredOptions: ISvcChipOption[] = [];
  selectedOptions: ISvcChipOption[] = [];

  @Optional() @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @ViewChild('formField', { read: ElementRef }) formField: ElementRef<HTMLElement>;
  @ViewChild('optionsListTemplate') optionsListTemplate: TemplateRef<any>;
  @ViewChild('readonlyOptionsContainer') readonlyOptionsContainer: ElementRef<HTMLElement>;
  @ViewChild(MatChipGrid) matChipGrid: MatChipGrid;

  @Input() label: string | { text: string, icon: string };
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() readonlyStyle: 'default' | 'chip' | 'transparent-chip' = 'default';
  @Input() set options(value: ISvcChipOption[]) {
    const options = [...(value ? value : [])];
    this._options = _.cloneDeep(options);
  }
  @Input() type: 'single' | 'multiple' = 'multiple';
  @Input() inputId: string;
  @Input() placeholder: string;
  @Input() fallbackTextValue: string;
  @Input() truncateTextValue: boolean = false;
  @Input() collapsedDisplaying: boolean = true;
  @Input() loading: boolean = false;

  @Output() onSelectChanged = new EventEmitter<{ option: ISvcChipOption, selected: boolean }>();

  protected valueText: string;
  protected collapsed = true
  protected searchText: string = null;
  protected optionsListIsOpened = false;
  protected undisplayedSelectedOptions = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _isUpdatingControl = false;
  private _overlayRef: OverlayRef;
  private _initialDisableClose = false;

  constructor(
    @Inject(Injector) injector: Injector,
    @Optional() private dialogRef: MatDialogRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.collapsed = this.collapsedDisplaying ?? true;
    this._initialDisableClose = this.dialogRef.disableClose ?? false;

    this.control?.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((values: (string | number)[]) => {
        if (!this._isUpdatingControl) {
          if (!this.loading) {
            const selectedOptions = [];
            for (const option of this._options) {
              option.selected = values?.includes(option.value) ?? false;
              if (option.selected) {
                selectedOptions.push(option);
              }
            }
            this.selectedOptions = selectedOptions;
          }
          this.searchText = '';
          this._defineValueText();
          this.filterOptions(this.searchText);
        }
      }),
    ).subscribe();

    this._checkSelectedOptions();
    this._defineValueText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control) {
      super.ngOnChanges(changes);
      this._checkSelectedOptions();
      this._update();
    }
    this.filterOptions(this.searchText);
  }

  ngAfterViewChecked(): void {
    this._checkUndisplayedSelectedOptions();
  }

  private _checkUndisplayedSelectedOptions() {
    if (this.collapsed && !this.loading) {
      this.undisplayedSelectedOptions = 0;
      if (this.selectedOptions?.length > 1) {
        if (this.matChipGrid) {
          const length = this.matChipGrid?._chips.length ?? 0;
          for (let i = 0; i < length; i++) {
            const chip = this.matChipGrid?._chips.get(i);
            if (chip._elementRef.nativeElement.parentElement.offsetTop > 0) {
              this.undisplayedSelectedOptions = length - i;
              break;
            }
          }
        }
        else if (this.readonlyOptionsContainer?.nativeElement) {
          const containerEl = this.readonlyOptionsContainer?.nativeElement;
          const length = containerEl?.children.length ?? 0;
          for (let i = 0; i < length; i++) {
            const chipEl = containerEl.children[i] as HTMLElement;
            if (chipEl?.offsetTop > 0) {
              this.undisplayedSelectedOptions = length - i;
              break;
            }
          }
        }
      }
    }
  }

  private _defineValueText() {
    if (this.selectedOptions?.length > 0) {
      this.valueText = this.selectedOptions.map((o) => o.label).join(', ');
    }
    else {
      this.valueText = '';
    }
  }

  public filterOptions(value: string): void {
    const filterValue = value ? value.toString().toLowerCase() : '';
    this.filteredOptions = filterValue ? this._options.filter(option => (option.label.toString().toLowerCase().includes(filterValue))) : this._options;
  }

  private _available(value: any) {
    const item = this._options.find(option => option.value.toString().toLowerCase() === value.toString().toLowerCase());
    if (item) {
      item.selected = false;
      this._update();
      this.onSelectChanged.emit({ option: item, selected: false });
      return true;
    }
    return false;
  }

  private _unavailable(value: any) {
    const item = this._options.find(option => option.value.toString().toLowerCase() === value.toString().toLowerCase());
    if (item) {
      item.selected = true;
      this.selectedOptions.push(item);
      this._update();
      this.onSelectChanged.emit({ option: item, selected: true });
      return true;
    }
    return false;
  }

  private _update() {
    if (this.loading) return;
    const newValue = this.selectedOptions.map(option =>
      option.selected ? option.value : null
    ).filter(option => option != null);
    if (JSON.stringify(newValue) != JSON.stringify((this.control?.value ?? []))) {
      this._isUpdatingControl = true;
      this.control?.setValue(newValue);
      this._isUpdatingControl = false;
    }
    this._defineValueText();
  }

  private _checkSelectedOptions(): void {
    if (this.loading) return;
    
    const selectedItems: ISvcChipOption[] = [];

    this._options.forEach(option => {
      if ((Array.isArray(this.control?.value) && this.control.value.some(x => x == option.value))) {
        option.selected = true;
        if (this.type == 'single') {
          if (selectedItems.length == 0) {
            selectedItems.push(option);
          } else {
            option.selected = false;
          }
        } else {
          option.selected = true;
          selectedItems.push(option);
        }
      }
    });

    this.selectedOptions = selectedItems;
  }

  openOptions() {
    if (this.loading || this.optionsListIsOpened || (this.control?.disabled ?? false)) {
      return;
    }

    const hasOverlayRefInstance = !!(this._overlayRef);

    if (!this._overlayRef && this.formField.nativeElement) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'bg-transparent',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        width: this.formField.nativeElement.clientWidth,
        maxHeight: 256,
        positionStrategy: this._getOptionsPositionStrategy(),
      });

      this._overlayRef.backdropClick().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this._overlayRef.detach();
      });

      this._overlayRef.detachments().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this.formField?.nativeElement?.focus();
        if (this.dialogRef) this.dialogRef.disableClose = this._initialDisableClose;
        setTimeout(() => this.optionsListIsOpened = false);
      });
    }

    if (hasOverlayRefInstance) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef.updatePositionStrategy(this._getOptionsPositionStrategy());
    }

    if (!this.optionsListIsOpened) {
      try {
        const viewRef = this._overlayRef.attach(
          new TemplatePortal(this.optionsListTemplate, this._viewContainerRef)
        );
        this.optionsListIsOpened = true;
        if (this.dialogRef) this.dialogRef.disableClose = true;
        setTimeout(() => {
          if (this.optionsListIsOpened) {
            if (viewRef.rootNodes.length) {
              const element = viewRef.rootNodes[0] as HTMLElement;
              if (element?.querySelector) {
                const input = element.querySelector('input');
                if (input) {
                  input?.focus();
                }
                else {
                  this.formField?.nativeElement?.focus();
                }
              }
            }
          }
        });
      }
      catch { }
    }
  }

  closeOptions() {
    this._overlayRef?.detach();
  }

  private _getOptionsPositionStrategy() {
    const fieldWrapper = this.formField.nativeElement.querySelector('.mat-mdc-text-field-wrapper') as HTMLElement;
    return this._overlay
    .position()
    .flexibleConnectedTo(this.formField.nativeElement)
    .withLockedPosition(true)
    .withPush(true)
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: fieldWrapper ? (this.formField.nativeElement.clientHeight - fieldWrapper.offsetHeight) : 0
      },
    ]);
  }

  remove(option: ISvcChipOption): void {
    
  }

  selected(option: ISvcChipOption, event?: MouseEvent): void {
    event?.stopImmediatePropagation();
    if (!option.selected && this.type === 'single') {
      this.closeOptions();
    }
    
    if (option.selected) {
      const index = this.selectedOptions.indexOf(option);
      if (index >= 0) {
        this.selectedOptions.splice(index, 1);
        option.selected = false;
        this._available(option.value);
      }
      setTimeout(() => this._checkUndisplayedSelectedOptions());
    }
    else {
      if (this._unavailable(option.value)) {
        option.selected = true;
      }
      setTimeout(() => this._checkUndisplayedSelectedOptions());
    }
  }

  forceFocusItemWhenDownPressed(event: Event) {
    event.preventDefault();
    if (this.filteredOptions.length > 0 && ((this.type === 'single' && this.selectedOptions.length == 0) || this.type != 'single')) {
      const container = (event.target as HTMLElement).parentElement.parentElement.children[1];
      if (container?.children.length > 0) {
        const item = container?.querySelector('.chip-option') as HTMLElement;
        item?.focus();
      }
    }
  }

  @HostListener('window:resize')
  private onWindowResize(event: Event) {
    if (this.optionsListIsOpened) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef?.updatePositionStrategy(this._getOptionsPositionStrategy());
    }
  }

  @HostListener('document:keydown.esc')
  private onEsc(event: Event) {
    if (this.optionsListIsOpened) {
      this.closeOptions();
    }
  }

  public ngOnDestroy() {
    this.closeOptions();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
