import { LanguagesModule } from './../languages/languages.module';
import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserComponent } from './user.component';
import { UserCardComponent } from './user-card/user-card.component';
import { UserNavigationComponent } from './user-navigation/user-navigation.component';
import { UserHashtagsComponent } from './user-hashtags/user-hashtags.component';
import { AddHashtagFormComponent } from './user-hashtags/form/add-hashtag/add-hashtag-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { UserPasswordComponent } from './user-password/user-password.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcButtonsModule } from 'projects/lib-shared-component/src/lib/svc-buttons/svc-buttons.module';
import { SvcDialogsModule } from 'projects/lib-shared-component/src/lib/svc-dialogs/svc-dialogs.module';
import {
  SvcAlertModule,
  SvcControlsModule,
  SvcSlideToggleModule,
  SvcUserAvatarModule,
  SvcValidationMessageModule,
  SvcWrappedLayoutModule
} from 'projects/lib-shared-component/src/public-api';
import { SitesModule } from "../sites/sites.module";
import { EditHashtagComponent } from './user-hashtags/form/edit-hashtag/edit-hashtag.component';
import { MatTabsModule } from "@angular/material/tabs";
import { AllHashtagComponent } from "./user-hashtags/form/all-hashtag/all-hashtag.component";
import { UserHttpClient } from "./user-http-client";
import { MatChipsModule } from '@angular/material/chips';
import { ListHashtagComponent } from './user-hashtags/form/list-hashtag/list-hashtag.component';
import { UserNavigationImagesComponent } from './user-navigation/user-navigation-images/user-navigation-images.component';
import { UserPasswordValidatorComponent } from './user-password/user-password-validator.component';

@NgModule({
  declarations: [
    UserComponent,
    UserCardComponent,
    UserPasswordComponent,
    UserNotificationsComponent,
    UserSettingsComponent,
    UserNavigationComponent,
    UserNavigationImagesComponent,
    UserHashtagsComponent,
    AddHashtagFormComponent,
    EditHashtagComponent,
    AllHashtagComponent,
    ListHashtagComponent,
    UserPasswordValidatorComponent
  ],
  imports: [
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    LibSharedCommonModule,
    TranslocoModule,
    LanguagesModule,
    SvcButtonsModule,
    SvcDialogsModule,
    SvcUserAvatarModule,
    SitesModule,
    SvcControlsModule,
    SvcValidationMessageModule,
    SvcAlertModule,
    MatTabsModule,
    MatChipsModule,
    MatTableModule,
    MatTooltipModule,
    SvcSlideToggleModule,
    SvcWrappedLayoutModule,
  ],
  exports: [
    UserComponent,
    UserCardComponent,
    UserHashtagsComponent,
    AddHashtagFormComponent,
    UserNavigationComponent,
    UserNavigationImagesComponent,
    ListHashtagComponent,
    UserPasswordValidatorComponent,
  ],
  providers: [
    UserHttpClient
  ]
})
export class UserModule {
}
