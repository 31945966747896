import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SvcReactionService } from '../svc-reaction.service';
import { SvcAppSettings, UserService} from 'projects/lib-shared-core/src/public-api';
import { Subject, Subscription, catchError, finalize, takeUntil, tap } from 'rxjs';
import { Reacted } from '../models/reacted.model';
import { ReactionType } from '../models/reaction-type.model';
import { SvcReactionComponent } from '../svc-reaction.component';

import { ModalTotalReactionsComponent } from '../modal/modal-total-reactions/modal-total-reactions.component';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';


@Component({
  selector: 'svc-total-reactions',
  templateUrl: './svc-total-reactions.component.html',
  styleUrls: ['./svc-total-reactions.component.scss'],
})
export class SvcTotalReactionsComponent implements OnInit, OnDestroy {

  @Input() public registryUniqueId: number | string;
  @Input() public reaction: SvcReactionComponent;
  @Input() public applicationId: string;
  @Input() public mockMode: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public siteId: number;
  @Input() public classText = 'text-primary';
  @Input() buttonId: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public loading = true;
  public reactionsType: ReactionType[] = [];
  public reactionsTypeReacted: ReactionType[] = [];
  public totalReactions: Reacted[];
  public total: number = 0;
  private _requestSubscription: Subscription;

  constructor(
    private _appSettings: SvcAppSettings,
    private _service: SvcReactionService,
    private _dialog: SvcDialogService,
    private _userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this._service.getReactionsType().pipe(
      tap((list) => {
        this.reactionsType = list.filter(x => x.active);
        if (!this.mockMode) {
          this.reaction?.onReactionChange.pipe(
            takeUntil(this._unsubscribeAll),
            tap(() => {
              this._getTotalReactions();
            }),
          ).subscribe();
          this._getTotalReactions();
        }
        else {
          this.reactionsTypeReacted = this.reactionsType;
          this.total = 160;
          this.loading = false;
        }
      }),
      catchError((err) => {
        this.loading = false;
        return err;
      })
    ).subscribe();
  }

  public refresh() {
    this.ngOnInit();
  }

  public modalShowReactions(totalReactions: Reacted[]) {
    if (!this.mockMode && !this.disabled) {
      this._dialog.open(ModalTotalReactionsComponent, { data: totalReactions, width: '420px' });
    }
  }

  private _getTotalReactions() {
    if (!this._requestSubscription) {
      this.loading = true;

      const currentRequest = typeof this.registryUniqueId == 'number'
        ? this._service.getIdTotalReaction({
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          registryUniqueId: this.registryUniqueId,
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
        })
        : this._service.getUidTotalReaction({
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          registryUniqueUId: this.registryUniqueId,
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
        });

      this._requestSubscription = currentRequest.pipe(
        tap((totalReactions?: Reacted[]) => {
          this.totalReactions = totalReactions;

          this.reactionsTypeReacted = this.reactionsType.filter(x => (totalReactions.find(y => y.enumKey == x.enumKey)?.count ?? 0) > 0);
          this.total = totalReactions.reduce((prev, curr) => prev + curr.count, 0);
        }),
        finalize(() => {
          this.loading = false;
          this._requestSubscription = null;
        }),
      ).subscribe();
    }
    else {
      this.loading = !this._requestSubscription.closed;
    }
  }

  ngOnDestroy(): void {
    this._requestSubscription?.unsubscribe();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
