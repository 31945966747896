import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonPagination, CommonPaginationRequest } from 'projects/lib-shared-model/src/public-api';
import { PraiseItem } from '../../../models/praise-item.model';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { HttpClient } from '@angular/common/http';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';

export interface SearchRequest extends CommonPaginationRequest {
  recipientUserIds?: string[];
  recipientTeamIds?: string[];
  originatorIds?: string[];
}

@Injectable()
export class SearchService extends SvcHttpClient {
  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlPraise, httpClient);
  }

  getPraises(params: SearchRequest): Observable<CommonPagination<PraiseItem[]>> {
    if (!params.originatorIds) delete params.originatorIds;
    if (!params.recipientTeamIds) delete params.recipientTeamIds;
    if (!params.recipientUserIds) delete params.recipientUserIds;

    params = {
      ...params,
      pageIndex: params.pageIndex <= 0 ? 1 : params.pageIndex,
      pageSize: params.pageSize,
    }

    const url = `/Praise`;
    return this.get(url, { params: params as any });
  }

  getPraisesByUser(userId: string, params: SearchRequest): Observable<CommonPagination<PraiseItem[]>> {
    params = {
      ...params,
      pageIndex: params.pageIndex <= 0 ? 1 : params.pageIndex,
      pageSize: params.pageSize,
    }

    return this.get(`/User/${userId}/praises`, { params: params as any });
  }
}
