import { AfterViewChecked, AfterViewInit, Component, ContentChildren, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import { SvcElementsBoardItemDirective } from './svc-elements-board-item.directive';
import { Subject, takeUntil, tap } from 'rxjs';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { pixelsToNumber } from 'projects/lib-shared-common/src/public-api';

const ZOOM_STEP = 0.2;
const MAX_ZOOM_SCALE = 1.5;
const MIN_ZOOM_SCALE = 0.1;

export enum SvcBoardMode {
  NONE = 'NONE',
  EDITING = 'EDITING',
  MOVING = 'MOVING',
}

@Component({
  selector: 'svc-elements-board',
  templateUrl: './svc-elements-board.component.html',
  styleUrls: ['./svc-elements-board.component.scss'],
  host: {
    '(window:mousedown)': 'onMouseDown($event)',
    '(window:mousemove)': 'onMouseMove($event)',
    '(window:mouseup)': 'onMouseUp($event)',
  },
})
export class SvcElementsBoardComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {

  @ContentChildren(SvcElementsBoardItemDirective) private queryBoardItems: QueryList<SvcElementsBoardItemDirective>;

  @Input() startIn: 'begin' | 'center' | 'centerX' | 'centerY' = 'begin';
  @Input() bordEdgeSize: number = 25;
  @Input() canMove: boolean = true;
  @Input() canZoom: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() grid: boolean = true;

  private _mode = SvcBoardMode.NONE;
  public get mode() { return this._mode; }
  public set mode(value) {
    this._mode = value;
    this.mode$?.next(value);
  }
  public mode$ = new Subject<SvcBoardMode>();

  private _wasInitialized = false;

  public boardWidth: number = null;
  public boardHeight: number = null;
  public showRightBottomOptions = false;
  public canZoomIn: boolean = false;
  public canZoomOut: boolean = true;
  public scale: number = 1;
  public isMoving: boolean = false;
  public moving = {
    startViewportInnerTop: 0,
    startViewportInnerLeft: 0,
    startX: 0,
    startY: 0,
  };
  public isDragging: boolean = false;
  public dragging: {
    item: SvcElementsBoardItemDirective,
    startItemX: number,
    startItemY: number,
    startX: number,
    startY: number,
  } = { item: null, startItemX: 0, startItemY: 0, startX: 0, startY: 0 };

  @AutoDestroy
  destroy$: Subject<void> = new Subject<void>();

  public get boardItems() {
    return this.queryBoardItems?.toArray() ?? [];
  }

  public get element(): HTMLElement {
    return this.elementRef?.nativeElement;
  }

  public get viewport(): HTMLElement {
    return this.elementRef?.nativeElement.children[0] as HTMLElement;
  }

  public get viewportInner(): HTMLElement {
    return this.viewport?.children[0] as HTMLElement;
  }

  public get viewportSize(): { width: number, height: number } {
    return {
      width: this.viewportInner?.clientWidth ?? 0,
      height: this.viewportInner?.clientHeight ?? 0,
    };
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) { }

  public ngOnInit(): void {
    this.mode = (this.canMove ?? true) ? SvcBoardMode.MOVING : SvcBoardMode.NONE;
  }

  public ngOnChanges(): void {
    if (this._wasInitialized) {
      this.checkMode();
      this.checkBoardInnerSize();
    }
  }

  public ngAfterViewInit(): void {
    this.checkZoomVariables();
    setTimeout(() => {
      this.checkBoardItemsZindex();
      this.checkBoardInnerSize();
      setTimeout(() => this.goToPosition(this.startIn))
    }, 100);
    this.queryBoardItems.changes.pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.checkBoardItemsZindex();
        this.checkBoardInnerSize();
      },)
    ).subscribe();
    this._wasInitialized = true;
  }

  public ngAfterViewChecked(): void {
  }

  public goToPosition(position: 'begin' | 'center' | 'centerX' | 'centerY') {
    if (position === 'begin') {
      this.viewportInner.style.top = '0';
      this.viewportInner.style.left = '0';
    }
    else {
      const centerX = (this.viewportInner.clientWidth > this.viewport.clientWidth ? (this.viewportInner.clientWidth - this.viewport.clientWidth) / 2 : 0) * -1;
      const centerY = (this.viewportInner.clientHeight > this.viewport.clientHeight ? (this.viewportInner.clientHeight - this.viewport.clientHeight) / 2 : 0) * -1;
      if (position === 'center' || position === 'centerX') {
        this.viewportInner.style.left = `${centerX}px`;
      }
      if (position === 'center' || position === 'centerY') {
        this.viewportInner.style.top = `${centerY}px`;
      }
    }
  }

  public startEditingMode() {
    if (this.canEdit) {
      this.mode = SvcBoardMode.EDITING;
      this.isMoving = false;
    }
  }

  public endEditingMode() {
    this.mode = (this.canMove ?? true) ? SvcBoardMode.MOVING : SvcBoardMode.NONE;
    this.showRightBottomOptions = false;
  }

  public zoomIn() {
    if (this.canZoom) {
      const newScale = Math.min(this.scale + ZOOM_STEP, MAX_ZOOM_SCALE);
      this.scale = this.scale < 1 && newScale > 1 ? 1 : newScale;
      this.checkZoomVariables();
    }
  }

  public zoomOut() {
    if (this.canZoom) {
      const newScale = Math.max(this.scale - ZOOM_STEP, MIN_ZOOM_SCALE);
      this.scale = this.scale > 1 && newScale < 1 ? 1 : newScale;
      this.checkZoomVariables();
    }
  }

  private checkZoomVariables() {
    this.canZoomIn = this.scale < MAX_ZOOM_SCALE;
    this.canZoomOut = this.scale > MIN_ZOOM_SCALE;
  }

  private onMouseDown(event: MouseEvent) {
    if (event.button === 0 && this.element.contains(event.target as HTMLElement)) {
      if (this.mode == SvcBoardMode.MOVING && this.canMove) {
        this.isMoving = true;
        this.moving.startViewportInnerTop = pixelsToNumber(this.viewportInner.style.top);
        this.moving.startViewportInnerLeft = pixelsToNumber(this.viewportInner.style.left);
        this.moving.startX = event.pageX;
        this.moving.startY = event.pageY;
      }
      if (this.mode == SvcBoardMode.EDITING && this.canEdit) {
        const item = this.boardItems.find(item => item.element.contains(event.target as HTMLElement));
        if (item) {
          this.isDragging = true;
          this.dragging.item = item;
          this.dragging.startItemX = item.x;
          this.dragging.startItemY = item.y;
          this.dragging.startX = event.pageX;
          this.dragging.startY = event.pageY;
          item.startMoving();
        }
      }
    }
  }
  private onMouseMove(event: MouseEvent) {
    if (this.isMoving) {
      const newTop = this.moving.startViewportInnerTop - (this.moving.startY - event.pageY);
      const newLeft = this.moving.startViewportInnerLeft - (this.moving.startX - event.pageX);
      this.viewportInner.style.top = `${newTop}px`;
      this.viewportInner.style.left = `${newLeft}px`;
      // this.viewport.scrollTop = Math.min(Math.max(newTop, 0), this.viewport.scrollWidth);
      // this.viewport.scrollLeft = Math.min(Math.max(newLeft, 0), this.viewport.scrollWidth);
    }
    else if (this.isDragging) {
      const distanceX = event.pageX - this.dragging.startX;
      const distanceY = event.pageY - this.dragging.startY;
      this.dragging.item?.setPosition({
        x: this.dragging.startItemX + distanceX,
        y: this.dragging.startItemY + distanceY,
      });
    }
  }
  private onMouseUp(event: MouseEvent) {
    this.isMoving = false;
    if (this.isDragging) {
      this.isDragging = false;
      this.dragging.item?.stopMoving();
    }
    this.checkBoardInnerSize();
  }

  private checkMode() {
    if (this.mode != SvcBoardMode.EDITING) {
      const canMoving = this.viewport.scrollWidth > this.viewport.clientWidth || this.viewport.scrollHeight > this.viewport.clientHeight;
      if (!canMoving && this.mode === SvcBoardMode.MOVING) {
        this.mode = SvcBoardMode.NONE;
      }
      else if (canMoving && this.mode === SvcBoardMode.NONE) {
        this.mode = SvcBoardMode.MOVING;
      }
    }
  }

  private checkBoardItemsZindex(){
    for(const item of this.boardItems) {
      if (!item.getZindex()) {
        const zIndex = this.boardItems.reduce((prev, curr) => curr.getZindex() > prev ? curr.getZindex() : prev, 0);
        item.setZindex(zIndex + 1);
      }
    }
  }

  public checkBoardInnerSize() {
    const moreInRight = this.boardItems.reduce((prev, curr) => (!prev || (prev.x + prev.element?.clientWidth) < (curr.x + curr.element?.clientWidth)) ? curr : prev, null);
    const moreInBottom = this.boardItems.reduce((prev, curr) => (!prev || (prev.y + prev.element?.clientHeight) < (curr.y + curr.element?.clientHeight)) ? curr : prev, null);

    this.boardWidth = moreInRight ? moreInRight.x + moreInRight.element?.clientWidth : null;
    this.boardHeight = moreInBottom ? moreInBottom.y + moreInBottom.element?.clientHeight : null;
  }

}
