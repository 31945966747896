<ng-container *screenSizes="let size">
  <!-- LIKE COMPONENT -->
  <div class="flex flex-col" *ngIf="_post">

    <div class="flex flex-row my-2 flex-wrap" [class.px-2]="size.isXS && fromPostModal">
      <div class="flex flex-auto justify-start">
        <svc-total-reactions
          [applicationId]="_post?.applicationRegistry?.applicationId ?? appId"
          [registryUniqueId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post.id"
          [reaction]="svcReaction"
          [siteId]="_post?.applicationRegistry?.applicationId ? _post?.site?.siteId : null"
          [buttonId]="'post-reactions-total-'+_post.id"
        ></svc-total-reactions>
      </div>

      <div class="flex items-center text-gray-500 text-sm flex-wrap" [ngClass]="{ 'justify-end gap-3': !size.isXS, 'justify-start gap-4': size.isXS }">

        <svc-totals-status *ngIf="(env.isDEV || env.isQA) && _post"
          #svcTotalsStatus
          class="h-fit flex"
          [ngClass]="{ 'hidden': !svcTotalsStatus.isLoading && (svcTotalsStatus.totals.views + svcTotalsStatus.totals.comments + svcTotalsStatus.totals.reactions) <= 0 }"
          [referenceId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post.id"
          [configTypeId]="_post?.commentConfigTypeId"
          [applicationId]="_post?.applicationRegistry?.applicationId ?? appId"
          [type]="size.isXS ? 'icon' : 'text'"
          [featureName]="_post?.featureName"
          [available]="{ reactions: false }"
          [external]="!!_post?.applicationRegistry?.applicationId"
          [siteId]="_post?.site?.siteId"
          (commentsClick)="!svcTotalsStatus.isLoading && toggleCommentsChat()"
          [id]="'post-comments-total-'+_post.id"
        ></svc-totals-status>

        <a *ngIf="_post.sharedTimes" (click)="(env.isDEV || env.isQA) && usersShared()" class="flex items-center gap-1.5 text-default text-sm font-medium leading-tight" [ngClass]="{'cursor-pointer': env.isDEV || env.isQA}" [matTooltip]="'Compartilhamentos' | transloco" matTooltipPosition="above" [matTooltipDisabled]="!size.isXS">
          <ng-container *ngIf="size.isXS">
            <mat-icon class="icon-size-3.5 text-current" svgIcon="heroicons_solid:share"></mat-icon>
            <span class="text-xs">{{_post.sharedTimes}}</span>
          </ng-container>
          <ng-container *ngIf="!size.isXS">
            {{_post.sharedTimes}}
            <span *ngIf="_post.sharedTimes === 1">{{ 'Compartilhamento' | transloco }}</span>
            <span *ngIf="_post.sharedTimes > 1">{{ 'Compartilhamentos' | transloco }}</span>
          </ng-container>
        </a>
      </div>

    </div>

    <div class="border-t" [ngClass]="size.isXS && fromPostModal ? 'mb-1' : 'mb-2'"></div>

    <div class="flex flex-row items-stretch gap-4" [class.px-2]="size.isXS && fromPostModal">

      <svc-reaction
        #svcReaction
        class="flex flex-auto"
        [applicationId]="_post?.applicationRegistry?.applicationId ?? appId"
        [registryUniqueId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post.id"
        positionX="center"
        [disabled]="isReactionLoading"
        [siteId]="_post?.applicationRegistry?.applicationId ? _post?.site?.siteId : null"
        (onLoadingChange)="isReactionLoading = $event"
        (onReactionChange)="isReactionDone = !!($event)"
      >
        <button
          mat-button
          class="grow"
          (click)="!isReactionLoading && (isReactionDone ? svcReaction?.removeCurrentReaction() : svcReaction?.makeReactionByEnum(ReactionTypeEnum.LIKE))"
          [id]="'post-reaction-'+_post.id"
        >
          <div class="flex items-center justify-center" *ngIf="!isReactionLoading" [ngClass]="isReactionDone ? 'text-primary' : 'text-gray-600'">
            <mat-icon class="text-current" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
            <span class="flex ml-2" [class.text-xs]="size.isXS">{{ 'Reagir' | transloco }}</span>
          </div>
          <div class="flex align-center justify-center p-2" *ngIf="isReactionLoading">
            <mat-spinner diameter="20"></mat-spinner>
          </div>

        </button>
      </svc-reaction>


      <button
        *ngIf="env.isDEV || env.isQA"
        class="grow text-default"
        mat-button
        (click)="toggleCommentsChat()"
        [id]="'post-comments-'+_post.id"
      >
        <mat-icon class="text-current" svgIcon="mat_solid:chat"></mat-icon>
        <span class="flex ml-2" [class.text-xs]="size.isXS">{{ 'Comentar' | transloco }}</span>
      </button>

      <button
        class="grow text-default"
        mat-button
        (click)="sharePost()"
        [id]="'post-share-'+_post.id"
      >
        <mat-icon class="text-current" svgIcon="heroicons_solid:share"></mat-icon>
        <span class="flex ml-2" [class.text-xs]="size.isXS">{{ 'Compartilhar' | transloco }}</span>
      </button>
    </div>

    <svc-comments-chat
      #svcCommentsChat
      *ngIf="(env.isDEV || env.isQA) && _post && showComments"
      class="block mt-5 overflow-x-hidden overflow-y-auto"
      [class.px-2]="size.isXS && fromPostModal"
      [style.maxHeight.px]="530"
      [referenceId]="_post?.applicationRegistry?.applicationRegistryReferenceId ?? _post.id"
      [configTypeId]="_post?.commentConfigTypeId"
      [applicationId]="_post?.applicationRegistry?.applicationId ?? appId"
      [external]="!!_post?.applicationRegistry?.applicationId"
      [siteId]="_post?.site?.siteId"
      (onCommentAdded)="svcTotalsStatus?.refresh({ comments: true })"
      (onCommentRemoved)="svcTotalsStatus?.refresh({ comments: true })"
    ></svc-comments-chat>
  </div>

</ng-container>
