import {Injectable} from "@angular/core";
import {AppEnvironmentConfig} from "../../../../../config/model/environment.config.model";
import {HttpClient} from "@angular/common/http";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";

@Injectable({
  providedIn: 'root'
})
export class UserHttpClient extends SvcHttpClient {

  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlUsers, httpClient);
  }
}
