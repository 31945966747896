<div class="flex flex-col w-full">
  <mat-form-field *ngIf="!readonly" class="flex-auto svc-mat-no-subscript svc-mat-dense" [class.none-label]="!label">
    <mat-label class="input-label-size input-label-weight text-default">
      {{ label }}
    </mat-label>

    <div class="w-full flex items-center truncate">
      <input
        class="w-full opacity-0 absolute"
        matInput
        [formControl]="internalControl"
        (focus)="showMatCalendar()"
        (keypress)="$event.preventDefault()"
        (keydown)="$event.preventDefault()"
        (input)="$event.preventDefault()"
        (paste)="$event.preventDefault()"
        [id]="inputId"
      />
      <div *ngIf="textToShow" class="mat-mdc-chip truncate flex gap-1.5 items-center px-1 py-0.5" [matTooltip]="textToShowEl.scrollWidth > textToShowEl.clientWidth ? textToShow : null">
        <div #textToShowEl class="truncate">
          {{ textToShow }}
        </div>
        <button mat-icon-button class="w-3 h-3 min-h-0 min-w-0 bg-text-default text-white rounded-full cursor-pointer" (click)="removeDate($event)">
          <mat-icon class="icon-size-3.5 text-current" svgIcon="heroicons_mini:x-mark"></mat-icon>
        </button>
      </div>
    </div>

    <button *ngIf="showIcon" mat-icon-button matSufix (click)="showMatCalendar()" class="w-6 h-6 min-w-0 min-h-0 -mr-1.5">
      <mat-icon class="text-default icon-size-5" svgIcon="heroicons_solid:calendar-range">
      </mat-icon>
    </button>
  </mat-form-field>

  <svc-validation-message *ngIf="!readonly" [control]="internalControl"></svc-validation-message>

  <div *ngIf="readonly" class="flex flex-col flex-auto">
    <div class="flex flex-row items-center">
      <mat-icon *ngIf="showIcon" class="text-default icon-size-4 mr-1" svgIcon="heroicons_solid:calendar-range">
      </mat-icon>

      <span class="input-label-size input-label-weight text-default">
        {{ label }}
      </span>
    </div>

    <span class="text-default font-medium text-base" *ngIf="textToShow || fallbackTextValue">
      {{ textToShow || fallbackTextValue }}
    </span>
  </div>
</div>

<ng-template #matCalendarTemplate>
  <svc-date-picker-calendar
    [min]="minDate"
    [max]="maxDate"
    [startDate]="startDate"
    [currentDate]="currentDate"
    (onSelect)="onSelect($event)"
    (closeCalendar)="closeCalendar($event)"
  >
  </svc-date-picker-calendar>

</ng-template>
