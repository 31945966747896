import { NgModule } from '@angular/core';
import { SvcMapsComponent } from './svc-maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';


@NgModule({
  declarations: [SvcMapsComponent],
  exports: [SvcMapsComponent],
  imports: [
    LibSharedCommonModule,
    GoogleMapsModule,
  ]
})
export class SvcMapsModule { }
