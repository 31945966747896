import { Component, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { SvcTreeBranchComponent } from '../svc-tree-branch/svc-tree-branch.component';
import { SvcTreeComponent, SvcTreeItem, SvcTreeOrientation } from '../svc-tree.component';

@Component({
  selector: 'svc-tree-entry',
  templateUrl: './svc-tree-entry.component.html',
  styleUrls: ['./svc-tree-entry.component.scss']
})
export class SvcTreeEntryComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.is-vertical') private isVertical = true;
  @HostBinding('class.is-horizontal') private isHorizontal = true;

  @Input() input: SvcTreeItem<any>;
  @Input() orientation: SvcTreeOrientation = SvcTreeOrientation.VERTICAL;

  public entry: SvcTreeBranchComponent;
  public showBranches: boolean = true;

  constructor(
    @Inject(forwardRef(() => SvcTreeComponent))
    public parentTree: SvcTreeComponent,
    @Inject(forwardRef(() => SvcTreeBranchComponent))
    public parentBranch: SvcTreeBranchComponent,
  ){
    this.entry = this as any;
  }

  public ngOnInit(): void {
    this.isVertical = this.orientation === SvcTreeOrientation.VERTICAL;
    this.isHorizontal = this.orientation === SvcTreeOrientation.HORIZONTAL;
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  public toggleVisibility() {
    if (this.input.children?.length) {
      this.showBranches = !this.showBranches;
    }
  }

  public edit(){}
  
  public new(){}

  public ngOnDestroy(): void {
  }
}
