export interface SvcComment {
  commentId: number;
  commentParentId: number;
  commentText: string;
  translatedCommentText?: string;
  createDate: string;
  user: SvcCommentUser,
  attachments: SvcCommentAttachment[];
  totalAnswers: number;
  isOwnerComment: boolean;
  translated?: boolean;
}

export interface SvcCommentAttachment {
  assignUrl: string;
  name: string;
  type: SvcCommentAttachmentType;
}

export interface SvcCommentUser {
  id: string;
  name: string;
  color: string;
  picture: string;
}

export enum SvcCommentAttachmentType {
  UNDEFINED = 0,
  IMAGE = 1,
  PDF = 2,
  COMPRESS = 3,
  EXCEL = 4,
  POWER_POINT = 5,
  WORD = 6,
  AUDIO = 7,
  VIDEO = 8,
}
