import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/lib/classes/svc-http-client";

@Injectable()
export class SurveyHttpClient extends SvcHttpClient {
  constructor(appConfig: AppEnvironmentConfig, httpClient: HttpClient) {
    super(appConfig.APIs.apiUrlSurvey, httpClient);
  }
}
