export function isNullOrUndefined(object: any): boolean {
  return object === null || object === undefined;
}

export function isObject(object: any): boolean {
  return object !== null && typeof object === 'object';
}

export function pixelsToNumber(pixels: string) {
  pixels = (pixels ?? '').replace(/[^\d|.|-]/g, '');
  if (pixels) {
    return parseFloat(pixels);
  }
  return 0;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {};
}

// Função auxiliar para verificar se o valor é considerado válido
export function isValidValue(value) {
  // Checa se o valor é não-nulo, não-undefined, não-string vazia, e para arrays/objetos, se não estão vazios
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    (!(value instanceof Array) || value.length > 0) && // Para arrays, verifica se não estão vazios
    (!(typeof value === 'object' && !(value instanceof Array)) ||
      Object.keys(value).length > 0) // Para objetos (excluindo arrays), verifica se não estão vazios
  );
}

export function base64ToBlob(b64Data: string, contentType?: string, sliceSize?: number) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize ?? 512) {
    const slice = byteCharacters.slice(offset, offset + sliceSize ?? 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType ?? ''});
  return blob;
}
