export interface Notification {
  unreadCount: number;
  notifiticationsAreGone: boolean;
  notifications: NotificationItem[];
}

export interface NotificationItem {
  applicationId: string;
  applicationName: string;
  referenceId: number;
  referenceUid: string;
  userId: string;
  senderName: string;
  senderPicture: string;
  senderInitialsColor: string;
  title: string;
  message: string;
  badges: string;
  jsonCallback: string;
  createDate: string;
  unread: boolean;
  diffDateFromCreationDuration: number;
  diffDateFromCreationLabel: string;
  diffDateFromCreationLabelTranslated: string;
}

export interface NotificationOwner {
  ownerName: string;
  ownerPicture: string;
  ownerInitialsColor: string;
}

export interface SvcNotificationLink {
  url: string;
  params: string;
  applicationType: SvcNotificationType
}

export enum SvcNotificationType {
  ASP = 1,
  CORE = 2,
  ANGULAR = 3,
  EXTERNAL  = 4,
  MESSAGE = 5
}
