import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserNavigationService {
  #httpClient = inject(HttpClient)
  #appConfig = inject(AppEnvironmentConfig)

  public getQualificationsCounter(): Observable<number> {
    return this.#httpClient.get<number>(`${this.#appConfig.APIs.apiUrlTraining}/Qualifications`);
  }

  public getPraiseReceivedCounter(): Observable<number> {
    return this.#httpClient.get<number>(`${this.#appConfig.APIs.apiUrlPraise}/User/received/counter`);
  }
}
