import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcControlsModule } from '../svc-controls/svc-controls.module';
import { SvcFiltersComponent } from './svc-filters.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { SvcFilterDialogComponent } from './components/svc-filter-dialog/svc-filter-dialog.component';
import { SvcDialogsModule } from "../svc-dialogs/svc-dialogs.module";
import { TranslocoModule } from "@ngneat/transloco";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SvcButtonsModule } from "../svc-buttons/svc-buttons.module";
import { SvcFilterSidebarComponent } from "./components/svc-filter-sidebar/svc-filter-sidebar.component";
import { SvcDrawerModule } from "../svc-drawer/svc-drawer.module";
import { SvcSlideToggleModule } from '../svc-slide-toggle/svc-slide-toggle.module';

@NgModule({
  declarations: [
    SvcFiltersComponent,
    SvcFilterDialogComponent,
    SvcFilterSidebarComponent
  ],
  imports: [
    LibSharedCommonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SvcControlsModule,
    SvcSlideToggleModule,
    SvcDialogsModule,
    TranslocoModule,
    SvcButtonsModule,
    SvcDrawerModule,
  ],
  exports: [
    SvcFiltersComponent,
    SvcFilterDialogComponent,
    SvcFilterSidebarComponent,
  ]
})
export class SvcFiltersModule { }
