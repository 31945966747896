import {
  CommonIndicator,
  BaseCommonIndicatorChart,
  CommonIndicatorChartInfo,
} from 'projects/lib-shared-model/src/public-api';

export enum ActionPlanIndicatorStatus {
  COMPLETED = 40,
  IN_APPROVAL = 30,
  ON_TIME = 20,
  DELAYED = 10
}

export enum ActionPlanIndicatorStatusNames {
  'Cumprido' = 40,
  'Em Aprovação' = 30,
  'No Prazo' = 20,
  'Atrasado' = 10
}

export enum ActionPlanIndicatorCategory {
  LATE = 1,
  TODAY = 2,
  TOMORROW_UP_TO_7_DAYS = 3,
  MORE_THAN_7_DAYS = 4,
}

export enum ActionPlanIndicatorCategoryNames {
  'Atrasado' = 1,
  'Hoje' = 2,
  '<= 7 dias' = 3,
  '> 7 dias' = 4
}

export enum ActionPlanSeriesColor {
  '#466900' = 40,
  '#A9D08E' = 30,
  '#FFC000' = 20,
  '#E22A2E' = 10
}

export class ActionPlanIndicator extends BaseCommonIndicatorChart {
  data: CommonIndicator;
  chartInfo: CommonIndicatorChartInfo;
  chartOrder = [4,3,2,1];
  seriesOrder = [1,2,3,4];

  constructor(data: CommonIndicator) {
    super({
      categories: ActionPlanIndicatorCategory,
      statuses: ActionPlanIndicatorStatus,
      colors: ActionPlanSeriesColor,
      statusesNames: ActionPlanIndicatorStatusNames,
      categoriesNames: ActionPlanIndicatorCategoryNames
    });
    this.data = data;
    this.chartInfo = this.generateChartInfo();
  }

}
