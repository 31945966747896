<div class="flex flex-col">
  <ng-container *ngIf="!readonly">
    <div #formFieldContainer class="flex flex-auto">
      <mat-form-field class="flex-auto svc-mat-no-subscript svc-mat-dense">
        <mat-label *ngIf="label" class="input-label-size input-label-weight text-default">
          {{ label }}
        </mat-label>
        <input matInput
          [dropSpecialCharacters]="false"
          [mask]="mask"
          [disabled]="control?.disabled ?? false"
          (focus)="inputFocused = true; showMatCalendar()"
          (blur)="inputFocused = false; control?.markAsTouched(); closeMatCalendar()"
          (input)="onInternalValueChanged()"
          [id]="inputId"
        />
        <button *ngIf="showIcon" class="text-default -mr-2" mat-icon-button matSufix tabindex="-1" (click)="showMatCalendar()">
          <mat-icon class="text-current" svgIcon="mat_solid:today"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <svc-validation-message *ngIf="control?.enabled" [control]="control"></svc-validation-message>
  </ng-container>
  <div *ngIf="readonly" class="flex flex-col flex-auto">
    <div class="flex flex-row items-center input-label-size input-label-weight text-default">
      {{ label }}
    </div>
    <span class="text-default font-medium text-base">
      {{ control?.value ?? value | date:format }}
    </span>
  </div>
</div>

<ng-template #matCalendarTemplate>
  <div class="date-picker-calendar bg-white shadow border rounded" [style.width.px]="250">
    <mat-calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [selected]="currentDate"
      [startAt]="currentDate"
      (selectedChange)="onSelect($event)"
    >
    </mat-calendar>
  </div>
</ng-template>
