<div class="custom-header flex justify-between items-center gap-2 bg-white">
  <p class="text-sm font-medium text-black/60 capitalize">
    {{ calendar.activeDate | date: 'MMMM' : 'pt-BR' | transloco }}
    {{ calendar.activeDate | date: 'yyyy' }}
  </p>

  <div class="flex items-center gap-7">
    <button class="calendar-header-button" mat-icon-button (click)="previousClicked()">
      <mat-icon class="text-black/60 icon-size-4" svgIcon="heroicons_solid:chevron-left">
      </mat-icon>
    </button>

    <button class="calendar-header-button" mat-icon-button (click)="nextClicked()">
      <mat-icon class="text-black/60 icon-size-4" svgIcon="heroicons_solid:chevron-right">
      </mat-icon>
    </button>
  </div>
</div>
