import { NgModule } from '@angular/core';
import { SvcCalendarComponent } from './svc-calendar.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  imports: [
    LibSharedCommonModule,
    FullCalendarModule
  ],
  declarations: [SvcCalendarComponent],
  exports: [SvcCalendarComponent],
})
export class SvcCalendarModule {}
