import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcReactionComponent } from './svc-reaction.component';
import { SvcReactionService } from './svc-reaction.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SvcTotalReactionsComponent } from './svc-total-reactions/svc-total-reactions.component';
import { ModalTotalReactionsComponent } from './modal/modal-total-reactions/modal-total-reactions.component';
import { SvcDialogsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
  declarations: [
    SvcReactionComponent,
    SvcTotalReactionsComponent,
    ModalTotalReactionsComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SvcDialogsModule,
    SvcUserAvatarModule,
    TranslocoModule,
  ],
  providers: [
    SvcReactionService,
  ],
  exports: [
    SvcReactionComponent,
    SvcTotalReactionsComponent,
    ModalTotalReactionsComponent,
  ],
})
export class SvcReactionModule { }
