import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcToastGamifiedComponent } from './svc-toast-gamified.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';

@NgModule({
  declarations: [
    SvcToastGamifiedComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule
  ],
  exports: [
    SvcToastGamifiedComponent
  ]
})
export class SvcToastGamifiedModule { }
