import { Component, Input } from '@angular/core';

@Component({
  selector: 'svc-progress-bar',
  templateUrl: './svc-progress-bar.component.html',
  styleUrls: ['./svc-progress-bar.component.scss'],
})
export class SvcProgressBarComponent {
  @Input('progress') progress = 0;
  @Input('color') color: string;
  @Input('color-degraded') degraded: any;
  @Input('disable-percentage') disabledP: boolean;

  constructor() {
    // Default color
    this.color = '#488aff';
    this.disabledP = false;
  }

  whichColor(percent: number) {
    let k: Array<any> = Object.keys(this.degraded);
    k.forEach((e, i) => (k[i] = +e));
    k = k.sort((a, b) => a - b);
    let p = +percent;
    let last = k[0];
    for (let val of k) {
      if (val < p) {
        last = val;
      } else if (val >= p - 1) {
        return this.degraded[last];
      }
    }
    return this.degraded[last];
  }

  whichProgress(progress: number) {
    try {
      return Math.round(+progress * 100) / 100;
    } catch {
      return progress;
    }
  }
}
