import { ISvcAvatarMultiple, UserAvatarType } from 'projects/lib-shared-component/src/lib/svc-user-avatar/interfaces/svc-avatar-multiple.interface';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';

import { PraiseItemCard } from '../models/praise-item-card.model';
import { PraiseItem } from '../models/praise-item.model';

export function transformPraiseItemToCard(praises: PraiseItem[], color: string): PraiseItemCard[] {
  return praises.map((praise: PraiseItem) => ({
    id: praise.praiseId,
    title: praise.titleDescription,
    description: praise.text,
    user: {
      name: praise.originatorName,
      id: praise.userOriginatorId
    },
    avatar: {
      type: UserAvatarType.user,
      size: UserAvatarSize.Card,
      initialsColor: praise.recipientUsers.length > 0 ? praise.recipientUsers[0].initialColor : color,
      data: [
        ...praise.recipientUsers.map(i => ({
          name: i.userName,
          picture: i.userPictureFile,
          initialsColor: i.initialColor ?? color,
          type: i.ownerType ?? 1,
        })),
        ...praise.recipientTeams.map(i => ({
          name: i.groupName,
          picture: i.pictureFile,
          initialsColor: i.initialColor ?? color,
          type: i.ownerType ?? 1,
        })),
      ]
    } as ISvcAvatarMultiple,
    date: praise.createDate,
    hasReactions: true,
    hasComments: true
  }));
}
