import { NgModule } from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LanguagesModule } from 'projects/lib-shared-core/src/lib/features/languages/languages.module';
import { UserModule } from 'projects/lib-shared-core/src/lib/features/user/user.module';
import { SitesModule } from 'projects/lib-shared-core/src/lib/features/sites/sites.module';
import { AppsModule } from 'projects/lib-shared-core/src/lib/features/apps/apps.module';
import { SettingsModule } from 'projects/lib-shared-core/src/lib/features/settings/settings.module';
import { SolvaceCustomLayoutComponent } from './solvace-custom-layout.component';
import { SvcNotificationsModule } from '../../../../general/svc-notifications/svc-notifications.module';
import { LibSharedComponentModule, SvcToastModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { LayoutComponentsModule } from '../components/layout-components.module';

@NgModule({
  declarations: [SolvaceCustomLayoutComponent],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LanguagesModule,
    SvcNotificationsModule,
    UserModule,
    SitesModule,
    AppsModule,
    SettingsModule,
    TranslocoModule,
    SvcUserAvatarModule,
    SvcToastModule,
    LayoutComponentsModule,
  ],
  exports: [SolvaceCustomLayoutComponent],
})
export class SolvaceCustomLayoutModule {}
