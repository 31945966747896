import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SvcToastService } from "projects/lib-shared-component/src/public-api";

@Injectable({
	providedIn: 'root'
})
export class NotificationIntegrationService {

	private _renderer2: Renderer2;
	private get _iframeEl(): HTMLIFrameElement {
		return this._document.querySelector('#notification-modal-integration');
	}

	constructor(
		@Inject(DOCUMENT) private _document: any,
		private _renderer: RendererFactory2,
		private _toastService: SvcToastService,
	) {
		this._renderer2 = this._renderer.createRenderer(null, null);
	}

	public addIntegration() {
		if (!this._iframeEl) {
			const html: string = `<iframe id="notification-modal-integration" class="hidden"></iframe>`;
			const template = this._renderer2.createElement('template');
			template.innerHTML = html.trim();
			this._renderer2.appendChild(this._document.body, template.content.firstChild);

			window['closeAngularNotification'] = () => {
				this.closeIframe();
			};
			window['angularNotificationShowToast'] = (data, config) => {
				return this._toastService.showByConfig(data, config);
			};
			window['angularNotificationCloseToast'] = (id) => {
				this._toastService.close(id);
			};
			window['angularNotificationClearToasts'] = () => {
				this._toastService.clear();
			};
			window['angularNotificationUnauthorized'] = () => {
				this.closeIframe();
			};
		}
	}

	public openIframeByPath(urlPath: string) {
		urlPath = !urlPath.startsWith('http') && !urlPath.startsWith('/') ? `/${urlPath}` : urlPath;
		urlPath = !urlPath.startsWith('http') && !urlPath.startsWith('/notification') ? `/notification${urlPath}` : urlPath;
		const origin = location.hostname == 'localhost' ? 'https://devsp.solvacelabs.com' : location.origin;
		const url = urlPath.startsWith('/') ? `${origin}${urlPath}` : urlPath;
		this._iframeEl.src = url;
		this._iframeEl.classList.remove('hidden', 'fadeOut');
		this._iframeEl.classList.add('fadeIn');
	}

	public closeIframe() {
		this._iframeEl.classList.add('fadeOut');
		this._iframeEl.classList.remove('fadeIn');
		setTimeout(() => {
			this._iframeEl.classList.add('hidden');
			this._iframeEl.src = null;
		}, 300);
	}
}
