<div [matDialogFullscreen]="fullscreen" [fullscreenDisabled]="!fullscreen || fullscreen.length === 0"
  (onFullScreen)="inFullscreen = $event" class="flex flex-col flex-auto bg-white h-full">
  <div class="flex items-center justify-between border-b border-inherit" mat-dialog-title>
    <div class="svc-dialog-title flex gap-2 text-xl font-bold text-default">
      <ng-content select="[svc-dialog-title-icon]"></ng-content>
      <ng-content select="[svc-dialog-title]"></ng-content>
    </div>
    <button mat-icon-button (click)="closeDialog()" class="close-btn -mr-2">
      <mat-icon class="text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>

  <div class="flex-auto overflow-auto" [style.max-height]="!inFullscreen ? 'calc(100vh - 250px)' : null">
    <ng-content select="[svc-dialog-content]" mat-dialog-content></ng-content>
  </div>

  <div [align]="'end'" class="border-t border-inherit" mat-dialog-actions>
    <ng-content select="[svc-dialog-actions]"></ng-content>
  </div>
</div>
