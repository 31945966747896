import { NgModule } from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import { ModuleHttpClient } from './module-http-client';
import { LibServiceKaizenAnalyticsService } from './lib-service-kaizen-analytics.service';

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule
  ],
  exports: [
  ],
  providers: [
    ModuleHttpClient,
    LibServiceKaizenAnalyticsService,
  ],
})
export class LibServiceKaizenAnalyticsModule { }
