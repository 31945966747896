import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ISvcChipOption } from 'projects/lib-shared-component/src/public-api';

@Component({
  selector: 'svc-user-picker-option',
  templateUrl: './svc-user-picker-option.component.html',
  styleUrls: ['./svc-user-picker-option.component.scss'],
})
export class SvcUserPickerOptionComponent implements OnDestroy {

  @Input() public option: ISvcChipOption;

  ngOnDestroy(): void {
  }
}
