import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SurveyModal } from '../models/survey-modal';
import { SurveyHttpClient } from './survey-http-client';

@Injectable()
export class SurveyModalService {
  private metadataSubject = new BehaviorSubject<any>(null);
  metadataObservable = this.metadataSubject.asObservable();

  private saveSubject = new BehaviorSubject<boolean>(false);
  save$ = this.saveSubject.asObservable();

  #http = inject(SurveyHttpClient);

  public sendData(data: any) {
    this.metadataSubject.next(data);
  }

  public isSave(valid: boolean) {
    this.saveSubject.next(valid);
  }

  getQuestions(surveyId: number): Observable<SurveyModal> {
    return this.#http.get<SurveyModal>(`/Survey/${surveyId}/questions`);
  }

  public sendSurvey(
    surveyId: number,
    payload: any
  ): Observable<{ surveyId: number }> {
    return this.#http.patch<{ surveyId: number }>(
      `/Survey/${surveyId}/questions/answers`,
      payload
    );
  }
}
