import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceDefecttagAnalyticsService } from './lib-service-defecttag-analytics.service';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [],
  providers: [
    ModuleHttpClient,
    LibServiceDefecttagAnalyticsService,
  ],
})
export class LibServiceDefecttagAnalyticsModule { }
