import { ChangeDetectionStrategy, Component, Inject, InjectionToken, TemplateRef } from "@angular/core";

export const TOOLTIP_TEMPLATE = new InjectionToken('TOOLTIP_TEMPLATE');
export const TOOLTIP_MAX_WIDTH = new InjectionToken('TOOLTIP_MAX_WIDTH');

@Component({
  selector: 'app-template-tooltip',
  templateUrl: './template-tooltip.component.html',
  styleUrls: ['./template-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateTooltipComponent {
  constructor(
    @Inject(TOOLTIP_TEMPLATE) public tooltipTemplate: TemplateRef<void>,
    @Inject(TOOLTIP_MAX_WIDTH) public maxWidth: string) { }
}
