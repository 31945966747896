import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import {
  ISvcSelectOption,
  SvcToastService
} from 'projects/lib-shared-component/src/public-api';
import { All, UserService } from 'projects/lib-shared-core/src/public-api';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import {
  Subject,
  catchError,
  distinctUntilChanged,
  finalize,
  takeUntil,
  tap,
} from 'rxjs';
import { LevelsEnum } from '../../enums/levels.enum';
import { QuestionTypesEnum } from '../../enums/question-types.enum';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-list-of-employees-answer',
  templateUrl: './list-of-employees-answer.component.html',
  styleUrls: ['./list-of-employees-answer.component.scss'],
})
export class ListOfEmployeesAnswerComponent implements OnInit {
  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public options: ISvcSelectOption[] = [];
  public isLoading = false;
  public LevelsEnum = LevelsEnum;
  public selected: string[];
  public label: string;
  public isSave = false;

  #formBuilder = inject(FormBuilder);
  #userService = inject(UserService);
  #toastService = inject(SvcToastService);
  #translocoService = inject(TranslocoService);
  #httpErrorService = inject(HttpErrorService);
  #modalService = inject(SurveyModalService);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.form = this.#formBuilder.group({
      userId: [],
    });

    if (this.question) {
      this.form.reset();

      if (this.question.questionTypeId === QuestionTypesEnum.Employees_List) {
        const level = this.question?.metadata?.level;
        if (level === LevelsEnum.SITE) {
          this.label = 'Site';
          this.getSite();
        }
        if (level === LevelsEnum.AREA) {
          this.label = 'Área';
          this.getAreas();
        }
        if (level === LevelsEnum.TIME) {
          this.label = 'Time';
          this.getTeams();
        }
      }
    }

    this.changesAnswered();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  private changesAnswered() {
    this.form?.get('userId')?.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap((response) => {
          this.question.answered = !!(response);
          this.selected = response;
        }),
      )
      .subscribe();
  }

  private getSite(): void {
    this.isLoading = true;
    this.#userService
      .getEmployeesSite()
      .pipe(
        tap(
          (site) =>
            (this.options = this.normalizeResponseToSelect<All>(
              site,
              'name',
              'userId'
            ))
        ),
        catchError((error) => {
          this.#toastService.error(
            this.#translocoService.translate(
              this.#httpErrorService.getErrorMessage(error)
            )
          );
          throw error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private getAreas(): void {
    this.isLoading = true;
    this.#userService
      .getEmployeesAreas(this.question.metadata?.areaIds)
      .pipe(
        tap(
          (areas) =>
            (this.options = this.normalizeResponseToSelect<All>(
              areas,
              'name',
              'userId'
            ))
        ),
        catchError((error) => {
          this.#toastService.error(
            this.#translocoService.translate(
              this.#httpErrorService.getErrorMessage(error)
            )
          );
          throw error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private getTeams(): void {
    this.isLoading = true;
    this.#userService
      .getEmployeesTeams(this.question.metadata?.teamIds)
      .pipe(
        tap(
          (teams) =>
            (this.options = this.normalizeResponseToSelect<All>(
              teams,
              'name',
              'userId'
            ))
        ),
        catchError((error) => {
          this.#toastService.error(
            this.#translocoService.translate(
              this.#httpErrorService.getErrorMessage(error)
            )
          );
          throw error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    this.doSave.emit({ UserId: this.selected });
  }

  private normalizeResponseToSelect<T>(
    response: T[],
    keyText: string,
    keyValue: string
  ) {
    return response?.map((item) => {
      return {
        text: item[keyText],
        value: item[keyValue],
      };
    });
  }
}
