import {Component, HostBinding, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'svc-breadcrumb-item',
  templateUrl: './svc-breadcrumb-item.component.html',
  styleUrls: ['./svc-breadcrumb-item.component.scss']
})
export class SvcBreadcrumbItemComponent implements OnInit {

  @Input() link: string;
  @Input() active: boolean = false;
  @Input() type: 'DEFAULT' | 'HOME' = 'DEFAULT';

  public get hasLink() {
    return this.link;
  }

  public get linkIsExternal() {
    return this.link?.startsWith('http');
  }

  constructor(
    private _router: Router,
  ){}

  public ngOnInit(): void {
    if (this.type === 'HOME') {
      this.link = `${location.origin}/myworkspace/`;
    }
  }
}
