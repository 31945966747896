<button *ngIf="!small"
	class="text-left mat-elevation-z1"
	[class.no-hover-shadow]="!shadow"
	[matMenuTriggerFor]="!isRecording && canChooseRecording ? matMenu : null"
	mat-mini-fab
	color="primary"
	type="button"
	[disabled]="disabled"
	(click)="isRecording ? recordAudio() : (!canChooseRecording && recordAudio(ActionType.AUDIO_TO_TEXT))"
>
	<ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ iconSizeClass: 'icon-size-7' }"></ng-container>
</button>

<button *ngIf="small"
	class="small-button text-left mat-elevation-z1"
	[class.no-hover-shadow]="!shadow"
	[matMenuTriggerFor]="!isRecording && canChooseRecording ? matMenu : null"
	mat-mini-fab
	color="primary"
	type="button"
	(click)="isRecording ? recordAudio() : (!canChooseRecording && recordAudio(ActionType.AUDIO_TO_TEXT))"
	[disabled]="disabled"
>
	<ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ iconSizeClass: 'icon-size-5' }"></ng-container>
</button>

<ng-template #content let-iconSizeClass="iconSizeClass">
	<mat-icon *ngIf="!loading && step == 'TO_RECORD'" [ngClass]="iconSizeClass" svgIcon="heroicons_mini:microphone"></mat-icon>
	<mat-icon *ngIf="!loading && step == 'RECORDING'" [ngClass]="iconSizeClass" svgIcon="mat_solid:stop"></mat-icon>
	<mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
</ng-template>

<mat-menu #matMenu xPosition="before">
	<button mat-menu-item (click)="recordAudio(ActionType.RECORD_AUDIO)">{{ 'Gravar áudio' | transloco }}</button>
	<button mat-menu-item (click)="recordAudio(ActionType.AUDIO_TO_TEXT)">{{ 'Voz para texto' | transloco }}</button>
</mat-menu>