import { Route } from '@angular/router';
import { SvcDialogResolver } from 'projects/lib-shared-component/src/public-api';
import {SvcProcessComponent} from "./svc-process.component";
import { AvailableRouteGuard } from 'projects/lib-shared-core/src/public-api';

export const processRoutes: Route[] = [
    {
        path     : '',
        resolve: {
            dialogRef: SvcDialogResolver
        },
        canActivate: [AvailableRouteGuard],
        component: SvcProcessComponent
    }
];
