import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { LoadingBarService } from './loading.service';

@Injectable()
export class LoadingBarInterceptor implements HttpInterceptor {

	constructor(
		private _loadingBarService: LoadingBarService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		this._loadingBarService._setLoadingStatus(true, req.url);

		return next.handle(req).pipe(
			tap({
				next: () => this._loadingBarService._setLoadingStatus(false, req.url),
				complete: () => this._loadingBarService._setLoadingStatus(false, req.url),
				finalize: () => this._loadingBarService._setLoadingStatus(false, req.url),
				error: () => this._loadingBarService._setLoadingStatus(false, req.url)
			})
		);
	}
}
