import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ISvcSelectOption } from '../svc-controls/svc-select/svc-select.component';

@Component({
  selector: 'svc-custom-fields',
  templateUrl: './svc-custom-fields.component.html',
  styleUrls: ['./svc-custom-fields.component.scss']
})

export class SvcCustomFieldsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() options: ISvcSelectOption[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initializeCustomTypeListeners();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get customFields(): FormArray {
    return this.form.get('customFields') as FormArray;
  }

  private initializeCustomTypeListeners(): void {
    this.customFields.controls.forEach((field, index) => {
      this.subscribeToCustomTypeChange(field, index);
    });
  }

  private subscribeToCustomTypeChange(field: AbstractControl, index: number): void {
    const sub = field.get('customType')?.valueChanges.subscribe(value => {
      this.handleCustomTypeChange(value, index);
    });
    if (sub) {
      this.subscriptions.push(sub);
    }
  }

  private handleCustomTypeChange(customType: number, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);
    // 4 = 'caixa'
    if (customType !== 4) {
      options.clear();
    }
  }

  getOptions(fieldIndex: number): FormArray {
    return this.customFields.at(fieldIndex).get('options') as FormArray;
  }

  addOption(fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.push(this.createOptionGroup());
  }

  removeOption(fieldIndex: number, optionIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.removeAt(optionIndex);
  }

  private createOptionGroup(): FormGroup {
    return this.fb.group({
      value: ['', Validators.required]
    });
  }

  addCustomField(): void {
    const customFieldGroup = this.fb.group({
      customId: '',
      customName: ['', Validators.required],
      customType: ['', Validators.required],
      isActive: [false],
      options: this.fb.array([this.createOptionGroup()])
    });

    const index = this.customFields.length;
    this.customFields.push(customFieldGroup);
    this.subscribeToCustomTypeChange(customFieldGroup, index);
  }

  removeCustomField(index: number): void {
    this.customFields.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex).controls;
    moveItemInArray(options, event.previousIndex, event.currentIndex);

    this.getOptions(fieldIndex).setValue(options.map(control => control.value));
  }
}
