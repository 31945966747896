<form [formGroup]="form" *screenSizes="let size" [ngClass]="{ 'pointer-events-none': readonly }">
  <mat-radio-group formControlName="selectedOption">
    <mat-radio-button *ngFor="let option of options; let i = index" class="flex" color="primary" [value]="option">
      {{ option }}
    </mat-radio-button>
  </mat-radio-group>
</form>

<div class="flex justify-end mt-3">
  <mat-icon *ngIf="isAnswered" class="icon-size-7 text-green-500" svgIcon="heroicons_outline:check-circle"></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
