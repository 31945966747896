import { Injectable } from '@angular/core';
import { SvcFaqHttpClient } from "./svc-faq-http-client";
import { SvcFaq, SvcFaqAnswer, SvcFaqQuestion } from "./svc-faq";
import { Observable, map } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SvcFaqService {

  constructor(
    private _faqHttpClient: SvcFaqHttpClient,
    private _httpClient: HttpClient,
  ) { }

  public getFaqList(applicationId: string) {
    return this._faqHttpClient.get<SvcFaq[]>('/Faq', {
      params: { applicationId }
    }).pipe(
      map((response) => {
        return response.map((item) => <SvcFaq>({
          ...item,
          trainingQuestions: item.trainingQuestions?.map((q) => <SvcFaqQuestion>({
            ...q,
            answers: q.answers?.map((a) => {
              const extension = (() => {
                if (a.file) {
                  const spplited = a.file.split('.');
                  return spplited[spplited.length - 1];
                }
                return null;
              })();
              return <SvcFaqAnswer>{
                ...a,
                extension,
                isImage: ['jpeg', 'jpg', 'png'].includes(extension),
                isVideo: ['mpeg', 'mpg', 'mp4', 'mov'].includes(extension),
              };
            }) ?? []
          })) ?? [],
        }));
      }),
    )
  }

  public getAudioByAnswerId(answerId: number) {
    return new Observable<{ base64: string }>((subscriber) => {
      const timeout = setTimeout(() => {
        subscriber.error(new HttpErrorResponse({
          error: {
            errors: {
              Server: 'Não foi possível transformar o texto em áudio.'
            }
          }
        }));
      }, 1000)

      return () => {
        clearTimeout(timeout);
        subscriber.complete();
      };
    });
  }
}
