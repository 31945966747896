import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcToastItemComponent } from './svc-toast-item/svc-toast-item.component';
import { SvcToastComponent } from './svc-toast/svc-toast.component';

@NgModule({
  declarations: [
    SvcToastComponent,
    SvcToastItemComponent,
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    MatIconModule,
  ],
  exports: [
    SvcToastComponent,
    SvcToastItemComponent,
  ]
})
export class SvcToastModule { }
