import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ISvcUserAvatar, UserAvatarSize} from "./interfaces/svc-user-avatar.interface";

@Component({
  selector: 'svc-user-avatar',
  templateUrl: './svc-user-avatar.component.html',
  styleUrls: ['./svc-user-avatar.component.scss']
})
export class SvcUserAvatarComponent implements OnInit, OnChanges{
  @Input() info: ISvcUserAvatar;
  @Input('border-color') borderColor: string = 'white';

  public fontSize: number;
  public size: number;
  public initials: string;

  public UserAvatarSize = UserAvatarSize;
  private wasInitialized = false;

  ngOnInit() {
    const names = this.info?.name?.replace(/\([^)]*\)|[^a-zA-Z 0-9]/g, '').trim().split(' ') ?? [];
    const firstName = names?.length ? names[0].substring(0, 1) : '';
    const lastName = names?.length > 1 ? names[names.length - 1].substring(0, 1) : '';
    this.initials = `${firstName}${lastName}`.toUpperCase();
    this.size = ((this.info?.size == UserAvatarSize.Workspace) ? 150 : this.info?.size) ?? 30;
    this.fontSize = this.size * 0.5;
    this.wasInitialized = true;
  }

  ngOnChanges() {
    if (this.wasInitialized) {
      this.ngOnInit();
    }
  }
}
