import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FormatNumberDefaultPipe } from './format-number.pipe';
import { dateStringToDate } from '../functions/date-functions';
import { formatNumberDefault } from '../functions/number-conversion';

@Pipe({
	name: 'timeHasPassed',
})
export class TimeHasPassedPipe implements PipeTransform {

	constructor(
		private _translocoService: TranslocoService,
	) { }

	public transform(date: Date | string) {
		if (date) {
			date = typeof date == 'string' ? dateStringToDate(`${date}Z`, 'yyyy/MM/dd') : date;
			let dateValue = this.getDateInMinutes(date);
			let convertedValue = dateValue;
			let unity = TimeUnity.m;

			// Seconds
			if (dateValue < 1) {
				convertedValue = Math.round(dateValue * 60);
				unity = TimeUnity.s;
			}
			else {
				dateValue = Math.round(dateValue);
				// Minutes
				if (dateValue < 60) {
					convertedValue = dateValue;
					unity = TimeUnity.m;
				}
				// Hours
				else if (dateValue < (24 * 60)) {
					convertedValue = Math.ceil(dateValue / 60);
					unity = TimeUnity.h;
				}
				// Days
				else if (dateValue < (7 * 24 * 60)) {
					convertedValue = Math.ceil(dateValue / (24 * 60));
					unity = TimeUnity.d;
				}
				// Weeks
				else if (dateValue < (4 * 7 * 24 * 60)) {
					convertedValue = Math.ceil(dateValue / (60 * 24 * 7));
					unity = TimeUnity.S;
				}
				// Months
				else if (dateValue < (12 * 4 * 7 * 24 * 60)) {
					convertedValue = Math.ceil(dateValue / (60 * 24 * 7 * 4));
					unity = TimeUnity.M;
				}
				// Years
				else if (dateValue > (12 * 4 * 7 * 24 * 60)) {
					convertedValue = Math.ceil(dateValue / (60 * 24 * 7 * 4 * 12));
					unity = TimeUnity.a;
				}
			}
			if (unity == TimeUnity.s) {
				return this.getUnity(convertedValue, unity);
			}
			return formatNumberDefault(Math.max(0, convertedValue)) + ' ' + this.getUnity(convertedValue, unity);
		}
		return date;
	}

	private getUnity(value: number, unity: TimeUnity): string {
		switch (unity) {
			case TimeUnity.s:
				return this._translocoService.translate('agora');
			case TimeUnity.m:
				return this._translocoService.translate(value > 1 ? 'minutos' : 'minuto');
			case TimeUnity.h:
				return this._translocoService.translate(value > 1 ? 'horas' : 'hora');
			case TimeUnity.d:
				return this._translocoService.translate(value > 1 ? 'dias' : 'dia');
			case TimeUnity.S:
				return this._translocoService.translate(value > 1 ? 'semanas' : 'semana');
			case TimeUnity.M:
				return this._translocoService.translate(value > 1 ? 'meses' : 'mês');
			case TimeUnity.a:
				return this._translocoService.translate(value > 1 ? 'anos' : 'ano');
		}
	}

	private getDateInMinutes(value: Date): number {
		let timeDiff = new Date().getTime() - value.getTime();
		let minutes = timeDiff / 60000;
		return minutes;
	}

}

enum TimeUnity {
	s = 's',
	m = 'm',
	h = 'h',
	d = 'd',
	S = 'S',
	M = 'M',
	a = 'a',
}