import { Pipe, PipeTransform } from '@angular/core';
import {formatNumberDefault} from "../functions/number-conversion";

@Pipe({
  name: 'blobToUrl'
})
export class BlobToUrlPipe implements PipeTransform {

  transform(blob: Blob): string {
    return URL.createObjectURL(blob);
  }

}
