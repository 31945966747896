import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatAddButtonComponent } from './float-add-button.component';
import { MatIconModule} from "@angular/material/icon";
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule} from "@ngneat/transloco";

@NgModule({
  declarations: [
    FloatAddButtonComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule
    ],
  exports: [
    FloatAddButtonComponent
  ]
})
export class FloatAddButtonModule { }
