import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { SvcUserPreferencesFeatures } from '../models/svc-user-preferences.model';
import { UserHttpClient } from '../user-http-client';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(private http: UserHttpClient) { }

  public getUserPreferencesFeatures(applicationId: string): Observable<SvcUserPreferencesFeatures[]> {
    return this.http.get(`/userpreference/features/${applicationId}`);
  }

  public getByFeatureName(applicationId: string, featureName: string): Observable<SvcUserPreferencesFeatures> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.find(x => x.featureName == featureName))
    );
  }

  public getByFeatureNames(applicationId: string, featureNames: string[]): Observable<SvcUserPreferencesFeatures[]> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.filter(x => featureNames.includes(x.featureName)))
    );
  }

  public updateUserPreferences(applicationId: string, featurePreferences: SvcUserPreferencesFeatures): Observable<boolean> {
    return this.http.put(`/userpreference/features`, {
      applicationId,
      featurePreferences
    })
  }

}
