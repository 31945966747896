import {Inject, Injectable} from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser, EnvironmentInfo, SsoItem } from './model/environment.info.model';
import { AWSAmplifyAuthInfo, AWSAmplifyAuthResult, AWSAmplifyLoginResponse, AWSAmplifyOAuth } from './model/aws-amplify-auth-info';
import { APPNAME } from '../Constants/app-injection-token';

@Injectable({
  providedIn: 'root',
})
export class AuthCognitoService {
  constructor(
    @Inject(APPNAME) public APPNAME: string,
  ) {}

  applyCongnitoConfig(env: EnvironmentInfo): void {
    Auth.configure(new AWSAmplifyAuthInfo(env, this.APPNAME));
  }

  ssoSignIn(sso: SsoItem): void {
    Auth.federatedSignIn({ customProvider: sso.id });
  }

  signIn(credentials: {
    user: string;
    password: string;
  }): Promise<AWSAmplifyLoginResponse> {
    const config = Auth.configure().oauth as AWSAmplifyOAuth;
    return Auth.signIn(credentials.user, credentials.password).then((user) => {
      return new Promise((resolve) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED')
          resolve({
            result: AWSAmplifyAuthResult.NewPasswordRequired,
            currentUser: user,
            oAuth: config,
          });
        else
          resolve({
            result: AWSAmplifyAuthResult.Success,
            currentUser: user,
            oAuth: config,
          });
      });
    });
  }

  getCurrentUser(urlEnv: string): Promise<CognitoUser | null> {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser().then((user) => {
        const cognitoUser: CognitoUser = {
          ssoId: user.challengeParam ? '' : user.attributes.sub,
          ssoUsername: user.username,
          username: (user.attributes ? ((user.attributes.identities && user.attributes.email) ? user.attributes.email : user.username.replace('sso_', '').replace('sso2_', '').replace('sso3_', '').replace('sso4_', '')) : user.username),
          firstName: user.challengeParam ? user.challengeParam.userAttributes.name : user.attributes.name??'',
          lastName: user.challengeParam ? '' : user.attributes.family_name??'',
          fullName: user.challengeParam ? user.challengeParam.userAttributes.name : user.attributes.name + ((user.attributes.family_name) ? ' ' + user.attributes.family_name : ''),
          email: user.challengeParam ? user.challengeParam.userAttributes.email : user.attributes.email,
          isFederated: !!user.attributes && !!user.attributes.identities,
          accessToken: user.challengeParam ? '' : user.signInUserSession.accessToken.jwtToken,
          baseUrl: urlEnv
        };
        resolve(cognitoUser);
      }).catch(() =>  resolve(null));
    });
  }

  getCurrentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  sendVerificationCode(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  resetPasswordWithVerificationCode(
    code: string,
    username: string,
    password: string
  ): Promise<string> {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  changeTemporaryPassword(user: CognitoUser, password: string): Promise<any> {
    return Auth.completeNewPassword(user, password);
  }

  changePassword(user: CognitoUser, oldPassword: string, newPassword: string) {
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  signOut(): Promise<any> {
    return Auth.signOut({ global: true }).then(() => {});
  }

  async getUserAttribute(name: string) {
    const user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(user);
    return attributes.find(a => a.Name === name);
  }

  async setUserAttribute(name: string, value: any) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, { [name]: value });
  }
}
