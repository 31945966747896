<div class="flex font-medium items-center" *screenSizes="let size">
  <mat-icon #showMenuButton
    *ngIf="size.isBelowMD"
    svgIcon="mat_solid:chevron_left"
    class="mr-1 cursor-pointer"
    (click)="toogleBreadcrumb($event)"
  ></mat-icon>
  <section #list class="breadcrumb-list flex items-center" [ngClass]="{ 'hidden-except-last': size.isBelowMD }">
	  <ng-content></ng-content>
  </section>
  <section #menu class="breadcrumb-menu flex flex-col" [ngClass]="{ 'hidden': !showMenuItems }" (click)="handleMenuClick($event)"></section>
</div>
