import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { ISvcSelectOption, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Subject, catchError, distinctUntilChanged, finalize, first, takeUntil, tap } from "rxjs";
import { FormModeEnum } from '../models/form-mode.enum';
import { UserRole } from '../models/user-role.model';
import { SvcAccessPermissionService } from "../svc-access-permission.service";

@Component({
  selector: 'svc-access-permission-form',
  templateUrl: './svc-access-permission-form.component.html',
  styleUrls: ['./svc-access-permission-form.component.scss']
})
export class SvcAccessPermissionFormComponent implements OnInit, OnDestroy {

  @Output() public onAccessAdded = new EventEmitter<void>();

  form!: FormGroup;
  submitted = false;
  listPermission: ISvcSelectOption[] = [];
  temporaryListPermission: ISvcSelectOption[] = [];
  applicationId: string;
  moduleName: string;
  roles: { roleId: string, roleName: string, propName: string }[] = [];
  loading = false;
  #lastChecked = ''; // Guardar o último controle alterado
  mode: FormModeEnum;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private formBuilder: FormBuilder,
    private _accessPermitionService: SvcAccessPermissionService,
    private _activatedRoute: ActivatedRoute,
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _elementRef: ElementRef<HTMLElement>,
    private _toastService: SvcToastService,
    private _httpErrorService: HttpErrorService,
  ) {
    this.moduleName = this._translocoService.translate(_appSettings.applicationName);
    this.applicationId = _appSettings.applicationId;
  }

  get isCreateMode(): boolean {
    return this.mode === FormModeEnum.add;
  }

  get isEditMode(): boolean {
    return this.mode === FormModeEnum.edit;
  }

  get isRemoveMode(): boolean {
    return this.mode === FormModeEnum.remove;
  }

  ngOnInit() {
    this.getRoles();
    this.getUsersPerRoleAvailables();

    this.form = this.formBuilder.group({
      userId: ['', Validators.required],
    });

    this.valueChanges();
  }

  private valueChanges() {
    // For Survey only
    if (this.applicationId === '41CE2D11-957D-40F9-9D3E-9BAF32496CDF') {
      this.form?.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this._unsubscribeAll)
      ).subscribe(value => {
        // If you need to use the form's valueChanges for other actions, pass the if here.
        const currentChecked = Object.keys(value).find(key => value[key] === true && key !== this.#lastChecked);
        if (currentChecked) {
          this.roles.forEach(role => {
            if (role.propName !== currentChecked) {
              this.form.get(role.propName)?.setValue(false, { emitEvent: false });
            }
          });
          this.#lastChecked = currentChecked;
        }
      });
    }
  }

  public editUser(user: UserRole) {
    this.mode = FormModeEnum.edit;
    this.temporaryListPermission = [{
      text: user.firstLastName,
      value: user.userId,
    }];
    this.form.get('userId').setValue(user.userId);
    this.form.get('userId').disable();
    this.roles.forEach((x) => {
      const role = user.roleItems.find(r => r.roleName.replace(/\s/g, '').toLowerCase() == x.propName);
      this.form.get(x.propName).setValue(role ? true : false);
    });
  }

  public addNew(options?: { focus: boolean }) {
    this.mode = FormModeEnum.add;
    this.form.get('userId').enable();
    this.form.reset();
    if (options?.focus) {
      setTimeout(() => this._elementRef.nativeElement.querySelector('input').focus());
    }
    this.getUsersPerRoleAvailables();
  }

  public remove() {
    this.mode = FormModeEnum.remove;
    this.roles.forEach((x) => {
      this.form.get(x.propName).setValue(false);
    });
    this.onSubmit();
  }

  public onSubmit() {
    if (!this.loading) {
      this.submitted = true;
      this.form.markAllAsTouched();

      // Verifica se ao menos um role está selecionado
      if (!this.isRemoveMode) {
        const isAnyRoleSelected = this.roles.some(role => this.form.get(role.propName).value);
        if (!isAnyRoleSelected) {
          this._toastService.warning(this._translocoService.translate('Selecione pelo menos uma permissão antes de submeter.'));
          return; // Impede a submissão do formulário
        }
      }

      if (this.form.invalid) {
        return;
      }
      const formValue = {
        ...this.form.value,
        userId: this.form.get('userId').value,
      };
      this.loading = true;
      this._accessPermitionService.addUserRoles({
        roleUserId: formValue.userId,
        applicationId: this.applicationId,
        roleIds: this.roles
          .filter(x => formValue[x.propName])
          .map(x => x.roleId),
      }).pipe(
        tap(() => {
          let messageKey: string;
          if (this.isEditMode) {
            messageKey = 'Permissão alterada com sucesso!';
          } else if (this.isRemoveMode) {
            messageKey = 'Permissão removida com sucesso!';
          } else {
            messageKey = 'Permissão adicionada com sucesso!';
          }
          const message = this._translocoService.translate(messageKey);
          this._toastService.success(message);
          this.addNew();
          this.onAccessAdded.emit();
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => this.loading = false),
      ).subscribe();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private getRoles() {
    this._accessPermitionService.getRoles(this.applicationId).pipe(
      takeUntil(this._unsubscribeAll),
      tap((response) => {
        this.roles = response.map((x) => ({
          roleId: x.roleId,
          roleName: x.roleName,
          propName: x.roleName.replace(/\s/g, '').toLowerCase(),
        }));
        this.roles.forEach((x) => {
          this.form.addControl(x.propName, new FormControl(false));
        });
      })
    ).subscribe();
  }

  private getUsersPerRoleAvailables() {
    this._accessPermitionService.getUsersPerRoleAvailables(this.applicationId).pipe(
      takeUntil(this._unsubscribeAll),
      first(),
      tap(response => {
        this.listPermission = response.map(user => ({
          value: user.userId,
          text: user.name,
        }));
      }),
    ).subscribe();
  }
}
