export enum SvcFilterType {
    NONE = 'none',
    TEXT = 'text',
    NUMBER = 'number',
    CURRENCY = 'currency',
    SELECT = 'select',
    DATE = 'date',
    FEDERAL_ID = 'federalId',
    CARD_NUMBER = 'cardNumber',
    CARD_MASK = 'cardMask'
}
