import { trigger, transition, animate, style, sequence } from '@angular/animations';

export const toastGamifiedEnterBounceAnimation = trigger('toastGamifiedEnterBounce', [
  transition(':enter', [
    sequence([
      style({ transform: 'translateY(170%)' }),
      animate('50ms ease-in-out', style({ transform: 'translateY(135%)' })),
      animate('50ms ease-in-out', style({ transform: 'translateY(85%)' })),
      animate('50ms ease-in-out', style({ transform: 'translateY(45%)' })),
      animate('50ms ease-in-out', style({ transform: 'translateY(-30%)' })),
      animate('50ms ease-in-out', style({ transform: 'translateY(0)' })),
      animate('100ms ease-in-out', style({ transform: 'translateY(-15%)' })),
      animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
    ])
  ])
]);

export const toastGamifiedSlideToTopAnimation = trigger('toastGamifiedSlideToTop', [
  transition('void => slideToTop', [
    sequence([
      style({
        transform: 'translateY(0)'
      }),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-40vh + 208px))'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-50vh + 208px))'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-60vh + 208px))',
        width: '340px',
        height: '75px'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-70vh + 208px)) translateX(calc(var(--translateX) - 20px)',
        width: '300px',
        height: '70px'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-80vh + 208px)) translateX(calc(var(--translateX) - 30px)',
        width: '280px',
        height: '60px'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-90vh + 208px)) translateX(calc(var(--translateX) - 40px)',
        width: '260px',
        height: '50px'
      })),
      animate('50ms ease-in-out', style({
        transform: 'translateY(calc(-100vh + 208px)) translateX(calc(var(--translateX) - 50px)',
        width: '244px',
        height: '46px'
      })),
    ])
  ]),
]);

export const toastGamifiedFadeOutAnimation = trigger('toastGamifiedFadeOut', [
  transition('void => fadeOut', [
    sequence([
      style({ opacity: 1 }),
      animate('300ms ease-in-out', style({ opacity: 1 })),
      animate('100ms ease-in-out', style({ opacity: 0.5 })),
      animate('50ms ease-in-out', style({ opacity: 0 })),
    ])
  ]),
]);
