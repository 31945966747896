/**
 * Custom palettes
 *
 * Uses the generatePalette helper method to generate
 * Tailwind-like color palettes automatically
 */
const screenSizes = {
	SM: 600,
	MD: 960,
	LG: 1280,
	XL: 1440,
}

module.exports = screenSizes;
