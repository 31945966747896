<div
  class="flex flex-col py-3 px-4 rounded-lg text-on-primary-400"
  [style.background-color]="app.backgroundColor"
>
  <div class="flex items-center h-20 cursor-pointer" (click)="toggle()">
    <img class="icon-size-8 mr-2" [src]="app.icon" *ngIf="app.icon" />
    <span class="text-base" [style.color]="app.textActiveColor">
      {{ app.name | transloco }}
    </span>
  </div>
  <div class="flex flex-col expandable" [ngClass]="expanded ? 'expanded' : ''">
    <div
      class="flex items-center py-1 px-2 cursor-pointer"
      *ngFor="let item of app.items"
      (click)="goTo(item)"
    >
      <ng-container *ngIf="item.locked">
        <mat-icon
          *ngIf="item.locked"
          class="icon-size-4 mr-2"
          [style.color]="app.textInactiveColor"
          [svgIcon]="'heroicons_solid:lock-closed'"
        ></mat-icon>
        <span class="text-sm" [style.color]="app.textInactiveColor">
          {{ item.name | transloco }}
        </span>
      </ng-container>

      <ng-container *ngIf="!item.locked">
        <mat-icon
          *ngIf="!item.loading"
          class="icon-size-4 mr-2"
          [style.color]="app.textActiveColor"
          [svgIcon]="'heroicons_solid:arrow-top-right-on-square'"
        ></mat-icon>
        <mat-spinner class="mr-2"
          *ngIf="item.loading"
          [style.color]="app.textActiveColor"
          diameter="15"
        ></mat-spinner>
        <span class="text-sm" [style.color]="app.textActiveColor">
          {{ item.name | transloco }}
        </span>
      </ng-container>
    </div>
  </div>
</div>
