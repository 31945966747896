import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {
  LibSharedComponentModule,
  SvcButtonsModule,
  SvcControlsModule,
  SvcDialogsModule,
  SvcPriorityButtonModule,
} from 'projects/lib-shared-component/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { DropdownListAnswerComponent } from './components/dropdown-list-answer/dropdown-list-answer.component';
import { EvaluationBarAnswerComponent } from './components/evaluation-bar-answer/evaluation-bar-answer.component';
import { EvaluationMatrixAnswerComponent } from './components/evaluation-matrix-answer/evaluation-matrix-answer.component';
import { ListOfEmployeesAnswerComponent } from './components/list-of-employees-answer/list-of-employees-answer.component';
import { MultipleChoiceAnswerComponent } from './components/multiple-choice-answer/multiple-choice-answer.component';
import { MultipleTextBoxesAnswerComponent } from './components/multiple-text-boxes-answer/multiple-text-boxes-answer.component';
import { SimpleChoiceAnswerComponent } from './components/simple-choice-answer/simple-choice-answer.component';
import { StartRatingAnswerComponent } from './components/start-rating-answer/start-rating-answer.component';
import { TextBoxAnswerComponent } from './components/text-box-answer/text-box-answer.component';
import { SurveyHttpClient } from './services/survey-http-client';
import { SurveyModalService } from './services/survey-modal.service';
import { StepperModule } from './stepper/stepper.module';
import { SurveyModalComponent } from './survey-modal.component';

@NgModule({
  declarations: [
    SurveyModalComponent,
    SimpleChoiceAnswerComponent,
    DropdownListAnswerComponent,
    EvaluationBarAnswerComponent,
    EvaluationMatrixAnswerComponent,
    ListOfEmployeesAnswerComponent,
    MultipleChoiceAnswerComponent,
    MultipleTextBoxesAnswerComponent,
    StartRatingAnswerComponent,
    TextBoxAnswerComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    SvcDialogsModule,
    SvcControlsModule,
    SvcButtonsModule,
    SvcPriorityButtonModule,
    TranslocoModule,
    StepperModule,
  ],
  exports: [
    SurveyModalComponent,
    SimpleChoiceAnswerComponent,
    DropdownListAnswerComponent,
    EvaluationBarAnswerComponent,
    EvaluationMatrixAnswerComponent,
    ListOfEmployeesAnswerComponent,
    MultipleChoiceAnswerComponent,
    MultipleTextBoxesAnswerComponent,
    StartRatingAnswerComponent,
    TextBoxAnswerComponent,
  ],
  providers: [SurveyModalService, SurveyHttpClient],
})
export class SurveyModalModule {}
