import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'svc-drawer',
  templateUrl: './svc-drawer.component.html',
  styleUrls: ['./svc-drawer.component.scss']
})
export class SvcDrawerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() position: 'left' | 'right' = 'right';
  @Input() mode: 'over' | 'side' = 'side';
  @Input() opened = false;

  @HostBinding('class.positioned-right') public isRight = false;
  @HostBinding('class.over') public isOver = false;
  @HostBinding('class.opened') public isOpened = false;

  @ViewChild('drawerSide') public drawerSide: ElementRef<HTMLElement>;
  @ViewChild('drawerContent') public drawerContent: ElementRef<HTMLElement>;

  private _drawerSideHiddenTimeout:  NodeJS.Timeout = null;

  public ngOnInit(): void {
    this._updateVariables();
  }

  public ngAfterViewInit(): void {
    this._updateViews();
  }

  public ngOnChanges(): void {
    this._updateVariables();
    this._updateViews();
  }

  private _updateVariables() {
    this.isRight = this.position == 'right';
    this.isOver = this.mode == 'over';
    this.isOpened = this.opened;
  }

  private _updateViews() {
    if (this.drawerContent && this.drawerSide) {
      this.drawerContent.nativeElement.style.width = (!this.isOver && this.opened) ? `calc(100% - ${this.drawerSide.nativeElement.clientWidth}px)` : '100%';
      this.drawerContent.nativeElement.style.transform = (this.isOver || this.isRight) ? '' : `translateX(${this.opened ? this.drawerSide.nativeElement.clientWidth : 0}px)`;

      clearTimeout(this._drawerSideHiddenTimeout);
      if (this.opened) {
        this.drawerSide.nativeElement.style.visibility = 'visible';
      }
      else {
        this._drawerSideHiddenTimeout = setTimeout(() => this.drawerSide.nativeElement.style.visibility = 'hidden', 300);
      }

      if (this.isRight) {
        this.drawerSide.nativeElement.style.right = this.opened ? '0' : `-${this.drawerSide.nativeElement.clientWidth}px`;
      }
      else {
        this.drawerSide.nativeElement.style.left = this.opened ? '0' : `-${this.drawerSide.nativeElement.clientWidth}px`;
      }
    }
  }

  public toggle() {
    this.opened = !this.opened;
    this._updateVariables();
    this._updateViews();
  }

  public ngOnDestroy(): void {
  }

}
