export interface SvcUserPreferencesFeatures {
  featureName: string;
  customProperties: SvcUserPreferencesCustomProperties[];
  featureFilters?: SvcUserPreferencesFeatureFilters[];
}

export interface SvcUserPreferencesCustomProperties {
  propertyKey: string;
  propertyValue: string;
  propertyType: string;
}

export interface SvcUserPreferencesFeatureFilters {
  filterName: string;
  lastFilter: boolean;
  filters: SvcUserPreferencesFilters[];
}

export interface SvcUserPreferenceFilterUpdate {
  applicationId: string,
  featureName: string,
  filterPreferences: SvcUserPreferencesFeatureFilters;
}

export interface SvcUserPreferencesFilters {
  filterKey: string;
  filterType: string
  filterValue: string[];
}

export enum SvcUserPreferenceFeatureCustomPorpertyKey {
  Tab = 'LastTabView',
  List = 'ListTableView'
}
