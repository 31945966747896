<mat-form-field #formField *ngIf="!readonly" class="flex-auto w-full svc-mat-no-subscript svc-mat-dense" [class.mat-form-field-disable]="control?.disabled" [class.cursor-default]="control?.disabled || loading" role="button" tabindex="0" (focus)="openOptions()" (keydown.tab)="closeOptions()" (keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? closeOptions() : null">
  <mat-icon *ngIf="icon" matPrefix [svgIcon]="icon"></mat-icon>
  <mat-label *ngIf="label" class="inline-flex input-label-size input-label-weight text-default">
    <div class="flex items-center">
      <mat-icon *ngIf="label?.icon" class="icon-size-3 text-current mr-1" [svgIcon]="label.icon"></mat-icon>
      {{ label?.text ?? label }}
    </div>
  </mat-label>
  <mat-chip-grid #chipGrid class="h-full" (click)="openOptions()">
    <mat-chip-row *ngIf="selected" (removed)="remove()" [disabled]="control?.disabled" class="my-1.5" [class.invisible]="loading">
      <ng-container *ngIf="!templateRef">{{ selected.text }}</ng-container>
      <ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: selected }"></ng-container>
      <button matChipRemove [disabled]="control?.disabled">
          <mat-icon class="icon-size-3 bg-text-default text-white rounded-full" svgIcon="heroicons_solid:x-mark"></mat-icon>
      </button>
    </mat-chip-row>
    <input
      class="ml-0 cursor-default self-center h-7 flex-0 max-w-50"
      [class.w-0]="loading || !placeholder || selected"
      [matChipInputFor]="chipGrid"
      [placeholder]="placeholder"
      [disabled]="control?.disabled ?? false"
      tabindex="-1"
      (focus)="openOptions()"
      (input)="$event.target.value = ''"
    >
  </mat-chip-grid>
  <div matSuffix class="text-default -mr-1.5">
		<mat-icon class="dropdown-icon text-current" [svgIcon]="'fontawesome_solid:' + (optionsListIsOpened? 'chevron-up' : 'chevron-down')" (click)="!control?.disabled && openOptions()"></mat-icon>
	</div>
  <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
</mat-form-field>
<ng-template #optionsListTemplate>
	<div class="mdc-menu-surface opacity-100 relative w-full flex flex-col bg-white rounded-md overflow-x-hidden">
		<div *ngIf="!selected" class="flex items-center w-full gap-2 py-1 px-2 border-b border-gray-200">
			<div class="flex-auto">
        <input
          type="text"
          class="w-full text-sm"
          [(ngModel)]="searchText"
          (ngModelChange)="filterOptions(searchText)"
          [placeholder]="'Pesquisar' | transloco"
          (keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? $event.preventDefault() : null"
          (keydown.arrowdown)="forceFocusItemWhenDownPressed($event)"
        />
      </div>
			<div class="flex items-center justify-center text-gray-200 w-8 h-8 cursor-pointer" (click)="searchInput.focus()">
				<mat-icon class="text-current icon-size-5" svgIcon="mat_solid:search"></mat-icon>
			</div>
		</div>
		<div class="flex-auto overflow-y-auto">
			<ng-container *ngIf="selected">
				<div class="py-2 px-3 text-sm font-normal">
					{{ 'Você pode selecionar apenas 1 item' | transloco }}
				</div>
			</ng-container>
			<ng-container *ngIf="!selected">
				<div *ngFor="let option of filteredOptions" [value]="option.value" class="select-option flex p-2 cursor-pointer focus:bg-gray-100 hover:bg-gray-100 break-all" (click)="select(option)" role="button" tabindex="0" (keydown.space)="$event.preventDefault(); select(option)" (keydown.enter)="$event.preventDefault(); select(option)" (keydown.arrowdown)="$event.preventDefault(); $event.target.nextElementSibling?.focus()" (keydown.arrowup)="$event.preventDefault(); $event.target.previousElementSibling?.focus()">
					<ng-container *ngIf="!templateRef">{{ option.text }}</ng-container>
          <ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option }"></ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
<svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>

<ng-container *ngIf="readonly">
  <div class="relative flex flex-col flex-auto">
    <div *ngIf="label" class="flex flex-row items-center input-label-size input-label-weight text-default">
      <mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
      {{ label }}
    </div>
    <span *ngIf="readonlyStyle === 'default' || !selected" class="text-default font-medium text-base" [class.truncate]="truncateTextValue" [class.invisible]="loading">
      {{ selected ? selected.text : (fallbackTextValue ?? '') }}
    </span>
		<div *ngIf="(readonlyStyle === 'chip' || readonlyStyle === 'transparent-chip') && selected" class="w-full overflow-y-hidden" [class.invisible]="loading">
			<div class="mat-mdc-chip cursor-default px-1.5 py-0.5 text-base truncate" [class.bg-transparent]="readonlyStyle === 'transparent-chip'">
        <ng-container *ngIf="!templateRef">{{ selected.text }}</ng-container>
        <ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: selected }"></ng-container>
      </div>
		</div>
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div *ngIf="loading" class="flex items-center absolute left-0 right-0 bottom-0 rounded-md text-default" [ngClass]="[(readonly ? 'top-0' : 'h-10 pl-2'), ((label && readonly) ? 'pt-4' : 'pt-0')]">
    <div class="absolute inset-0 bg-hite opacity-40"></div>
    <div class="droplist-shimmer"></div>
  </div>
</ng-template>
