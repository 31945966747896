import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription, finalize, tap } from 'rxjs';
import { SvcCommentsService } from '../../services/svc-comments.service';

@Component({
  selector: 'svc-comments-total',
  templateUrl: './svc-comments-total.component.html',
  styleUrls: ['./svc-comments-total.component.scss']
})
export class SvcCommentsTotalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public referenceId: string | number;
  @Input() public configTypeId: string;
  @Input() public siteId: number;
  @Input() public external: boolean = false;
  @Input() public textColorClass: string = 'text-default';
  @Input() public textSizeClass: string = 'text-sm';
  @Input() public mockMode = false;

  private _wasInitialized = false;
  private _subscription: Subscription;
  public isLoading: boolean = false;
  public total: number;

  constructor(
    private _commentsService: SvcCommentsService,
  ) {
  }

  ngOnInit(): void {
    this._getTotals();
    this._wasInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._wasInitialized && ('referenceId' in changes || 'configTypeId' in  changes)) {
      this._getTotals();
    }
  }

  private _getTotals(){
    if (!this.mockMode) {
      this.isLoading = true;
      this._subscription?.unsubscribe()
      const request = (typeof this.referenceId == 'number'
        ? this._commentsService.getTotalCommentsById.bind(this._commentsService)
        : this._commentsService.getTotalCommentsByUid.bind(this._commentsService));
      this._subscription = request({
        referenceId: this.referenceId,
        referenceUId: this.referenceId,
        commentConfigTypeId: this.configTypeId,
        externalSiteId: this.siteId,
      }, this.external).pipe(
        tap((res: number) => {
          this.total = res;
        }),
        finalize(() => this.isLoading = false),
      ).subscribe();
    }
    else {
      this.total = 26;
    }
  }

  public refresh() {
    this._getTotals();
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
