import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'svc-confirm-dialog',
  templateUrl: './svc-confirm-dialog.component.html',
  styleUrls: ['./svc-confirm-dialog.component.scss']
})
export class SvcConfirmDialogComponent {

  public title: string;
  public text: string;
  public okText: string;
  public cancelText: string;
  public hideCloseButton: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef?: MatDialogRef<SvcConfirmDialogComponent>
  ) {
    this.title = data?.title ?? 'Confirmação';
    this.text = data?.text;
    this.okText = data?.okText ?? 'OK';
    this.cancelText = data?.cancelText ?? 'Cancelar';
    this.hideCloseButton = data?.hideCloseButton ?? false;

    this._dialogRef.disableClose = true;
  }

  ok() {
    this._dialogRef.close(true);
  }

  cancel() {
    this._dialogRef.close(false);
  }

}
