import { Calendar, EventInput, CalendarOptions } from '@fullcalendar/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { externalNetcoreURL } from 'projects/lib-shared-common/src/lib/functions/external-url-redirect';
import { ISvcCalendarSearch } from './interfaces/svc-calendar-search.interface';

@Component({
  selector: 'svc-calendar',
  templateUrl: './svc-calendar.component.html',
  styleUrls: ['./svc-calendar.component.scss'],
})
export class SvcCalendarComponent implements OnInit {
  @Input() languageAlias: string;

  @Input() actionPlanCalendar: ISvcCalendarSearch;
  @Input() checklistInspectionCalendar: ISvcCalendarSearch;
  @Input() cilInspectionCalendar: ISvcCalendarSearch;
  @Input() defectTagCalendar: ISvcCalendarSearch;

  isLoadingEvents: boolean = true;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent =
    {} as FullCalendarComponent;
  calendarApi: Calendar = {} as Calendar;

  hasFutureEvents: boolean = false;
  futureEvents: EventInput[] = [];

  calendarOptions: CalendarOptions = {
    handleWindowResize: true,
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    customButtons: {
      cbPreviousMonth: {
        icon: 'fc-icon fc-icon-chevron-left',
        click: () => {
          this.calendarApi.prev();
          this.isLoadingEvents = true;
          this.loadCalendarEvents(
            this.calendarApi.view.currentStart,
            this.calendarApi.view.currentEnd,
            this.calendarApi.view.activeStart,
            this.calendarApi.view.activeEnd
          );
        },
      },
      cbNextMonth: {
        icon: 'fc-icon fc-icon-chevron-right',
        click: () => {
          this.calendarApi.next();
          this.isLoadingEvents = true;
          this.loadCalendarEvents(
            this.calendarApi.view.currentStart,
            this.calendarApi.view.currentEnd,
            this.calendarApi.view.activeStart,
            this.calendarApi.view.activeEnd
          );
        },
      },
    },
    headerToolbar: {
      start: 'cbPreviousMonth',
      center: 'title',
      end: 'cbNextMonth',
    },
    height: 'calc(100vh - 180px)',
    themeSystem: 'standard',
    eventDisplay: 'block',
    eventOrder: 'title',
    displayEventTime: false,
    dayMaxEventRows: true,
    eventClick: ($event) => {
      $event.jsEvent.preventDefault();
      this.redirectToEventURL($event.event.url);
    },
    eventMouseEnter: ($event) => {},
    events: [],
  };

  constructor() {}

  ngOnInit(): void {
    this.calendarOptions.locale = this.languageAlias;
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.loadCalendarEvents(
      this.calendarApi.view.currentStart,
      this.calendarApi.view.currentEnd,
      this.calendarApi.view.activeStart,
      this.calendarApi.view.activeEnd
    );
  }

  loadCalendarEvents(
    monthStartDate: Date,
    monthFinalDate: Date,
    startDate: Date,
    finalDate: Date
  ) {
    const events: EventInput[] = [];
    const defectTagEvents = this.defectTagCalendar?.searhCalendarEvents(
      monthStartDate,
      monthFinalDate,
      startDate,
      finalDate
    );
    const checklistEvents =
      this.checklistInspectionCalendar?.searhCalendarEvents(
        monthStartDate,
        monthFinalDate,
        startDate,
        finalDate
      );
    const checklistFutureEvents =
      this.checklistInspectionCalendar?.searchCalendarFutureEvents(
        monthStartDate,
        monthFinalDate,
        startDate,
        finalDate
      );
    const cilEvents = this.cilInspectionCalendar?.searhCalendarEvents(
      monthStartDate,
      monthFinalDate,
      startDate,
      finalDate
    );
    const cilFutureEvents =
      this.cilInspectionCalendar?.searchCalendarFutureEvents(
        monthStartDate,
        monthFinalDate,
        startDate,
        finalDate
      );
    const actionPlanEvents = this.actionPlanCalendar?.searhCalendarEvents(
      monthStartDate,
      monthFinalDate,
      startDate,
      finalDate
    );

    const eventsToLoad: Promise<any>[] = [
      defectTagEvents,
      checklistEvents,
      cilEvents,
      actionPlanEvents,
    ];
    if (!this.hasFutureEvents) {
      eventsToLoad.push(checklistFutureEvents);
      eventsToLoad.push(cilFutureEvents);
      this.hasFutureEvents = true;
    }

    Promise.all(eventsToLoad)
      .then((responses) => {
        this.isLoadingEvents = false;
        responses.map((response) => {
          response.map((event: EventInput) => {
            !event.future
              ? events.push(event)
              : this.addCalendarFutureEvents(event);
          });
        });
        this.calendarOptions.events = [...events, ...this.futureEvents];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addCalendarFutureEvents(event: EventInput) {
    if (
      this.futureEvents.filter(
        (x) =>
          x.title === event.title &&
          (<Date>x.date).getTime() === (<Date>event.date).getTime()
      ).length == 0
    )
      this.futureEvents.push(event);
  }

  redirectToEventURL(url: string): void {
    const mainURL: string = url.split('@')[0];
    const mainParams: string = url.split('@')[1];

    externalNetcoreURL(mainURL, mainParams);
  }
}
