<section>
    <div class="flex flex-col w-full h-full">
        <img class="image-info" src="assets/img/info-icon.svg"/>

        <h3 class="text-3xl font-semibold text-primary">
            {{'Esta página não pode ser carregada!' | transloco}}
        </h3>
        <p class="text-lg">
            <ng-container *ngIf="type === 'DEFAULT'">
                {{'Por favor recarregue usando o botão abaixo.' | transloco}}
            </ng-container>
            <ng-container *ngIf="type === 'PERMISSION'">
                {{'Você não possui as permissões necessárias para acessar esta página.' | transloco}}
            </ng-container>
            <ng-container *ngIf="type === 'GDPR'">
              {{'Por favor aceite a política de privacidade para acessar esta página.' | transloco}}
            </ng-container>
        </p>

        <img class="image-banner" src="assets/img/solvace-maintenance.svg"/>

        <div class="flex flex-auto flex-col justify-end sm:flex-row sm:justify-start">
            <svc-button *ngIf="type === 'DEFAULT'"  class="flex mr-3 mb-3"
                [fullWidth]="isSmScreen"
                mode="flat"
                color="primary"
                (click)="refresh()"
            >
                <div class="flex items-center font-normal">
                    <mat-icon class="icon-size-5 text-current mr-2" svgIcon="heroicons_solid:arrow-path"></mat-icon>
                    {{'Atualizar' | transloco}}
                </div>
            </svc-button>
            <svc-button *ngIf="type === 'PERMISSION'" class="flex mr-3 mb-3"
                [fullWidth]="isSmScreen"
                mode="flat"
                color="primary"
                (click)="back()"
            >
                <div class="flex items-center font-normal">
                    <mat-icon class="icon-size-5 text-current mr-2" svgIcon="heroicons_solid:arrow-uturn-left"></mat-icon>
                    {{'Voltar' | transloco}}
                </div>
            </svc-button>
            <svc-button class="flex mr-3 mb-3"
                [fullWidth]="isSmScreen"
                mode="flat"
                color="primary"
                (click)="signOut()"
            >
                <div class="flex items-center font-normal">
                    <mat-icon *ngIf="isLogged" class="icon-size-5 text-current mr-2" svgIcon="heroicons_solid:arrow-left-on-rectangle"></mat-icon>
                    {{ isLogged ? 'Exit' : 'Entrar' | transloco }}
                </div>
            </svc-button>
        </div>
    </div>
</section>
<div *ngIf="isBacking || !termsLoaded" class="overlay-loading">
    <mat-spinner diameter="50"></mat-spinner>
</div>
