import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { svcAnimations } from 'projects/lib-shared-component/src/public-api';
import { SvcValidators } from 'projects/lib-shared-common/src/public-api';
import { Router } from '@angular/router';
import { AuthService, passwordValidator } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'auth-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: svcAnimations,
})
export class AuthResetPasswordComponent implements OnInit {
  @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;

  alert: { type: string; message: string } = {
    type: 'success',
    message: '',
  };
  resetPasswordForm: UntypedFormGroup;
  showAlert: boolean = false;
  changePasswordRequired: boolean = false;
  public solvaceLogo$ = this._authService.solvaceLogo$;

  private _nameControlCode = 'verificator'
  private _verificatorControls: string[] = [];

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _sanitizer: DomSanitizer,
    private _router: Router
  ) { }

  get backgroundImg() {
    return this._sanitizer.bypassSecurityTrustStyle(
      this._authService.getCoverImage()
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    if(this._authService.changePasswordRequired){
      this.changePasswordRequired = true;
      this.resetPasswordForm = this._formBuilder.group(
        {
          password: ['', Validators.required, passwordValidator()],
          passwordConfirm: ['', Validators.required]
        },
        {
          validators: SvcValidators.mustMatch('password', 'passwordConfirm'),
        }
      );
    } else {
      this.resetPasswordForm = this._formBuilder.group(
        {
          password: ['', Validators.required, passwordValidator()],
          passwordConfirm: ['', Validators.required]
        },
        {
          validators: SvcValidators.mustMatch('password', 'passwordConfirm'),
        }
      );

      this.setVerificatorControls();

      this._verificatorControls.forEach((controlName: string) =>
        this.resetPasswordForm.addControl(controlName, this._formBuilder.control('', Validators.required))
      );
    }
  }

  private setVerificatorControls(): void {
    [1, 2, 3, 4, 5, 6].forEach((position: number) =>
      this._verificatorControls.push(`${this._nameControlCode}${position}`)
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Reset password
   */
  resetPassword(): void {
    // Return if the form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    // Disable the form
    this.resetPasswordForm.disable();

    // Hide the alert
    this.showAlert = false;

    const formValue = this.resetPasswordForm.getRawValue();
    const verficatorKeys = Object.keys(formValue).filter((key) =>
      key.startsWith(this._nameControlCode)
    );
    const concatenatedString = verficatorKeys
      .map((key) => formValue[key])
      .join('');

    // Send the request to the server
    this._authService
      .resetPassword(
        concatenatedString,
        this.resetPasswordForm.get('password').value
      )
      .then(() => {
        this._authService.setPasswordChangeDate().then(() => {
          this._router.navigate(['/sign-in']);
        });
      })
      .catch(() => {
        this.alert = {
          type: 'error',
          message: this._translocoService.translate('Código de verificação inválido'),
        };
      })
      .finally(() => {
        this.resetPasswordForm.enable();
        this.resetPasswordNgForm.resetForm();
        this.showAlert = true;
      });
  }

  changeTemporaryPassword(): void {
    // Return if the form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    // Disable the form
    this.resetPasswordForm.disable();

    // Hide the alert
    this.showAlert = false;

    // Send the request to the server
    this._authService
      .changeTemporaryPassword(
        this.resetPasswordForm.get('password').value
      )
      .then(() => {
        this._authService.setPasswordChangeDate().then(() => {
          this._router.navigate(['/sign-in']);
        });
      })
      .catch(() => {
        this.alert = {
          type: 'error',
          message: this._translocoService.translate('Ocorreu um erro ao tentar alterar a senha'),
        };
      })
      .finally(() => {
        this.resetPasswordForm.enable();
        this.resetPasswordNgForm.resetForm();
        this.showAlert = true;
      });
  }

  handleBackspace(
    event: KeyboardEvent,
    currentInput: HTMLInputElement,
    previousInput: HTMLInputElement
  ) {
    if (currentInput.value === '') {
      previousInput.focus();
      previousInput.value = '';
    }
  }

  public handleFocusNextCode(currentCode: string, nextCode: HTMLInputElement): void {
    if (/^\d+$/.test(currentCode) && currentCode?.length === 1)
      nextCode.focus();
  }

  public handlePasteCode(clipboardEvent: ClipboardEvent, currentCode: HTMLInputElement): void {
    clipboardEvent.preventDefault();

    const pastedCode: string = clipboardEvent.clipboardData.getData('text');

    if (/^\d+$/.test(pastedCode) && pastedCode?.length === 6 && this._verificatorControls?.length === 6) {
      this._verificatorControls.forEach((controlName: string, index: number) =>
        this.resetPasswordForm.get(controlName).setValue(pastedCode[index])
      );

      currentCode.blur();
    }
  }
}
