<form [formGroup]="form" *screenSizes="let size" [ngClass]="{ 'pointer-events-none': readonly }">
  <div class="flex flex-col">
    <ng-container *ngFor="let option of form?.controls?.options.controls ?? []">
      <mat-checkbox [formControl]="option.controls?.selected">
        <span class="text-sm text-default">
          {{ option.controls?.description?.value ?? '' }}
        </span>
      </mat-checkbox>
    </ng-container>
  </div>
</form>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
