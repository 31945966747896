import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  private _datePipe = new DatePipe('en-US');

  constructor(
    private _svcAppSettings: SvcAppSettings,
  ){}

  transform(date: string): string {
    if (typeof date == 'string') {
      date = date?.replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{1,2}.*Z)/g, (term) => term.replace('Z', ''));
    }
    return this._datePipe.transform(date, this._svcAppSettings.dateFormat);
  }

}
