<svc-dialog-primary dialogStyle="close">

  <div svc-dialog-title class="flex w-full items-center">
    <img class="w-auto max-w-fit max-h-8" [src]="environment.logoImageNegative" />

    <div class="mx-auto text-w">
      <h1 class="uppercase text-base font-bold">
        {{ 'Processo' | transloco }}
      </h1>
    </div>
  </div>

  <div svc-dialog-content>
    <img src="https://images.pexels.com/photos/14808402/pexels-photo-14808402.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
  </div>

  <div class="space-x-2" svc-dialog-actions>
    <svc-button-neutral [label]="'Fechar' | transloco" type="button" mat-dialog-close></svc-button-neutral>
  </div>

</svc-dialog-primary>
