<section #draggingArea>
  <div #leftLimit class="left-limit" [style.left]="leftLimitControl.position + '%'">
    <p
      id="left-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && leftLimitControl.currentYear && (!isOverlapping || isDraggingLeft)"
    >
      {{ leftLimitControl.currentYear }}
    </p>
  </div>

  <div #rightLimit class="right-limit" [style.right]="rightLimitControl.position + '%'">
    <p
      id="right-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && rightLimitControl.currentYear && (!isOverlapping || isDraggingRight)"
    >
      {{ rightLimitControl.currentYear }}
    </p>
  </div>
</section>
<div class="flex">
  <p class="flex-auto text-base font-light">{{ minYear }}</p>
  <p class="flex-auto text-base text-right font-light">{{ maxYear }}</p>
</div>
