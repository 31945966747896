import {Component, Input, OnInit} from '@angular/core';
import {IconItem, IconList, IconName, IconType} from "./icon-list";
import {IconSize} from "./icon-size";

@Component({
  selector: 'svc-icon',
  templateUrl: './svc-icon.component.html',
  styleUrls: ['./svc-icon.component.scss']
})
export class SvcIconComponent implements OnInit {

  @Input() type: IconType = "solid";
  @Input() size: IconSize = 'md';
  @Input()
  set icon(icon: IconName) {
    this._iconName = icon;
    this._iconItem = this._iconList.find(i => i.name === icon);
  }
  get icon() {
    return this._iconName;
  }

  @Input('class') customClass: string = '';

  _iconList = IconList;

  _iconName: IconName;
  _iconItem: IconItem;
  _iconSizeClass: string;

  ngOnInit(): void {
    this.setSizeDefinition();
  }

  setSizeDefinition() {
      switch (this.size) {
        case "sm":
          this._iconSizeClass = `icon-size-4`;
          break;
        case "md":
          this._iconSizeClass = `icon-size-6`;
          break;
        case "lg":
          this._iconSizeClass = `icon-size-8`;
          break;
        case "xl":
          this._iconSizeClass = `icon-size-10`;
          break;
        default:
          if (typeof this.size === "number")
            this._iconSizeClass = `icon-size-${this.size}`;
          else
            this._iconSizeClass = `icon-size-4`;
          break;
      }
      console.log('numero', this._iconSizeClass);

  }



}
