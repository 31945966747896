<input *ngIf="type === 'federalId'"
    class="svc-data-filter-input"
    cpfCnpjMask
    type="text"
    [formControl]="frmControl"
    [placeholder]="placeholder ? placeholder : 'Número do CPF'"
/>
<input *ngIf="type === 'cpf'"
    class="svc-data-filter-input"
    cpfCnpjMask="cpf"
    type="text"
    [formControl]="frmControl"
    [placeholder]="placeholder ? placeholder : 'Número do CPF'"
/>
<input *ngIf="type === 'cnpj'"
    class="svc-data-filter-input"
    cpfCnpjMask="cnpj"
    type="text"
    [formControl]="frmControl"
    [placeholder]="placeholder ? placeholder : 'Número do CNPJ'"
/>
    
<input *ngIf="type === 'cardNumber'"
    class="svc-data-filter-input"
    [formControl]="frmControl" type="text"
    [placeholder]="placeholder ? placeholder : '0000 0000 0000 0000'"
    minlength="19" maxlength="19"
    autocomplete="nope"
/>

<input *ngIf="type === 'currency'"
    class="svc-data-filter-input" name="valor"
    type="text"
    autocomplete="off"
    currencyMask
    [formControl]="frmControl"
    [placeholder]="placeholder ? placeholder : 'R$ 0,00'"
    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
/>

<select *ngIf="type === 'select'" 
    class="svc-data-filter-select"
    [formControl]="frmControl">
    <option [value]="selectAllOptionValue">{{optionAllLabel}}</option>
    <option *ngFor="let option of options" [value]="option.value">{{option.text}}</option>
</select>

<input
    *ngIf="type === 'cardMask'"
    class="svc-data-filter-input"
    [formControl]="frmControl"
    type="text"
    [placeholder]="placeholder ? placeholder : '0000-00**-****-0000'"
/>

<input
    *ngIf="type === 'text'"
    class="svc-data-filter-input"
    [formControl]="frmControl"
    type="text"
    [placeholder]="placeholder ? placeholder : ''"
/>

<input
    *ngIf="type === 'number'"
    class="svc-data-filter-input"
    [formControl]="frmControl"
    type="text"
    only-number
    [placeholder]="placeholder ? placeholder : ''"
/>

<date-picker *ngIf="type === 'date'"
    class="svc-data-filter-input"
    ngDefaultControl
    show-button-bar
    [formControl]="frmControl"
    [date-format]="dateFormat"
    [readonly]="readOnly"
    [min-date]="minDate"
    [max-date]="maxDate">
</date-picker>
