<div class="card rounded shadow-sm mb-2">
  <!-- <div class="card-header bg-white border-0">
      <span class="h5 fw-bold text-gray-accent">{{'Calendário' | translate}}</span>
  </div> -->
  <div class="card-body position-relative">
    <!--event loading indicator (prevents multiple API calls)-->
    <div
      class="position-absolute top-0 bottom-0 start-0 end-0"
      style="z-index: 2"
      *ngIf="isLoadingEvents"
    >
      <div
        class="position-absolute top-0 bottom-0 start-0 end-0 bg-white"
        style="opacity: 0.7"
      ></div>
      <div class="position-absolute top-0 bottom-0 start-0 end-0">
        <div
          class="d-flex flex-column h-100 align-items-center justify-content-center"
        >
          <h1 class="text-gray-alpha">
            ...
          </h1>
        </div>
      </div>
    </div>
    <!--/event loading indicator-->

    <full-calendar
      #calendar
      class="solvace-main-calendar"
      [options]="calendarOptions"
    ></full-calendar>
  </div>
</div>
