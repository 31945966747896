import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SvcViewPanelButtonsData } from '../../interfaces/svc-view-panel-buttons-data.interface';

@Component({
  selector: 'svc-view-toggle',
  templateUrl: './svc-view-toggle.component.html',
  styleUrls: ['./svc-view-toggle.component.scss']
})
export class SvcViewToggleComponent implements OnInit, OnChanges {

  @Input() view: string = '';
  @Input() viewButtons: SvcViewPanelButtonsData[] = [];

  @Output() onViewChanged = new EventEmitter<SvcViewPanelButtonsData>();
  
  protected showButtons = false;
  protected activeViewButton: SvcViewPanelButtonsData;
  private wasInitialized = false;

  constructor() {
  }

  ngOnInit() {
    this.wasInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('viewButtons' in changes) {
      this.viewButtons = this.viewButtons?.sort((a, b) => a.order - b.order) ?? [];
      if (!('view' in changes)) {
        const view = this.viewButtons.find(x => x.active)?.id;
        if (view !== this.view) {
          this.view = view;
          this.onActiveViewChanged(this.view);
        }
      }
    }
    if ('view' in changes) {
      const view = this.viewButtons?.find(x => this.view ? (x.id === this.view) : x.active)?.id;
      if (view !== this.view) {
        this.view = view;
        this.onActiveViewChanged(this.view);
      }
    }
  }

  public onActiveViewChanged(view?: string) {
    this.view = view;
    this.activeViewButton = this.viewButtons.find(x => x.id === this.view);
    this.onViewChanged.emit(this.activeViewButton);
  }
}
