<div class="grid grid-cols-1 gap-5 py-4 w-full relative">

  <svc-post-form></svc-post-form>

  <svc-post-filter></svc-post-filter>

  <div #postsContainer class="relative">

    <div *ngIf="hasNewPosts || hasUpdatedPosts" class="sticky flex justify-center w-fit z-10 top-0 ml-auto mr-auto mt-3 -mb-20 pt-9">
      <div #showPostsBtn class="new-posts-btn bg-primary text-on-primary-400 px-2.5 py-1.5 flex justify-center rounded-full cursor-pointer" (click)="showNewPosts(showPostsBtn, hasUpdatedPosts)">
        <span class="text-base leading-tight">{{'Novas publicações' | transloco}}</span>
        <mat-icon class="text-current ml-1.5 icon-size-5" svgIcon="fontawesome_solid:circle-arrow-up"></mat-icon>
      </div>
    </div>

    <div *ngIf="!posts?.length && !loadingPost" class="flex flex-col p-4 relative bg-white rounded-lg shadow">
      {{'Nenhum post encontrado. Tente refinar os critérios de pesquisa.' | transloco }}
    </div>

    <div class="flex flex-col gap-4 relative">
      <svc-post-item *ngFor="let post of (posts ?? [])" [post]="post"></svc-post-item>
    </div>

  </div>

  <div class="flex justify-center my-2" *ngIf="loadingPost">
    <mat-progress-spinner [diameter]="28" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>

</div>
