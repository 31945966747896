import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcDatePickerCalendarHeaderComponent } from './svc-date-picker-calendar-header.component';
import { LibSharedComponentModule } from '../../../lib-shared-component.module';
import { LibSharedCommonModule } from '../../../../../../lib-shared-common/src/public-api';

@NgModule({
  declarations: [
    SvcDatePickerCalendarHeaderComponent
  ],
  imports: [
    LibSharedComponentModule,
    LibSharedCommonModule,
    TranslocoModule
  ],
  exports: [
    SvcDatePickerCalendarHeaderComponent
  ]
})
export class SvcDatePickerCalendarHeaderModule { }
