import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcValidationMessageComponent } from './svc-validation-message.component';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [
    SvcValidationMessageComponent
  ],
  exports: [
    SvcValidationMessageComponent
  ],
  imports: [
    LibSharedCommonModule,
    TranslocoModule,
  ]
})
export class SvcValidationMessageModule { }
