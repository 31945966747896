import { Component, HostBinding, QueryList, ContentChildren, AfterViewInit, OnDestroy, ElementRef, Input, SimpleChanges, OnChanges, ViewChild, AfterViewChecked, ChangeDetectorRef, Output, EventEmitter, HostListener } from '@angular/core';
import { SvcDataBodyComponent } from '../svc-data-body/svc-data-body.component';
import { Subscription } from 'rxjs';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/lib/services/svc-media-query.service';
import { SvcDataCellComponent } from '../svc-data-cell/svc-data-cell.component';

const MAX_INDEX = 500;

@Component({
	selector: 'svc-data-row',
	templateUrl: './svc-data-row.component.html',
	styleUrls: ['./svc-data-row.component.scss'],
})
export class SvcDataRowComponent implements OnChanges, AfterViewChecked, OnDestroy {
	@HostBinding('class.svc-data-row') public class: boolean = true;

	@ContentChildren(SvcDataCellComponent) private queryCells: QueryList<SvcDataCellComponent>;
	@ViewChild('dragButton') public dragButtonElRef: ElementRef<HTMLElement>;
	@ViewChild('content', { static: false }) public contentElRef: ElementRef<HTMLElement>;

	@Input('draggable') public draggable: boolean = true;

	@Output()
	public rowClick: EventEmitter<Event> = new EventEmitter();

	public maxIndex = MAX_INDEX;

	public set _height(value: string) {
		if (this.el) {
			this.el.style.height = value;
		}
	}

	public set _minHeight(value: string) {
		if (this.el) {
			this.el.style.minHeight = value;
		}
	}

	public get _height() {
		return this.el ? this.el.style.minHeight : 'auto';
	}

	public get cells() {
		return this.queryCells.toArray();
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	public get dragButtonEl() {
		return this.dragButtonElRef.nativeElement;
	}

	public get contentEl() {
		return this.contentElRef.nativeElement;
	}

	public parent: SvcDataBodyComponent;

	public virtualIndex: number;
	public index: number;

	private subscription: Subscription;

	public canDragAndReorder: boolean = false;


	constructor(
		private cdRef: ChangeDetectorRef,
		private elRef: ElementRef<HTMLElement>,
	) {
	}

	ngAfterViewChecked() {
		this.checkCanDragAndReorder();
	}

	ngOnChanges(changes: SimpleChanges) {
	}

	public onRender(parent: SvcDataBodyComponent, virtualIndex: number) {
		this.parent = parent;
		this.virtualIndex = virtualIndex;
		this.index = this.parent.getRowIndexBasedVirtual(virtualIndex);
		this.configureChildrens();
		this.defineClass();
		this.checkCanDragAndReorder();

		if (this.subscription) this.subscription.unsubscribe();
		this.subscription = this.queryCells.changes.subscribe(this.configureChildrens.bind(this));

		this._minHeight = this.parent.rowHeight;
		this.contentEl.style.zIndex = `${this.maxIndex - this.virtualIndex}`;
	}

	public onDraggableChange() {
		if (this.parent.dragAndReorder) this.el.classList.add('draggable');
		else this.el.classList.remove('draggable');
	}

	public configureChildrens() {
		this.cells.forEach((cell, index) => {
			cell.onRender(this, index);
		});
	}

	public checkCanDragAndReorder() {
		if (this.canDragAndReorder !== !!(this.parent && this.parent.dragAndReorder)) {
			this.canDragAndReorder = !!(this.parent && this.parent.dragAndReorder);
			this.cdRef.detectChanges();
		}
	}

	private defineClass() {
		if (this.index >= 0) {
			let position = this.index + 1;
			let currentClassName = 'svc-data-row-' + (position % 2 === 0 ? 'even' : 'odd');
			let previousClassName = 'svc-data-row-' + (position % 2 === 0 ? 'odd' : 'even');

			if (!this.el.classList.contains(currentClassName)) this.el.classList.add(currentClassName);

			if (this.el.classList.contains(previousClassName)) this.el.classList.remove(previousClassName);
		}
		this.onDraggableChange();
	}

	public emitClick(event: Event) {
		this.rowClick.emit(event);
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}
