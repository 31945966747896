<svc-dialog-primary [dialogStyle]="'close'" [ngClass]="{ 'invisible': loading }" matDialogFullscreen="SM">
  <div svc-dialog-title class="flex w-full align-items-center">
    <img class="w-auto max-w-fit max-h-8" [src]="environment.logoImageNegative" />

    <div class="flex-auto flex items-center justify-center text-base font-bold uppercase">
      {{ "Perguntas Frequentes" | transloco }}
    </div>
  </div>

  <div svc-dialog-content class="py-3">
    <ng-container *ngIf="questions?.length; else noQuestions">
      <div class="px-3 py-2" *ngFor="let question of questions; let index = index">
        <div class="flex cursor-pointer text-default py-3 px-5 shadow rounded-md" (click)="toggleShowAnswers(question) && faqAnswers?.stopAudio()">
          <div class="flex-auto">
            {{ index + 1 }} - {{ question.question }}
          </div>
          <div>
            <mat-icon class="text-current icon-size-5" [svgIcon]="'mat_solid:' + (question.showAnswers ? 'arrow_drop_up' : 'arrow_drop_down')"></mat-icon>
          </div>
        </div>
        <div class="overflow-hidden transition-all duration-300 rounded-md border border-solid" style="transition: height 1s;" [style.height.px]="question.showAnswers ? faqAnswers?.clientHeight : 0" [ngClass]="{ 'border-gray-300 shadow mt-2' : question.showAnswers, 'border-transparent' : !question.showAnswers }">
          <svc-faq-answers #faqAnswers class="block" [answers]="question.answers"></svc-faq-answers>
        </div>
      </div>
    </ng-container>

    <ng-template #noQuestions>
      <p class="px-5 py-2 text-default text-sm">{{ 'Não existem questões para serem exibidas no momento.' | transloco }}</p>
    </ng-template>
  </div>

</svc-dialog-primary>
<div *ngIf="loading" class="flex absolute inset-0 items-center justify-center">
  <mat-spinner diameter="30"></mat-spinner>
</div>
