import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { LangDefinition, TRANSLOCO_LOADER, TranslocoService } from '@ngneat/transloco';
import { LanguagesService } from './services/languages.service';
import { finalize } from 'rxjs';
import { TranslocoApiLoader } from '../transloco/transloco.api-loader';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
  availableLangs: LangDefinition[];
  activeLang: LangDefinition;
  flagCodes: any;
  pendingLang: LangDefinition;
  public isLoading = false;

  @Input() onlyImage: boolean = true;
  @Input() hasUser: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _languageService: LanguagesService,
    @Inject(TRANSLOCO_LOADER) private _translocoApiLoader: TranslocoApiLoader
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe((selectedLang) => {

      // Get the active lang
      this.activeLang = this.availableLangs.find((l: LangDefinition) => l.id == selectedLang);

      // Update the navigation
      //this._updateNavigation(selectedLang);
    });

    this._translocoService.events$.subscribe((event) => {
      if (event.type === 'langChanged') {
        const lang = event.payload.langName;
        this.isLoading = !this._translocoApiLoader.isAlreadyLoaded(lang);
      }
      if (event.type === 'translationLoadSuccess') {
        this.isLoading = false;
      }
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      en: 'us',
      tr: 'tr',
      br: 'br',
      jp: 'jp',
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang: string): void {
    if (this._translocoService.getActiveLang() !== lang) {
      // Set the active lang
      this.pendingLang = this.availableLangs.find((l: LangDefinition) => l.id == lang);
      this._translocoService.setActiveLang(lang);

      //reflect language change at API
      if (this.hasUser) {
        this.isLoading = true;
        this._languageService.updateUserLanguage(lang).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe();
      }
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
