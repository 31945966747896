<div *ngIf="!chartInfo?.error" class="flex flex-col flex-auto p-2"
  [ngStyle]="chartInfo?.loading ? { 'filter': 'blur(2px)' } : null">

  <div class="flex flex-col" *ngIf="chartInfo?.title || chartInfo?.description">
    <div class="text-2xl font-bold leading-6" *ngIf="chartInfo?.title">
      {{ chartInfo?.title }}
    </div>

    <div class="text-xl font-light" *ngIf="chartInfo?.description">
      {{ chartInfo?.total | formatNumberDefault }} {{ chartInfo?.description }}
    </div>
  </div>

  <div class="flex flex-col md:flex-row items-center justify-center">
    <div
      echarts
      #chart
      class="echarts flex flex-auto w-full"
      [style.height]="height"
      [options]="chartOptions"
      (chartInit)="onChartInit($event)"
      (chartClick)="onChartClick($event)"
    ></div>
  </div>
</div>

<div *ngIf="chartInfo?.error" class="absolute flex justify-center items-center left-0 top-0 w-full h-full"
  (click)="refresh($event)">
  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1"
    [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>

  <span class="text-primary text-base underline">
    {{ errorMsg }}
  </span>
</div>

<div *ngIf="!chartInfo?.error && chartInfo?.loading"
  class="absolute flex justify-center items-center left-0 top-0 w-full h-full">
  <mat-spinner diameter="30"></mat-spinner>
</div>
