import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'projects/environments/environment';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { formatNumberDefault } from '../functions/number-conversion';

@Pipe({
  name: 'formatNumberDefault'
})
export class FormatNumberDefaultPipe implements PipeTransform {

  private get decimalSymbol() {
    return this._svcAppSettings.delimiterSymbol;
  }
  private get thousandsSymbol() {
    return this._svcAppSettings.delimiterSymbol === ',' ? '.' : ',';   
  }

  constructor(
    private _svcAppSettings: SvcAppSettings,
  ){}

  transform(value: number, forceDecimal: boolean = false): string {
    if (value || forceDecimal) {
      value = value ?? 0;
      const integerValue = Math.floor(value);
      const decimalValue = value - integerValue;
      const integerStr = (() => {
        let numberStr = integerValue.toString();
        if (numberStr.length > 3) {
          for(let i = numberStr.length - 3; i >= 1; i -= 3) {
            numberStr = numberStr.slice(0, i) + this.thousandsSymbol + numberStr.slice(i);
          }
        }
        return numberStr;
      })();
      const decimalStr = (decimalValue || forceDecimal) ? this.decimalSymbol + (decimalValue ?? 0).toString().padEnd(2, '0') : '';
      return integerStr + decimalStr;
    }
    return value?.toString() ?? '';
  }

}
