import { NgModule } from "@angular/core";
import { AvailableLangs, TranslocoConfig } from "@ngneat/transloco";

@NgModule()
export class TranslocoCommonConfig implements TranslocoConfig {
    defaultLang = 'en-US';
    reRenderOnLangChange = true;
    prodMode = true;
    fallbackLang = 'en-US';
    
    availableLangs?: AvailableLangs;
    
    constructor(){
    }
}