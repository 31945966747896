import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'svc-chart-buttons',
  templateUrl: './svc-chart-buttons.component.html',
  styleUrls: ['./svc-chart-buttons.component.scss']
})
export class SvcChartButtonsComponent {
  @Input() itemWidth: number;
  @Input() items: any[];
  @Input() padding: number = 0;
  @Output() clicked = new EventEmitter<any>();
}
