<svc-dialog-config [fullscreen]="['XS']" (onDialogClose)="closeDialog()" *transloco="let t">

  <mat-icon svc-dialog-title-icon svgIcon="heroicons_solid:lock-closed"></mat-icon>

  <div svc-dialog-title>
    {{ t("Alteração de Senha") }}
  </div>

  <form [formGroup]="passwordForm" svc-dialog-content>
    <div class="flex-col divide-y">
      <!-- Alert -->
      <svc-alert *ngIf="showAlert" class="p-4" [appearance]="'outline'" [showIcon]="true" [type]="alert.type"
        [@shake]="alert.type === 'error'">
        {{ alert.message }}
      </svc-alert>

      <div class="flex p-4">
        <svc-text class="w-full" [label]="t('Senha atual')" [formControl]="passwordForm.controls.passwordActual"
          [type]="'password'" [isObscure]="true">
        </svc-text>
      </div>

      <div class="flex p-4">
        <svc-text class="w-full" [label]="t('Nova senha')" [formControl]="passwordForm.controls.password"
          [type]="'password'" [isObscure]="true">
        </svc-text>
      </div>

      <div class="flex p-4">
        <svc-text class="w-full" [label]="t('Nova senha')" [formControl]="passwordForm.controls.passwordConfirm"
          [type]="'password'" [isObscure]="true">
        </svc-text>
      </div>
      <div class="flex p-4">
        <user-password-validator
          [control]="passwordForm.controls.password"
        ></user-password-validator>
      </div>
    </div>
  </form>

  <div class="space-x-2" svc-dialog-actions>
    <svc-button-neutral [label]="t('Cancelar')" [type]="'button'" mat-dialog-close></svc-button-neutral>
    <svc-button-confirm [label]="t('Salvar')" [processing]="passwordForm.disabled" [disabled]="passwordForm.invalid"
      [type]="'button'" (click)="onSubmit()" cdkFocusInitial></svc-button-confirm>
  </div>

</svc-dialog-config>
