import { Component, Inject, Input, ViewChild, forwardRef } from '@angular/core';
import { FeedItem } from '../../models/feed-item.model';
import { SvcCommentsFeedComponent } from '../svc-comments-feed/svc-comments-feed.component';
import { SvcCommentsChatComponent } from '../svc-comments-chat/svc-comments-chat.component';

@Component({
  selector: 'svc-comments-viewer',
  templateUrl: './svc-comments-viewer.component.html',
  styleUrls: ['./svc-comments-viewer.component.scss']
})
export class SvcCommentsViewerComponent {
  @ViewChild(SvcCommentsChatComponent)
  public svcCommentsChat: SvcCommentsChatComponent;
  
  @Input() selectedFeed: FeedItem;

  constructor(
    @Inject(forwardRef(() => SvcCommentsFeedComponent))
    public parent: SvcCommentsFeedComponent
  ){}

  public tryToRefreshChatByCommentId(commentId: number) {
    this.svcCommentsChat?.tryToUpdateFromCommentId(commentId);
  }
}
