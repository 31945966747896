import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { CloudfrontCookie } from '../model/cloudfront-cookie.mode';

@Injectable({
  providedIn: 'root',
})
export class CloudfrontService {
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
  ) {}

  getSignedCookies(): Observable<CloudfrontCookie> {
    return this._httpClient
      .get<CloudfrontCookie>(`${this._appConfig.APIs.apiUrlAuth}/Cookie/cloudfront`);
  }
}
