<div class="relative flex-auto">

  <ng-container *ngIf="loadingData">
    <svc-common-loader></svc-common-loader>
  </ng-container>

  <table
    @stagger
    [dataSource]="availableItems | async"
    class="w-full mat-mdc-table-custom"
    mat-table
    matSort
    multiTemplateDataRows
  >
    <!-- Checkbox Column -->
<!--    <ng-container matColumnDef="checkbox">-->
<!--      <th *matHeaderCellDef mat-header-cell [ngClass]="column.cssClasses" class="bg-table-header font-semibold text-default" [style.width]="column.width">-->
<!--        <mat-checkbox-->
<!--          (change)="$event ? toggleAll() : null"-->
<!--          [checked]="selection.hasValue() && isAllSelected()"-->
<!--          [indeterminate]="selection.hasValue() && !isAllSelected()"-->
<!--          color="primary"-->
<!--        >-->
<!--        </mat-checkbox>-->
<!--      </th>-->
<!--      <td *matCellDef="let row" mat-cell [style.width]="column.width" class="text-default">-->
<!--        <mat-checkbox-->
<!--          (change)="$event ? selection.toggle(row, index) : null"-->
<!--          (click)="$event.stopPropagation()"-->
<!--          [checked]="selection.isSelected(row, index)"-->
<!--          color="primary"-->
<!--        >-->
<!--        </mat-checkbox>-->
<!--      </td>-->
<!--    </ng-container>-->

    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'checkbox'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" class="w-4" mat-cell [style.width]="column.width" class="text-default">
          <div class="flex justify-center">
            <svc-slide-toggle
              [ngClass]="column.cssClasses"
              [checked]="row[column.property]"
            ></svc-slide-toggle>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <!-- Date Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'date'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          {{ row[column.property] | dateFormat }}
        </td>
      </ng-container>
    </ng-container>

    <!-- Link Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'link'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <button
            mat-button
            class="text-primary font-bold -mx-3"
            (click)="viewItem(row[column.actionProperty])"
          >
            {{ row[column.property] }}
          </button>
        </td>
      </ng-container>
    </ng-container>

    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'text'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" [style.width]="column.width" mat-cell class="text-default">
          {{ row[column.property] }}
        </td>
      </ng-container>
    </ng-container>

    <!-- Status Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'status'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-status-badge
            [badgeData]="row[column.property].badgeData"
          ></svc-status-badge>
        </td>
      </ng-container>
    </ng-container>

    <!-- Status Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'active'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-status-badge [badgeData]="{ description: (row[column.property] ? 'Ativo' : 'Inativo') | transloco, type: row[column.property] ? 'active' : 'inactive' }"></svc-status-badge>
        </td>
      </ng-container>
    </ng-container>

    <!-- Legend Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'legend'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <div class="flex flex-row items-center">
            <div
              class="w-4 h-4 rounded-full"
              [ngStyle]="{ 'background-color': row[column.property].color }"
            ></div>
            <div class="ml-3">{{ row[column.property].text }}</div>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <!-- simpleProgress Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'simpleProgress'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-progress-bar
            [progress]="row[column.property]"
            [color-degraded]="column.cssClasses"
          ></svc-progress-bar>
        </td>
      </ng-container>
    </ng-container>

    <!-- multiColor progress Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'multiColorProgress'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-multicolor-progress-bar
            [progressData]="row[column.property]"
          ></svc-multicolor-progress-bar>
        </td>
      </ng-container>
    </ng-container>

    <!-- TextArray Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'textArray'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <span *ngFor="let item of column.propertyArray">
            <span> {{ row[item] }}</span> <br />
          </span>
        </td>
      </ng-container>
    </ng-container>

    <!-- Currency Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'currency'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          {{ row[column.property] | currency : "BRL" }}
        </td>
      </ng-container>
    </ng-container>

    <!-- Array Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'array'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <mat-chip-list>
            <mat-chip
              *ngFor="let item of row[column.property]"
              [class]="column.cssDetailClass"
            >
              {{ item.name }}</mat-chip
            >
          </mat-chip-list>
        </td>
      </ng-container>
    </ng-container>

    <!-- Boolean Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'boolean'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-4" mat-cell [style.width]="column.width" class="text-default">
          <div class="flex justify-center">
            <mat-checkbox
              [checked]="row[column.property]"
              [disabled]="column.readonly"
              color="primary"
            >
            </mat-checkbox>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <!-- user  Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'user'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <div class="flex items-center">
            <img
              class="w-8 h-8 bg-slate-500 rounded-full"
              [src]="
                row[column.property].avatar
                  ? row[column.property].avatar
                  : 'assets/images/avatars/blank-avatar.png'
              "
            />
            <span class="ml-3">
              {{ row[column.property].name }}
            </span>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <!-- lockclosed  Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'lockButton'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          <svc-lock-button
            [readonly]="true"
            [locked]="true"
          ></svc-lock-button>
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-lock-button
            [readonly]="column.readonly"
            [locked]="row[column.property]"
            (onLockChanged)="changeLock($event, row)"
          ></svc-lock-button>
        </td>
      </ng-container>
    </ng-container>

    <!-- priorityButton  Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'priorityButton'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          <svc-priority-button
            [readonly]="true"
            [priority]="true"
          ></svc-priority-button>
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-priority-button
            [readonly]="column.readonly"
            [priority]="row[column.property]"
            (onPriorityChange)="changePriority($event, row)"
          ></svc-priority-button>
        </td>
      </ng-container>
    </ng-container>

    <!-- settings  Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty; let index">
      <ng-container
        *ngIf="column.type === 'settings'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          class="uppercase bg-table-header font-semibold text-default"
          mat-header-cell
          mat-sort-header
          [ngClass]="column.cssClasses"
          [style.width]="column.width"
        >
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell [style.width]="column.width" class="text-default">
          <svc-priority-button
            [priority]="row[column.property].priority"
            (onPriorityChange)="changePriority($event, row)"
          ></svc-priority-button>
          <svc-lock-button
            [locked]="row[column.property].locked"
            (onLockChanged)="changeLock($event, row)"
          ></svc-lock-button>
        </td>
      </ng-container>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr
      *matRowDef="let row; columns: visibleColumns;"
      @fadeInUp
      mat-row
      (click)="rowClicked(row)"
    ></tr>
  </table>



  <!-- Default text when no data to show -->
  <!-- <div
    *ngIf="!hasData"
    class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
  >
    <div
      class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-warn-100"
    >
      <mat-icon
        class="text-primary-500-700"
        color="warn"
        [svgIcon]="'heroicons_outline:exclamation-circle'"
      ></mat-icon>
    </div>
    <div class="mt-5 text-4xl font-semibold tracking-tight">
      No records to show
    </div>
    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
      Try adjusting the search filters
    </div>
  </div> -->
  <div *ngIf="loadingData" class="flex flex-row justify-center text-gray-700 basis-full py-12">


  </div>

  <div class="flex flex-row justify-end">
    <mat-paginator
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      class="bg-transparent"
    ></mat-paginator>
  </div>
</div>
