import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcBreadcrumbComponent } from './svc-breadcrumb.component';
import { SvcBreadcrumbItemComponent } from './svc-breadcrumb-item/svc-breadcrumb-item.component';

@NgModule({
  declarations: [
    SvcBreadcrumbComponent,
    SvcBreadcrumbItemComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
  ],
  exports: [
    SvcBreadcrumbComponent,
    SvcBreadcrumbItemComponent,
  ]
})
export class SvcBreadcrumbModule { }
