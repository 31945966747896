import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'projects/environments/environment';
import { RequestCollectorService, getAccessTokenPayloadInfo } from 'projects/lib-shared-common/src/public-api';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { NavigationService } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.service';
import { Navigation } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { Counters } from 'projects/lib-shared-core/src/lib/features/user/models/counters';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';
import { User, UserEnvironment } from 'projects/lib-shared-core/src/lib/features/user/services/user.types';
import { SvcFeatureToggleService, tryToRedirectToAspFromQueryParamData, tryToRedirectToCoreFromQueryParamData } from 'projects/lib-shared-core/src/public-api';
import { Subject, takeUntil, tap } from 'rxjs';
import { StaticApplicationId } from '../../../../../../../lib-shared-core/src/lib/Constants/static-application-id.enum';
import { ModalPraiseReceivedComponent } from '../../../../praise/modals/modal-praise-received/modal-praise-received.component';
import { NotificationIntegrationService } from '../../../notification/notification-integration.service';
import { LayoutService } from '../../layout.service';
import { MobileMenuType } from "../components/mobile-menu/mobile-menu.component";
import { SidebarNavigationComponent } from "../components/sidebar-navigation/sidebar-navigation.component";
import { SvcSidebarNavigationService } from "../components/sidebar-navigation/svc-sidebar-navigation.service";
import { VerticalNavigationComponent } from '../components/vertical-navigation/vertical-navigation.component';

@UntilDestroy()
@Component({
  selector: 'svc-workspace-layout',
  templateUrl: './solvace-workspace-layout.component.html',
  styleUrls: ['./solvace-workspace-layout.component.scss'],
})
export class SolvaceWorkspaceLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('appsPanel') appsPanel: any;
  @ViewChild('sidebarNavigationComponent') sidebarNavigationComponent: SidebarNavigationComponent;

  navigation: Navigation;
  user: User;
  environment: UserEnvironment;
  counters: Counters;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public env = environment;
  public feedIsEnabled = false;

  @ViewChild(VerticalNavigationComponent)
  verticalNavigation: VerticalNavigationComponent;

  public windowWidth: number = window.innerWidth;

  public get navigationGotError(): boolean {
    return this._navigationService.error != null;
  }
  public get navigationIsLoading(): boolean {
    return this._navigationService.isLoading;
  }

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _renderer: Renderer2,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _layoutService: LayoutService,
    private _requestCollectorService: RequestCollectorService,
    private _svcSidebarNavigationService: SvcSidebarNavigationService,
    private _dialogService: SvcDialogService,
    private _notificationIntegrationService: NotificationIntegrationService,
    private _featureToggleService: SvcFeatureToggleService,
  ) {
    this._renderer.listen(window, 'resize', () => {
      this.windowWidth = window.innerWidth;
    });
    this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._router.events.pipe(
      untilDestroyed(this),
      tap((event: any) => {
        if (event instanceof NavigationStart) {
          const currentUrl = this._router.url.replace('/', '');
          const nextUrl = event.url.replace('/', '');
          if (currentUrl !== nextUrl) {
            this._requestCollectorService.cancelAll();
          }
        }
      }),
    ).subscribe();

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    this.user = this._userService.user$;
    this.environment = this._userService.environment$;

    this._svcSidebarNavigationService.onOpen.subscribe(module => {
      this.mobileMenuClick('tasks');
    });
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this._layoutService.setVerticalNavigationComponent(this.verticalNavigation);
    this._activatedRoute.queryParamMap.pipe(
      tap((params) => {
        if (params.has('openMenuType')) {
          this.mobileMenuClick(params.get('openMenuType') as MobileMenuType);
        }
        if (params.has('redirectAsp')) {
          const redirectAsp = params.get('redirectAsp');
          if (tryToRedirectToAspFromQueryParamData(redirectAsp)) {
            this.hideApplication();
          }
        }
        if (params.has('redirectCore')) {
          const redirectCore = params.get('redirectCore');
          if (tryToRedirectToCoreFromQueryParamData(redirectCore)) {
            this.hideApplication();
          }
        }
        if (params.has('openNotificationModal')) {
          this._notificationIntegrationService.openIframeByPath(params.get('openNotificationModal'));
        }
      }),
    ).subscribe();
  }

  public reloadUserNavigation(): void {
    if (!this.navigationIsLoading) {
      this._navigationService.get(StaticApplicationId.myworkspace).subscribe();
    }
  }

  public toggleMenu(): void {
    this.appsPanel?.toggle();
  }

  private hideApplication() {
    const splashElement = document.body.querySelector('svc-splash-screen') as HTMLElement;
    if (splashElement) {
      splashElement.style.visibility = 'visible';
      splashElement.style.opacity = '1';
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  sidebarVisible: boolean;

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(): void {
    this.verticalNavigation.toggle();
  }

  onKaizenResumeClicked() {
    this._router.navigate(['/board/main/kaizen']);
  }

  mobileMenuClick(type: MobileMenuType) {
    if (type === 'modules' || type === 'menu') {
      this.toggleMenu();
    }
    else if (type === 'tasks') {
      this.sidebarNavigationComponent.open('tasks');
    }
    else if (type === 'feed') {
      this.sidebarNavigationComponent.open('feed');
    }
  }

  getCounters(value: Counters) {
    this.counters = { ...this.counters, ...value };
  }

  openPraiseReceived() {
    if (this.counters?.compliments > 0) {
      const userId = this._userService.userId$;
      this._dialogService.open(ModalPraiseReceivedComponent, {
        data: userId,
        width: '100%',
        panelClass: ['sm:p-3'],
        maxWidth: '794px',
        autoFocus: false,
        disableClose: true,
      });
    }
  }
}
