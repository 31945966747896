<section *ngIf="!loading">
	<img [src]="imageUrl" [style.width]="width" [style.height]="height" />
	<div class="modal-image-viewer-actions">
		<button matRipple class="rounded-full" [matTooltip]="'Fechar' | transloco" matTooltipPosition="above" (click)="close()">
			<mat-icon class="" svgIcon="mat_solid:close"></mat-icon>
		</button>
	</div>
</section>
<div *ngIf="loading" class="modal-image-viewer-loading">
	<mat-spinner diameter="30"></mat-spinner>
</div>