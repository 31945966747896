import { Injectable } from '@angular/core';
import { MatDialog} from "@angular/material/dialog";
import { SvcUserWireframeComponent, UserWireframe, UserWireframeData} from "./svc-user-wireframe.component";
import { UserEnvironment, UserService } from 'projects/lib-shared-core/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class SvcUserWireframeService {

  private readonly _environment: UserEnvironment;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    this._environment = this.userService.environment$;
  }

  showUser(user: UserWireframe) {
    this.dialog.open(SvcUserWireframeComponent, {
      data: {
        user: user,
        logoImageNegative: this._environment.logoImageNegative,
        showClose: true
      } as UserWireframeData,
      width: '300px',
      minWidth: '300px',
      maxWidth: '300px',
      disableClose: true,
    });
  }

}
