import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvcIconComponent } from './svc-icon/svc-icon.component';
import {NgClass, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";


@NgModule({
  declarations: [

    SvcIconComponent
  ],
  imports: [
    HttpClientModule,
    MatIconModule,
    NgSwitchCase,
    NgSwitch,
    NgIf,
    NgClass
  ],
  exports: [
    SvcIconComponent
  ]
})
export class LibSharedIconsModule {

    constructor(
      private _domSanitizer: DomSanitizer,
      private _matIconRegistry: MatIconRegistry
  )
  {
      // Register icon sets
      this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-mini.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_regular', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-regular.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_brands', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-brands.svg'));
  }
}
