<div
  class="flex justify-around w-full"
  [style.padding-left.px]="padding"
  [style.padding-right.px]="padding"
>
  <div
    *ngFor="let item of items"
    class="text-center"
    [style.width]="itemWidth + 'px'"
  >
    <mat-icon
      svc-dialog-title-icon
      class="text-primary cursor-pointer"
      svgIcon="heroicons_solid:plus-circle"
      (click)="clicked.emit(item)"
    ></mat-icon>
  </div>
</div>
