import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, finalize, tap } from 'rxjs';
import { App, MenuGroup, AppItem } from './apps.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AvailableModule } from './available-module.enum';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private _apps: ReplaySubject<App[]> = new ReplaySubject<App[]>(1);
  public isLoading: boolean = true;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private sanitizer: DomSanitizer
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for apps
   */
  get apps$(): Observable<App[]> {
    return this._apps.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all apps
   */
  getAll(): Observable<MenuGroup[]> {
    this.isLoading = true;
    return this._httpClient
      .get<MenuGroup[]>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup`)
      .pipe(
        tap((apps: MenuGroup[]) => {
          const listApps = apps.map((c) => {
            return {
              id: c.groupId,
              name: c.groupName,
              icon: c.imagePath ? this.sanitizer.bypassSecurityTrustResourceUrl(c.imagePath) : null,
              backgroundColor: c.backgroundColor,
              textActiveColor: c.textColor,
              textInactiveColor: c.inactiveColor,
              highlight: c.isMasterApplication,
              items: c.menuItems.map((x) => {
                return {
                  id: x.applicationId,
                  name: x.applicationName,
                  locked: !x.isActive,
                  codeLanguage: x.aspxLanguageResourceKey,
                  url: x.subUrlPath,
                } as AppItem;
              }),
            } as App;
          });

          this._apps.next(listApps);
        }),
        finalize(() => this.isLoading = false),
      );
  }

  /**
   * Check module by ApplicationId
   */
  checkModuleByApplicationId(applicationId: string): Observable<AvailableModule> {
    return this._httpClient.get<AvailableModule>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${applicationId}/accessible`);
  }
}
