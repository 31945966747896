import { Injectable } from '@angular/core';
import { themeColorIsValid } from 'projects/lib-shared-common/src/public-api';

const DEFAULT_THEME = 'svc-default';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _theme: string = DEFAULT_THEME;

  get theme$(): string {
    return this._theme;
  }

  constructor() {}

  public handleThemeChange(theme: string): void {
    const newTheme = themeColorIsValid(theme) ? theme : DEFAULT_THEME;
    document.body.classList.remove(this._theme);
    document.body.classList.add(newTheme);
    this._theme = newTheme;
  }
}
