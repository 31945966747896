import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Observable } from "rxjs";
import { RuleAndReference, RuleConfiguration } from './models/rule-configuration.model';

@Injectable()
export class SvcNotificationManagementService extends SvcHttpClient {
  constructor(
    protected appConfig: AppEnvironmentConfig,
    protected httpClient: HttpClient,
    private _appSettings: SvcAppSettings,
  ) {
    super(appConfig.APIs.apiUrlNotification, httpClient);
  }

  public getRulesConfig(): Observable<RuleConfiguration[]> {
    return this.get<RuleConfiguration[]>(`/Configuration/ByApplication?applicationId=${this._appSettings.applicationId}`);
  }

  public updateConfigTypeActivation(configId: number, active: boolean): Observable<void> {
    return this.put<void>(`/Configuration/Default?configurationId=${configId}&active=${active}`, null);
  }

  public getByRule(ruleId: number): Observable<RuleConfiguration[]> {
    return this.get<RuleConfiguration[]>(`/Configuration/ByRule?ruleId=${ruleId}`);
  }

  public getByRuleAndReference(ruleId: number, referenceId: number): Observable<RuleAndReference[]> {
    return this.get<RuleAndReference[]>(`/Configuration/ByRuleAndReference?ruleId=${ruleId}&referenceId=${referenceId}`);
  }

  public putCommunicationChannels(communicationId: number, referenceId: number, active: boolean): Observable<RuleAndReference> {
    return this.put<RuleAndReference>(`/Configuration/ByReferenceId?ConfigurationId=${communicationId}&ReferenceId=${referenceId}&Active=${active}`, null);
  }

  public putSurveyChannels(surveyId: number, referenceId: number, active: boolean): Observable<RuleAndReference> {
    return this.put<RuleAndReference>(`/Configuration/ByReferenceId?ConfigurationId=${surveyId}&ReferenceId=${referenceId}&Active=${active}`, null);
  }
}
