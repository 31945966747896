import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {of} from 'rxjs';

export interface PasswordValidationErrors {
	minLength: boolean;
	hasUpperCase: boolean;
	hasNumber: boolean;
	hasSpecialChar: boolean;
}

export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) return null;
		const password = control.value;

		const minLength = /^(?=.{8,})/;
		const hasUpperCase = /^(?=.*[A-Z])/;
		const hasNumber = /^(?=.*[0-9])/;
		const hasSpecialChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/;

		const errors: PasswordValidationErrors = {
			minLength: !minLength.test(password),
			hasUpperCase: !hasUpperCase.test(password),
			hasNumber: !hasNumber.test(password),
			hasSpecialChar: !hasSpecialChar.test(password),
		};

		return of(Object.keys(errors).some(key => errors[key]) ? errors : null);
	};
}