import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Injectable, TemplateRef} from "@angular/core";
import {ComponentType} from "@angular/cdk/portal";
import {SvcDialogConfig} from "./models/svc-dialog-config";
import {SvcDialogSizesWidth} from "./models/svc-dialog-sizes";
import { SvcModalImageViewerComponent } from "../modals/svc-modal-image-viewer/svc-modal-image-viewer.component";
import { SvcConfirmDialogComponent } from "./components/svc-confirm-dialog/svc-confirm-dialog.component";
import { Observable } from "rxjs";
import { SvcWarningDialogComponent } from "./components/svc-warning-dialog/svc-warning-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class SvcDialogService {

  constructor(private _matDialog: MatDialog) {
  }

  open<T>(
    component: ComponentType<T> | TemplateRef<T>,
    config?: SvcDialogConfig
  ): MatDialogRef<T> {
    if (config?.size) {
      config.minWidth = SvcDialogSizesWidth[config.size];
      config.maxWidth = SvcDialogSizesWidth[config.size];
    }
    return this._matDialog.open(component, config);
  }

  openImage(src: string) {
    this._matDialog.open(SvcModalImageViewerComponent, {
      data: {
        imageUrl: src
      },
      panelClass: ['cdk-ignore-hidden'],
    });
  }

  openConfirm(text: string, config?: {
    title?: string,
    okText?: string,
    cancelText?: string,
    hideCloseButton?: boolean,
  }): Observable<boolean> {
    return this._matDialog.open(SvcConfirmDialogComponent, {
      data: {
        text,
        title: config?.title,
        okText: config?.okText,
        cancelText: config?.cancelText,
        hideCloseButton: config?.hideCloseButton
      },
      width: '100%',
      maxWidth: '600px',
      panelClass: ['cdk-ignore-hidden'],
    }).afterClosed();
  }

  closeAll() {
    this._matDialog.closeAll();
  }

  public openWarning(text: string, config?: {
    title?: string,
    buttonText?: string,
  }): Observable<void> {
    return this._matDialog.open(SvcWarningDialogComponent, {
      data: {
        text,
        ...config,
      },
      width: '100%',
      maxWidth: '600px',
      panelClass: ['cdk-ignore-hidden'],
    }).afterClosed();
  }
}
