import {CognitoUser} from "./environment.info.model";

export interface UserStatusAuth {
  user: CognitoUser;
  status: AuthStatus;
}

export enum AuthStatus {
  EmployeeUser = 0, //User is employee
  NotFoundUser, //User reported an invalid site
  MandatoryFederatedUser, //User is internal, must sign in using federation
  PendingGDPRUser, //Missing to confirm GDPR term
  PermissionPendingUser, //User's permission is pending by administrator
  RejectedUser, //User was rejected because they reported an invalid site
  SupplierUser, //User is supplier
  ContractorUser, //User is contractor
  TemporaryPasswordUser //User need to change to a permanent password
}
