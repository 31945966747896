<div class="flex flex-col md:col-span-2 flex-wrap">
  <mat-form-field *ngIf="!readonly" class="w-full flex-auto svc-mat-no-subscript svc-mat-dense">
    <mat-icon *ngIf="icon" matPrefix [svgIcon]="icon"></mat-icon>
    <mat-label *ngIf="label" class="inline-flex input-label-size input-label-weight text-default">
      <div class="flex items-center">
        <mat-icon *ngIf="label.icon" class="icon-size-3 text-current mr-1" [svgIcon]="label.icon"></mat-icon>
        {{ label?.text ?? label }}
      </div>
    </mat-label>

    <textarea *ngIf="!autosize"
      #textArea
      matInput
      [formControl]="control"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
      (blur)="blur.emit()"
    ></textarea>

    <textarea *ngIf="autosize"
      #textArea
      matInput
      [formControl]="control"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
      [id]="inputId"
      (blur)="blur.emit()"
    ></textarea>

  </mat-form-field>
  <p *ngIf="charCount && (control?.value?.length || maxLength)" class="text-right text-sm text-secondary pr-1">
    {{control?.value?.length ?? 0}}<span *ngIf="maxLength">/{{ maxLength }}</span>
  </p>
  <svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>
  <ng-container *ngIf="readonly">
    <div class="flex flex-col flex-auto">
      <div class="flex flex-row items-center input-label-size input-label-weight text-default">
        <mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
        {{ label }}
      </div>
      <span class="text-default font-medium text-base">{{ value ? value : fallbackTextValue }}</span>
    </div>
  </ng-container>
</div>
