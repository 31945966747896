import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, Observable, of, ReplaySubject, Subscription, switchMap, take, tap } from 'rxjs';
import { Notification } from './notifications.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications$: ReplaySubject<Notification> = new ReplaySubject<Notification>(1);
  private _pageIndex = 0;
  private _pageSize = 20;
  private _nextPageSubscription: Subscription;
  private _getAllSubscription: Subscription;
  public isLoading: boolean = false;
  private notifications: Notification;
  private set _notifications(value: Notification) {
    this.notifications = this._pageIndex <= 0 ? value : {
      unreadCount: value.unreadCount,
      notifiticationsAreGone: value.notifications.length < this._pageSize,
      notifications: [...this.notifications.notifications, ...value.notifications],
    };
    this._notifications$.next(this.notifications);
  }

  get notifications$(): Observable<Notification> { return this._notifications$.asObservable(); }

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig
  ) {}

  async verifyNew(): Promise<Observable<Notification>> {
    setTimeout(async () => {
      this._pageIndex = 1;
      this._notifications = {
        unreadCount: this.notifications.unreadCount,
        notifiticationsAreGone: false,
        notifications: [],
      };
      this._getAllSubscription = this.getAll().subscribe();
      this.verifyNew();
    }, 300000);
    return this.notifications$;
  }

  nextPage() {
    this._pageIndex++;
    this._nextPageSubscription = this.getAll().subscribe();
  }

  getAll(): Observable<Notification> {
    this._nextPageSubscription?.unsubscribe();
    this._getAllSubscription?.unsubscribe();
    this.isLoading = true;
    return this._httpClient.get<Notification>(`${this._appConfig.APIs.apiUrlNotification}/Notification`, {
      params: {
        pageIndex: this._pageIndex,
        pageSize: this._pageSize,
      }
    }).pipe(
      tap((response: Notification) => {
        response.notifications.forEach((item) => {
          if (item.title === item.message) {
            item.message = '';
          }
          else if (item.title && !item.title.endsWith('.')) {
            item.title += '.';
          }
          if (item.badges === item.applicationName) {
            item.badges = null;
          }
        });
        this._notifications = response;
      }),
      finalize(() => this.isLoading = false),
    );
  }

  markAllAsRead(): Observable<void> {
    return this._httpClient.put<void>(`${this._appConfig.APIs.apiUrlNotification}/Notification/ReadNotificationsByUserId`, null)
    .pipe(
      switchMap(() => {
        this._pageIndex = 0;
        return this.getAll().pipe(
          switchMap(() => of(null)),
        );
      })
    );
  }
}
