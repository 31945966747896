import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SvcFilterMode } from '../enums/svc-filter-mode.enum';
import { SvcFilterType } from '../enums/svc-filter-type.enum';
import { SvcDataHeaderComponent } from '../svc-data-header/svc-data-header.component';
import { SvcFunctionsHelper, isNullOrUndefined, isObject } from 'projects/lib-shared-common/src/public-api';
@Component({
	selector: 'svc-data-col',
	templateUrl: './svc-data-col.component.html',
	styleUrls: ['./svc-data-col.component.scss']
})
export class SvcDataColComponent implements AfterViewInit, OnInit, OnChanges {
	@HostBinding('class.svc-data-col') public _class: boolean = true;
	@HostBinding('class.justify-start') public get alignToLeft() { return this.align == 'left'; }
	@HostBinding('class.justify-end') public get alignToRight() { return this.align == 'right'; }
	@HostBinding('class.justify-center') public get alignToCenter() { return this.align == 'center'; }

	@ViewChild('ngContent') ngContent: ElementRef<HTMLElement>;

	@Input() public width: string = null;

	@Input('custom-filter') public customFilter: { field: string, mode?: SvcFilterMode, value?: any } |
		{ field: string, mode?: SvcFilterMode, value?: any }[] |
		string |
		string[];
	@Input() public filter: SvcFilterType = SvcFilterType.NONE;
	@Input() public field: string = '';
	@Input() public mode: SvcFilterMode = null;
	@Input() public placeholder = '';
	@Input() public readOnly: boolean = false;
	@Input() public minDate: Date;
	@Input() public maxDate: Date;
	@Input() public dateFormat: string = 'dd/MM/yyyy';
	@Input() public dateSeparator: string = '/';
	@Input() public optionAllLabel: string = 'Todos';
	@Input() public options: { text: string, value: any }[] | any[] = [];
	@Input() public title: string;
	@Input() public align: 'left' | 'right' | 'center' = 'left';

	@Input('no-sort') public noSort: boolean = true;
	@Input('no-truncate') public noTruncate: boolean = false;
	@Input('sort') public sort: string = null;
	@Input('disable-filter') public disableFilter: boolean = false;
	@Input('filter-value') public filterValue: any = null;

	public set _width(value: string) {
		if (this.el) {
			this.el.style.width = value;
			this.el.style.maxWidth = value;
			this.el.style.minWidth = value === 'auto' ? '0' : value;
		}
	}
	public get _width() { return (this.elRef && this.elRef.nativeElement) ? this.elRef.nativeElement.style.width : 'auto'; }

	public get el() { return (this.elRef && this.elRef.nativeElement) ? this.elRef.nativeElement : null; }

	public colIndex: number = 0;
	public parent: SvcDataHeaderComponent;
	public canFiltering: boolean = false;
	public fields: string[] = [];
	public columnName: string;

	public get canFilter() {
		return (this.filter && this.filter !== SvcFilterType.NONE && this.filter.toString() !== '') ||
			(this.customFilter && (this.customFilter as any[]).length > 0);
	}

	public filterActive: boolean = false;

	public get isSorting() { return this.parent && this.parent.colSorting && this.parent.colSorting.col === this; }
	public get isSortAsc() { return this.isSorting && this.parent.colSorting.asc; }

	constructor(
		private helper: SvcFunctionsHelper,
		private fb: FormBuilder,
		private elRef: ElementRef<HTMLElement>
	) {
	}

	ngOnInit() {
		if (!this.noSort && !this.field) {
			this.filter = SvcFilterType.NONE;
			console.warn(`The sorting/ordering will not be applied because the [field] of the column has not been defined.`);
		}
	}

	ngAfterViewInit() {
		this.columnName = this.ngContent?.nativeElement?.textContent;
	}

	ngOnChanges(changes: SimpleChanges) {

		if (!changes || 'width' in changes) {
			if (!this.widthIsValid())
				this.width = 'auto';

			if (this.widthIsValid() && this.helper.onlyNumbers(this.width) === this.width)
				this.width += 'px';

			if (this.parent && this.parent.cols) {
				let unit = this.getWidthUnit();
				if (unit !== '' && this.parent.cols.some((x) => x.getWidthUnit() !== '' && x.getWidthUnit() !== unit)) {
					let anotherUnit = this.parent.cols.find((x) => x.getWidthUnit() !== '' && x.getWidthUnit() !== unit).getWidthUnit();
					console.warn(`Another column has the width specified with "${anotherUnit}". So [width] will be changed to "auto".`);
					this.width = 'auto';
					this.setWidth();
					return;
				}
			}

			if (this.getWidthUnit() === '%') {
				if (parseFloat(this.width.replace(this.getWidthUnit(), '')) >= 100) {
					console.warn(`The columns cannot have width equal or greater than 100%.`);
					this.width = 'auto';
					this.setWidth();
					return;
				}
			}
			this.setWidth();
		}

		if (changes) {
			if ('noSort' in changes)
				this.noSort = (typeof this.noSort === 'string' && (this.noSort === '' || this.noSort === 'true')) ? true : this.noSort;
			if ('noTruncate' in changes)
				this.noTruncate = (typeof this.noTruncate === 'string' && (this.noTruncate === '' || this.noTruncate === 'true')) ? true : this.noTruncate;

			if ('sort' in changes) {
				this.sort = !this.sort ? null : this.sort;

				if (this.sort !== 'asc' && this.sort !== 'desc')
					this.sort = null;

				if (this.parent) {
					this.parent.colSorting = {
						col: this,
						asc: this.sort === 'asc'
					};
					this.sort = null;
					this.parent.parent.onSortChange();
				}
			}

			if ('filter' in changes) {
				if (!this.filter && this.filter.toString() !== '')
					this.filter = SvcFilterType.NONE;
				else if (this.filter.toString() === '')
					this.filter = SvcFilterType.TEXT;

				if (this.filter !== SvcFilterType.NONE && !this.mode) {
					switch (this.filter) {
						case SvcFilterType.TEXT:
						case SvcFilterType.FEDERAL_ID:
						case SvcFilterType.CARD_NUMBER:
						case SvcFilterType.CURRENCY:
						case SvcFilterType.CARD_MASK:
							this.mode = SvcFilterMode.CONTAINS;
							break;
						case SvcFilterType.SELECT:
						case SvcFilterType.DATE:
							this.mode = SvcFilterMode.EQUALS;
							break;
						default:
							this.mode = SvcFilterMode.CONTAINS;
							break;
					}
				}

				if (this.parent) {
					if (this.field && this.parent.filterCtrls[this.field]) {
						if (this.disableFilter)
							this.parent.filterCtrls[this.field].disable();
						else
							this.parent.filterCtrls[this.field].enable();
					}
					else if (this.customFilter)
						(this.customFilter as { field: string, mode?: SvcFilterMode, value?: any }[]).forEach((custom) => {
							if (this.disableFilter)
								this.parent.filterCtrls[custom.field].disable();
							else
								this.parent.filterCtrls[custom.field].enable();
						});
				}

				if (this.canFilter && !this.field) {
					this.filter = SvcFilterType.NONE;
					console.warn(`The filter will not applied because the [field] of column has not been defined.`);
				}

				setTimeout(() => {
					if (this.parent)
						this.parent.parent.definePaddingTop();
				});
			}

			if ('customFilter' in changes) {
				if (this.canFilter && this.field) {
					console.warn(`The [custom-filter] will not applied because the [filter] has already been defined.`);
					this.customFilter = null;
					return;
				}
				else {
					if (this.customFilter && (isObject(this.customFilter) || typeof this.customFilter === 'string')) {
						if (isObject(this.customFilter) && !Array.isArray(this.customFilter))
							this.customFilter = [(this.customFilter as any)];
						else if (Array.isArray(this.customFilter) && (this.customFilter.length > 0 && typeof this.customFilter[0] === 'string'))
							this.customFilter = (this.customFilter as string[]).map(field => ({ field }));
						else if (!isObject(this.customFilter))
							this.customFilter = [{ field: this.customFilter }] as any[];

						if (!Array.isArray(this.customFilter) || !(this.customFilter as any[]).every(x => (isObject(x) && x.field) ? true : false)) {
							console.warn(`The [custom-filter] will not applied because field has not been informed or is invalid. The value should look like this: { field: string, mode?: string }`);
							this.customFilter = null;
						}
						else {
							this.noSort = true;

							(this.customFilter as any[]).forEach((filter) => {
								if (!filter.mode)
									filter.mode = SvcFilterMode.CONTAINS;
							});

							setTimeout(() => {
								if (this.parent)
									this.parent.parent.definePaddingTop();
							});
						}
					}
					else {
						console.warn(`The [custom-filter] will not applied because value is invalid. The value should look like this: { field: string, mode?: string }`);
						this.customFilter = null;
					}
				}
			}

			if ('dateFormat' in changes) {
				if (this.dateFormat)
					this.dateFormat = this.dateFormat.replace(/m/g, 'M').substr(0, 10);
				else
					this.dateFormat = 'dd/MM/yyyy';
			}
		}
	}

	public makeFormControl(defaultValue: any = null) {
		return this.fb.control(defaultValue);
	}

	public onFiltering() {
		if (this.parent && this.parent.parent) {
			if (this.parent) {
				if (this.field)
					this.filterActive = (this.parent.filterCtrls[this.field] && this.parent.filterCtrls[this.field].value !== null && this.parent.filterCtrls[this.field].value !== '');
				else if (this.customFilter)
					this.filterActive = (this.customFilter as { field: string, mode?: SvcFilterMode, value?: any }[]).some((custom) => {
						return this.parent.filterCtrls[custom.field] && this.parent.filterCtrls[custom.field].value !== null && this.parent.filterCtrls[custom.field].value !== '';
					});
			}
			else
				this.filterActive = false;

			this.parent.parent.onFilterChange();
		}
	}

	public onFilterRender() {
		if (this.parent) {
			this.parent.parent.definePaddingTop();
		}
	}

	public toggleSort() {
		if (this.parent && this.parent.parent) {
			let asc;
			if (this.isSorting) {
				if (this.parent.colSorting.asc) {
					asc = false;
					this.parent.colSorting = {
						col: this,
						asc: asc
					};
				} else {
					this.parent.colSorting = null;
				}
			} else {
				asc = true;
				this.parent.colSorting = {
					col: this,
					asc: asc
				};
			}

			this.parent.parent.onSortChange();
		}
	}

	public widthIsValid() {
		return !isNullOrUndefined(this.width) && (typeof this.width === 'string') && this.width !== '' && this.width !== 'auto';
	}

	public getWidthUnit() {
		return this.widthIsValid() ? this.width.replace(this.helper.onlyNumbers(this.width), '') : '';
	}

	public onRender(parent: SvcDataHeaderComponent, index) {
		this.parent = parent;
		this.colIndex = index;
		this.canFiltering = this.canFilter && !isNullOrUndefined(this.parent.filterCtrls);
		this.fields = this.generateFieldsFilter();
		this.setWidth();
		this.ngOnChanges(null);
	}

	private generateFieldsFilter() {
		if (this.canFilter) {
			if (this.field)
				return [this.field];
			else
				return (this.customFilter as any[]).map((custom) => custom.field);
		}
		return [];
	}

	public setWidth() {
		if (this.parent && this.parent.parent.body.itemsContainerElRef && this.parent.parent.body.itemsContainerElRef.nativeElement) {
			this._width = this.width;
		}
		else {
			this._width = 'auto';
		}

		this.setCellsWidth();
	}

	public setCellsWidth() {
		if (this.parent && this.parent.parent.body && this.parent.parent.body.rows.length) {
			this.parent.parent.body.rows.forEach(row => {
				let cell = row.cells.find(cell => cell.cellIndex === this.colIndex);
				if (cell)
					cell.setWidth();
			});
		}
	}
}
