import { Injectable } from '@angular/core';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcFilterMode } from '../enums/svc-filter-mode.enum';
import { SvcDataChangeAction } from '../enums/svc-data-change-action.enum';
import { SvcFilterType } from '../enums/svc-filter-type.enum';
import { SvcFunctionsHelper, isNullOrUndefined, isObject } from 'projects/lib-shared-common/src/public-api';
import { DatePipe } from '@angular/common';

@Injectable()
export class SvcDataPrepareService {

	constructor(
		private _helper: SvcFunctionsHelper,
		private _datePipe: DatePipe,
	) {
	}

	public apply(data: any[], sbDataTable: SvcDataTableComponent) {
		if (data && Array.isArray(data) && data.length) {
			if (!sbDataTable.serverSide) {
				let _data: any[];

				let length = data.length;
				let lengthPaginated = data.length;

				if (sbDataTable.isFiltering) {
					_data = this.applyFilter(data, sbDataTable);
					length = _data.length;
				}

				if (sbDataTable.isOrdering) {
					_data = this.applySort(_data ? _data : data, sbDataTable);
					length = _data.length;
				}

				if (sbDataTable.isPaging) {
					_data = this.applyPaginate(_data ? _data : data, sbDataTable);
					lengthPaginated = _data.length;
				}

				sbDataTable.setDataLength(length, lengthPaginated);

				return _data ? _data : data;
			}

			return data;
		}
		else {
			if (!sbDataTable.serverSide)
				sbDataTable.setDataLength(0, 0);

			return [];
		}
	}

	private applyFilter(data: any[], table: SvcDataTableComponent) {
		let header = table.header;
		let frm = header.frmFilter;

		if (table.actionChange === SvcDataChangeAction.FILTER)
			table.setCurrentPage(1);

		let _data = data.filter((item) => {

			let ok = true;
			for (let field in frm.controls) {

				let value: any;
				if (field.includes('.')) {
					let fields = field.split('.');
					value = item[fields[0]];
					fields.slice(1).forEach(field => {
						if (isObject(value))
							value = (field in value) ? value[field] : null;
					});
				}
				else
					value = item[field];

				let filterCol = header.getColByField(field);
				let filterMode = header.getColFilterModeByField(field);

				let filterValue = frm.controls[field].value;

				if (filterCol && filterCol.filter === SvcFilterType.DATE) {
					value = this._datePipe.transform(value, filterCol.dateFormat);
					filterValue = filterValue ? this._datePipe.transform(filterValue, filterCol.dateFormat) : filterValue;
				}
				else {
					if (filterValue || (typeof filterValue === 'number'))
						filterValue = this._helper.removeAccents(filterValue.toString() as string).toUpperCase();
					else
						filterValue = '';

					if ((value || (typeof value === 'number')))
						value = this._helper.removeAccents(value.toString() as string).toUpperCase();
					else
						value = '';
				}

				if (filterCol && !isNullOrUndefined(filterValue) && filterValue !== '') {
					filterValue = this._helper.removeAccents(filterValue.toString() as string).toUpperCase();
					switch (filterMode) {
						case SvcFilterMode.CONTAINS:
							ok = value.includes(filterValue);
							break;
						case SvcFilterMode.EQUALS:
							ok = value === filterValue;
							break;
						case SvcFilterMode.STARTS_WITH:
							ok = value.startsWith(filterValue);
							break;
						case SvcFilterMode.ENDS_WITH:
							ok = value.endsWith(filterValue);
							break;
					}
				}

				if (!ok) break;
			}

			return ok;
		});

		return _data;
	}

	private applySort(data: any[], table: SvcDataTableComponent) {
		if (table.actionChange === SvcDataChangeAction.ORDER)
			table.setCurrentPage(1);

		if (table.header.colSorting) {
			let field = table.header.colSorting.col.field;
			let asc = table.header.colSorting.asc;

			let dateRegex: RegExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
			let _data = data.map(item => ({...item})).sort((a: any, b: any) => {
				let valueA = a[field];
				let valueB = b[field];

				if (typeof valueA === 'string' && dateRegex.test(valueA))
					valueA = new Date(valueA);
				if (typeof valueB === 'string' && dateRegex.test(valueB))
					valueB = new Date(valueB);

				if (typeof valueA === 'string' && valueA?.toString().toLowerCase() < valueB?.toLowerCase())
					return asc ? -1 : 1;
				if (typeof valueB === 'string' && valueA?.toString().toLowerCase() > valueB?.toLowerCase())
					return asc ? 1 : -1;

				if (valueA < valueB)
					return asc ? -1 : 1;
				if (valueA > valueB)
					return asc ? 1 : -1;

				return 0;
			});
			return _data;
		}

		return data;
	}

	private applyPaginate(data: any[], table: SvcDataTableComponent) {
		let currentPage = table.currentPage;
		let size = table.paginate;

		let begin = (currentPage - 1) * size;
		let end = begin + size;

		let _data = data.slice(begin, end);

		return _data;
	}

}
