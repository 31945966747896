import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { Observable, delay, map, of } from "rxjs";

@Injectable()
export class SvcAudioToTextButtonService {
	constructor(
		private _appConfig: AppEnvironmentConfig,
		private _httpClient: HttpClient,

	) { }

	transcript(blob: Blob): Observable<string> {
		const url = `${this._appConfig.APIs.apiUrlMultilingual}/SpeechToText`;
		const formData = new FormData();
    formData.append('audio', blob);
		return this._httpClient.post<{ text: string }>(url, formData, { reportProgress: true }).pipe(
			map((response) => response.text),
		);
	}
}