export type SvcDialogSizes =
  'sm' | 'md' | 'lg' | 'xl'
;

export const SvcDialogSizesWidth: Record<SvcDialogSizes, string> = {
  sm: '30vw',
  md: '50vw',
  lg: '70vw',
  xl: '90vw'
}
