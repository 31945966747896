import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcControlsModule } from '../svc-controls/svc-controls.module';
import { SvcCustomFieldsComponent } from './svc-custom-fields.component';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule,
    SvcControlsModule,
    DragDropModule
  ],
  exports: [SvcCustomFieldsComponent],
  declarations: [SvcCustomFieldsComponent],
})

export class SvcCustomFieldsModule {}
