import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvcPrinterData, SvcPrinterOption, SvcPrinterSubOption } from './svc-printer-classes';
import { delay, lastValueFrom, of } from 'rxjs';

@Component({
  selector: 'svc-printer-modal',
  templateUrl: './svc-printer-modal.component.html',
  styleUrls: ['./svc-printer-modal.component.scss'],
})
export class SvcPrinterModalComponent implements OnInit, OnDestroy {

  protected someOptionSeleted = false;
  protected isLoading = false;

  private set _isLoading(value: boolean) {
    this.isLoading = value;
    this._dialogRef.disableClose = this.isLoading;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: SvcPrinterData,
    private _dialogRef: MatDialogRef<SvcPrinterModalComponent>,
  ) {
  }

  public ngOnInit(): void {
    this.checktIfThereIsSomeOptionSeleted();
  }

  protected async print() {
    await this.data?.onPrinting?.printWillStart?.(this.data.options);
    await this._takeBreak();

    const elementsToPrint: HTMLElement[] = []
    const elementHandling = (element: HTMLElement, option: SvcPrinterOption | SvcPrinterSubOption) => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden');
      }
      if (element instanceof HTMLImageElement) {
        element.classList.add('w-full', 'h-auto');
      }
    };
    const addSeparation = () => {
      const separation = document.createElement('div');
      separation.classList.add('page-break');
      elementsToPrint.push(separation);
    };
    for (const option of this.data.options) {
      if ((!(option.show ?? true) || option.selected) && option.element) {
        const clonedElement = option.element.cloneNode(true) as HTMLElement;
        elementHandling(clonedElement, option);
        if (option.separatedPage && elementsToPrint.length > 0) addSeparation();
        elementsToPrint.push(clonedElement);

        for (const optionChild of (option.options ?? [])) {
          if ((!(optionChild.show ?? true) || optionChild.selected) && optionChild.elements.length > 0) {
            for (const elementChild of optionChild.elements) {
              const clonedChildElement = elementChild.cloneNode(true) as HTMLElement;
              elementHandling(clonedChildElement, optionChild);
              if (optionChild.separatedPage) addSeparation();
              elementsToPrint.push(clonedChildElement);
              if (optionChild.separatedPage) addSeparation();
            }
          }
        }

        if (option.separatedPage) addSeparation();
      }
    }

    const containerElement = this._showElementsToRender(elementsToPrint);
    const imgs = containerElement.querySelectorAll('img');
    if (imgs.length > 0) {
      this._isLoading = true;
      await this._waitImgsBeLoaded(Array.from(imgs));
      this._isLoading = false;
    }

    await this.data?.onPrinting?.printDidStart?.(this.data.options);
    await this._takeBreak();
    window.print();
    setTimeout(async () => {
      await this.data?.onPrinting?.printWillFinish?.(this.data.options);
      await this._takeBreak();
      this._hideElementsToRender();
      await this.data?.onPrinting?.printDidFinish?.(this.data.options);
    }, 1000);
  }

  protected changeOptionSelected(option: SvcPrinterOption) {
    option.selected = !(option.selected ?? false);
    if (!option.selected && option.options?.length) {
      option.options.forEach((op) => op.selected = false);
    }
    this.checktIfThereIsSomeOptionSeleted();
  }

  protected checktIfThereIsSomeOptionSeleted() {
    this.someOptionSeleted = (this.data?.options ?? []).some(x => x.selected);
  }

  private _showElementsToRender(elements: HTMLElement[]): HTMLElement {
    const printContainer = document.createElement('div');
    printContainer.id = 'printable';
    printContainer.classList.add('printable-container');
    for(const element of elements) {
      printContainer.appendChild(element);
    }
    document.body.prepend(printContainer);
    return printContainer;
  }

  private _hideElementsToRender() {
    const printContainer = document.querySelector('#printable');
    if (printContainer) {
      document.body.removeChild(printContainer);
    }
  }

  private _waitImgsBeLoaded(imgs: HTMLImageElement[]): Promise<void> {
    return new Promise<void>((resolve) => {
      const imgsLoading = Array.from(imgs).map(() => true);
      const checkIfLoadingWasDone = () => {
        if (imgsLoading.every((loading) => !loading)) {
          resolve();
        }
      }
      imgs.forEach((img, i) => {
        img.onload = () => {
          imgsLoading[i] = false;
          checkIfLoadingWasDone();
        }
        img.onerror = () => {
          imgsLoading[i] = false;
          checkIfLoadingWasDone();
        }
      });
    });
  }

  private async _takeBreak() {
    return lastValueFrom(of([]).pipe(delay(0)));
  }

  ngOnDestroy(): void {
    this._hideElementsToRender();
  }
}
