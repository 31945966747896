import { AfterViewChecked, Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { SettingsService, SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { LegacyService } from 'projects/lib-shared-feature/src/public-api';

@Component({
  selector: 'svc-search-actionplan',
  templateUrl: './svc-search-actionplan.component.html',
  styleUrls: ['./svc-search-actionplan.component.scss']
})
export class SvcSearchActionplanComponent implements AfterViewChecked, OnDestroy {

  @ViewChild('iframeContent') public set iframeContet(element: HTMLDivElement) {
    if (element && this.firstTime) {
      this.firstTime = false;
      this._legacyService._addLegacyModalSupportSearchActionplan();
    }
  }

  public firstTime = true;
  public moduleName: string;

  private unsubscribe$ = new Subject<void>();

  constructor (
    private _appSettings: SvcAppSettings,
    private _legacyService: LegacyService,
    private _settingsService: SettingsService,
    private _router: Router,
    private _translocoService: TranslocoService,
    private _renderer: Renderer2,
  ) {
    this.moduleName = this._appSettings.applicationName;
    this.getLegacyCallback();
  }

  public ngAfterViewChecked(): void {
    this.showButtonFloat('none');
  }

  public ngOnDestroy(): void {
    this.showButtonFloat('block');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showActionPlanSearch(): void {
    const params = {
      functionName: 'OpenDynamicPage',
      urlToOpen: '/actionplan/plan/search',
      modalData: {
        flmoduleintegration:true,
        cbosystem: this._appSettings.applicationId
      },
      modalId: '#action-plan-search',
      modalTimeout: 0,
      theme: this._settingsService.theme$.replace('svc-', ''),
      modals: ['custom-kanban-edit-modal', 'registry-escalate-plan-modal', 'registry-reschedule-plan-modal', 'registry-action-plan-modal'],
      loadingText: this._translocoService.translate('Carregando')
    };

    window?.['_sendMessagePage']?.('openPage', params);
  }

  private getLegacyCallback(): void {
    this._legacyService.onLegacyCallback$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (customEvent: CustomEvent) => {
          if (customEvent?.detail?.functionName === 'openNotificationModal' && customEvent?.detail?.data?.url)
            this._router.navigateByUrl(customEvent.detail.data.url);
        }
      });
  }

  private showButtonFloat(display: 'block' | 'none'): void {
    const buttonFloat: HTMLElement = document.querySelector('svc-button-float');

    if (buttonFloat?.style?.display !== display)
      this._renderer.setStyle(buttonFloat, 'display', display);
  }
}
