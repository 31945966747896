<ng-container [ngSwitch]="mode">

  <button
    *ngSwitchCase="'simple'"
    mat-button
    [ngClass]="[size, (iconOnly ? 'icon-only' : '')]"
    [color]="color"
    [type]="type"
    [disabled]="disabled || processing"
    [class.w-full]="fullWidth"
    [id]="buttonId"
  >
    <ng-container *ngTemplateOutlet="contentButton"></ng-container>
  </button>

  <button
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    [ngClass]="[size, (iconOnly ? 'icon-only' : '')]"
    [color]="color"
    [type]="type"
    [disabled]="disabled || processing"
    [class.w-full]="fullWidth"
    [id]="buttonId"
  >
    <ng-container *ngTemplateOutlet="contentButton"></ng-container>
  </button>

  <button
    *ngSwitchCase="'flat'"
    mat-flat-button
    [ngClass]="[size, (iconOnly ? 'icon-only' : '')]"
    [color]="color"
    [type]="type"
    [class.w-full]="fullWidth"
    [disabled]="disabled || processing"
    [id]="buttonId"
  >
    <ng-container *ngTemplateOutlet="contentButton"></ng-container>
  </button>

</ng-container>

<ng-template #contentButton>

  <mat-progress-spinner
    *ngIf="processing"
    [diameter]="24"
    mode="indeterminate"
  ></mat-progress-spinner>

  <ng-container *ngIf="!processing">
    <ng-content></ng-content>
  </ng-container>

</ng-template>

