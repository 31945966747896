<div class="flex gap-1" [ngClass]="{ 'pointer-events-none': readonly }">
  <div *ngFor="let filled of ratingArr; index as i" class="text-yellow-500">
    <mat-icon
      (click)="onStarClick(i)"
      class="cursor-pointer icon-size-6 text-current"
      [svgIcon]="filled ? 'heroicons_solid:star' : 'heroicons_outline:star'"
    ></mat-icon>
  </div>
</div>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
