import { SvcTableComponent } from './svc-table.component';
import { NgModule } from '@angular/core';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcProgressBarModule } from '../svc-progress-bar/svc-progress-bar.module';
import { SvcStatusBadgeModule } from '../svc-status-badge/svc-status-badge.module';
import { SvcPriorityButtonModule } from '../svc-priority-button/svc-priority-button.module';
import { SvcLockButtonModule } from '../svc-lock-button/svc-lock-button.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import {SvcLoadersModule} from "../svc-loaders/svc-loaders.module";
import { TranslocoModule } from '@ngneat/transloco';
import {SvcSlideToggleModule} from "../svc-slide-toggle/svc-slide-toggle.module";

@NgModule({
    imports: [
        LibSharedCommonModule,
        LibSharedComponentModule,
        LibSharedIconsModule,
        SvcProgressBarModule,
        SvcStatusBadgeModule,
        SvcPriorityButtonModule,
        SvcLockButtonModule,
        SvcLoadersModule,
        TranslocoModule,
        SvcSlideToggleModule,
    ],
  declarations: [SvcTableComponent],
  exports: [SvcTableComponent],
})
export class SvcTableOldModule {}
