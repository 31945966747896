<div class="progress flex flex-row bg-slate-50 border rounded-full sm:min-w-90 md:min-w-0">
  <div
    class="flex flex-row justify-center items-center text-white text-sm font-semibold rounded-full"
    [style.width]="whichProgress(progress) + '%'"
    [style.background-color]="!degraded ? color : whichColor(progress)"
  >
    <ng-container *ngIf="!disabledP">
      {{ whichProgress(progress) }}%
    </ng-container>
    <ng-container *ngIf="disabledP">
      {{ whichProgress(progress) }}
    </ng-container>
  </div>
</div>
