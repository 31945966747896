import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcRouterOutletComponent } from './apps/svc-router-outlet/svc-router-outlet.component';


@NgModule({
  declarations: [
    SvcRouterOutletComponent,
  ],
  imports: [
    LibSharedCommonModule
  ],
  exports: [
    SvcRouterOutletComponent,
  ]
})
export class LibSharedCoreModule { 
    /**
   * Constructor
   */
  constructor(
      @Optional() @SkipSelf() parentModule?: LibSharedCoreModule
  )
  {
      // Do not allow multiple injections
      if ( parentModule )
      {
          throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
      }
  }
}