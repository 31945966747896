import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SvcReactionService } from './svc-reaction.service';
import { finalize, tap } from 'rxjs/operators';
import { ReactionType, ReactionTypeEnum } from './models/reaction-type.model';
import { SvcAppSettings, UserService} from 'projects/lib-shared-core/src/public-api';
import { Subscription } from 'rxjs';
import { Reacted } from './models/reacted.model';

@Component({
  selector: 'svc-reaction',
  templateUrl: './svc-reaction.component.html',
  styleUrls: ['./svc-reaction.component.scss'],
})
export class SvcReactionComponent implements OnInit, OnDestroy {
  @HostBinding('attr.role') private role = 'button';
  @HostBinding('attr.tabIndex') private tabIndex = 0;
  @HostBinding('class.cursor-default') private get cursorDefault() {
    return this.disabled;
  }

  @Input() public registryUniqueId: number | string;
  @Input() public disabled: boolean = false;
  @Input() public positionX: 'left' | 'right' | 'center' = 'center';
  @Input() public positionY: 'top' | 'bottom' = 'top';
  @Input() public applicationId: string;
  @Input() public dontEmitFirstTime: boolean = false;
  @Input() public siteId: number;
  @Output() public onLoadingChange = new EventEmitter<boolean>();
  @Output() public onReactionChange = new EventEmitter<ReactionType>();

  public loadingList: boolean = false;
  public loadingCurrent: boolean = false;
  public showReactions = false;
  public reactionsType: ReactionType[] = [];
  public currentReactionType: ReactionType;

  private _requestSubscription: Subscription;

  constructor(
    private _appSettings: SvcAppSettings,
    private _service: SvcReactionService,
    private _userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this._loadReaction(true);
  }

  private _loadReaction(itIsFirstTime: boolean = false) {
    this.loadingList = true;
    const emit = !itIsFirstTime || !this.dontEmitFirstTime;
    emit && this.onLoadingChange.emit(true);
    this._requestSubscription = this._service.getReactionsType().pipe(
      tap((list: ReactionType[]) => {
        this.reactionsType = list;

        if (this.registryUniqueId) {
          const currentRequest = typeof this.registryUniqueId == 'number'
            ? this._service.getIdTotalReaction({
              applicationId: this.applicationId ?? this._appSettings.applicationId,
              registryUniqueId: this.registryUniqueId,
              siteId: this.siteId ?? this._userService.user$.lastSiteId,
            })
            : this._service.getUidTotalReaction({
              applicationId: this.applicationId ?? this._appSettings.applicationId,
              registryUniqueUId: this.registryUniqueId,
              siteId: this.siteId ?? this._userService.user$.lastSiteId,
            });
          this.loadingCurrent = true;
          this._requestSubscription = currentRequest.pipe(
            tap((totalReactions?: Reacted[]) => {
              const current = totalReactions?.find(x => x.userHasReacted);
              if (current) {
                this.currentReactionType = this.reactionsType.find(x => x.reactionUniqueId === current.reactionUniqueId);
                emit && this.onReactionChange.emit(this.currentReactionType);
              }
              else {
                this.currentReactionType = null;
                emit && this.onReactionChange.emit();
              }
            }),
            finalize(() => {
              this.loadingCurrent = false;
              emit && this.onLoadingChange.emit(false);
            }),
          ).subscribe();
        }
        else {
          this.loadingCurrent = false;
          emit && this.onLoadingChange.emit(false);
        }
      }),
      finalize(() => this.loadingList = false),
    ).subscribe();
  }

  public refresh() {
    this._loadReaction();
  }

  public makeReactionByEnum(reactionTypeEnum: ReactionTypeEnum) {
    const reactionType = this.reactionsType.find(x => x.enumKey == reactionTypeEnum);
    if (reactionType) {
      this.makeReaction(reactionType);
    }
  }

  public async makeReaction(reactionType: ReactionType) {
    if (reactionType.reactionUniqueId == this.currentReactionType?.reactionUniqueId) {
      this.removeCurrentReaction();
    }
    else {
      if (this.currentReactionType) {
        await this.removeCurrentReaction({ skipEmit: true });
      }
      this.loadingCurrent = true;
      this.onLoadingChange.emit(true);
      const request = typeof this.registryUniqueId == 'number'
        ? this._service.reactionId({
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: reactionType.reactionUniqueId,
          registryUniqueId: this.registryUniqueId,
        })
        : this._service.reactionUid({
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: reactionType.reactionUniqueId,
          registryUniqueUId: this.registryUniqueId,
        });
      this._requestSubscription = request.pipe(
        tap(() => {
          this.currentReactionType = reactionType;
          this.onReactionChange.emit(this.currentReactionType);
        }),
        finalize(() => {
          this.loadingCurrent = false;
          this.showReactions = false;
          this.onLoadingChange.emit(false);
        }),
      ).subscribe();
    }
  }

  public removeCurrentReaction(options?: { skipEmit: boolean }) {
    return new Promise<void>((resolve) => {
      this.loadingCurrent = true;
      this.onLoadingChange.emit(true);
      const request = typeof this.registryUniqueId == 'number'
        ? this._service.removeIdReaction({
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: this.currentReactionType.reactionUniqueId,
          registryUniqueId: this.registryUniqueId,
        })
        : this._service.removeUidReaction({
          siteId: this.siteId ?? this._userService.user$.lastSiteId,
          applicationId: this.applicationId ?? this._appSettings.applicationId,
          reactionUniqueId: this.currentReactionType.reactionUniqueId,
          registryUniqueUId: this.registryUniqueId,
        });
      this._requestSubscription = request.pipe(
        tap(() => {
          if (!options?.skipEmit) {
            this.currentReactionType = null;
            this.onReactionChange.emit(null);
          }
          resolve();
        }),
        finalize(() => {
          this.loadingCurrent = false;
          this.showReactions = false;
          this.onLoadingChange.emit(false);
        }),
      ).subscribe();
    });
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('focus', ['$event'])
  public onMouseEnter(event: Event) {
    this.showReactions = true;
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('blur', ['$event'])
  public onMouseLeave(event: Event) {
    this.showReactions = false;
  }

  ngOnDestroy(): void {
    this._requestSubscription?.unsubscribe();
  }
}
