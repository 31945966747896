import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'svc-post-translate',
  templateUrl: './svc-post-translate.component.html',
  styleUrls: ['./svc-post-translate.component.scss']
})
export class SvcPostTranslateComponent {
  @Input() text: string;
  @Input() loading: boolean;
  @Input() postId: string;
  @Output() translate = new EventEmitter();

  translateText(e) {
    e.stopPropagation();
    if (this.loading) return;
    this.translate.emit();
  }
}
