<ng-container *ngIf="control">
  <mat-slide-toggle
    color="primary"
    [formControl]="control"
    (change)="onChanged($event)"
    [id]="inputId"
  >
    <ng-container *ngTemplateOutlet="ngContent"></ng-container>
  </mat-slide-toggle>
</ng-container>
<ng-container *ngIf="!control">
  <mat-slide-toggle
    color="primary"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onChanged($event)"
    [id]="inputId"
  >
    <ng-container *ngTemplateOutlet="ngContent"></ng-container>
  </mat-slide-toggle>
</ng-container>
<ng-template #ngContent>
	<ng-content></ng-content>
</ng-template>