<div class="flex space-x-2 space-y-1">

  <!-- User avatar -->
  <div class="flex flex-col flex-shrink-0" #userAvatarContainer>
    <svc-user-avatar [info]="userAvatar"></svc-user-avatar>
  </div>

  <div class="flex flex-col space-y-1" [ngClass]="{ 'flex-auto': hasImageOrAudioAttachment }">
    <div class="relative flex flex-col rounded-b-2xl py-2 px-4 rounded-tr-2xl" [ngClass]="{'bg-primary-100': message.isOwnerComment, 'bg-gray-100': !message.isOwnerComment}">
      <div class="flex flex-row space-x-3 mb-2" [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }">
        <span class="text-sm font-bold" [style.word-break]="'break-all'">
          {{message.user.name}}
        </span>
        <span class="text-xs opacity-50" [ngClass]="{'text-on-primary-100': message.user, 'text-on-primary-50': !message.user}">
          {{message.createDate | timeAgo}}
        </span>
        <div *ngIf="svcReaction.currentReactionType" class="flex flex-auto justify-end pl-2">
          <img [src]="svcReaction.currentReactionType.staticImageUrl" class="w-4 h-4"/>
        </div>
      </div>

      <quill-editor *ngIf="message.commentText" class="text-md font-light"
        [modules]="{ toolbar: false, clipboard: { matchVisual: false } }"
        [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }"
        [(ngModel)]="message.commentText"
        [readOnly]="true"
      ></quill-editor>

      <div class="px-2 py-1 rounded-lg bg-white mt-1" *ngIf="message.translatedCommentText" [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }">
        <quill-editor class="block text-md font-light italic"
          [modules]="{ toolbar: false, clipboard: { matchVisual: false } }"
          [(ngModel)]="message.translatedCommentText"
          [readOnly]="true"
        ></quill-editor>
      </div>

      <div *ngIf="mediasCarousel.length > 0" class="pt-2" [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }">
        <svc-carousel
          [slides]="mediasCarousel"
        ></svc-carousel>
      </div>

      <div *ngIf="attachments.length > 0" [ngClass]="{ 'blur-sm': removingIsLoading || translatingIsLoading }">
        <div *ngFor="let attachment of attachments">
          <div *ngIf="attachment.type == AttachmentType.PDF" class="attachment-item text-default" (click)="openAttachment(attachment)">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:file-pdf"></mat-icon>
            </div>
            <div class="attachment-content">
              {{ attachment.name }}
            </div>
          </div>
          <div *ngIf="attachment.type == AttachmentType.COMPRESS" class="attachment-item text-default" (click)="openAttachment(attachment)">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:file-zipper"></mat-icon>
            </div>
            <div class="attachment-content">
              {{ attachment.name }}
            </div>
          </div>
          <div *ngIf="attachment.type == AttachmentType.EXCEL" class="attachment-item text-default" (click)="openAttachment(attachment)">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:file-excel"></mat-icon>
            </div>
            <div class="attachment-content">
              {{ attachment.name }}
            </div>
          </div>
          <div *ngIf="attachment.type == AttachmentType.POWER_POINT" class="attachment-item text-default" (click)="openAttachment(attachment)">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:file-powerpoint"></mat-icon>
            </div>
            <div class="attachment-content">
              {{ attachment.name }}
            </div>
          </div>
          <div *ngIf="attachment.type == AttachmentType.WORD" class="attachment-item text-default" (click)="openAttachment(attachment)">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="fontawesome_solid:file-lines"></mat-icon>
            </div>
            <div class="attachment-content">
              {{ attachment.name }}
            </div>
          </div>
          <div *ngIf="attachment.type == AttachmentType.AUDIO" class="attachment-item text-default">
            <div class="attachment-file">
              <mat-icon class="text-current icon-size-6" svgIcon="mat_solid:mic"></mat-icon>
            </div>
            <div class="attachment-content">
              <audio controls>
                <source [src]="attachment.assignUrl">
              </audio>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="removingIsLoading || translatingIsLoading" class="loading-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    </div>

    <!-- Message reactions -->
    <div class="flex flex-row divide-x text-sm text-gray-500 font-medium">
      <svc-total-reactions
        [ngClass]="{ 'hidden': svcTotalReactions?.total <= 0 && !svcTotalReactions?.loading }"
        class="flex pr-1"
        [registryUniqueId]="message.commentId"
        [reaction]="svcReaction"
        [applicationId]="'E07E98AE-9978-4C93-B787-1D961F7FE2E6'"
        [siteId]="siteId"
      ></svc-total-reactions>
      <svc-reaction class="flex px-1"
        #svcReaction
        [ngClass]="{ 'border-l-0': svcTotalReactions?.total <= 0 && !svcTotalReactions?.loading }"
        [registryUniqueId]="message.commentId"
        [disabled]="removingIsLoading || translatingIsLoading"
        [applicationId]="'E07E98AE-9978-4C93-B787-1D961F7FE2E6'"
        positionX="left"
        [dontEmitFirstTime]="true"
        [siteId]="siteId"
        (onReactionChange)="svcTotalReactions?.refresh()"
      >
        <a
          class="text-xs"
          [ngClass]="{'cursor-pointer': !svcReaction.loadingCurrent && !removingIsLoading && !translatingIsLoading, 'text-primary': svcReaction.currentReactionType != null, 'opacity-50': svcReaction.loadingCurrent }"
          (click)="!svcReaction.loadingCurrent && svcReaction.currentReactionType != null && svcReaction.removeCurrentReaction()"
        >
          {{ 'Reagir' | transloco }}
        </a>
      </svc-reaction>
      <a
        *ngIf="!isChild"
        class="px-1 text-xs"
        [ngClass]="{'cursor-pointer': !removingIsLoading && !translatingIsLoading}"
        (click)="answer({ force: true, focus: true })"
      >
        {{ 'Responder' | transloco }}
      </a>
      <a
        *ngIf="!message.translated"
        class="px-1 text-xs"
        [ngClass]="{'cursor-pointer': !removingIsLoading && !translatingIsLoading}"
        (click)="translate()"
      >
        {{ 'Traduzir' | transloco }}
      </a>
      <a
        *ngIf="message.isOwnerComment"
        class="px-1 text-xs"
        [ngClass]="{'cursor-pointer': !removingIsLoading && !translatingIsLoading}"
        (click)="exclude()"
      >
        {{ 'Excluir' | transloco }}
      </a>
    </div>
    <div *ngIf="!showChildrenMessages && message.totalAnswers > 0" class="flex text-gray-500 font-medium">
      <a class="text-xs" [ngClass]="{'cursor-pointer': !removingIsLoading && !translatingIsLoading}" (click)="showMessages()">
        {{message.totalAnswers}} {{ 'resposta(s)' | transloco }}
      </a>
    </div>
  </div>
</div>

<svc-comments-chat *ngIf="showChildrenMessages"
  [style.paddingLeft.px]="userAvatarContainer.clientWidth + 16"
  class="block mt-3"
  [referenceId]="message.commentId"
  [applicationId]="applicationId"
  [configTypeId]="configTypeId"
  [parentMessage]="message"
  [smallSize]="smallSize"
  [siteId]="siteId"
  [external]="external"
  (onCommentAdded)="onCommentAdded.emit($event)"
  (onCommentRemoved)="onCommentRemoved.emit($event)"
></svc-comments-chat>
