import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

import { SvcControl } from "../svc-control";
import { getDefaultSelectPeriodOptions } from './shared/utils/svc-select-period-dates';
import { SvcSelectPeriodOption } from './shared/interfaces/svc-select-period-option.interface';
import { SvcSelectPeriodDefaultIds } from './shared/enums/svc-select-period-default-ids.enum';
import { ISvcSelectOption } from '../svc-select/svc-select.component';
import { takeUntil, tap } from 'rxjs';

@Component({
  selector: 'svc-select-period',
  templateUrl: './svc-select-period.component.html',
  styleUrls: ['./svc-select-period.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SvcSelectPeriodComponent),
      multi: true
    }
  ]
})
export class SvcSelectPeriodComponent extends SvcControl implements OnInit, OnChanges, OnDestroy {

  @Input() label: string | { text: string, icon: string };
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() truncateTextValue: boolean = false;
  @Input() inputId: string;
  @Input() fallbackTextValue: string;
  @Input() set options(value: SvcSelectPeriodOption[]) {
    this._options = value;
    this.selectOptions = this._options.map(o => ({ value: o.id, text: o.text }));
  }

  @Input() showIcon: boolean = true;
  @Input('min') minDate?: Date;
  @Input('max') maxDate?: Date;
  @Input() format: string;

  @Output() ngModelChange = new EventEmitter<any>();

  private _options: SvcSelectPeriodOption[] = getDefaultSelectPeriodOptions(this._translocoService);
  protected selectOptions: ISvcSelectOption[] = this._options.map(o => ({ value: o.id, text: o.text }));
  public selectedId: string;
  public selected: SvcSelectPeriodOption;
  public controlRangeDates = new FormControl(null);
  public defaultActions = SvcSelectPeriodDefaultIds;

  private _isUpdatingControl = false;
  private _isUpdatingControlRangeDates = false;
  private _isInitialized = false;

  private get _isCustomize() {
    return this.selectedId === SvcSelectPeriodDefaultIds.Customize;
  }

  constructor(
    @Inject(Injector) injector: Injector,
    private _translocoService: TranslocoService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.control?.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((value: any) => {
        if (!this._isUpdatingControl && (this.selected?.id !== this.defaultActions.Customize || !this.control?.value)) {
          let selected: SvcSelectPeriodOption = null;
          if (value) {
            for (const option of this._options ?? []) {
              if (value?.id === option?.id) {
                selected = option;
                break;
              }
            }
          }
          this.selected = selected;
          this.selectedId = selected?.id;
          if (!selected?.id) {
            this._isUpdatingControlRangeDates = true
            this.controlRangeDates.setValue([]);
            setTimeout(() => this._isUpdatingControlRangeDates = false);
          }
        }
      }),
    ).subscribe();
    this._valueChangesDates();
    this._checkSelected();
    this._isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (this._isInitialized) {
      this._checkSelected();
    }
  }

  private _valueChangesDates(): void {
    this.controlRangeDates.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((dates: string[]) => {
        if (!this._isUpdatingControlRangeDates) {
          this.control.setValue({
            id: this.selected?.id,
            dates: dates?.length ? dates : []
          });
        }
      })
    ).subscribe();
  }

  private _checkSelected(): void {
    let selected: SvcSelectPeriodOption = null;
    for (const option of this._options ?? []) {
      if (this.control?.value?.id === option.id) {
        selected = option;
        break;
      }
    };
    this.selected = selected;
    this.selectedId = selected?.id;

    if (this._isCustomize) {
      this._isUpdatingControlRangeDates = true;
      if (this.control?.value?.dates) {
        this.controlRangeDates.setValue(this.control.value.dates);
      }
      else {
        this.controlRangeDates.setValue([]);
      }
      this._isUpdatingControlRangeDates = false;
    }
  }

  public onSelectedIdChanged() {
    this.selected = (this._options ?? []).find(option => option?.id === this.selectedId);
    if (this.selected?.id !== this.control?.value?.id) {
      try {
        this._isUpdatingControl = true;
        if (!this._isCustomize && this?.selected?.id)
          this.control?.setValue({
            id: this.selected?.id,
            dates: [this.selected?.startDate, this.selected?.endDate]
          });
        else if (!this._isCustomize)
          this.control?.setValue(null);
      }
      finally {
        this._isUpdatingControl = false;
      }
    }
  }

  protected onValueChanged(value): void {
    this.ngModelChange.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }
}

