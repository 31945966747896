import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { Observable, Subject, catchError, finalize, map, of, take, tap } from "rxjs";
import { ReactionType } from "./models/reaction-type.model";
import { Reacted } from "./models/reacted.model";

export interface ReactionParams {
	applicationId: string;
}
export interface IdReactionParams extends ReactionParams {
	siteId: number;
	reactionUniqueId: number;
	registryUniqueId: number;
}
export interface UidReactionParams extends ReactionParams {
	siteId: number;
	reactionUniqueId: number;
	registryUniqueUId: string;
}
export interface GetIdReactionParams extends ReactionParams {
	registryUniqueId: number;
  siteId: number;
}
export interface GetUidReactionParams extends ReactionParams {
	registryUniqueUId: string;
  siteId: number;
}

@Injectable()
export class SvcReactionService extends SvcHttpClient {

	private _reactionsType$: Subject<ReactionType[]>;

	private _countReactions: {
		[key: number | string]: {
			request$: Subject<Reacted[]>,
			loading: boolean,
		}
	} = {};

	constructor(
		appConfig: AppEnvironmentConfig,
		httpClient: HttpClient,
	) {
		super(appConfig.APIs.apiUrlReaction, httpClient);
	}

	getReactionsType(): Observable<ReactionType[]> {
		if (!this._reactionsType$) {
			this._reactionsType$ = new Subject<ReactionType[]>();
			this.get<ReactionType[]>('/Reaction?active=true').pipe(
				tap((response) => {
					this._reactionsType$.next(response);
					this._reactionsType$.complete();
				}),
				finalize(() => {
					this._reactionsType$ = null;
				})
			).subscribe();
		}
		return this._reactionsType$.pipe(take(1));
	}

	reactionId(data: IdReactionParams): Observable<number> {
		return this.post<{ registryReactionUniqueId: number }>('/RegistryId', data).pipe(
			map((response) => response.registryReactionUniqueId),
		);
	}

	reactionUid(data: UidReactionParams): Observable<number> {
		return this.post<{ registryReactionUniqueId: number }>('/RegistryUid', data).pipe(
			map((response) => response.registryReactionUniqueId),
		);
	}

	getIdTotalReaction(params: GetIdReactionParams): Observable<Reacted[]> {
		const id = params.registryUniqueId;
		if (!this._countReactions[id]) {
			this._countReactions[id] = {
				request$: new Subject<Reacted[]>(),
				loading: true,
			}
			this.get<Reacted[]>(`/CountersId`, { params: {
          applicationId: params.applicationId,
          registryUniqueId: id,
          siteId: params.siteId
        }}).pipe(
				tap((response) => {
					this._countReactions[id].request$.next(response);
					this._countReactions[id].request$.complete();
				}),
				catchError((err) => {
					this._countReactions[id].request$.next(null);
					this._countReactions[id].request$.complete();
					return err;
				}),
				finalize(() => {
					this._countReactions[id].loading = false;
					this._countReactions[id] = null;
				}),
			).subscribe();
		}
		return this._countReactions[id].request$.pipe(take(1));
	}

	getUidTotalReaction(params: GetUidReactionParams): Observable<Reacted[]> {
		const id = params.registryUniqueUId;
		if(!this._countReactions[id]) {
			this._countReactions[id] = {
				request$: new Subject<Reacted[]>(),
				loading: true,
			}
			this.get<Reacted[]>(`/CountersUid`, {params: {
          applicationId: params.applicationId,
          registryUniqueUId: id,
          siteId: params.siteId
        }}).pipe(
				tap((response) => {
					this._countReactions[id].request$.next(response);
					this._countReactions[id].request$.complete();
				}),
				catchError((err) => {
					this._countReactions[id].request$.next(null);
					this._countReactions[id].request$.complete();
					return err;
				}),
				finalize(() => {
					this._countReactions[id].loading = false;
					this._countReactions[id] = null;
				}),
			).subscribe();
		}
		return this._countReactions[id].request$.pipe(take(1));
	}

	removeIdReaction(data: IdReactionParams): Observable<void> {
		return this.request<void>('delete', '/RegistryId', {
			body: data
		});
	}

	removeUidReaction(data: UidReactionParams): Observable<void> {
		return this.request<void>('delete', '/RegistryUid', {
			body: data
		});
	}
}
