import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../functions/helper-functions';

@Pipe({
	name: 'padRight',
})
export class PadRightPipe implements PipeTransform {
	transform(value: any, length: number = 2, char: string = '0'): string {
		if (!isNullOrUndefined(value)) {
			let _value = value.toString() as string;
			let diffSize = length - _value.length;

			if (diffSize > 0) return _value + Array.from({ length: diffSize }).fill(char).join('');
			else return _value;
		}

		return value;
	}
}
