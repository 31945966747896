import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SvcFilterEvent} from "../../classes/svc-filter-event";
import {SvcFilterField} from "../../classes/svc-filter-field";
import {SvcFiltersComponent} from "../../svc-filters.component";
import {SvcDrawerComponent} from "../../../svc-drawer/svc-drawer.component";


@Component({
  selector: 'svc-filter-sidebar',
  templateUrl: './svc-filter-sidebar.component.html',
  styleUrls: ['./svc-filter-sidebar.component.scss']
})
export class SvcFilterSidebarComponent {

  @ViewChild('drawer') public svcDrawer: SvcDrawerComponent;

  @ViewChild(SvcFiltersComponent)
  public svcFilters: SvcFiltersComponent;

  @Input() public filters: SvcFilterField[] = [];
  @Output() public onFilterChanged = new EventEmitter<SvcFilterEvent>();

  isFiltersLoading: boolean;

  public get fields() {
    return this.svcFilters.fields;
  }

  public get isOpened() {
    return this.svcDrawer.isOpened;
  }

  public clearFiltersField(options?: { clearDisabled: boolean, preventEmit: boolean }) {
    return this.svcFilters.clearFiltersField(options);
  }

  public toggle() {
    this.svcDrawer.toggle();
  }
}
