import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {changeSiteCallback, updateLastSiteIdCookie} from 'projects/lib-shared-common/src/public-api';
import { Subject, debounceTime, takeUntil, timeout } from 'rxjs';
import { SitesService } from './services/sites.service';
import { Site } from './services/sites.types';
import { HttpClient} from "@angular/common/http";

@Component({
  selector: 'sites',
  templateUrl: './sites.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'sites',
})
export class SitesComponent implements OnInit, OnDestroy {
  @Input() renderAsMenuItemContent: boolean = false;
  @Input() renderAsSubMenuItemContent: boolean = false;

  sites: Site[];
  active: Site;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  siteList: Site[];

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _sitesService: SitesService,
    private _httpClient: HttpClient
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to sites changes
    this.getSiteList();
  }

  private getSiteList() {
    this._sitesService.sites$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sites: Site[]) => {
        this.siteList = sites;
        this.sites = sites.filter((x) => !x.isSiteCurrent);
        this.active = sites.find((x) => x.isSiteCurrent);
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the active site
   *
   * @param id
   */
  async changeActiveSite(id: number) {
    this._sitesService.update(id).subscribe(async (done) => {
      if (done.isSucess) {
        this.sites = this.siteList.filter((x) => x.siteId != id);
        this.active = this.siteList.find((x) => x.siteId == id);
        this._changeDetectorRef.markForCheck();
      }

      //legacy (c#/asp) session reload cookies
      changeSiteCallback(this._httpClient, id).subscribe({next:() => {
        updateLastSiteIdCookie(id);
        window.location.reload();
      },error: () => window.location.reload()});

      //when not legacy support required remove above and uncomment below
      //window.location.reload();
    });
  }
}
