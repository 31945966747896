import { NgModule } from '@angular/core';
import { SvcCommonLoaderComponent } from './svc-common-loader/svc-common-loader.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';

@NgModule({
  declarations: [
    SvcCommonLoaderComponent
  ],
  imports: [
    LibSharedCommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    SvcCommonLoaderComponent
  ]
})
export class SvcLoadersModule { }
