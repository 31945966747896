import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartupConfigService } from 'projects/config/startup-config.service';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { DatePipe } from '@angular/common';
import { SVC_APP_SETTINGS, StaticApplicationId, SvcAppSettingsValue, SvcModule } from 'projects/lib-shared-core/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { NgxPermissionsModule } from "ngx-permissions";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APPNAME } from "../../../lib-shared-core/src/lib/Constants/app-injection-token";
import { deleteCookie, getCookie } from 'projects/lib-shared-common/src/public-api';
import { LibSharedFeatureModule } from 'projects/lib-shared-feature/src/public-api';

export function StartupConfigFactory$(
  StartupConfigService: StartupConfigService
) {
  return () => {
    if (getCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C'))
      deleteCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C');

    return StartupConfigService.load$();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    NgxPermissionsModule.forRoot(),

    // Core module of your application
    LibSharedFeatureModule,
    LibSharedIconsModule,
  ],
  providers: [
    DatePipe,
    {
      provide: APPNAME,
      useValue: 'authentication'
    },
    {
      provide: AppEnvironmentConfig,
      deps: [DatePipe],
      useExisting: StartupConfigService,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [StartupConfigService],
      useFactory: StartupConfigFactory$,
    },
    {
      provide: SVC_APP_SETTINGS,
      useValue: new SvcAppSettingsValue({
        applicationId: StaticApplicationId.authentication,
        applicationName: 'Authentication',
        module: SvcModule.Communication,
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
