<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
  <ng-container *ngIf="notificationUnreadCount > 0">
    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 text-white text-xs font-medium">
        {{ notificationUnreadCount }}
      </span>
    </span>
  </ng-container>
  <mat-icon class="text-current" [svgIcon]="'heroicons_solid:bell'"></mat-icon>
</button>

<ng-template #notificationsPanel *screenSizes="let size">
  <div class="fixed inset-0 md:relative flex flex-col md:min-w-90 md:w-90 md:rounded-lg overflow-hidden mat-elevation-z8 bg-white">
    <div *ngIf="size.isBelowMD" class="flex items-center border-b px-4 py-2.5">
      <div class="flex flex-auto items-center gap-2 text-default">
        <mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:bell"></mat-icon>
        <p class="text-2xl font-bold">{{ 'Notificações' | transloco }}</p>
      </div>
      <button mat-icon-button (click)="closePanel()" class="w-8 h-8 min-h-8 text-default">
        <mat-icon class="icon-size-5 text-current" svgIcon="heroicons_solid:x-mark"></mat-icon>
      </button>
    </div>

    <div *ngIf="(notifications?.length ?? 0) > 0" class="relative flex-auto overflow-y-auto" [style.height]="size.isBelowMD ? 'auto' : '400px'">
      <ag-virtual-scroll #vs [items]="notifications" [height]="size.isBelowMD ? '100%' : '400px'" min-row-height="90" infiniteScroll (scrollEnd)="nextPage()">
        <div *ngFor="let notification of vs.items;" class="border-b border-solid border-slate-200">
          <div class="flex group hover:bg-gray-50" [ngClass]="{ unread: notification.unread }">
            <div (click)="executeJsonCallback(notification.jsonCallback)" class="flex flex-col flex-auto py-3 pl-4" [ngClass]="{ 'cursor-pointer': notification.jsonCallback }">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>

            <div class="flex flex-col py-3 pr-4 pl-2 space-y-2 items-center min-w-20 justify-center">

              <div class="flex text-xs leading-none text-primary font-bold">
                <span *ngIf="notification.diffDateFromCreationDuration > 0">
                  {{ notification.diffDateFromCreationDuration }} {{ notification.diffDateFromCreationLabelTranslated }}
                </span>
                <span *ngIf="notification.diffDateFromCreationDuration <= 0">
                  {{ notification.diffDateFromCreationLabelTranslated }}
                </span>
              </div>

              <button *ngIf="notification.unread" class="w-6 h-6 min-h-6" mat-icon-button>
                <span class="w-2 h-2 rounded-full bg-primary"></span>
              </button>
            </div>
          </div>

          <ng-template #notificationContent>

            <div class="flex flex-wrap">
              <div *ngIf="notification.applicationName" class="line-clamp-1 mr-2 mb-2">
                <span class="text-center rounded p-1 text-sm font-medium bg-gray-200 text-default">
                  {{ notification.applicationName | uppercase }}
                </span>
              </div>
              <div *ngIf="notification.badges" class="line-clamp-1">
                <span class="text-center rounded p-1 text-sm bg-primary-200 text-primary">
                  {{ notification.badges }}
                </span>
              </div>
            </div>

            <div class="flex flex-auto items-center mt-2">
              <ng-container *ngIf="notification.icon && !notification.senderPicture">
                <div class="shrink-0 w-8 h-8 mr-3 rounded-full items-center justify-center bg-gray-100">
                  <mat-icon class="icon-size-5" [svgIcon]="notification.icon">
                  </mat-icon>
                </div>
              </ng-container>

              <svc-user-avatar [info]="{name: notification.senderName, picture: notification.senderPicture, initialsColor: notification.senderInitialsColor, size: avatarSize}"></svc-user-avatar>

              <div
                class="line-clamp-3 text-sm leading-tight ml-2"
                [innerHTML]="(notification.title ?? '') + ' ' + (notification.message ?? '')"
                [title]="(notification.title ?? '') + ' ' + (notification.message ?? '') | removeHtml"
              ></div>
            </div>
          </ng-template>
        </div>
      </ag-virtual-scroll>
      <div *ngIf="isLoading" class="absolute w-full bottom-0 flex justify-center items-center pb-5">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>

    <ng-container *ngIf="!isLoading && ((notifications?.length ?? 0) <= 0)">
      <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
          <mat-icon class="text-primary-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
        </div>
        <div class="mt-5 text-2xl font-semibold tracking-tight">
          {{ 'Sem notificações' | transloco }}
        </div>
        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
          {{ 'Quando você tiver notificações, elas aparecerão aqui.' | transloco }}
        </div>
      </div>
    </ng-container>
    <div *ngIf="isLoading && (notifications.length ?? 0) === 0" class="flex flex-auto justify-center p-10">
      <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div *ngIf="size.isBelowMD" class="flex justify-end p-4 border-t">
      <svc-button size="sm" mode="flat" color="primary">
        {{ 'Fechar' | transloco }}
      </svc-button>
    </div>
  </div>
</ng-template>
