export enum CommunicationStatus {
  Elaboration = 1,
  InProcess = 2,
  Sent = 3,
}

export enum CommunicationStatusTyped {
  elaboration = 1,
  inProcess = 2,
  sent = 3,
}

export enum CommunicationStatusDescription {
  Elaboration = 'Em elaboração',
  InProcess = 'Em Processo',
  Sent = 'Enviado',
}
