import { DatePipe } from '@angular/common';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { TemplatePortal } from '@angular/cdk/portal';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { dateStringToDate } from 'projects/lib-shared-common/src/public-api';
import { SvcControl } from '../svc-control';
import { DateValidators } from '../svc-date-picker/date-picker-validators';
import { SvcDatePickerCalendarComponent } from './svc-date-picker-calendar/svc-date-picker-calendar.component';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';

@Component({
  selector: 'svc-date-picker-range',
  templateUrl: './svc-date-picker-range.component.html',
  styleUrls: ['./svc-date-picker-range.component.scss'],
  providers: [
    DatePipe,
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SvcDatePickerRangeComponent),
      multi: true
    },
    MatCalendar
  ],
})
export class SvcDatePickerRangeComponent extends SvcControl implements OnInit, OnChanges, OnDestroy {

  @Output() public dateRangeChange = new EventEmitter<string[]>();

  @Input() showIcon: boolean = true;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input('min') minDate?: Date;
  @Input('max') maxDate?: Date;
  @Input() format: string
  @Input() inputId: string;
  @Input() fallbackTextValue: string;

  @ViewChild('matCalendarTemplate') matCalendarTemplate: TemplateRef<any>;
  @ViewChild(MatFormField) matCalendarOrigin: MatFormField;
  @ViewChild(SvcDatePickerCalendarComponent) set calendar(svcCalendar: SvcDatePickerCalendarComponent) {
    if (svcCalendar) {
      this.svcCalendar = svcCalendar;
      const _date: Date = (this.currentDate as DateRange<Date>)?.start || this.currentDate as Date;

      if (_date?.getFullYear())
        this.svcCalendar?.calendar?._goToDateInView(_date, 'month');
    }
  };

  public internalControl = new FormControl<string>(null);
  public currentDate: DateRange<Date> | Date;
  public startDate: Date;
  public svcCalendar: SvcDatePickerCalendarComponent;
  public textToShow: string;
  public calendarIsOpened = false;

  private _overlayRef: OverlayRef;
  private internalIsSettingValue = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _initialCurrentDate: DateRange<Date> | Date;
  private _selectedDates: string[] = [];
  private _settingCurrentDate = false;

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _datePipe: DatePipe,
    private _appSettings: SvcAppSettings,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    @Inject(Injector) injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.format = this.format ?? this._appSettings.dateFormat;

    this.control?.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((dates: string[]) => {
        if (!this.internalIsSettingValue) {
          this._setCurrentDate(dates);
        }

        this._setTextToShow();
      }),
    ).subscribe();

    this._prepareControl();
    this._setCurrentDate(this.control?.value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.readonly?.previousValue !== changes?.readonly?.currentValue)
      this._setTextToShow();
  }

  private _getDateString(date: string): string {
    if (!date) return '';

    const _date: Date = dateStringToDate(date, this.format);
    if (!_date) return '';

    date = this._getDateFormated(_date, { shortenedYear: true });
    if (!date) return '';
    
    return date;
  }

  private _setTextToShow(): void {
    const date: string[] = this.internalControl?.value?.split(' - ');
    if (date?.length === 2) {
      if (this._getDateString(date?.[0]) !== this._getDateString(date?.[1]))
        this.textToShow = `${
          this._getDateString(date?.[0])
          } - ${
            this._getDateString(date?.[1])
          }`;
      else
        this.textToShow = this._getDateString(date?.[0]);
    }
    else if (date?.length === 1)
      this.textToShow = this._getDateString(date?.[0]);
    else
      this.textToShow = '';
  }

  private _prepareControl() {
    if (this.control) {
      const currentValidators = this.control['_rawValidators'] ?? [];
      let validators = [
        ...(Array.isArray(currentValidators) ? currentValidators : [currentValidators]),
      ];
      if (this.minDate) {
        validators.push(DateValidators.min(this.minDate, this.format));
      }
      if (this.maxDate) {
        validators.push(DateValidators.max(this.maxDate, this.format));
      }
      this.internalControl.addValidators(validators);
    }
  }

  public showMatCalendar(): void {
    if (this.control?.disabled) return;

    if (!this._overlayRef) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'svc-date-picker-range-backdrop',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(
            this.matCalendarOrigin._elementRef.nativeElement,
          )
          .withLockedPosition(true)
          .withPush(true)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ]),
      });

      this._overlayRef.backdropClick().subscribe(() => this.closeCalendar());
      this._overlayRef.detachments().subscribe(() => this.calendarIsOpened = false);
    }

    try {
      this._initialCurrentDate = this.currentDate;

      if (!this.calendarIsOpened) {
        this._overlayRef.attach(
          new TemplatePortal(this.matCalendarTemplate, this._viewContainerRef)
        );
        this.calendarIsOpened = true;
      }
    }
    catch { }
  }

  public removeDate(clickEvent: MouseEvent): void {
    clickEvent.stopPropagation();

    const _dates: Date[] = [
      null,
      null
    ];

    const newSection = new DateRange<Date>(
      _dates?.[0],
      _dates?.[1]
    );

    this.selectionModel.updateSelection(newSection, this);
    this._setCurrentDate(_dates);
    this._setTextToShow();
    this.control.setValue([]);
    this.dateRangeChange.emit([]);
  }

  public closeCalendar(resetValues = true): void {
    if (resetValues) {
      const _dates: Date[] = [
        (this._initialCurrentDate as DateRange<Date>)?.start ?? this._initialCurrentDate as Date,
        (this._initialCurrentDate as DateRange<Date>)?.end ?? null
      ];

      const newSection = new DateRange<Date>(
        _dates?.[0],
        _dates?.[1]
      );

      this.selectionModel.updateSelection(newSection, this);
      this._setCurrentDate(_dates);
      this._setTextToShow();
    }
    else {
      const _dates: Date[] = this._selectedDates?.map(date => this._getDateUpdate(date));
      this._setCurrentDate(_dates);
      if (this.control)
        this.control.setValue(this._selectedDates);
      this.dateRangeChange.emit(this._selectedDates);
    }
    this._overlayRef.detach();
  }

  public onSelect(event: Date): void {
    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(
      event,
      selection
    );

    this.selectionModel.updateSelection(newSelection, this);

    this._setCurrentDate([
      newSelection.start,
      newSelection.end
    ]);
  }

  private _getDateUpdate(date: string | Date): Date {
    if (typeof date === 'string') {
      const [year, month, day] = date.split('-').map(Number);
      return new Date(year, month - 1, day);
    }

    return date as Date;
  }

  private _setDateString(dates: (Date | string)[]): void {
    if (Array.isArray(dates) && dates.length >= 1) {
      if (typeof dates[0] === 'string' && typeof dates[1] === 'string')
        this.currentDate = new DateRange(
          this._getDateUpdate(dates[0]),
          this._getDateUpdate(dates[1])
        );
      else if (!dates?.[1]) {
        if (typeof dates[0] === 'string')
          this.currentDate = this._getDateUpdate(dates[0]);
        else
          this.currentDate = this._getDateUpdate(dates[0]);

        this.svcCalendar?.calendar?._goToDateInView(this.currentDate, 'month');
      }
      else
        this.currentDate = new DateRange<Date>(
          this._getDateUpdate(dates[0]),
          this._getDateUpdate(dates[1])
        );
      if (dates[1])
        this.svcCalendar?.calendar?._goToDateInView((this.currentDate as DateRange<Date>).start, 'month');
    }
    else {
      this.currentDate = null;
      this.startDate = null;
    }
  }

  private _getDateFormated(date: string | Date, options?: { shortenedYear?: boolean }): string {
    const format = options?.shortenedYear ? this.format.replace('yyyy', 'yy') : this.format;
    return this._datePipe.transform(date, format);
  }

  private _setControlValue(): void {
    this.startDate = (this.currentDate as DateRange<Date>)?.start || this.currentDate as Date;
    const strStart = ((this.currentDate as DateRange<Date>)?.start || this.currentDate as Date)?.toISOString().substring(0, 10);
    const strEnd = (this.currentDate as DateRange<Date>)?.end?.toISOString().substring(0, 10);

    this.internalIsSettingValue = true;
    if (strStart && strEnd) {
      const strStartFormatted = this._getDateFormated(strStart);
      const strEndFormatted = this._getDateFormated(strEnd);
      this.internalControl.setValue(`${strStartFormatted} - ${strEndFormatted}`);
      this._setTextToShow();
      this._selectedDates = [strStart, strEnd];
    }
    else if (strStart) {
      const strStartFormatted = this._getDateFormated(strStart);
      this.internalControl.setValue(strStartFormatted);
      this._setTextToShow();
      this._selectedDates = [strStart, strStart];
    }
    else {
      this.internalControl.setValue('');
      this.startDate = null;
      this._setTextToShow();
      this._selectedDates = [];
    }
    this.internalIsSettingValue = false;
  }

  private _setCurrentDate(dates: any): void {
    if (this._settingCurrentDate) return;

    dates = Array.isArray(dates) ? dates : dates?.dates;

    if (Array.isArray(dates) && dates.length >= 2 && !dates[0]) {
      this.internalControl.setValue('');
      this.startDate = null;
      this.currentDate = null;
      this._selectedDates = [];
      return;
    }

    this._settingCurrentDate = true;
    this._setDateString(dates);
    this._setControlValue();
    this._settingCurrentDate = false;
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
