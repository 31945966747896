<section [style.height]="parent?.items.length ? '100%' : 'auto'">
	<div class="total-padding" [style.height.px]="virtualScroll?.totalHeightContent"></div>
	<div
		#itemsContainer
		class="scrollable-content"
		[ngClass]="{ 'relative': !parent?.useParentScroll && parent?.height === 'auto' }"
		[style.transform]="'translateY(' + virtualScroll?.paddingScroll + 'px)'"
		[style.top.px]="parent?.infinite && parent?.loading && parent?.currentPage > 1 ? -80 : 0"
	>
		<ng-content></ng-content>
	</div>
</section>
<div class="svc-data-table-empty animated fadeIn" *ngIf="parent && !parent.loading && parent?.filteredItems.length <= 0">
	<span [innerHTML]="'Não há resultados' | transloco"></span>
</div>
<div *ngIf="parent?.infinite" class="svc-data-infinite" [ngClass]="{ 'svc-data-infinite-show': parent?.loading && parent?.currentPage > 1 }" [style.width.px]="itemsContainer.clientWidth">
	<div>
		<mat-spinner diameter="30"></mat-spinner>
	</div>
</div>
