import { Component } from '@angular/core';

@Component({
  selector: 'svc-common-loader',
  templateUrl: './svc-common-loader.component.html',
  styleUrls: ['./svc-common-loader.component.scss']
})
export class SvcCommonLoaderComponent {

}
