import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvcViewsService {
  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _httpClient: HttpClient
  ) { }

  public getViews(params: { registryId: string, applicationId: string, featureName: string }): Observable<void> {
    return this._httpClient.get<void>(`${this._appConfig.APIs.apiUrlViews}/View`, { params });
  }

  public setViews(params: { registryId: string, applicationId: string, featureName: string }): Observable<void> {
    return this._httpClient.post<void>(`${this._appConfig.APIs.apiUrlViews}/View`, {
      ...params,
    });
  }

  public getViewsCount(params: { registryId: string, applicationId: string, featureName: string }): Observable<void> {
    return this._httpClient.get<void>(`${this._appConfig.APIs.apiUrlViews}/View/count`, { params });
  }
}

