<div class="flex flex-col gap-4 drilldown">
  <div class="drilldown-header px-4 pt-4 pb-2 flex justify-between items-center gap-2">
    <p class="text-default text-xl font-bold">{{ config?.title | transloco }}</p>

    <button class="close-button" mat-icon-button (click)="closeDrilldown()">
			<mat-icon class="icon-size-6 text-default" svgIcon="heroicons_mini:x-mark"></mat-icon>
		</button>
  </div>

  <div class="px-4 overflow-y-auto h-full">
    <svc-data-table
      #dataTable
      [server-side]="config?.serverSide"
      [hoverable]="config?.hoverable"
      [items]="items$ | async"
      [dataLength]="dataLength$ | async"
      [loading]="isLoading$ | async"
      [height]="config?.height ?? 'auto'"
      [clickable]="config?.clickable"
      [infinite]="config?.infinite ?? 0"
      [get-data-init]="config?.getDataInit ?? true"
      [currentPage]="config?.currentPage ?? 1"
      [canChangePageSize]="config?.canChangePageSize ?? true"
      [dataAreOver]="config?.dataAreOver"
      [minWidth]="config?.minWidth"
      [minHeight]="config?.minHeight"
      [useParentScroll]="config?.useParentScroll"
      (onGetData)="onGetData($event)"
    >
      <svc-data-header class="h-9">
        <svc-data-col
          *ngFor="let column of config?.columns"
          [width]="column?.width"
          [custom-filter]="column?.customFilter"
          [filter]="column?.filter ?? svcFilterTypes.NONE"
          [field]="column?.field"
          [mode]="column?.mode"
          [placeholder]="column?.placeholder"
          [readOnly]="column?.readOnly"
          [minDate]="column?.minDate"
          [maxDate]="column?.maxDate"
          [dateFormat]="column?.dateFormat ?? 'dd/MM/yyyy'"
          [dateSeparator]="column?.dateSeparator ?? '/'"
          [optionAllLabel]="column?.optionAllLabel ?? 'Todos'"
          [options]="column?.options ?? []"
          [title]="column?.title"
          [align]="column?.align ?? 'left'"
          [no-sort]="column?.noSort"
          [no-truncate]="column?.noTruncate"
          [sort]="column?.sort"
          [disable-filter]="column?.disableFilter"
          [filter-value]="column?.filterValue"
        >
          <p class="text-xs text-default font-semibold uppercase">
            {{ column?.label | transloco }}
          </p>
        </svc-data-col>
      </svc-data-header>

      <svc-data-body>
        <svc-data-row *ngFor="let item of dataTable.items; index as i">
          <svc-data-cell *ngFor="let column of config?.columns">

            <ng-container [ngSwitch]="column?.type">
              <p *ngSwitchCase="'string'" class="text-default text-sm">
                {{ item?.[column?.field] }}
              </p>

              <svc-button
                *ngSwitchCase="'id'"
                mode="simple"
                color="primary"
                size="sm"
                class="font-medium text-base text-primary cursor-pointer button-id"
                [buttonId]="(column?.textBeforeValue | transloco) + '-' + item?.[column?.field] + i"
                (click)="clickItemTypeId(item)"
              >
                {{ column?.textBeforeValue | transloco }}-{{ item?.[column?.field] }}
              </svc-button>

              <svc-status-badge
                *ngSwitchCase="'status'"
                [badgeData]="item?.[column?.field]">
              </svc-status-badge>

            </ng-container>
          </svc-data-cell>

        </svc-data-row>
      </svc-data-body>

    </svc-data-table>
  </div>
</div>

