import { NgModule } from '@angular/core';
import { SvcLockButtonComponent } from './svc-lock-button.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';

@NgModule({
  imports: [
    LibSharedCommonModule, 
    LibSharedComponentModule,
    LibSharedIconsModule
  ],
  declarations: [SvcLockButtonComponent],
  exports: [SvcLockButtonComponent],
})
export class SvcLockButtonModule {}
