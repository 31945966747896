<svc-chip *ngIf="!isGrouped"
  class="w-full"
  [formControl]="formControl"
  [options]="chipOptions"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [readonly]="readonly"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [fallbackTextValue]="fallbackTextValue"
  readonlyStyle="transparent-chip"
>
  <ng-template let-option="option">
    <svc-user-picker-option [option]="option"></svc-user-picker-option>
  </ng-template>
</svc-chip>

<svc-chip-grouped *ngIf="isGrouped"
  class="w-full"
  [formControl]="formControl"
  [options]="chipGroupedOptions"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [readonly]="readonly"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [fallbackTextValue]="fallbackTextValue"
  readonlyStyle="transparent-chip"
>
  <ng-template let-option="option">
    <svc-user-picker-option [option]="option"></svc-user-picker-option>
  </ng-template>
</svc-chip-grouped>

<div *ngIf="isLoading" class="flex items-center absolute left-0 bottom-0 h-10 w-full px-2">
  <div class="absolute inset-0 bg-white opacity-50"></div>
  <mat-spinner diameter="20"></mat-spinner>
</div>
