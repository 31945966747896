<quill-editor
  class="content-editor"
  theme="snow"
  [placeholder]="placeholder"
  [modules]="modules"
  [format]="format"
  [styles]="styles"
  [formControl]="control"
  [required]="true"
  [id]="inputId"
>
</quill-editor>
<div *ngIf="control.invalid && control.touched" class="text-xs text-red-500">
  <span>{{ 'Campo obrigatório' | transloco }}</span>
</div>
