import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { Site } from './sites.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  private _sites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>([]);
  public onSiteChange: EventEmitter<void> = new EventEmitter<void>();
  public currentSite: Site;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for sites
   */
  get sites$(): Observable<Site[]> {
    return this._sites.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all sites
   */
  getAll(): Observable<Site[]> {
    return this._httpClient.get<Site[]>(`${this._appConfig.APIs.apiUrlAuth}/site`).pipe(
      tap((sites) => {
        this.currentSite = sites.find((x) => x.isSiteCurrent);
        this._sites.next(sites);
      })
    );
  }

  /**
   * Update the active site
   *
   * @param id
   */
  update(id: number): Observable<{ isSucess: boolean }> {
    return this._httpClient
      .put<{ isSucess: boolean }>(
        `${this._appConfig.APIs.apiUrlAuth}/UserPreference/change-site`,
        {
          siteId: id,
        }
      )
      .pipe(
        tap(() => {
          this.onSiteChange.emit();
        })
      );
  }
}
