import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvcAudioToTextButtonService } from './svc-audio-to-text-button.service';
import { finalize, tap } from 'rxjs/operators';

enum AudioRecordingStep {
  TO_RECORD = 'TO_RECORD',
  RECORDING = 'RECORDING'
}
enum ActionType {
  RECORD_AUDIO,
  AUDIO_TO_TEXT,
}

export interface SvcAudioToTextEvent {
  file: Blob,
}

@Component({
  selector: 'svc-audio-to-text-button',
  templateUrl: './svc-audio-to-text-button.component.html',
  styleUrls: ['./svc-audio-to-text-button.component.scss'],
})
export class SvcAudioToTextButtonComponent implements OnInit {

  @Input()
  public small: boolean = true;
  @Input()
  public disabled: boolean = false; 
  @Input()
  public canChooseRecording: boolean = false;
  @Input()
  public shadow: boolean = false;

  public ActionType = ActionType;
  public loading: boolean = false;
  public chosenAction: ActionType;
  public step: AudioRecordingStep | string = AudioRecordingStep.TO_RECORD;
  public audioBlobs: Blob[] = []
  public audioEnabled: boolean = false;
  public mediaRecorder: MediaRecorder;
  public mediaStream: MediaStream;

  public get isRecording() { return this.step === AudioRecordingStep.RECORDING; }

  @Output()
  public onText = new EventEmitter<String>();
  @Output()
  public onAudioRecorded = new EventEmitter<SvcAudioToTextEvent>();

  constructor(
    private service: SvcAudioToTextButtonService,
  ) { }

  ngOnInit(): void {
  }

  public recordAudio(type?: ActionType): void {
    this.chosenAction = type ?? this.chosenAction;
    switch (this.step) {
      case AudioRecordingStep.TO_RECORD:
        this._startRecording();
        break;
      case AudioRecordingStep.RECORDING:
        this._stopRecording();
        break;
    }
  }

  private async _startRecording() {
    try {
      await this._enableAudioUserMedia();
      this.step = AudioRecordingStep.RECORDING;
      this.audioBlobs = [];
      this.mediaRecorder.addEventListener('dataavailable', event => {
        this.audioBlobs.push(event.data);
      });
      this.mediaRecorder.start();
    }
    catch (error) {
      console.log(error);
    }
  }

  private _stopRecording() {
    this.loading = true
    this.mediaRecorder.addEventListener('stop', async () => {
      this.step = AudioRecordingStep.TO_RECORD;
      if (this.chosenAction === ActionType.RECORD_AUDIO) {
        this.loading = false;
        const blob = new Blob(this.audioBlobs, { type: 'audio/mp3' });
        this.onAudioRecorded.emit({ file: blob });
      }
      else {
        const blob = new Blob(this.audioBlobs, { type: 'audio/linear16' });
        this.service.transcript(blob).pipe(
          tap((text: string) => {
            this.onText.emit(text);
          }),
          finalize(() => this.loading = false),
        ).subscribe();
      }
    });

    this.mediaRecorder.stop();
    this.mediaStream.getTracks().forEach(track => track.stop());
  }

  private _enableAudioUserMedia() {
    return new Promise<void>((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaStream = stream;
          this.mediaRecorder = new MediaRecorder(stream);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }
}
