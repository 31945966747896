<form [formGroup]="form" *screenSizes="let size" [ngClass]="{ 'pointer-events-none': readonly }">
  <div class="flex flex-col">
    <div
      class="flex items-start gap-2"
      *ngFor="let option of form?.controls?.options?.controls ?? []"
    >
      <!-- Editable description input -->
      <div class="flex flex-col gap-1 justify-start w-full">
        <div class="input-label-size input-label-weight text-default">
          {{ option.controls.title?.value }}
        </div>

        <div>
          <svc-text-area
            #text
            class="w-full"
            [formControl]="option.controls.text"
            [label]="''"
            [icon]="''"
            [minRows]="2"
            [maxRows]="3"
            [autosize]="true"
            charCount
            [maxLength]="1000"
            [placeholder]="'Digite aqui' | transloco"
          >
          </svc-text-area>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
