import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { Observable, ReplaySubject, map, switchMap, tap } from 'rxjs';
import { AvailableLanguages } from '../model/available-languages.model';
import { AuthService } from '../../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private _languagesMapping: { [key: string]: number } = {};
  private _languages: ReplaySubject<AvailableLanguages[]> = new ReplaySubject<AvailableLanguages[]>(1);

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
    private _httpClient: HttpClient
  ) { }

  get languages$(): Observable<AvailableLanguages[]> {
    return this._languages.asObservable();
  }

  getAll(): Observable<AvailableLanguages[]> {
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          // Se autenticado, faz a chamada para o endpoint de usuários autenticados
          return this._httpClient.get<AvailableLanguages[]>(`${this._appConfig.APIs.apiUrlAuth}/Languages/authenticated`);
        } else {
          // Se não autenticado, faz a chamada para o endpoint de usuários não autenticados
          return this._httpClient.get<AvailableLanguages[]>(`${this._appConfig.APIs.apiUrlAuth}/Languages`);
        }
      }),
      tap((languages) => {
        // Mapeia os idiomas para o formato desejado e notifica os observadores
        languages.forEach(language => {
          this._languagesMapping[language.languageTag] = language.languageId;
        });
        this._languages.next(languages);
      })
    );
  }

  getTerms(lang: string): Observable<any> {
    //if user is already logged in then only call the authenticated endpoint which brings the default terms from current site
    if (this._authService.accessToken)
      return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlAuth}/Languages/terms/authenticated`);
    else
      return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlAuth}/Languages/terms/${this._languagesMapping[lang] ?? 2}`);
  }

  updateUserLanguage(lang: string): Observable<boolean> {
    return this._httpClient.put<boolean>(`${this._appConfig.APIs.apiUrlAuth}/UserPreference/change-language`, { languageId: this._languagesMapping[lang] }).pipe(
      map((response: any) => response.isSucess)
    );
  }
}
