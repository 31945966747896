import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';

import { SvcDatePickerCalendarHeaderComponent } from '../svc-date-picker-calendar-header/svc-date-picker-calendar-header.component';

@Component({
  selector: 'svc-date-picker-calendar',
  templateUrl: './svc-date-picker-calendar.component.html',
  styleUrls: ['./svc-date-picker-calendar.component.scss']
})
export class SvcDatePickerCalendarComponent {

  @ViewChild('matCalendar') public calendar: MatCalendar<Date> | undefined;

  @Input('min') public minDate?: Date;
  @Input('max') public maxDate?: Date;
  @Input() public startDate: Date;
  @Input() public currentDate: DateRange<Date> | Date;

  @Output() public onSelect = new EventEmitter<Date>();
  @Output() public closeCalendar = new EventEmitter<boolean>();

  public customCalendarHeader = SvcDatePickerCalendarHeaderComponent;
}
