import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcStatusBadgeComponent } from './svc-status-badge.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    SvcStatusBadgeComponent
  ],
  exports: [
    SvcStatusBadgeComponent
  ],
  imports: [
    LibSharedCommonModule,
    TranslocoModule
  ],
})
export class SvcStatusBadgeModule {}
