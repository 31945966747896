import { NgModule } from '@angular/core';

import { NgxEchartsModule } from 'ngx-echarts';

import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcChartStackedComponent } from './svc-chart-stacked.component';

@NgModule({
  declarations: [
    SvcChartStackedComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    SvcChartStackedComponent
  ]
})
export class SvcChartStackedModule { }
