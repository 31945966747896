export interface Navigation {
  default: SvcDefaultNavigationItem[];
  workspace: SvcWorkspaceNavigationItem[];
}

export interface SvcMenuNavigation {
  menuGroupName: string;
  expandedDefault: boolean;
  menuGroupId: number;
  menus: SvcMenuNavigationItem[];
}

export interface SvcMenuNavigationItem {
  menuDesc: string;
  menuCategoryProvider?: SvcMenuNavigationItemInfo;
}

export interface SvcMenuNavigationItemInfo {
  url: string;
  route: string;
  params: string;
  applicationType: SvcMenuNavigationItemType
}

export enum SvcMenuNavigationItemType {
  ASP = 1,
  CORE = 2,
  ANGULAR = 3,
  EXTERNAL = 4,
  MESSAGE = 5
}

export interface SvcDefaultNavigationItem {
  title?: string;
  icon?: string;
  url?: string | Function;
  children: SvcDefaultNavigationItem[];
}


export interface SvcWorkspaceNavigationItem {
  title?: string;
  icon?: string;
  url?: string | Function;
  params?: string;
}
