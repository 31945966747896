import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { EmptyLayoutComponent } from './empty.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LayoutComponentsModule } from '../components/layout-components.module';
import { SvcToastModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
    declarations: [
        EmptyLayoutComponent
    ],
    imports     : [
        LibSharedCommonModule,
        MatProgressBarModule,
        LayoutComponentsModule,
        SvcToastModule,
    ],
    exports     : [
        EmptyLayoutComponent
    ]
})
export class EmptyLayoutModule
{
}
