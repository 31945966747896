import { Component, OnChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { SvcAppSettings, UserService } from 'projects/lib-shared-core/src/public-api';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SvcDistributionAccordionItem } from './interfaces/svc-distribution-manage.interface';
import { SvcDistributionManageFormComponent } from './svc-distribution-manage-form/svc-distribution-manage-form.component';
import { SvcDistributionManageService } from './svc-distribution-manage.service';
import { SvcDistributionAccordionItemSub } from './interfaces/svc-distribution-manage.interface';
import { HttpResponse } from '@angular/common/http';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SvcDataTableAccordion } from 'projects/lib-shared-component/src/public-api';

@Component({
  selector: 'svc-distribution-manage',
  templateUrl: './svc-distribution-manage.component.html',
  styleUrls: ['./svc-distribution-manage.component.scss']
})
export class SvcDistributionManageComponent implements OnChanges {
  @ViewChild(SvcDistributionManageFormComponent) distributionForm: SvcDistributionManageFormComponent;

  public showSearchControl = true;
  public showOpenAllAccordions = false;
  public showDownloadOptions = true;
  public isLoading = true;

  public moduleName: string;
  public applicationId: string;
  public siteId: number;
  public subItemEmit: SvcDistributionAccordionItemSub;
  public exporting = false;

  public items: SvcDistributionAccordionItem[] = [];
  public sort: MatSort;
  public dataSource = new MatTableDataSource<any>();
  public searchControl = new FormControl();
  public expandedRows = new Set<any>();

  dataTable: SvcDataTableAccordion = {
    subItemsPropertyName: 'sub',
    columns: [
      {
        label: this._translocoService.translate('Distribuição'),
        sort: true,
        property: 'area',
        isSearchable: true,
        accordion: {
          property: 'area',
          type: 'string'
        },
        type: 'string',
        cssClass: 'uppercase',
        cellCssClass: 'uppercase',
      },
      {
        label: this._translocoService.translate('Total responsável'),
        sort: false,
        property: 'total',
        accordion: {
          property: 'total',
          type: 'number',
          cssClass: 'w-[180px] text-center',
          cellCssClass: 'w-[180px] text-center'
        },
        cssClass: 'w-[180px] text-center uppercase',
        cellCssClass: 'w-[180px] text-center',
        type: 'numberRounded'
      }
    ]
  };

  constructor(
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _userService: UserService,
    private _distributionManageService: SvcDistributionManageService,
    private _httpErrorService: HttpErrorService,
  ) {
    this.moduleName = this._translocoService.translate(_appSettings.applicationName);
    this.applicationId = this._appSettings.applicationId;
    this.siteId = this._userService.user$.originSiteId;
    this.fetchDistributions();
  }

  public ngOnChanges(): void {
    this.updateData();
  }

  private fetchDistributions(): void {
    this._distributionManageService.getDistributionList(this.applicationId, this.siteId).subscribe({
      next: (data) => this.transformData(data),
      error: (err) => console.error(err)
    });
  }

  private transformData(data: any[]): void {
    this.isLoading = true;

    const transformedItems: SvcDistributionAccordionItem[] = [];
    let currentItem: SvcDistributionAccordionItem | null = null;

    data.forEach(item => {
      const responsibles = item.responsibles.map((responsible: any) => responsible.responsibleId);

      if (item.isDepartment) {
        if (currentItem) {
          transformedItems.push(currentItem);
        }
        currentItem = {
          area: item.department,
          areaId: item.areaId,
          distributionId: item.distributionId,
          total: item.responsiblesCount,
          sub: [],
          responsibles: responsibles
        };
      } else if (currentItem) {
        currentItem.sub.push({
          area: item.area,
          areaId: item.areaId,
          distributionId: item.distributionId,
          total: item.responsiblesCount,
          responsibles: responsibles
        });
      }
    });

    if (currentItem) {
      transformedItems.push(currentItem);
    }

    this.items = transformedItems;
    this.updateData();
  }

  private updateData(): void {
    this.dataSource.data = this.items;
    setTimeout(() => { this.isLoading = false }, 500);
  }

  private downloadFileFromResponse(responseBlob: HttpResponse<Blob>) {
    const fileName = responseBlob.headers.get('content-disposition')?.split(';')[1].split('=')[1];
    const blobFile: Blob = responseBlob.body as Blob
    const link = document.createElement('a');
    link.download = fileName ?? this._translocoService.translate('Distribuição');
    link.href = URL.createObjectURL(blobFile);
    link.click();
  }

  public getDownloadFiles(file: string): void {
    this.exporting = true;

    if(file === 'excel') {
      this._distributionManageService.downloadExcel(this.applicationId, this.siteId).pipe(
        tap((responseBlob: HttpResponse<Blob>) => this.downloadFileFromResponse(responseBlob)),
        catchError(err => {
          this.showToastError(err);
          return err;
        }),
        finalize(() => {
          this.exporting = false;
        })
      ).subscribe();
    }

    if(file === 'pdf') {
      this._distributionManageService.downloadPdf(this.applicationId, this.siteId).pipe(
        tap((responseBlob: HttpResponse<Blob>) => this.downloadFileFromResponse(responseBlob)),
        catchError(err => {
          this.showToastError(err);
          return err;
        }),
        finalize(() => {
          this.exporting = false;
        })
      ).subscribe();
    }
  }

  public handleExportFile(format: 'pdf' | 'excel'): void {
    this.getDownloadFiles(format);
  }

  public handleSubRowClick(item: SvcDistributionAccordionItemSub): void {
    this.emitSubItemClick(item);
  }

  emitSubItemClick(item: SvcDistributionAccordionItemSub): void {
    this.subItemEmit = {
      area: item.area,
      areaId: item.areaId,
      distributionId: item.distributionId,
      responsibles: item.responsibles
    };
  }

  public onFormSubmitted(): void {
    this.fetchDistributions();
  }

  private showToastError(err: any): void {
    this._httpErrorService.showErrorInToast(err);
  }
}
