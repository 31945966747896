import { Component, OnInit, Inject, ElementRef, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'svc-modal-image-viewer',
	templateUrl: './svc-modal-image-viewer.component.html',
	styleUrls: ['./svc-modal-image-viewer.component.scss'],
	host: {
		'(document:keydown)': 'onKeydown($event)',
		'(window:resize)': 'onWindowResize()'
	}
})
export class SvcModalImageViewerComponent implements OnInit {

	public imageUrl: string = '';

	public loading: boolean = true;

	private img: HTMLImageElement;
	private scaleRate: number = 1;

	public get width(): string { return (this.loading ? 0 : this.img.width * this.scaleRate) + 'px'; }
	public get height(): string { return (this.loading ? 0 : this.img.height * this.scaleRate) + 'px'; }

	private get windowWidth(): number { return window.innerWidth - 40; }
	private get windowHeight(): number { return window.innerHeight - 40; }

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<SvcModalImageViewerComponent>,
		public elRef: ElementRef<HTMLElement>
	) {
		this.imageUrl = data.imageUrl;

		this.dialogRef.disableClose = true;

		this.loadImage();
	}

	ngOnInit() {
		this.addClassMatDialogContainer();
	}

	private addClassMatDialogContainer() {
		this.elRef.nativeElement.parentElement.classList.add('mat-dialog-container-no-bg', 'p-0');
		this.elRef.nativeElement.parentElement.parentElement.style.maxWidth = '100%';
		this.elRef.nativeElement.parentElement.parentElement.parentElement.style.padding = '20px 0';
	}

	private loadImage() {
		this.loading = true;
		this.img = new Image();
		this.img.onload = () => {
			this.loading = false;
			this.onWindowResize();
		};
		this.img.src = this.imageUrl;
	}

	onKeydown(event: KeyboardEvent) {
		event.preventDefault();
		event.stopImmediatePropagation();

		if (event.keyCode === 27)
			this.close();
	}

	onWindowResize() {
		if (!this.loading) {
			let widthDiff = this.windowWidth - this.img.width;
			let heightDiff = this.windowHeight - this.img.height;

			if (widthDiff < 0 || heightDiff < 0) {
				if (widthDiff < heightDiff) {
					if (this.img.width > this.windowWidth) {
						this.scaleRate = this.windowWidth / this.img.width;
						return;
					}
				}
				else {
					if (this.img.height > this.windowHeight) {
						this.scaleRate = this.windowHeight / this.img.height;
						return;
					}
				}
			}
		}
		this.scaleRate = 1;
	}

	close() {
		this.dialogRef.close()
	}
}
