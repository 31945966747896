export interface UserHashtags {
  siteName: string,
  hashtags: Hashtag[]
}

export interface AvailableHashtags {
  siteName: string,
  types: HashtagType[]
}

export interface Hashtag {
  id?: number;
  hashtagId: number;
  name?: string;
  isGlobal?: boolean;
  siteId?: number;
  active?: boolean;
  order?: number;
}

export interface PostHashtag {
  hashtags: Hashtag[];
  page: {
    pageIndex?: number;
    pageSize?: number;
    pagesTotal?: number;
    registersTotal?: number;
  }
}

export enum HashtagTypeEnum {
  'Local' = 'Local',
  'Global' = 'Global'
}

export interface HashtagType {
  typeName: string,
  hashtags: Hashtag[]
}
