import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcPriorityButtonComponent } from './svc-priority-button.component';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule
  ],
  declarations: [SvcPriorityButtonComponent],
  exports: [SvcPriorityButtonComponent],
})
export class SvcPriorityButtonModule {}
