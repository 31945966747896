import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcUserPickerComponent } from './svc-user-picker.component';
import { SvcControlsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcUserPickerOptionComponent } from './svc-user-picker-option/svc-user-picker-option.component';

@NgModule({
  declarations: [
    SvcUserPickerComponent,
    SvcUserPickerOptionComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatProgressSpinnerModule,
    SvcUserAvatarModule,
    SvcControlsModule,
    TranslocoModule,
  ],
  exports: [
    SvcUserPickerComponent,
  ],
})
export class SvcUserPickerModule { }
