import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SvcHttpClient } from 'projects/lib-shared-common/src/lib/classes/svc-http-client';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable()
export class SvcPivotTableHttpService extends SvcHttpClient {

  constructor(
    protected appConfig: AppEnvironmentConfig,
    protected httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlMasterdata, httpClient);
  }
}
