import { Route } from '@angular/router';
import { SvcDialogResolver } from 'projects/lib-shared-component/src/public-api';
import { SvcFaqComponent } from "./svc-faq.component";

export const faqRoutes: Route[] = [
    {
        path: '',
        component: SvcFaqComponent,
        resolve: { dialogRef: SvcDialogResolver },
        data: {
            width: '100%',
            maxWidth: '1140px',
            panelClass: ['sm:p-4'],
        },
    }
];
