import { Component, ElementRef, Injector, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'projects/environments/environment';
import { ISvcCarousel, SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { Subscription, delay, finalize, of, tap } from 'rxjs';
import { Post } from '../../models/post';
import { PostForm } from '../../models/post-form';
import { SvcPostMentionComponent } from '../svc-post-mention/svc-post-mention.component';
import { SvcPostViewModalComponent } from '../svc-post-view-modal/svc-post-view-modal.component';
import { StaticApplicationId, SvcViewsService } from 'projects/lib-shared-core/src/public-api';
import { SvcPostItemFooterComponent } from '../svc-post-item-footer/svc-post-item-footer.component';

@Component({
  selector: 'svc-post-item',
  templateUrl: './svc-post-item.component.html',
  styleUrls: ['./svc-post-item.component.scss'],
})
export class SvcPostItemComponent extends PostForm implements OnChanges {
  @ViewChild('mentionComponent') mentionComponent: SvcPostMentionComponent;
  @ViewChild('postItemFooterComponent') postItemFooterComponent: SvcPostItemFooterComponent;

  @Input('post')
  public get post(): Post {
    return this._post;
  }
  public set post(post: Post) {
    this._post = post;
    this.text = post.textContent;
  }
  public env = environment;
  public text: string;
  public textTranslated: string;
  public translatingText: boolean;
  public showMore: boolean;
  public currentSlide: ISvcCarousel;
  public itsBeSeen: boolean = false;
  public setViewsIncrementIsLoading = false;
  private startedToBeSeenSubscription: Subscription;
  public applicationId = StaticApplicationId.posts;

  public get element() {
    return this._elementRef.nativeElement;
  }

  constructor(
    private _svcDialog: SvcDialogService,
    protected injector: Injector,
    private _elementRef: ElementRef<HTMLElement>,
    private _svcViewsService: SvcViewsService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    const isFirstPostValue = 'post' in changes && !changes.post.previousValue;
    const postWasChanged = 'post' in changes && changes.post.previousValue && this._post?.id !== changes.post.previousValue?.id;
    const postOriginatorWasChanged = postWasChanged && 'post' in changes && this._post.originator?.originatorId !== changes.post.previousValue?.originator?.originatorId;
    
    if (postWasChanged || isFirstPostValue) {
      this.ngOnInit();
      this.setPostMediaCarousel();
      this.setPostUsersInfo();
      this.setPostTeamInfo();
      if (postWasChanged && this.postItemFooterComponent) {
        this.postItemFooterComponent.showComments = false;
        this.postItemFooterComponent.refreshSubcomponents();
      }
    }
  }

  startedToBeSeen() {
    if (!this.itsBeSeen) {
      this.itsBeSeen = true;

      if (!this._post.wasMarkedAsSeen) {
        this.startedToBeSeenSubscription = of(null).pipe(
          delay(3000)
        ).subscribe(() => {
          if (!this.setViewsIncrementIsLoading) {
            this.setViewsIncrementIsLoading = true;
            this._svcViewsService.setViews({
              registryId: this.post?.applicationRegistry?.applicationRegistryReferenceId?.toString() ?? this.post.id.toString(),
              applicationId: this.post?.applicationRegistry?.applicationId ?? this.applicationId,
              featureName: this.post?.featureName,
            }).pipe(
              tap(() => this._post.wasMarkedAsSeen = true),
              finalize(() => this.setViewsIncrementIsLoading = false),
            ).subscribe();
          }
        });
      }
    }
  }

  stoppedToBeSeen() {
    if (this.itsBeSeen) {
      this.itsBeSeen = false;
      this.setViewsIncrementIsLoading = false;
      this.startedToBeSeenSubscription?.unsubscribe();
      this.startedToBeSeenSubscription = null;
    }
  }

  viewPost() {
    this._svcDialog.open(SvcPostViewModalComponent, {
      data: {
        reload: true,
        post: this._post,
      },
      maxWidth: '720px',
      width: '100%',
      panelClass: ['sm:p-4'],
      disableClose: true,
      enterAnimationDuration: '0s',
    }).afterClosed().pipe(
      tap((response) => {
        if (response?.viewed) {
          this.postItemFooterComponent?.updateTotalStatus();
          this.postItemFooterComponent?.updateTotalReactions();
          this.postItemFooterComponent?.updateReaction();
        }
      }),
    ).subscribe();
  }

  translatePost() {
    super.translatePost(this.mentionComponent.html);
  }

  setCurrentSlide(slide) {
    this.currentSlide = slide;
  }
}
