import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcSearchActionplanComponent } from './svc-search-actionplan.component';
import { SvcSearchActionplanRoutingModule } from './svc-search-actionplan-routing.module';
import { SvcBreadcrumbModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
  declarations: [
    SvcSearchActionplanComponent
  ],
  imports: [
    CommonModule,
    SvcSearchActionplanRoutingModule,
    SvcBreadcrumbModule,
    TranslocoModule,
    RouterModule
  ],
  exports: [
    SvcSearchActionplanComponent
  ]
})
export class SvcSearchActionplanModule { }
