import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SvcComment, SvcCommentAttachment, SvcCommentAttachmentType } from '../../models/comment.model';
import { ISvcCarousel, ISvcUserAvatar, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { SvcCommentsChatComponent } from '../svc-comments-chat/svc-comments-chat.component';
import { SvcCommentsService } from '../../services/svc-comments.service';
import { catchError, finalize, tap } from 'rxjs';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SvcTotalReactionsComponent } from '../../../svc-reaction/svc-total-reactions/svc-total-reactions.component';

@Component({
  selector: 'svc-comments-message',
  templateUrl: './svc-comments-message.component.html',
  styleUrls: ['./svc-comments-message.component.scss']
})
export class SvcCommentsMessageComponent implements OnInit, OnChanges {
  @Input() public message: SvcComment;
  @Input() public configTypeId: string;
  @Input() public parentMessage: SvcComment;
  @Input() public smallSize: boolean = false;
  @Input() public applicationId: string;
  @Input() public siteId: number;
  @Input() public external: boolean = false;

  @Output() public onCommentRemoved = new EventEmitter<SvcComment>();
  @Output() public onCommentAdded = new EventEmitter<SvcComment>();

  @ViewChild(SvcCommentsChatComponent) public svcCommentsChatComponent: SvcCommentsChatComponent;
  @ViewChild(SvcTotalReactionsComponent) public svcTotalReactions: SvcTotalReactionsComponent;

  public AttachmentType = SvcCommentAttachmentType;
  public isChild: boolean = false;
  public userAvatar: ISvcUserAvatar;
  public mediasCarousel: ISvcCarousel[];
  public attachments: SvcCommentAttachment[];
  public showChildrenMessages: boolean = false;
  public hasImageOrAudioAttachment: boolean = false;
  public removingIsLoading: boolean = false;
  public translatingIsLoading: boolean = false;

  constructor(
    private _commentsService: SvcCommentsService,
    private _httpErrorService: HttpErrorService,
  ) { }

  public ngOnInit(): void {
    this.isChild = !!(this.parentMessage);
    this.userAvatar = {
      name: this.message.user.name,
      initialsColor: this.message.user.color,
      picture: this.message.user.picture,
      size: UserAvatarSize.Comment,
    };
    this.mediasCarousel = this.message.attachments
      .filter(item => {
        return [SvcCommentAttachmentType.IMAGE, SvcCommentAttachmentType.VIDEO].includes(item.type)
      })
      .map((item) => ({
        title: item.name,
        url: item.assignUrl,
        type: item.type === SvcCommentAttachmentType.VIDEO ? 'video' : 'image',
      }));
    this.attachments = this.message.attachments.filter((item) => !this.mediasCarousel.some(x => x.url === item.assignUrl));
    this.hasImageOrAudioAttachment = this.message.attachments.some(x => [SvcCommentAttachmentType.AUDIO, SvcCommentAttachmentType.IMAGE].includes(x.type));
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  public openAttachment(attachment: SvcCommentAttachment) {
    if (!this.removingIsLoading && !this.translatingIsLoading) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = attachment.assignUrl;
      link.click();
    }
  }

  public async answer(options?: { force: boolean, focus: boolean }) {
    if (options?.force || (!this.removingIsLoading && !this.translatingIsLoading)) {
      await this.showMessages({ showInput: true, inputFocus: options?.focus });
    }
  }

  public showMessages(options?: { showInput?: boolean, inputFocus?: boolean }): Promise<void> {
    const finalize = (resolve: () => void) => {
      if (options?.inputFocus) {
        this.svcCommentsChatComponent.focus();
      }
      else if (options?.showInput) {
        this.svcCommentsChatComponent.showInput = true;
      }
			this.svcCommentsChatComponent.loadSuggestions();
      resolve();
    }
    return new Promise<void>((resolve) => {
      if (this.showChildrenMessages) {
        finalize(resolve);
        return;
      }
      this.showChildrenMessages = true;
      setTimeout(() => {
        finalize(resolve);
      });
    });
  }

  public translate() {
    if (!this.removingIsLoading && !this.translatingIsLoading) {
      this.translatingIsLoading = true;
      this._commentsService.translateTextComment(this.message.commentText).pipe(
        tap((text: string) => {
          this.message.translatedCommentText = text;
          this.message.translated = true;
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => this.translatingIsLoading = false)
      ).subscribe();
    }
  }

  public exclude() {
    if (!this.removingIsLoading && !this.translatingIsLoading) {
      this.removingIsLoading = true;
      this._commentsService.removeComment(this.message.commentId).pipe(
        tap(() => {
          this.onCommentRemoved.emit(this.message);
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => this.removingIsLoading = false)
      ).subscribe();
    }
  }
}
