import {EventEmitter, Injectable} from '@angular/core';
import {SidebarModules} from "./sidebar-navigation.component";

@Injectable()
export class SvcSidebarNavigationService {

  onOpen = new EventEmitter<SidebarModules>();

  constructor() { }

  open(module: SidebarModules) {
    this.onOpen.emit(module);
  }
}
