import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { ISvcUserAvatar, SvcDialogComponent } from 'projects/lib-shared-component/src/public-api';
import { PostService } from "../../services/post.service";
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { Subscription, catchError, finalize, tap } from "rxjs";
import { PostShared } from '../../models/post-shared';

export interface SharedItem {
  userName: string;
  userRole: string;
  avatar: ISvcUserAvatar;
  sharedTimes: number;
}

@Component({
  selector: 'svc-shared-list-modal',
  templateUrl: './svc-shared-list-modal.component.html',
  styleUrls: ['./svc-shared-list-modal.component.scss']
})
export class SvcSharedListModalComponent implements OnInit, OnDestroy {

  private postId: string;
  @ViewChild('dialog') dialog: SvcDialogComponent;

  text: string;
  searching: boolean;
  items: SharedItem[] = [];
  isLoading: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _userService: UserService,
    private _postService: PostService,
    private _httpErrorService: HttpErrorService
  ) {
    this.postId = data.postId;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptions.push(this._postService.getAllSharedUsersByPostId(this.postId).pipe(
      tap((response) => {
        this.getUsersInfo(response);
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        this.dialog?.closeDialog();
        return error;
      }),
    ).subscribe());
  }

  private getUsersInfo(usersShared: PostShared[]) {
    this.isLoading = true;
    let items: SharedItem[] = [];
    const check = () => {
      if (usersShared.every((item) => item['loaded'] ?? false)) {
        this.isLoading = false;
        this.items = items;
      }
    }
    for (const item of usersShared) {
      this.subscriptions.push(this._userService.getAvatar(item.userId).pipe(
        tap((user) => {
          items.push({
            userName: user.firstLastName,
            userRole: user.role,
            avatar: {
              name: user.firstLastName,
              picture: user.pictureUrl,
              size: 46 as any,
              initialsColor: user.preferenceColor,
            },
            sharedTimes: item.sharedTimes,
          });
        }),
        finalize(() => {
          item['loaded'] = true;
          check();
        }),
      ).subscribe());
    }
  }

  public close() {
    this.dialog?.closeDialog();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

}
