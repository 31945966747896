import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'svc-breadcrumb',
  templateUrl: './svc-breadcrumb.component.html',
  styleUrls: ['./svc-breadcrumb.component.scss']
})
export class SvcBreadcrumbComponent {
  @ViewChild('menu') public menuElementRef: ElementRef<HTMLElement>;
  @ViewChild('list') public listElementRef: ElementRef<HTMLElement>;
  @ViewChild('showMenuButton') public showMenuButtonRef: MatIcon;

  public showMenuItems: boolean = false;
  private windowClickListen: () => void;

  constructor(
    private _router: Router,
    private _renderer: Renderer2,
  ) { }

  public toogleBreadcrumb(event: MouseEvent) {
    const target = event.target as HTMLElement;
    this.showMenuItems = this.showMenuButtonRef?._elementRef.nativeElement.contains(target);
    this.menuElementRef.nativeElement.innerHTML = this.listElementRef?.nativeElement?.innerHTML;
    if (this.showMenuItems) {
      setTimeout(() => {
        this.windowClickListen = this._renderer.listen(window, 'click', this.onMouseClick.bind(this));
      });
    }
  }

  public handleMenuClick(event: MouseEvent) {
    const secondLevelBreadcrumbItem = (event.target as HTMLElement).closest('svc-breadcrumb-item[ng-reflect-link="/"]');
    if (secondLevelBreadcrumbItem !== null) {
      this._router.navigateByUrl('/');
    }
  }

  public onMouseClick() {
    if (this.showMenuItems) {
      this.windowClickListen && this.windowClickListen();
      this.showMenuItems = false;
    }
  }
}
