import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root'
})
export class FloatAddButtonService {

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _httpClient: HttpClient,
  ) {
  }

  get() {
    return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlAdministration}/FloatingMenu/workspace`);
  }
}
