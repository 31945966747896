import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil, tap } from 'rxjs';
import { NotificationsService } from './services/notifications.service';
import { Notification, NotificationItem } from './services/notifications.types';
import { Router } from '@angular/router';
import { SitesService } from '../../../../../lib-shared-core/src/lib/features/sites/services/sites.service';
import { TranslocoService } from "@ngneat/transloco";
import { SvcToastService, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { SvcMediaQuery, externalCommonAspURL, externalNetcoreURL, simpleRedirectURL } from 'projects/lib-shared-common/src/public-api';
import { AgVirtualSrollComponent } from 'ag-virtual-scroll';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { NotificationIntegrationService } from '../../ui/notification/notification-integration.service';
import { environment } from 'projects/environments/environment';
import { AuthService } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'svc-notifications',
  templateUrl: './svc-notifications.component.html',
  styleUrls: ['./svc-notifications.component.scss']
})
export class SvcNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel')
  private _notificationsPanel: TemplateRef<any>;

  notificationUnreadCount: number = 0;
  notifications: NotificationItem[] = [];
  notifiticationsAreGone = false;
  avatarSize: UserAvatarSize = UserAvatarSize.Notification;
  env = environment;

  private timeStartNotificationHubConnection: number = 5000;
  private timeStartToastHubConnection: number = 5000;
  private notificationHubConnection: HubConnection;
  private toastHubConnection: HubConnection;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild(AgVirtualSrollComponent) agVirtualSroll: AgVirtualSrollComponent;

  get isLoading(): boolean {
    return this._notificationsService.isLoading;
  }

  constructor(
    private _authService: AuthService,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _router: Router,
    private _sitesService: SitesService,
    private _translocoService: TranslocoService,
    private _svcMediaQuery: SvcMediaQuery,
    private _appConfig: AppEnvironmentConfig,
    private _toastService: SvcToastService,
    private _notificationIntegrationService: NotificationIntegrationService,
  ) { }

  ngOnInit(): void {
    this.prepareListenNotificationHub();
    this.startListenNotificationHub();
    
    this.prepareListenToastHub();
    this.startListenToastHub();

    this._notificationsService.getAll().subscribe();
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification) => {
        this.notifications = notifications.notifications;
        this.notificationUnreadCount = notifications.unreadCount;
        this.notifiticationsAreGone = notifications.notifiticationsAreGone;
      });

    this._sitesService.onSiteChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async () => {
        this._notificationsService.getAll().subscribe();
      });

    this._svcMediaQuery.size$.pipe(
      takeUntil(this._unsubscribeAll),
      tap(() => {
        setTimeout(() => this.agVirtualSroll?.refreshData(), 1);
      }),
    ).subscribe();
  }

  private prepareListenNotificationHub() {
    this.notificationHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlNotification}/notificationHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this.notificationHubConnection.onclose(async () => {
      await this.startListenNotificationHub();
    });

    this.notificationHubConnection.onreconnecting((error) => {
      console.log(`[NotificationSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this.notificationHubConnection.on('SendNotification', (notification: Notification) => {
      const currentNotification = notification.notifications[0];
      this.notificationUnreadCount = notification.unreadCount;
      this.notifiticationsAreGone = notification.notifiticationsAreGone;
      this.notifications = [
        currentNotification,
        ...this.notifications,
      ];
    });
  }

  private async startListenNotificationHub() {
    try {
      if (this.notificationHubConnection) {
        await this.notificationHubConnection.start();
      }
    }
    catch(e) {
      console.log(e);
      setTimeout(() => this.startListenNotificationHub(), this.timeStartNotificationHubConnection);
      this.timeStartNotificationHubConnection += 5000;
    }
  }

  private prepareListenToastHub() {
    this.toastHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlNotification}/toastHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this.toastHubConnection.onclose(async () => {
      await this.startListenNotificationHub();
    });

    this.toastHubConnection.onreconnecting((error) => {
      console.log(`[ToastSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this.toastHubConnection.on('SendToast', (toast: { title: string }) => {
      this._toastService.success(
        toast.title,
        null,
        {
          hideIcon: true,
          timeout: 10000,
        }
      );
    });
  }

  private async startListenToastHub() {
    try {
      if (this.toastHubConnection) {
        await this.toastHubConnection.start();
      }
    }
    catch(e) {
      console.log(e);
      setTimeout(() => this.startListenToastHub(), this.timeStartToastHubConnection);
      this.timeStartToastHubConnection += 5000;
    }
  }

  openPanel(): void {
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    if (!this._overlayRef) {
      this._createOverlay();
    }

    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
    this.markAllAsRead();
  }

  closePanel(): void {
    this._overlayRef.detach();
  }

  nextPage() {
    if (!this.notifiticationsAreGone && !this._notificationsService.isLoading) {
      this._notificationsService.nextPage();
    }
  }

  markAllAsRead(): void {
    if (this.notificationUnreadCount > 0) {
      this._notificationsService.markAllAsRead().subscribe();
    }
  }

  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'svc-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  executeJsonCallback(jsonCallback: string) {
    if (jsonCallback.startsWith('https')) {
      if (jsonCallback.includes('/notification/')) {
        this._notificationIntegrationService.openIframeByPath(jsonCallback);
      }
      else {
        simpleRedirectURL(jsonCallback);
      }
    }
    else if (['openAngularDialog', 'redirectDynamicPageAsp', 'RedirectDynamicPage'].some((x) => jsonCallback?.startsWith(x))) {
      new Function(`this.${jsonCallback}`).bind(this).call();
    }

    this.closePanel();
  }

  redirectDynamicPageAsp(url: string, params: any) {
    externalCommonAspURL(url, params);
  }

  RedirectDynamicPage(url: string, params: string) {
    externalNetcoreURL(url, params);
  }

  openAngularDialog(path: string) {
    this._notificationIntegrationService.openIframeByPath(path);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    this.notificationHubConnection.stop();
  }
}
