import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from '@angular/core';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { SvcCommentsFeedComponent } from '../svc-comments-feed/svc-comments-feed.component';
import { FeedItem } from '../../models/feed-item.model';
import { NotificationIntegrationService } from 'projects/lib-shared-feature/src/lib/ui/notification/notification-integration.service';
import { externalCommonAspURL, externalNetcoreURL, isItValidExternalModuleToOpenInTheModal, openAspRegistryModalByAppId, openNetCoreRegistryModalByAppId, simpleRedirectURL } from 'projects/lib-shared-common/src/public-api';
import { environment } from 'projects/environments/environment';

@Component({
  selector: 'svc-comments-list',
  templateUrl: './svc-comments-list.component.html',
  styleUrls: ['./svc-comments-list.component.scss']
})
export class SvcCommentsListComponent implements OnInit {
  @Output() onFeedClick = new EventEmitter<FeedItem>();

  @Input() feeds: FeedItem[] = [];

  public UserAvatarSize = UserAvatarSize;

  constructor(
    @Inject(forwardRef(() => SvcCommentsFeedComponent))
    public parent: SvcCommentsFeedComponent,
    private _notificationIntegrationService: NotificationIntegrationService,
  ) { }

  public ngOnInit(): void {
  }

  public loadFeed(feed: FeedItem) {
    this.onFeedClick.emit(feed);
  }

  public openFeedModule(event: MouseEvent, feed: FeedItem) {
    if (!environment.isDEV && !environment.isQA) return;
    if (!feed.jsonCallback) return;
    event.stopImmediatePropagation();
    let jsonCallback = feed.jsonCallback;
    if (jsonCallback.startsWith('https')) {
      if (jsonCallback.includes('/notification/')) {
        this._notificationIntegrationService.openIframeByPath(jsonCallback);
        this.parent.closeSidebarIfOpened();
      }
      else {
        simpleRedirectURL(jsonCallback);
      }
    }
    else if (['openAngularDialog', 'redirectDynamicPageAsp', 'RedirectDynamicPage'].some((x) => jsonCallback?.startsWith(x))) {
      jsonCallback = jsonCallback.replace(/(\);)$|\)$/g, '');
      jsonCallback += `, ${feed.registryInternalId ?? 'null'}, '${feed.applicationId}');`;
      new Function(`this.${jsonCallback}`).bind(this).call();
    }
  }

  public redirectDynamicPageAsp(url: string, params: { system: string, redirectId: number }, customRedirectId: number, applicationId: string) {
    if (isItValidExternalModuleToOpenInTheModal(applicationId)) {
      const redirectId = customRedirectId ?? params.redirectId;
      openAspRegistryModalByAppId(redirectId, applicationId);
      this.parent.closeSidebarIfOpened();
    }
    else {
      externalCommonAspURL(url, params as any);
    }
  }

  public RedirectDynamicPage(url: string, params: string, customRedirectId: number, applicationId: string) {
    if (isItValidExternalModuleToOpenInTheModal(applicationId)) {
      const environmentUrl = url.split('.com')[0] + '.com';
      const modulePathName = url.replace(environmentUrl, '');
      openNetCoreRegistryModalByAppId(modulePathName, params, customRedirectId, applicationId);
      this.parent.closeSidebarIfOpened();
    }
    else {
      externalNetcoreURL(url, params);
    }
  }

  public openAngularDialog(path: string, customRedirectId: number, applicationId: string) {
    this._notificationIntegrationService.openIframeByPath(path);
    this.parent.closeSidebarIfOpened();
  }
}
