export enum QuestionTypesEnum {
  Simple_Choice = 1,
  Multiple_Choice = 2,
  Text_Box = 3,
  Multiple_Text_Boxes = 4,
  Evaluation_Bar = 5,
  Evaluation_Matrix = 6,
  Star_Rating = 7,
  Employees_List = 8,
  Dropdown_List = 9,
}

export enum QuestionTypesDescriptionEnum {
  Simple_Choice = 'Simples escolha',
  Multiple_Choice = 'Múltipla escolha',
  Text_Box = 'Caixa de texto',
  Multiple_Text_Boxes = 'Múltiplas caixas de texto',
  Evaluation_Bar = 'Barra de Pesquisa',
  Evaluation_Matrix = 'Matriz de Avaliação',
  Star_Rating = 'Avaliação Estrela',
  Employees_List = 'Lista de Funcionários',
  Dropdown_List = 'Lista Suspensa',
}
