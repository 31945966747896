import { HttpErrorResponse } from '@angular/common/http';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { StaticApplicationId } from 'projects/lib-shared-core/src/lib/Constants/static-application-id.enum';
import {
  AppsService, AuthService,
  NavigationService,
  NotificationsService,
  PageCantBeLoadedType,
  SitesService,
  SvcAppSettings, UserService,
} from 'projects/lib-shared-core/src/public-api';
import {catchError, first, forkJoin, Observable, of, switchMap, tap} from 'rxjs';
import { SvcGamificationService } from '../features/gamification/services/svc-gamification.service';
import {AuthStatus} from "../auth/model/user-auth.model";
import {externalURLSamePageWithoutHistory} from 'projects/lib-shared-common/src/public-api';
import {EnvironmentInfo} from "../auth/model/environment.info.model";

export class RootResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    protected _router: Router,
    protected _navigationService: NavigationService,
    protected _authService: AuthService,
    protected _userService: UserService,
    protected _sitesService: SitesService,
    protected _appsService: AppsService,
    protected _notificationsService: NotificationsService,
    protected _appSettings: SvcAppSettings,
    protected _gamificationService: SvcGamificationService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this._userService.get().pipe(
      first(),
      switchMap(_ => this._navigationService.get(this._appSettings.applicationId as StaticApplicationId).pipe(
        switchMap(() => forkJoin([
          this._userService.getEnvironment(),
          this._sitesService.getAll(),
          this._appsService.getAll(),
          this._userService.getCurrencyAcronym(),
          this._authService.getUserStatusAuth(),
        ]).pipe(
          catchError((_) => {
            this._router.navigate(['page-cant-be-load']);
            return of(null);
          }),
        )),
        catchError<any, any>((error: HttpErrorResponse) => {
          if (error?.status === 400) {
            this._router.navigate(['page-cant-be-load'], {
              queryParams: {
                type: PageCantBeLoadedType.PERMISSION,
                from: this._router.url,
              }
            });
          }
          else {
            this._router.navigate(['page-cant-be-load']);
          }
          return error;
        }) as any,
      )),
      tap(([environment, sites, apps, currency, userStatus]) => {
        if(<AuthStatus>userStatus == AuthStatus.SupplierUser)
          externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/supliermanagement`, ``);

        if(<AuthStatus>userStatus == AuthStatus.ContractorUser)
          externalURLSamePageWithoutHistory(`${(<EnvironmentInfo>environment).url}/contractormanagement`, ``);

        if(<AuthStatus>userStatus == AuthStatus.PendingGDPRUser)
          this._router.navigate(['page-cant-be-load'], {
            queryParams: {
              type: PageCantBeLoadedType.GDPR,
              from: this._router.url,
            }
          });

        this._gamificationService.getIsGamefied().subscribe();
      }),
      catchError((_) => {
        this._router.navigate(['page-cant-be-load']);
        return of(null);
      }),
    );
  }
}
