import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Inject, Injector, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslocoService } from "@ngneat/transloco";
import { environment } from "projects/environments/environment";
import { SvcDialogComponent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { StaticApplicationId, SvcViewsService } from 'projects/lib-shared-core/src/public-api';
import { Subscription, finalize, tap } from 'rxjs';
import { AppEnvironmentConfig } from "../../../../../../../config/model/environment.config.model";
import { Post } from "../../models/post";
import { PostForm } from "../../models/post-form";
import { SvcBookmarkService } from "../../../../../../../lib-shared-core/src/lib/services/svc-bookmark.service";
import { SvcBookmarkListById, SvcBookmarkListByUid } from "../../../../../../../lib-shared-core/src/lib/interfaces/svc-bookmark.interface";

export type ViewSize = 'sm' | 'xl';

export interface SvcPostViewConfig {
  reload?: boolean;
  size?: ViewSize;
  post?: Post;
}

@Component({
  selector: 'svc-post-view-modal',
  templateUrl: './svc-post-view-modal.component.html',
  styleUrls: ['./svc-post-view-modal.component.scss'],
})
export class SvcPostViewModalComponent extends PostForm {

  @ViewChild('dialog') dialog: SvcDialogComponent;

  public env = environment;
  public loading: boolean;
  private get _element() {
    return this._elementRef.nativeElement;
  }

  protected _post: Post;
  public applicationId = StaticApplicationId.posts;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: SvcPostViewConfig,
    protected injector: Injector,
    private _http: HttpClient,
    private _elementRef: ElementRef<HTMLElement>,
    private _appConfig: AppEnvironmentConfig,
    private _translocoService: TranslocoService,
    private _toastService: SvcToastService,
    private _svcViewsService: SvcViewsService,
    private _svcBookmarkService: SvcBookmarkService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loading = true;
    if (this._data?.reload && this._data?.post?.id) {
      this._postService.getPost(this._data.post.id).subscribe({
        next: post => {
          this._post = post;
          this.text = post.textContent;
          this._postService.replacePost(this._post);
          this.defineModalMeasurements();
          this.setPostUsersInfo();
          this.setPostMediaCarousel();
          this.loadBookMark();
          /* this.setOriginatorAvatar().subscribe(r => {
            this.loading = false;
            super.ngOnInit();
          }); */
          this.setView();
          this.loading = false;
          super.ngOnInit();
        },
        error: () => {
          this._toastService.error(this._translocoService.translate('Ocorreu um erro, tente novamente em outro momento'));
          this.dialog.closeDialog();
        }
      })
    } else {
      this._post = this._data.post;
      this.text = this._post.textContent;
      this.defineModalMeasurements();
      this.setPostUsersInfo();
      this.setPostMediaCarousel();
      this.loadBookMark();
      /* this.setOriginatorAvatar().subscribe(r => {
        this.loading = false;
        super.ngOnInit();
      }); */
      this.setView();
      this.loading = false;
      super.ngOnInit();
    }
  }

  closeDialog() {
    this.dialog.closeDialog({ viewed: this._data.post.wasMarkedAsSeen });
  }

  translatePost() {
    super.translatePost(this._post.textContent);
  }

  private setView() {
    if (!this._data?.post?.wasMarkedAsSeen) {
      this._svcViewsService.setViews({
        registryId: this._data?.post?.applicationRegistry?.applicationRegistryReferenceId?.toString() ?? this._data?.post?.id.toString(),
        applicationId: this._data?.post?.applicationRegistry?.applicationId ?? this.applicationId,
        featureName: this._data?.post?.featureName,
      }).pipe(
        tap(() => this._data.post.wasMarkedAsSeen = true),
      ).subscribe();
    }
  }

  private defineModalMeasurements() {
    const haveMedia = (this._post?.mediaUrls?.length ?? 0) > 0;

    const cdkOverlayPane = this.getCdkOverlayPane();
    if (cdkOverlayPane) {
      cdkOverlayPane.style.width = '100%';
      cdkOverlayPane.style.maxWidth = haveMedia ? '1430px' : '720px';
    }
  }

  private getCdkOverlayPane(): HTMLElement {
    let parent: HTMLElement;
    do {
      parent = (parent ?? this._element).parentElement;
    } while ((parent == null || !parent.classList.contains('cdk-overlay-pane')) && parent !== document.body);
    return parent;
  }

  private loadBookmarkById(): void {
    this._svcBookmarkService.getBookmarkListByIds(
      this._post.applicationRegistry.applicationId,
      this._post.site.siteId,
      [this._post.applicationRegistry.applicationRegistryReferenceId]
    ).subscribe((bookmarkList: SvcBookmarkListById[]) =>
      this._post.bookmarked = bookmarkList.some(bookmark =>
        bookmark.registryUniqueId === this._post.applicationRegistry.applicationRegistryReferenceId
      )
    )
  }

  private loadBookmarkByUid(): void {
    this._svcBookmarkService.getBookmarkListByUIds(
      StaticApplicationId.posts,
      this._userService.user$.lastSiteId,
      [this._post.id]
    ).subscribe((bookmarkList: SvcBookmarkListByUid[]) =>
      this._post.bookmarked = bookmarkList.some(bookmark =>
        bookmark.registryUniqueUId === this._post.id
      )
    );
  }

  private loadBookMark(): void {
    this._post?.applicationRegistry?.applicationId ? this.loadBookmarkById() : this.loadBookmarkByUid();
  }
}
