import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { LibSharedCommonModule } from '../../../../../../lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../../lib-shared-component.module';
import { SvcDatePickerCalendarComponent } from './svc-date-picker-calendar.component';
import { SvcDatePickerCalendarHeaderModule } from '../svc-date-picker-calendar-header/svc-date-picker-calendar-header.module';



@NgModule({
  declarations: [
    SvcDatePickerCalendarComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDatePickerCalendarHeaderModule
  ],
  exports: [
    SvcDatePickerCalendarComponent
  ]
})
export class SvcDatePickerCalendarModule { }
