import { Component, OnInit } from '@angular/core';
import { UserHashtagsService } from "../../services/user-hastags.service";
import { Hashtag, HashtagTypeEnum } from "../../services/user-hashtags.types";
import { MatDialogRef } from "@angular/material/dialog";
import { catchError, finalize, forkJoin, Subject, takeUntil, tap } from "rxjs";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { SvcToastService, SvcWarappedLayoutSorting } from 'projects/lib-shared-component/src/public-api';
import { environment } from 'projects/environments/environment';

@Component({
  selector: 'edit-hashtag',
  templateUrl: './edit-hashtag.component.html',
  styleUrls: ['./edit-hashtag.component.scss']
})
export class EditHashtagComponent implements OnInit {

  forDelete: number[] = [];
  hashtags: Hashtag[] = [];
  filteredHashtags: Hashtag[];
  hashtagSearch: string;
  hashtagTypeEnum = HashtagTypeEnum;
  typeActive: HashtagTypeEnum = HashtagTypeEnum.Local;
  isLoading = false;
  isFiltering = false;
  env = environment;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _dialogRef: MatDialogRef<EditHashtagComponent>,
    private _userHashtagService: UserHashtagsService,
    private _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
    private _toastService: SvcToastService,
  ) { }

  ngOnInit() {
    this._userHashtagService.getUserHashtags().subscribe();

    this._userHashtagService.hashtags$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.hashtags = res;
        this.filter(this.hashtagSearch);
      });
  }

  ngOnDestroy(): void {
    //Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  save() {
    if (this.forDelete?.length > 0) {
      this.deleteHashtags();
    }
    else {
      this.saveHashtags();
    }
  }

  deleteHashtags() {
    this.isLoading = true;
    forkJoin(
      this.forDelete.map(id => this._userHashtagService.removeHashtag(id))
    ).pipe(
      tap(() => {
        if (this.env.isDEV || this.env.isQA) {
          this.saveHashtags();
        }
        else {
          this._userHashtagService.getUserHashtags().subscribe();
          this._dialogRef.close({ cleanFilter: true });
          this._toastService.success(this._translocoService.translate('Hashtags atualizadas com sucesso!'));
        }
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
    ).subscribe();
  }

  saveHashtags() {
    this.isLoading = true;
    const hashtags = this.hashtags.map((h) => ({ id: h.id, order: h.order ?? 1 }));
    this._userHashtagService.updateHashtagsOrder(hashtags).pipe(
      tap(() => {
        this._userHashtagService.getUserHashtags().subscribe();
        this._dialogRef.close({ cleanFilter: true });
        this._toastService.success(this._translocoService.translate('Hashtags atualizadas com sucesso!'));
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

  tabChanged = (event: MatTabChangeEvent): void => {
    this.typeActive = event.index === 0 ? HashtagTypeEnum.Local : HashtagTypeEnum.Global;
    this.filter(this.hashtagSearch)
  }

  filter(value: string) {
    if (!value?.trim()) {
      this.isFiltering = false;
      this.filteredHashtags = this.hashtags.filter(res => res.isGlobal == (this.typeActive == HashtagTypeEnum.Global));
    } else {
      this.isFiltering = true;
      this.filteredHashtags = this.hashtags.filter(x =>
        x.name.trim().toLowerCase().includes(value.trim().toLowerCase()) && x.isGlobal == (this.typeActive == HashtagTypeEnum.Global)
      );
    }
  }

  private removeHashtag(hashtags: Hashtag[], hashtagIdToRemove: number): Hashtag[] {
    return hashtags?.filter((hashtag: Hashtag) => hashtag.hashtagId !== hashtagIdToRemove);
  }

  public toggleDelete(hashtagIdToDelete: number): void {
    this.forDelete.push(hashtagIdToDelete);
    this.filteredHashtags = this.removeHashtag(this.filteredHashtags, hashtagIdToDelete);
    this.hashtags = this.removeHashtag(this.hashtags, hashtagIdToDelete);
  }

  public onSortingChanged(event: SvcWarappedLayoutSorting<Hashtag[]>) {
    let count = 1;
    for (const hashtag of event.items.after) {
      hashtag.order = count++;
    }
    this.hashtags = [
      ...this.hashtags.filter(h => !(h.isGlobal == (this.typeActive == HashtagTypeEnum.Global))),
      ...event.items.after,
    ];
    this.filter(this.hashtagSearch);
  }

  public closeDialog() {
    this._dialogRef.close();
  }
}
