import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { QuillModule } from 'ngx-quill';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcQuillTextEditorComponent } from './svc-quill-text-editor.component';

@NgModule({
  imports: [LibSharedCommonModule, QuillModule.forRoot(), TranslocoModule],
  declarations: [SvcQuillTextEditorComponent],
  exports: [SvcQuillTextEditorComponent],
})
export class QuillTextEditorModule {}
