import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {
  LibSharedComponentModule,
  SvcButtonsModule,
  SvcControlsModule,
} from 'projects/lib-shared-component/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { StepperComponent } from './stepper.component';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    CdkStepperModule,
    MatStepperModule,
    SvcControlsModule,
    SvcButtonsModule,
  ],
  declarations: [StepperComponent],
  exports: [StepperComponent],
})
export class StepperModule {}
