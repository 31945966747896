<svc-dialog dialogStyle="empty">
	<div svc-dialog-content class="px-5 py-3">
		<div class="flex pb-3">
			<div class="flex flex-auto items-center text-lg">
				{{ 'Incorporar' | transloco }}
			</div>
			<div class="flex items-center -mr-2">
				<button mat-icon-button (click)="closeDialog()">
					<mat-icon class="text-current icon-size-5" svgIcon="heroicons_solid:x-mark"></mat-icon>
				</button>
			</div>
		</div>
		<div class="relative">
			<pre class="truncate pr-12">
				<code class="rounded-lg" [highlight]="html" #code></code>
			</pre>
			<button mat-icon-button (click)="copyToClipboard()" class="absolute right-0 top-1" [matTooltip]="copied ? ('Copiado' | transloco) : null" matTooltipPosition="above">
				<mat-icon class="text-current icon-size-5" [svgIcon]="copied ? 'mat_solid:done' : 'mat_solid:content_copy'"></mat-icon>
			</button>
		</div>
	</div>
</svc-dialog>