import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Question } from '../models/survey-modal';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperComponent implements OnInit {
  @Input() questions: Question[];

  @ViewChild('scrollerContainer') scrollerContainer: ElementRef;
  isDragging = false;
  startX: number;
  scrollLeft: number;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.questions = this.questions?.reduce((acc, topic: any) => acc.concat(topic.questions), []) ?? [];
  }

  public onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - this.scrollerContainer.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollerContainer.nativeElement.scrollLeft;
    this.renderer.addClass(this.scrollerContainer.nativeElement, 'dragging');
  }

  public onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    event.preventDefault();
    const x = event.pageX - this.scrollerContainer.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.scrollerContainer.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  public onMouseUp(event: MouseEvent) {
    this.isDragging = false;
    this.renderer.removeClass(this.scrollerContainer.nativeElement, 'dragging');
  }
}
