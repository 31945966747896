import { ElementRef } from '@angular/core';

export class SvcDataVirtualScrollModel {
    public currentStartIndex: number = 0;
    public currentEndIndex: number = 0;
    public totalHeightContent: number = 0;
    public paddingScroll: number = 0;
    public previousItemsHeight: number[] = [];
	public containerSrollElRef: () => ElementRef<HTMLElement>;
	
    public get containerSrollEl(): HTMLElement { return this.containerSrollElRef()?.nativeElement }
    public get currentSrollTop(): number { return this.containerSrollEl?.scrollTop ?? 0; }
	public get scrollHeight(): number { return this.containerSrollEl?.scrollHeight ?? 0; }
	public get height(): number { return this.containerSrollEl?.clientHeight ?? 0; }

    constructor(obj?: Partial<SvcDataVirtualScrollModel>) {
        if (obj) Object.assign(this, obj);
    }
}
