import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { Observable } from 'rxjs';

@Injectable()
export class CommunicationService {
  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _httpClient: HttpClient
  ) { }

  public getCommunicationPreview(communicationId: number): Observable<any> {
    return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlCommunication}/Communication/${communicationId}/preview`);
  }

  public setAsRead(communicationId: number): Observable<void> {
    return this._httpClient.patch<void>(`${this._appConfig.APIs.apiUrlCommunication}/Communication/${communicationId}/read`, null);
  }
}

