import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from "@ngneat/transloco";
import { LibServiceKaizenAnalyticsModule } from 'projects/lib-service-kaizen-analytics/src/public-api';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcKaizenResumeComponent } from './svc-kaizen-resume.component';

@NgModule({
  declarations: [
    SvcKaizenResumeComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibServiceKaizenAnalyticsModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  exports: [
    SvcKaizenResumeComponent
  ]
})
export class SvcKaizenResumeModule { }
