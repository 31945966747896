<div class="grid gap-4">
  <form [formGroup]="form" class="grid gap-4" *screenSizes="let size" [ngClass]="{ 'pointer-events-none': readonly }">
    <svc-select
      *ngIf="!isLoading"
      [label]="label | transloco"
      [formControl]="form.controls?.userId"
      [options]="options"
      [placeholder]="'Selecione' | transloco"
      [style.maxWidth]="size.isXS ? '100%' : '400px'"
    >
    </svc-select>

    <mat-progress-spinner
      *ngIf="isLoading"
      [diameter]="24"
      mode="indeterminate"
    ></mat-progress-spinner>
  </form>
</div>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
