<div class="flex flex-col min-w-0" *screenSizes="let size">
  <svc-breadcrumb class="px-6 py-3">
    <svc-breadcrumb-item type="HOME">{{ 'Home' }}</svc-breadcrumb-item>
    <svc-breadcrumb-item *ngIf="moduleName" link="/">{{ moduleName | transloco }}</svc-breadcrumb-item>
    <svc-breadcrumb-item [active]="true">{{ 'Gestão de Notificações' | transloco }}</svc-breadcrumb-item>
  </svc-breadcrumb>

  <div class="flex flex-col flex-auto pt-3">
    <svc-data-table #dt *ngIf="rules"
      [items]="rules"
      [noPagination]="true"
      [loading]="loading"
      [dataLength]="rules?.length ?? 0"
      [min-width]="size.isXS ? '500px' : null"
    >
      <svc-data-header>
        <svc-data-col class="uppercase text-base">
          {{ 'REGRAS' | transloco }}
        </svc-data-col>
        <ng-container *ngIf="size.isAboveSM">
          <svc-data-col class="uppercase text-base" width="100px" *ngFor="let column of columns">
            {{ column | transloco }}
          </svc-data-col>
        </ng-container>
        <svc-data-col *ngIf="size.isBelowMD">
          <svc-data-col class="uppercase text-base justify-center" width="180px">
            {{ 'Notificação' | transloco }}
          </svc-data-col>
        </svc-data-col>
      </svc-data-header>
      <svc-data-body>
        <svc-data-row *ngFor="let rule of dt.items">
          <svc-data-cell class="flex-row justify-start leading-loose">
            <span class="flex gap-2 items-center">
              {{ rule.ruleName | transloco }}
              <mat-icon *ngIf="(rule.ruleDescription ?? rule.ruleName)" [style.color]="'#969191'" class="icon-size-4" svgIcon="fontawesome_solid:circle-info" [matTooltip]="(rule.ruleDescription ?? item.ruleName) | transloco" matTooltipPosition="right"></mat-icon>
            </span>
          </svc-data-cell>
          <ng-container *ngIf="size.isAboveSM">
            <svc-data-cell *ngFor="let column of columns; let i = index">
              <svc-slide-toggle
                *ngIf="column | configType:rule"
                [(checked)]="rule.types[i].active"
                (change)="onCheckboxValueChanged(rule, rule.types[i], $event.checked, $event.source)"
              ></svc-slide-toggle>
            </svc-data-cell>
          </ng-container>
          <svc-data-cell *ngIf="size.isBelowMD">
            <svc-chip
              [formControl]="rule.control"
              [options]="rule.options"
              (onSelectChanged)="onCheckboxValueChanged(rule, configTypePipe.transform($event.option.value, rule), $event.selected)"
            ></svc-chip>
          </svc-data-cell>
        </svc-data-row>
      </svc-data-body>
    </svc-data-table>

    <div *ngIf="!rules" class="flex justify-center py-10">
      <mat-spinner diameter="35"></mat-spinner>
    </div>

    <div class="flex space-x-2 justify-center pt-4">
      <svc-button mode="stroked" color="primary" [disabled]="loading || !typesNeedToUpdate.length" (click)="cancel()">
        {{'Cancelar' | transloco}}
      </svc-button>
      <svc-button mode="flat" color="primary" [disabled]="loading || !typesNeedToUpdate.length" (click)="save()">
        {{'Salvar' | transloco}}
      </svc-button>
    </div>
  </div>
</div>
