import {Component, Input} from '@angular/core';
import {Hashtag} from "../../services/user-hashtags.types";

@Component({
  selector: 'svc-list-hashtag',
  templateUrl: './list-hashtag.component.html',
  styleUrls: ['./list-hashtag.component.scss']
})
export class ListHashtagComponent {

  @Input() hashtags: Hashtag[];
  @Input() alignItems: 'middle' | 'left' = "middle";
}
