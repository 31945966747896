import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({
  selector: '[checkImageLoaded]'
})
export class CheckImageLoadedDirective {

  @Input() srcOnError: string;
  @Input() hideIfError: boolean = false;

  @Output() imageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('load', ['$event.target'])
  onLoad(image: HTMLImageElement): void {
    this._imageLoaded = true;
    this.imageLoaded?.emit(this._imageLoaded);
  }

  @HostListener('error', ['$event.target'])
  onError(image: HTMLImageElement): void {
    this._errorsCount++;

    this._imageLoaded = false;
    this.imageLoaded?.emit(this._imageLoaded);

    if (this.hideIfError) {
      image.style.display = 'none';
      return;
    }

    if (this._errorsCount > 1) return;
    if (this.srcOnError) image.src = this.srcOnError;
  }

  private _imageLoaded: boolean = false;
  private _imageSrc: string;

  /**
   * Error count to avoid loop when srcOnError cannot be loaded
   * @private
   */
  private _errorsCount: number = 0;

  constructor(private _elementRef: ElementRef) {
    this._imageSrc = this._elementRef.nativeElement.src
  }

  ngOnInit() {
    if (!this.isValidUrl(this._elementRef.nativeElement.src) && this.srcOnError) {
      this._elementRef.nativeElement.src = this.srcOnError;
      this._errorsCount++;
    }
  }
  private isValidUrl(url: string): boolean {
    const defaultSrc = `${document.location.origin}${document.location.pathname}`;
    return !!url && url !== defaultSrc;
  }

}
