import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Navigation, NavigationService, PageCantBeLoadedType, StaticApplicationId, SvcAppSettings } from 'projects/lib-shared-core/src/public-api';

@Injectable({
	providedIn: 'root'
})
export class AvailableRouteGuard implements CanActivate {
	/**
	 * Constructor
	 */
	constructor(
		private _router: Router,
		private _navigationService: NavigationService,
		private _appSettings: SvcAppSettings,
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Can activate
	 *
	 * @param route
	 * @param state
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this._navigationService.get(this._appSettings.applicationId as StaticApplicationId).pipe(
			switchMap((response: Navigation) => {
				if (response.default) {
					for (const menu of response.default) {
						for (const subMenu of menu.children) {
							if (typeof subMenu.url === 'string' && state.url.startsWith(subMenu.url)) {
								return of(true);
							}
						}
					}
				}
				this._router.navigate(['/']);
				return of(false);
			}),
			catchError<any, any>((error: HttpErrorResponse) => {
				if (error?.status === 400) {
					this._router.navigate(['page-cant-be-load'], {
						queryParams: {
							type: PageCantBeLoadedType.PERMISSION,
							from: this._router.url,
						}
					});
				}
				else {
					this._router.navigate(['page-cant-be-load']);
				}
				return error;
			}) as any,
		);
	}
}
