import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { notificationManagementRoutes } from './svc-notification-management.routing';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {
  LibSharedComponentModule,
  SvcAlertModule,
  SvcBreadcrumbModule,
  SvcButtonsModule,
  SvcControlsModule,
  SvcDataTableModule,
  SvcSlideToggleModule,
} from 'projects/lib-shared-component/src/public-api';
import { SvcNotificationManagementComponent } from './svc-notification-management.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcNotificationManagementService } from './svc-notification-management.service';
import { ConfigurationTypePipe } from './pipes/configuration-type.pipe';

@NgModule({
  declarations: [
    SvcNotificationManagementComponent,
    ConfigurationTypePipe
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDataTableModule,
    SvcBreadcrumbModule,
    SvcButtonsModule,
    SvcControlsModule,
    SvcAlertModule,
    SvcSlideToggleModule,
    RouterModule.forChild(notificationManagementRoutes),
  ],
  providers: [
    SvcNotificationManagementService
  ],
})
export class SvcNotificationManagementModule {}
