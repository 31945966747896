import {Component, Input, OnChanges} from '@angular/core';
import {ButtonBase} from '../svc-button-base';

@Component({
  selector: 'svc-button',
  templateUrl: './svc-button.component.html',
  styleUrls: ['./svc-button.component.scss']
})
export class SvcButtonComponent extends ButtonBase implements OnChanges {
  @Input() mode: 'stroked' | 'flat' | 'simple' = 'stroked';
  @Input('icon-only') iconOnly: boolean = false;
  @Input() buttonId: string;

  ngOnInit(): void {
    super.ngOnInit();
    this.iconOnly = typeof this.iconOnly == 'string' ? !this.iconOnly || this.iconOnly == 'true' : this.iconOnly;
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

}
