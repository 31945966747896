<svc-drawer #drawer *screenSizes="let size" [mode]="size.isXS ? 'over' : 'side'">
  <ng-container svcDrawerContent>
    <ng-content></ng-content>
  </ng-container>

  <ng-container svcDrawerSide>
    <div class="flex flex-col h-full bg-white rounded-lg overflow-x-hidden" [style.maxWidth.px]="255">
      <div class="flex px-8 py-3 items-center justify-between border-b border-inherit">

        <div class="text-xl font-bold tracking-tight">
          {{ 'Filtro' | transloco }}
        </div>

        <button mat-icon-button (click)="drawer.toggle()" class="-mr-2">
          <mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
        </button>

      </div>

      <div class="overflow-y-auto flex-auto p-5" [style.minWidth.px]="255">
        <svc-filters
          #svcFilters
          [hidden]="isFiltersLoading"
          [filters]="filters"
          (onFilterChanged)="onFilterChanged.emit($event)"
        ></svc-filters>

        <div *ngIf="isFiltersLoading" class="flex flex-col p-10 items-center">
          <mat-spinner diameter="35"></mat-spinner>
        </div>

      </div>

      <div class="flex flex-row px-7 py-4 items-center justify-center border-t border-inherit">
        <svc-button color="primary" mode="simple" [disabled]="svcFilters.isEmpty" (click)="!svcFilters.isEmpty && clearFilters()">
          {{ 'Limpar filtros' | transloco }}
        </svc-button>
      </div>

    </div>
  </ng-container>
</svc-drawer>
