import { Injectable } from '@angular/core';
import { Observable, finalize, map } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { LanguagesService } from '../languages/services/languages.service';

@Injectable({
    providedIn: 'root'
})
export class TranslocoApiLoader implements TranslocoLoader
{
    private langTerms: { [lang: string]: { loading: boolean, terms: any } } = {};

    /**
     * Constructor
     */
    constructor(
        private _languagesService: LanguagesService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public getTranslation(lang: string): Observable<Translation> {
        this.langTerms[lang] = {
            loading: true,
            terms: null,
        }
        const response = this._languagesService.getTerms(lang);
        return response.pipe(
            map((res: any) => res.map((term) => ( [ term.termName,  term.termValue ]))),
            map((array) => {
                this.langTerms[lang].terms = Object.fromEntries(array);
                return this.langTerms[lang].terms;
            }),
            finalize(() => this.langTerms[lang].loading = false)
        ); 
    }

    public isAlreadyLoaded(lang: string) {
        return this.langTerms[lang] && !this.langTerms[lang].loading;
    }
}
