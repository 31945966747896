<div class="flex">
	<div *ngIf="!hideIcon" class="flex items-center pr-2" [ngClass]="{ 'text-warn-800': type === 'error', 'text-default': type !== 'error' }">
		<mat-icon *ngIf="type == 'success'" svgIcon="mat_solid:done" class="icon-size-5 text-current"></mat-icon>
		<mat-icon *ngIf="type == 'error'" svgIcon="mat_solid:report" class="icon-size-5 text-current"></mat-icon>
		<mat-icon *ngIf="type == 'warning'" svgIcon="mat_solid:priority_high" class="icon-size-5 text-current"></mat-icon>
		<mat-icon *ngIf="type == 'info'" svgIcon="mat_solid:info" class="icon-size-5 text-current"></mat-icon>
	</div>
	<div class="flex flex-col flex-auto pr-2" [ngClass]="{ 'text-warn-900': type == 'error' }">
		<p *ngIf="title" class="text-base font-semibold">{{title}}</p>
		<p class="text-sm break-all" [innerHTML]="message"></p>
	</div>
	<div class="flex items-center pl-2 text-current">
		<mat-icon
			class="icon-size-4 cursor-pointer text-current"
			svgIcon="mat_solid:close"
			(click)="onDismissClicked.emit()"
		></mat-icon>
	</div>
</div>
