import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from "@angular/router";
import { LayoutComponent } from './layout.component';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { SolvaceCustomLayoutModule } from './layouts/solvace-custom/solvace-custom-layout.module';
import { SolvaceWorkspaceLayoutModule } from './layouts/solvace-workspace/solvace-workspace-layout.module';
import { LayoutService } from './layout.service';

const layoutModules = [
  // Empty
  EmptyLayoutModule,

  //Dense custom navigation
  SolvaceCustomLayoutModule,

  //Workspace custom navigation
  SolvaceWorkspaceLayoutModule
];

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    ...layoutModules,
  ],
  exports: [
    LayoutComponent,
    ...layoutModules
  ],
  providers: [
    LayoutService,
  ],
})
export class LayoutModule { }
