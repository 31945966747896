import {
	Component,
	OnInit,
	HostBinding,
	QueryList,
	ContentChildren,
	AfterViewInit,
	ElementRef,
	OnDestroy,
	Input,
} from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { SvcDataColComponent } from '../svc-data-col/svc-data-col.component';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcFilterMode } from '../enums/svc-filter-mode.enum';
import { SvcFunctionsHelper, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';

@Component({
	selector: 'svc-data-header',
	templateUrl: './svc-data-header.component.html',
	styleUrls: ['./svc-data-header.component.scss'],
})
export class SvcDataHeaderComponent {
	@HostBinding('class.svc-data-header') public class: boolean = true;

	@ContentChildren(SvcDataColComponent) private queryCols: QueryList<SvcDataColComponent>;

	@Input('mobile-header-order') public mobileMaxHeaderIndex: number[] = [];

	public get cols() {
		return this.queryCols ? this.queryCols.toArray() : [];
	}

	public get height() {
		return this.el ? `${this.el.children[0].clientHeight}px` : '0px';
	}

	public get visible() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement.style.visibility !== 'hidden' : false;
	}
	public set visible(value: boolean) {
		if (this.elRef && this.elRef.nativeElement) this.elRef.nativeElement.style.visibility = value ? '' : 'hidden';
	}

	public parent: SvcDataTableComponent;

	public frmFilter: FormGroup;

	public colSorting: { col: SvcDataColComponent; asc: boolean } = null;

	public get filterCtrls(): { [key: string]: AbstractControl } {
		return this.frmFilter && this.frmFilter.controls ? this.frmFilter.controls : null;
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	constructor(
		public elRef: ElementRef<HTMLElement>,
		private helper: SvcFunctionsHelper,
		private fb: FormBuilder
	) {
		this.frmFilter = this.fb.group({ none: [null] });
	}

	private makeForm() {
		let objControls = {};

		this.cols.forEach(col => {
			if (col.canFilter) {
				if (col.field) {
					objControls[col.field] = col.makeFormControl();

					if (col.disableFilter) {
						objControls[col.field].disable();
					}
				} else {
					(col.customFilter as { field: string; mode?: SvcFilterMode; value?: any }[]).forEach(custom => {
						objControls[custom.field] = col.makeFormControl(custom.value ? custom.value : null);

						if (col.disableFilter) {
							objControls[col.field].disable();
						}
					});
				}
			}
		});

		if (Object.keys(objControls).length === 0) objControls['none'] = this.fb.control(null);

		this.frmFilter = this.fb.group(objControls);

		if (this.parent && this.parent.loading) this.frmFilter.disable();
	}

	public enableForm() {
		this.cols.forEach(col => {
			if (col.canFilter) {
				if (col.field) this.checkFilterStatus(col.field, col);
				else if (col.customFilter)
					(col.customFilter as { field: string; mode?: SvcFilterMode; value?: any }[]).forEach(custom => {
						this.checkFilterStatus(custom.field, col);
					});
			}
		});
	}

	public checkFilterStatus(field: string, col: SvcDataColComponent) {
		let control = this.filterCtrls[field];

		if (control) {
			if (col.disableFilter) control.disable();
			else control.enable();
		}
	}

	public checkDefaultValue(field: string, value: string, col: SvcDataColComponent) {
		let control = this.filterCtrls[field];

		if (value) control.setValue(col.filterValue);
	}

	public onRender(parent: SvcDataTableComponent) {
		this.parent = parent;
		this.configureChildrens();
		this.queryCols.changes.subscribe(this.configureChildrens.bind(this));
		this.onBodyWidthChange();
	}

	public configureChildrens() {
		this.makeForm();
		this.cols.forEach((col, index) => {
			col.onRender(this, index);

			if (col.field) this.checkDefaultValue(col.field, col.filterValue, col);
			else if (col.customFilter)
				(col.customFilter as { field: string; mode?: SvcFilterMode; value?: any }[]).forEach(custom => {
					this.checkDefaultValue(custom.field, custom.value, col);
				});
		});

		if (this.colSorting) {
			if (this.parent.initialized) this.parent.onSortChange();
		} else if (this.cols.some(x => x.sort === 'asc' || x.sort === 'desc')) {
			let colSort = this.cols.find(x => x.sort === 'asc' || x.sort === 'desc');
			this.colSorting = {
				col: colSort,
				asc: colSort.sort === 'asc',
			};
			colSort.sort = null;
			if (this.parent.initialized) this.parent.onSortChange();
		}

		if (this.parent) this.parent.definePaddingTop();

		this.onBodyWidthChange();
	}

	public getColByField(field: string) {
		return this.cols.find(col => {
			if (col.field) return col.field === field;
			else if (col.customFilter) return (col.customFilter as any[]).some(x => x.field === field);
			return false;
		});
	}

	public getColFilterModeByField(field: string) {
		let col = this.getColByField(field);
		if (col) {
			if (col.field) return col.mode;
			else if (col.customFilter) {
				let customFilter = (col.customFilter as any[]).find(x => x.field === field);
				if (customFilter) return customFilter.mode;
			}
		}

		return SvcFilterMode.CONTAINS;
	}

	public getFormDataModel() {
		let formValue = this.frmFilter.value as { [key: string]: any };
		let dataModel = {};

		for (let field in formValue) {
			let value = formValue[field];
			if (!isNullOrUndefined(value) && value !== '') dataModel[field] = value;
		}

		return dataModel;
	}

	public onBodyWidthChange() {
		let currentWidth =
			this.parent && this.parent.body && this.parent.body.el ? this.parent.body.el.clientWidth + 'px' : null;
		if (currentWidth && this.el) {
			let currentWidthNumber = this.helper.onlyNumberAndToFloat(currentWidth);
			let minWidthIsDefined = !!(this.parent && this.parent.minWidth);
			let headerWidth = this.el.clientWidth;

			if (minWidthIsDefined) {
				this.el.style.width = currentWidth;
				headerWidth = this.parent.el.clientWidth;
			} else this.el.style.width = '';

			if (this.cols.length > 0) {
				this.cols.forEach(col => col.setWidth());

				let lastCol = this.cols[this.cols.length - 1];

				if (headerWidth > currentWidthNumber) {
					this.el.style.paddingRight = minWidthIsDefined ? '' : headerWidth - currentWidthNumber + 'px';
					lastCol.el.style.paddingRight = '0px';
				} else {
					this.el.style.paddingRight = '';
					lastCol.el.style.paddingRight = '';
				}
			}
		}
	}
}
