import { EventEmitter, Injectable } from '@angular/core';
import { SvcDialogService } from "../../svc-dialogs/svc-dialog.service";
import {
  FilterDialogResult,
  SvcFilterDialogComponent
} from "../components/svc-filter-dialog/svc-filter-dialog.component";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { SvcFilterConfig } from "../classes/svc-filter-config";
import { ISvcChipGroupedOption, ISvcChipOption } from "../../svc-controls/svc-chip/interfaces/svc-chip.interface";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SvcFilterDialogService {

  public onFilterDefined = new EventEmitter<FilterDialogResult>();

  private dialogRef: MatDialogRef<SvcFilterDialogComponent>;
  private componentInstance: SvcFilterDialogComponent;
  private afterClosedSubscription: Subscription;

  constructor(
    private dialog: SvcDialogService
  ) { }

  openFilterModal(filterConfig: SvcFilterConfig, dialogConfig?: MatDialogConfig) {
    this.dialogRef = this.dialog.open(SvcFilterDialogComponent, {
      maxWidth: '1145px',
      width: '90%',
      maxHeight: '97vh',
      panelClass: 'p-4',
      ...(dialogConfig ?? {}),
      data: filterConfig
    });

    this.afterClosedSubscription = this.dialogRef.afterClosed().subscribe((r) => {
      this.afterClosedSubscription.unsubscribe();
      if (!r) r = { reason: 'cancel' }
      this.onFilterDefined.emit(r);
    });

    this.componentInstance = this.dialogRef.componentInstance;
    return this.componentInstance;
  }

  closeFilterModal() {
    this.dialogRef.close();
  }
  setFilterConfig(filterConfig: SvcFilterConfig) {
    this.componentInstance?.setFilterConfig(filterConfig);
  }

  setFilterOptions(filterName: string, options: ISvcChipOption[] | ISvcChipGroupedOption[]) {
    this.componentInstance?.setFilterOptions(filterName, options);
  }

  setFilterDisabled(filterName: string, disabled: boolean) {
    this.componentInstance?.setFilterDisabled(filterName, disabled);
  }

  setLoading(loading: boolean) {
    if (this.componentInstance?.config) {
      this.componentInstance.config.loading = loading;
    }
  }

}
