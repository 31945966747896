export enum StaticApplicationId {
  authentication = '',
  praise = '6258E8CD-F2FB-429E-AE16-1C01C3F633A8',
  communication = '709CF739-3D63-4ACE-BCA2-722A6401E8EE',
  myworkspace = '5555C35C-5B5C-441B-9D92-34C3B34CD13D',
  digitalObeya = 'BCE987A3-58DD-4830-B9E4-08A9DE7A0A07',
  posts = '655EC1FF-9A03-43A4-8EF8-D035F4DD8EAC',
  survey = '41CE2D11-957D-40F9-9D3E-9BAF32496CDF',
  alert = '8B5B3C53-7A21-4A1D-AA02-CDCB068D9C48',
  users = 'CB9FF60D-44A6-4B01-8461-CB797FB80FF2',
  rca = 'E4878F2F-9A3C-4114-AB94-31B6A04C022A',
  bos = 'E7E53D89-96D5-474D-AB7C-CAC76EE99F62',
  quiz = '7A58FFEB-E343-451F-AA32-BE7735A95FEC',
  multilingual = '63FDFFA2-19E6-42D5-977B-BB5F734B06BD',
  general = '9D306CEF-9C6B-416A-A077-82E295EDDF53',
  unsafeCondition = 'CE583F03-E4D5-498E-8D20-593C7169790A',
}
