import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcButtonsModule } from 'projects/lib-shared-component/src/public-api';
import { NotFound404Component } from './404.component';

@NgModule({
  declarations: [
    NotFound404Component
  ],
  imports: [
    RouterModule,
    LibSharedCommonModule,
    SvcButtonsModule,
    TranslocoModule
  ],
  exports: [
    NotFound404Component
  ]
})
export class NotFound404Module { }
