import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceActionplanAnalyticsService } from './lib-service-actionplan-analytics.service';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [],
  providers: [
    ModuleHttpClient,
    LibServiceActionplanAnalyticsService,
  ]
})
export class LibServiceActionplanAnalyticsModule { }
