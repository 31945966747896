import { ISvcStatusBadge } from "projects/lib-shared-component/src/public-api";
import { CategorizedDrillDown } from "projects/lib-shared-model/src/public-api";

export interface DefectTagDrillDownItem {
    defectTagId: number;
    defect: string;
    inclusionDate: Date;
    status: number;
    owners?: any;
    deadline: string;
}

export class DefectTagCategorizedDrillDown implements CategorizedDrillDown<DefectTagDrillDownItem> {
    indicatorEnum: number;
    indicatorItems: DefectTagDrillDownItem[];
}

export enum DefectTagDrillDownStatus {
  PLANNED = 3, // Planejado
  DELAYED = 6, // Planejado
  SOLVED = 2, // Resolvido
}

export const DefectTagDrillDownStatusBadge = <DefectTagDrillDownStatus>(
    enumDefinition: DefectTagDrillDownStatus
  ): ISvcStatusBadge => {
    switch (enumDefinition) {
      case DefectTagDrillDownStatus.SOLVED:
        return { description: 'Resolvida', type: 'resolved' };
      case DefectTagDrillDownStatus.PLANNED:
        return { description: 'Planejada', type: 'planned-without-delay' };
      case DefectTagDrillDownStatus.DELAYED:
        return { description: 'Planejada', type: 'delayed' };
    }
  }
