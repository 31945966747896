/**
 * Custom palettes
 *
 * Uses the generatePalette helper method to generate
 * Tailwind-like color palettes automatically
 */
const customPalettes = {
    svc_default: {
      50: '#6b78ca',
      100: '#5c6ac4',
      200: '#4151b6',
      300: '#374498',
      400: '#2c367a',
      500: '#21295c',
      600: '#121733',
      700: '#030409',
      800: '#000000',
      900: '#000000'
    },
    svc_00274C: {
        50: '#e6e9ed',
        100: '#ccd4db',
        200: '#99a9b7',
        300: '#667d94',
        400: '#335270',
        500: '#00274c',
        600: '#001f3d',
        700: '#00172e',
        800: '#00101e',
        900: '#00080f'
    },
    svc_003366: {
        50: '#e6ebf0',
        100: '#ccd6e0',
        200: '#99adc2',
        300: '#6685a3',
        400: '#335c85',
        500: '#003366',
        600: '#002952',
        700: '#001f3d',
        800: '#001429',
        900: '#000a14'
    },
    svc_003399: {
        50: '#e6ebf5',
        100: '#ccd6eb',
        200: '#99add6',
        300: '#6685c2',
        400: '#335cad',
        500: '#003399',
        600: '#00297a',
        700: '#001f5c',
        800: '#00143d',
        900: '#000a1f'
    },
    svc_005F86: {
        50: '#e6eff3',
        100: '#ccdfe7',
        200: '#99bfcf',
        300: '#669fb6',
        400: '#337f9e',
        500: '#005f86',
        600: '#004c6b',
        700: '#003950',
        800: '#002636',
        900: '#00131b'
    },
    svc_0F58D6: {
        50: '#e7eefb',
        100: '#cfdef7',
        200: '#9fbcef',
        300: '#6f9be6',
        400: '#3f79de',
        500: '#0f58d6',
        600: '#0c46ab',
        700: '#093580',
        800: '#062356',
        900: '#03122b'
    },
    svc_161718: {
        50: '#e8e8e8',
        100: '#d0d1d1',
        200: '#a2a2a3',
        300: '#737474',
        400: '#454546',
        500: '#161718',
        600: '#121213',
        700: '#0d0e0e',
        800: '#09090a',
        900: '#040505'
    },
    svc_3A8DD2: {
        50: '#ebf4fb',
        100: '#d8e8f6',
        200: '#b0d1ed',
        300: '#89bbe4',
        400: '#61a4db',
        500: '#3a8dd2',
        600: '#2e71a8',
        700: '#23557e',
        800: '#173854',
        900: '#0c1c2a'
    },
    svc_512072: {
        50: '#eee9f1',
        100: '#dcd2e3',
        200: '#b9a6c7',
        300: '#9779aa',
        400: '#744d8e',
        500: '#512072',
        600: '#411a5b',
        700: '#311344',
        800: '#200d2e',
        900: '#100617'
    },
    svc_7B7521: {
        50: '#eee9f1',
        100: '#dcd2e3',
        200: '#b9a6c7',
        300: '#9779aa',
        400: '#744d8e',
        500: '#512072',
        600: '#411a5b',
        700: '#311344',
        800: '#200d2e',
        900: '#100617'
    },
    svc_830051: {
        50: '#f3e6ee',
        100: '#e6ccdc',
        200: '#cd99b9',
        300: '#b56697',
        400: '#9c3374',
        500: '#830051',
        600: '#690041',
        700: '#4f0031',
        800: '#340020',
        900: '#1a0010'
    },
    svc_E4002B: {
        50: '#fce6ea',
        100: '#faccd5',
        200: '#f499aa',
        300: '#ef6680',
        400: '#e93355',
        500: '#e4002b',
        600: '#b60022',
        700: '#89001a',
        800: '#5b0011',
        900: '#2e0009'
    },
    svc_E5A812: {
        50: '#fcf6e7',
        100: '#faeed0',
        200: '#f5dca0',
        300: '#efcb71',
        400: '#eab941',
        500: '#e5a812',
        600: '#b7860e',
        700: '#89650b',
        800: '#5c4307',
        900: '#2e2204'
    },
    svc_F3F3F3: {
        50: '#fefefe',
        100: '#fdfdfd',
        200: '#fafafa',
        300: '#f8f8f8',
        400: '#f5f5f5',
        500: '#f3f3f3',
        600: '#c2c2c2',
        700: '#929292',
        800: '#616161',
        900: '#313131'
    },
    svc_FAF5EB: {
        50: '#fffefd',
        100: '#fefdfb',
        200: '#fdfbf7',
        300: '#fdfbf7',
        400: '#fbf7ef',
        500: '#faf5eb',
        600: '#c8c4bc',
        700: '#96938d',
        800: '#64625e',
        900: '#32312f'
    },
    svc_FEFEFE: {
        50: '#ffffff',
        100: '#ffffff',
        200: '#ffffff',
        300: '#fefefe',
        400: '#fefefe',
        500: '#fefefe',
        600: '#cbcbcb',
        700: '#989898',
        800: '#666666',
        900: '#333333'
    },
    svc_FF7900: {
        50: '#fff2e6',
        100: '#ffe4cc',
        200: '#ffc999',
        300: '#ffaf66',
        400: '#ff9433',
        500: '#ff7900',
        600: '#cc6100',
        700: '#994900',
        800: '#663000',
        900: '#331800'
    },
    svc_FFD30F: {
        50: '#fffbe7',
        100: '#fff6cf',
        200: '#ffed9f',
        300: '#ffe56f',
        400: '#ffdc3f',
        500: '#ffd30f',
        600: '#cca90c',
        700: '#997f09',
        800: '#665406',
        900: '#332a03'
    },
    svc_006400: {
        50: '#e0ece0',
        100: '#b3d1b3',
        200: '#80b280',
        300: '#4d934d',
        400: '#267b26',
        500: '#006400',
        600: '#005c00',
        700: '#005200',
        800: '#004800',
        900: '#003600'
    },
};

module.exports = customPalettes;
