import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { SitesService, UserService } from "projects/lib-shared-core/src/public-api";
import { finalize, forkJoin, map, Subject, Subscription, tap } from "rxjs";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SvcControl } from 'projects/lib-shared-component/src/lib/svc-controls/svc-control';
import { QuillEditorComponent } from "ngx-quill/lib/quill-editor.component";
import { PostService } from "../../services/post.service";
import QuillType, { DeltaStatic, Quill } from 'quill';
import "quill-mention";
import { TranslocoService } from "@ngneat/transloco";
import _ from 'lodash';
import { PostApplicationRegistry } from '../../models/post-application-registry';
import {
  generateModuleMenuURL,
  openAspRegistryModalByAppId, openNetCoreRegistryModalByAppId
} from 'projects/lib-shared-common/src/lib/functions/external-url-redirect';
import { getAccessTokenPayloadInfo } from 'projects/lib-shared-common/src/public-api';

const Embed = QuillType.import('blots/embed');

const Block = QuillType.import('blots/block');
Block.tagName = 'div';
QuillType.register(Block);

const MentionBlot = QuillType.import('blots/mention');
MentionBlot.tagName = 'div';
QuillType.register(MentionBlot);

class SeeMoreButton extends Embed {
  static create(value) {
    const node = super.create();
    node.innerText = value;
    return node;
  }
}
SeeMoreButton.blotName = 'seeMoreEmbed';
SeeMoreButton.tagName = 'div';
SeeMoreButton.className = 'mention-see-more';
QuillType.register(SeeMoreButton);

class RegisterLinkButton extends Embed {
  static create(value: any) {
    const node = super.create();
    node.innerText = value.text;
    node.addEventListener('click', function(e) {
      e.stopImmediatePropagation();
      if (typeof value.open === 'function')
        value.open();
    });
    return node;
  }
}
RegisterLinkButton.blotName = 'registerLinkEmbed';
RegisterLinkButton.tagName = 'div';
RegisterLinkButton.className = 'mention-register-link';
QuillType.register(RegisterLinkButton);

const LINE_CLAMP_MAX_LENGTH = 300;

@Component({
  selector: 'svc-post-mention',
  templateUrl: './svc-post-mention.component.html',
  styleUrls: ['./svc-post-mention.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcPostMentionComponent),
    multi: true
  }]
})
export class SvcPostMentionComponent extends SvcControl implements AfterViewInit, OnDestroy {

  @Input() readOnly: boolean;
  @Input() placeholder: string;
  @Input() cursorForce: boolean;
  @Input() lineClamp: boolean;
  @Input() inputId: string;
  @Input() applicationRegistry: PostApplicationRegistry;

  @ViewChild('editor') editor: QuillEditorComponent;
  @Output() selectTerm = new EventEmitter<any>();

  mentions: any[];

  private _unsubscribe$ = new Subject<void>();
  private _modalOpen = true;
  private _mentionSubscription: Subscription;
  private _mentionIsLoading = false;
  private _isApplyingLineClamp = false;

  constructor(
    private _userService: UserService,
    private _postService: PostService,
    private _sitesService: SitesService,
    private _injector: Injector,
    private transloco: TranslocoService,
  ) {
    super(_injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this._modalOpen = false;
    const containerToRemove = document.querySelector('.ql-mention-list-container');
    if (containerToRemove) containerToRemove.remove();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  modules = {
    toolbar: false,
    clipboard: {
      matchVisual: false
    },
    mention: {
      minChars: 0,
      maxChars: 31,
      dataAttributes: ['id', 'value', 'denotationChar'],
      positioningStrategy: 'fixed',
      defaultMenuOrientation: 'bottom',
      allowedChars: /(^[A-zÀ-ÿ0-9]+)/,
      mentionDenotationChars: ["@", "#"],
      isolateCharacter: true,
      mentionListClass: 'mention-editor-list',
      onSelect: (item: any, insertItem: any) => {
        let mention = this.mentions.find(m => m.id && m.id.toString() === item.id.toString())
        if (mention) {
          mention.denotationChar = item.denotationChar;
          this.selectTerm.emit(mention);
          insertItem(mention)
        }
      },
      renderLoading: () => {
        return this.transloco.translate('Digite ao menos 3 caracteres');
      },
      source: async (searchTerm: string, renderList: any, mentionChar: string) => {
        this._mentionSubscription?.unsubscribe();

        if (searchTerm.length < 3) {
          this._mentionIsLoading = false;
          this.setIsQuillMentionLoading();
          return;
        }

        this._mentionIsLoading = true;
        this.setIsQuillMentionLoading();
        if (mentionChar === "@") {
          this._mentionSubscription = this.mentionUserAndTeam(searchTerm).pipe(
            tap(results => {
              const mentions = [];
              const teamUsersGrouped = _.groupBy([
                ...results.usersResult.users as any,
                ...results.teamsResult.teams as any,
              ], 'site');
              for (const site of Object.keys(teamUsersGrouped).sort((a) => a === 'Multi-Tenance' ? 1 : -1)) {
                const list = teamUsersGrouped[site];
                mentions.push({
                  value: this.transloco.translate(site == 'Multi-Tenance' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                const grouped = _.groupBy(list, 'type');
                for (const type in grouped) {
                  const list = grouped[type];
                  mentions.push({
                    value: this.transloco.translate(type == 'user' ? 'Usuários' : 'Times'),
                    disabled: true,
                    target: 'level-2'
                  });
                  list.forEach((d) => mentions.push({
                    id: d.userId || d.teamId,
                    value: d.name,
                    type: type == 'user' ? 1 : 2,
                  }));
                }
              }
              this.mentions = mentions;
              renderList(this.mentions, searchTerm);
            }),
            finalize(() => this._mentionIsLoading = false)
          ).subscribe()
        } else {
          this._mentionSubscription = this._postService.mentionHashtag(searchTerm).pipe(
            tap(list => {
              const mentions = [];
              const hashtagsGrouped = _.groupBy(list.hashtags, 'isGlobal');
              for (const isGlobal in hashtagsGrouped) {
                const list = hashtagsGrouped[isGlobal];
                mentions.push({
                  value: this.transloco.translate(isGlobal == 'true' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                list.forEach((h) => mentions.push({
                  id: h.hashtagId,
                  value: h.name.replace('#', ''),
                  siteId: h.siteId
                }));
              }
              this.mentions = mentions;
              renderList(this.mentions, searchTerm);
            }),
            finalize(() => this._mentionIsLoading = false)
          ).subscribe()
        }
      }
    }
  }

  private setIsQuillMentionLoading() {
    const mentionLoading = document.querySelector('.ql-mention-list-container .ql-mention-loading');
    if (mentionLoading) {
      if (this._mentionIsLoading) {
        mentionLoading.innerHTML = this.transloco.translate('Pesquisando...');
      }
      else {
        mentionLoading.innerHTML = this.transloco.translate('Digite ao menos 3 caracteres');
      }
    }
  }

  get allMentions(): {
    id: any;
    value: string;
    denotationChar: string;
    isGlobal?: boolean;
    type?: string;
    siteId?: string;
  }[] {
    return this.deltas.filter(d => d.insert.hasOwnProperty('mention'))
      .map(d => d.insert.mention)
  }

  get text() {
    return this.editor.quillEditor.getText();
  }

  get html() {
    return this.editor.quillEditor.root.innerHTML;
  }
  set html(html) {
    this.editor.quillEditor.root.innerHTML = html;
  }

  get deltas(): DeltaStatic {
    return this.editor.quillEditor.getContents();
  }

  set deltas(deltas: DeltaStatic) {
    this.editor.quillEditor.setContents(deltas, 'silent');
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
  }

  contentChanged(event) {
    if (this.readOnly && this.lineClamp && !this._isApplyingLineClamp) {
      const text: string = event.editor.getText() ?? '';
      const seeMoreText = this.transloco.translate('Ver mais');
      let length = LINE_CLAMP_MAX_LENGTH;
      if (event.editor.getLength() > length && (!text.endsWith('...') && !text.endsWith('...\n') && !text.endsWith(seeMoreText) && !text.endsWith(`${seeMoreText}\n`))) {
        this._isApplyingLineClamp = true;
        length++;
        while((length - 1) < text.length && /[A-zÀ-ÿ0-9]/g.test(text[length - 1])) {
          length++;
        }
        event.editor.deleteText(length, event.editor.getLength());
        let currentOpsContents = (event.editor.getContents()?.ops ?? []) as any[];
        currentOpsContents = currentOpsContents.map((c, i) => ({
          ...c,
          insert: i === (currentOpsContents.length - 1) ? c.insert.replace(/\n$/g, '') : c.insert,
        }))
        event.editor.setContents({
          ops: [
            ...currentOpsContents.filter((c, i) => {
              return i === (currentOpsContents.length - 1) && c.insert === '\n' ? false : true;
            }),
            { insert: '...' },
            { insert: '\n' },
          ],
        });
        event.editor.insertEmbed(event.editor.getLength() - 1, 'seeMoreEmbed', seeMoreText);
        this._isApplyingLineClamp = false;
      }
    }
    if (this.applicationRegistry && !event.content.ops[0].insert.hasOwnProperty('registerLinkEmbed')) {
      event.editor.insertEmbed(0, 'registerLinkEmbed', {
        text: `${this.transloco.translate(this.applicationRegistry.applicationName)}-${this.applicationRegistry.applicationRegistryReferenceId}`,
        open: () => {
          this.openRegistry();
        },
      });
    }
  }

  private mentionUserAndTeam(name: string) {
    return forkJoin({
      usersResult: this._userService.getUsersInfo({
        name: name,
        active: true,
        pageIndex: 0,
        pageSize: 99
      }),
      teamsResult: this._userService.getTeamsInfo({
        name: name,
        active: true,
        pageIndex: 0,
        pageSize: 99
      })
    }).pipe(
      map((response) => {
        const currentSite = this._sitesService.currentSite;
        return {
          teamsResult: {
            page: response.teamsResult.page,
            teams: response.teamsResult.teams.map(t => ({
              type: 'team',
              teamId: t.teamId,
              name: t.name,
              siteId: t.siteId,
              site: t.siteName !== currentSite.siteName ? 'Multi-Tenance' : t.siteName
            })),
          },
          usersResult: {
            users: response.usersResult.users.map((u) => ({
              type: 'user',
              ...u,
              site: u.site !== currentSite.siteName ? 'Multi-Tenance' : u.site
            })),
          },
        };
      }),
    );
  }

  private openRegistry() {

    if (this.applicationRegistry.applicationId.toUpperCase() === 'F232DDBA-C1C9-4BE1-9B49-FFDE33E63F1B') {
      // kaizen
      openAspRegistryModalByAppId(this.applicationRegistry.applicationRegistryReferenceId, this.applicationRegistry.applicationId, { siteId: this.applicationRegistry.siteId.toString() });
    } else {
      if (this._userService.user$.originSiteId === this.applicationRegistry.siteId) {
        // se for da planta
        openAspRegistryModalByAppId(this.applicationRegistry.applicationRegistryReferenceId, this.applicationRegistry.applicationId, { siteId: this.applicationRegistry.siteId.toString() });
      } else {
        // se nao for da planta
        if (this.applicationRegistry.applicationId.toUpperCase() === '469494BE-C7E2-4DA1-8A43-C9BE6B68B0ED') {
          openNetCoreRegistryModalByAppId('/controltower/', `{controller=OPL||action=Search||variables={redirectId=${this.applicationRegistry.applicationRegistryReferenceId}}}`, null, this.applicationRegistry.applicationId)
        }
        if (this.applicationRegistry.applicationId.toUpperCase() === '6F0234AA-49CE-4FFB-B1A9-AC8F1F507875') {
          openNetCoreRegistryModalByAppId('/controltower/', `{controller=RCA||action=Search||variables={redirectId=${this.applicationRegistry.applicationRegistryReferenceId}}}`, null, this.applicationRegistry.applicationId)
        }
      }
    }
  }

}
