import {AfterContentInit, AfterViewInit, Component, EventEmitter, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SvcFilterConfig} from "../../classes/svc-filter-config";
import {SvcFiltersComponent} from "../../svc-filters.component";
import {ISvcChipGroupedOption, ISvcChipOption} from "../../../svc-controls/svc-chip/interfaces/svc-chip.interface";
import { SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { Subject, fromEvent, takeUntil, tap } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';

export interface FilterDialogResult {
  reason: 'define' | 'clear' | 'cancel';
  filter: SvcFiltersComponent;
}

@Component({
  selector: 'svc-filter-dialog',
  templateUrl: './svc-filter-dialog.component.html',
  styleUrls: ['./svc-filter-dialog.component.scss']
})
export class SvcFilterDialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild(SvcFiltersComponent) public svcFilters: SvcFiltersComponent;

  public onFilterValueChanged = new EventEmitter();
  public onFilterChanged = new EventEmitter();

  public isFormNotFilled = true;
  public config: SvcFilterConfig;
  public gridTemplateColumnsStyleValue: string;
  private detroySubject = new Subject();

  private _defaultConfig: SvcFilterConfig = {
    title: 'Busca detalhada'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public configData: SvcFilterConfig,
    public dialogRef: MatDialogRef<SvcFilterDialogComponent>,
    public svcMediaQuery: SvcMediaQuery,
  ) {
    this.setFilterConfig(configData);
    fromEvent(window, 'resize').pipe(
      takeUntil(this.detroySubject),
      tap(() => this.setDynamicTemplateColumnsGrid()),
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this._fieldsChanged();
  }

  _fieldsChanged() {
    this.isFormNotFilled = !this.svcFilters?.fields?.some?.(field => {
      if (typeof field.value === "boolean") return true;
      return field?.value?.length > 0
    });
    this.onFilterChanged.emit(this.svcFilters?.fields);
  }

  _valueChanged($event: any) {
    this.onFilterValueChanged.emit($event);
  }

  getFilter(filterName: string) {
    const index = this.config.filtersField.findIndex(i => i.name === filterName);
    return (index >= 0) ? this.config.filtersField[index] :  null;
  }

  getControl(filterName: string) {
    const index = this.svcFilters.fields.findIndex(i => i.name === filterName);
    return (index >= 0) ? this.svcFilters?.fields[index]?.control :  null;
  }

  setFilterConfig(config: SvcFilterConfig) {
    this.config = {...this._defaultConfig, ...config};
    this.setDynamicTemplateColumnsGrid();
  }

  setFilterOptions(filterName: string, options: ISvcChipOption[] | ISvcChipGroupedOption[]) {
    const filter = this.getFilter(filterName);
    if (filter) filter.config.options = options;
  }

  setFilterDisabled(filterName: string, disabled: boolean) {
    const filter = this.getFilter(filterName);
    if (filter) filter.disabled = disabled;
    const control = this.getControl(filterName);
    if (control) disabled ? control.disable() : control.enable();
  }

  clearFilters() {
    this.svcFilters.clearFiltersField();
    this.dialogRef.close({reason: 'clear', filter: this.svcFilters} as FilterDialogResult);
  }

  defineFilter() {
    this.svcFilters?.markAllFieldAsTouched();
    if (this.svcFilters?.isValid) {
      this.dialogRef.close({reason: 'define', filter: this.svcFilters} as FilterDialogResult);
    }
  }

  cancelFilter() {
    this.dialogRef.close({reason: 'cancel', filter: this.svcFilters} as FilterDialogResult);
  }

  private getColumnsGrid(columns: number): string {
    return `repeat(${columns}, minmax(0, 1fr))`;
  }

  private setDynamicTemplateColumnsGrid(): void {
    const columnMappings = {
      isXS: {
        breakpoint: 0,
        columns: this.config.fieldsPerRow?.xs ?? 1,
      },
      isSM: {
        breakpoint: 600,
        columns: this.config.fieldsPerRow?.sm ?? 2,
      },
      isMD: {
        breakpoint: 960,
        columns: this.config.fieldsPerRow?.md ?? 3,
      },
      isLG: {
        breakpoint: 1280,
        columns: this.config.fieldsPerRow?.lg ?? 3,
      },
      isXL: {
        breakpoint: 1440,
        columns: this.config.fieldsPerRow?.xl ?? 3,
      },
    };
    const sizeScreen = Object.keys(columnMappings).reverse().find((condition: string) => window.innerWidth >= columnMappings[condition].breakpoint);
    const columnsSize: string = this.getColumnsGrid((columnMappings[sizeScreen] || columnMappings.isXS).columns);
    this.gridTemplateColumnsStyleValue = columnsSize;;
  }

  public ngOnDestroy() {
    this.detroySubject?.next(null);
  }
}
