import { Component } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { timer } from 'rxjs';

import { SvcToastGamifiedConfig } from './interfaces/svc-toast-gamified.interface';
import { toastGamifiedEnterBounceAnimation, toastGamifiedFadeOutAnimation, toastGamifiedSlideToTopAnimation } from './animations/gamification-animations';
import { SvcToastGamifiedService } from './services/svc-toast-gamified.service';

@Component({
  selector: 'svc-toast-gamified',
  templateUrl: './svc-toast-gamified.component.html',
  styleUrls: ['./svc-toast-gamified.component.scss'],
  animations: [
    toastGamifiedEnterBounceAnimation,
    toastGamifiedSlideToTopAnimation,
    toastGamifiedFadeOutAnimation
  ]
})
export class SvcToastGamifiedComponent {

  public toastConfig: SvcToastGamifiedConfig;
  public toastToTop = false;
  public toastIntoTop = false;
  public toastLayoutTop = false;

  constructor (
    private _toastGamifiedService: SvcToastGamifiedService
  ) { }

  public callToastToTopAnimation(): void {
    if (!this.toastToTop) {
      this.toastToTop = true;

      timer(200).subscribe({
        next: () => this.toastLayoutTop = true
      });
    }
  }

  public updatePositionToast(animationEvent: AnimationEvent): void {
    if (animationEvent?.toState === 'slideToTop') {
      this._toastGamifiedService.adjustToastGamifiedPosition();
      this.toastIntoTop = true;
    }
  }

  public closeToast(): void {
    this._toastGamifiedService.closeToastGamified(this.toastConfig?.points);
  }

  public animationFinished(animationEvent: AnimationEvent): void {
    if (animationEvent?.toState === 'fadeOut')
      this.closeToast();
  }
}
