import { Pipe, PipeTransform } from "@angular/core";
import { RuleConfiguration, RuleConfigurationType } from '../models/rule-configuration.model';

@Pipe({
  name: 'configType'
})
export class ConfigurationTypePipe implements PipeTransform {

  transform(nameOrId: string | number, ruleConfig: RuleConfiguration): RuleConfigurationType {
    return nameOrId ? ruleConfig.types.find(x =>
      typeof nameOrId === 'number'
        ? x.configurationId === nameOrId
        : x.typeName === nameOrId
    ) : null;
  }
}
