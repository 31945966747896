import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';
import { SvcSelectComponent } from './svc-select.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    SvcSelectComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcValidationMessageModule,
    TranslocoModule
  ],
  exports: [
    SvcSelectComponent
  ]
})
export class SvcSelectModule { }
