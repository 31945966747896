import { Component, ContentChildren, Directive, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output, QueryList, Renderer2, TemplateRef, forwardRef } from '@angular/core';

export interface SvcWarappedLayoutSorting<T> {
  currentIndex: number;
  previousIndex: number;
  items: {
    before: T;
    after: T;
  };
  cancelFn: () => void;
}

@Directive({ selector: '[wrapped-layout-item]' })
export class SvcWrappedLayoutItem implements OnInit {
  @HostBinding('class.wrapped-layout-item') class = true;
  @HostBinding('class.is-sorting') isSorting = false;
  @HostBinding('class.is-current-target') isTarget = false;

  @Input() public ignoreClick: any;

  public element: HTMLElement;
  public get index(): number {
    return this.parent.itemsElements.indexOf(this);
  }
  public get draggingIndex(): number {
    return this.parent.itemsElements.indexOf(this.parent.draggingItemElement);
  }

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(forwardRef(() => SvcWarappedLayoutComponent)) public parent: SvcWarappedLayoutComponent,
  ) { }

  public ngOnInit() {
    this.element = this.elementRef?.nativeElement;
    if (this.ignoreClick && this.ignoreClick instanceof Element) {
      this.renderer.listen(this.ignoreClick, 'mousedown', (e: MouseEvent) => e.stopImmediatePropagation());
    }
  }

  public isInside(target: HTMLElement) {
    return this.element == target || this.element?.contains(target);
  }

  public isOnTop(draggedElement: HTMLElement): { occupiedAreaPercent: number, positioned?: 'left' | 'right' } {
    const draggingBounds = draggedElement.getBoundingClientRect();
    const targetBounds = this.element.getBoundingClientRect();
    const checkIfIsOn = (dBounds: DOMRect, tBounds: DOMRect): { occupiedAreaPercent: number, positioned?: 'left' | 'right' } => {
      const totalArea = dBounds.width * dBounds.height;
      // is inside
      if ((dBounds.left >= tBounds.left && dBounds.right <= tBounds.right && dBounds.top >= tBounds.top && dBounds.bottom <= tBounds.bottom) ||
        dBounds.left <= tBounds.left && dBounds.right >= tBounds.right && dBounds.top <= tBounds.top && dBounds.bottom >= tBounds.bottom) {
        return { occupiedAreaPercent: 100, positioned: 'left' };
      }
      // is on by RB and LT
      if (dBounds.right > tBounds.left && dBounds.right <= tBounds.right && dBounds.bottom > tBounds.top && dBounds.bottom <= tBounds.bottom) {
        const occupiedArea = (dBounds.right - tBounds.left) * (dBounds.bottom - tBounds.top);
        return { occupiedAreaPercent: (occupiedArea * 100) / totalArea, positioned: 'left' };
      }
      // is on by RT and LB
      if (dBounds.right > tBounds.left && dBounds.right <= tBounds.right && dBounds.top >= tBounds.top && dBounds.top < tBounds.bottom) {
        const occupiedArea = (dBounds.right - tBounds.left) * (tBounds.bottom - dBounds.top);
        return { occupiedAreaPercent: (occupiedArea * 100) / totalArea, positioned: 'left' };
      }
      // is on by LT and RB
      if (dBounds.left < tBounds.right && dBounds.left >= tBounds.left && dBounds.top >= tBounds.top && dBounds.top < tBounds.bottom) {
        const occupiedArea = (tBounds.right - dBounds.left) * (dBounds.bottom - tBounds.top);
        return { occupiedAreaPercent: (occupiedArea * 100) / totalArea, positioned: 'right' }
      }
      // is on by LB and RT
      if (dBounds.left < tBounds.right && dBounds.left >= tBounds.left && dBounds.bottom > tBounds.top && dBounds.bottom <= tBounds.bottom) {
        const occupiedArea = (tBounds.right - dBounds.left) * (tBounds.bottom - dBounds.top);
        return { occupiedAreaPercent: (occupiedArea * 100) / totalArea, positioned: 'right' }
      }
      // is on but with leftovers in left and right
      if (dBounds.left <= tBounds.left && dBounds.right >= tBounds.right && ((dBounds.bottom <= tBounds.bottom && dBounds.bottom > tBounds.top) || (dBounds.top >= tBounds.top && dBounds.top < tBounds.bottom))) {
        const occupiedArea = dBounds.width * (Math.min(tBounds.bottom, dBounds.bottom) - Math.max(tBounds.top, dBounds.top));
        return {
          occupiedAreaPercent: (occupiedArea * 100) / totalArea,
          positioned: (tBounds.left - dBounds.left) > (dBounds.right - tBounds.right) ? 'left' : 'right',
        }
      }
      // is on but with leftovers in top and bottom
      if (dBounds.top <= tBounds.top && dBounds.bottom >= tBounds.bottom && ((dBounds.right <= tBounds.right && dBounds.right > tBounds.left) || (dBounds.left >= tBounds.left && dBounds.left < tBounds.right))) {
        const occupiedArea = dBounds.height * (Math.min(tBounds.right, dBounds.right) - Math.max(tBounds.left, dBounds.left));
        return {
          occupiedAreaPercent: (occupiedArea * 100) / totalArea,
          positioned: (dBounds.left - tBounds.left) < (tBounds.right - dBounds.right) ? 'left' : 'right',
        }
      }
      return { occupiedAreaPercent: 0 };
    }

    const isOnChecked = checkIfIsOn(draggingBounds, targetBounds);

    if (isOnChecked.positioned === 'left' && (this.index - 1) === this.draggingIndex) {
      isOnChecked.positioned = 'right';
    }
    if (isOnChecked.positioned === 'right' && (this.index + 1) === this.draggingIndex) {
      isOnChecked.positioned = 'left';
    }

    return isOnChecked;
  }

  public setAsTheTarget() {
    this.isTarget = true;
  }

  public unsetAsTheTarget() {
    this.isTarget = false;
  }
}

@Component({
  selector: 'svc-wrapped-layout',
  templateUrl: './svc-wrapped-layout.component.html',
  styleUrls: ['./svc-wrapped-layout.component.scss'],
  host: {
    '(window:mousedown)': 'onMouseDown($event)',
    '(window:mousemove)': 'onMouseMove($event)',
    '(window:mouseup)': 'onMouseUp($event)',
  },
})
export class SvcWarappedLayoutComponent implements OnInit {

  @HostBinding('class.is-sorting') get isSortingClass() {
    return this.sort;
  }

  @ContentChildren(SvcWrappedLayoutItem) private queryItemsElement: QueryList<SvcWrappedLayoutItem>;

  @Input('items') public items: any[] = [];
  @Input('sort') public sort = true;
  @Output() public onSortingChanged = new EventEmitter<SvcWarappedLayoutSorting<any>>();

  public draggingItemElement: SvcWrappedLayoutItem = null;
  private draggingElementClone: HTMLElement = null;
  private draggingState: 'START' | 'DRAGGING' | 'NONE' = 'NONE';
  public draggingDirection: 'left' | 'right' = null;
  private draggingPositions = {
    initX: 0,
    initY: 0,
    currentX: 0,
    currentY: 0,
    distanceX: 0,
    distanceY: 0,
  };
  public draggingOnItemElement: SvcWrappedLayoutItem = null;
  public draggingOnItemPositioned: 'left' | 'right' = null;

  public get itemsElements() {
    return this.queryItemsElement?.toArray();
  }
  public get element() { return this.elementRef?.nativeElement; }

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private rederer: Renderer2,
  ) { }

  ngOnInit() {

  }

  public onMouseDown(event: MouseEvent) {
    if (this.sort) {
      if (event.button === 0 && this.draggingState === 'NONE' && this.draggingItemElement == null) {
        const targetEl = event.target as HTMLElement;
        const component = this.itemsElements?.find(e => e.isInside(targetEl));
        if (component) {
          this.draggingItemElement = component;
          this.draggingState = 'START';
          this.draggingItemElement.isSorting = true;
          this.draggingPositions = {
            initX: event.clientX,
            initY: event.clientY,
            currentX: event.clientX,
            currentY: event.clientY,
            distanceX: 0,
            distanceY: 0,
          };
          this.prepareCloneElement();
        }
      }
    }
  }

  public onMouseMove(event: MouseEvent) {
    if (this.draggingState !== 'NONE') {
      this.draggingState = 'DRAGGING';
      this.draggingPositions.currentX = event.clientX;
      this.draggingPositions.currentY = event.clientY;
      this.updateCloneElement();
      this.checekIfDraggingIsOnAnyItem();
    }
  }

  public onMouseUp(event: MouseEvent) {
    if (this.draggingState !== 'NONE') {
      if (this.draggingOnItemElement) {
        this.doTheReorder();
        this.setNewDraggingOn();
      }
      this.draggingState = 'NONE';
      this.draggingItemElement.isSorting = false;
      this.draggingItemElement = null;
      this.element?.removeChild(this.draggingElementClone);
      this.draggingElementClone = null;
    }
  }

  private prepareCloneElement() {
    this.draggingElementClone = this.draggingItemElement.element.cloneNode(true) as HTMLElement;
    this.draggingElementClone.classList.add('is-dragging');
    this.draggingElementClone.style.transform = 'translate(0,0)';
    this.draggingElementClone.style.left = `${this.draggingItemElement.element.offsetLeft}px`;
    this.draggingElementClone.style.top = `${this.draggingItemElement.element.offsetTop}px`;
    this.element.appendChild(this.draggingElementClone);
  }

  private updateCloneElement() {
    const newDistanceX = this.draggingPositions.initX - this.draggingPositions.currentX;
    const newDistanceY = this.draggingPositions.initY - this.draggingPositions.currentY;
    this.draggingDirection = Math.abs(newDistanceX) < Math.abs(this.draggingPositions.distanceX) ? 'left' : 'right';
    this.draggingPositions.distanceX = newDistanceX;
    this.draggingPositions.distanceY = newDistanceY;
    this.draggingElementClone.style.transform = `translate(${this.draggingPositions.distanceX * -1}px,${this.draggingPositions.distanceY * -1}px)`;
  }

  private checekIfDraggingIsOnAnyItem() {
    if (this.itemsElements.length > 1) {
      let onItem: { item: SvcWrappedLayoutItem, occupiedAreaPercent: number, positioned?: 'left' | 'right' } = null
      for (const item of this.itemsElements) {
        if (item === this.draggingItemElement) continue;
        const onTop = item.isOnTop(this.draggingElementClone);
        if (onTop.occupiedAreaPercent > 0 && onTop.occupiedAreaPercent > (onItem?.occupiedAreaPercent ?? 0)) {
          onItem = { item, ...onTop };
        }
      }
      if (onItem) {
        this.setNewDraggingOn(onItem);
        return;
      }
    }
    this.setNewDraggingOn();
  }

  private setNewDraggingOn(onData: { item: SvcWrappedLayoutItem, positioned?: 'left' | 'right' } = null) {
    this.draggingOnItemPositioned = onData?.positioned;
    if (this.draggingOnItemElement !== onData?.item) {
      this.draggingOnItemElement?.unsetAsTheTarget();
      this.draggingOnItemElement = onData?.item;
      this.draggingOnItemElement?.setAsTheTarget();
    }
  }

  private doTheReorder() {
    const initialItems = this.items;
    const fromIndex = this.itemsElements.indexOf(this.draggingItemElement);
    if (fromIndex >= 0) {
      const fromItem = this.items[fromIndex];
      let toIndex = [...this.itemsElements.slice(0, fromIndex), ...this.itemsElements.slice(fromIndex + 1)].indexOf(this.draggingOnItemElement);
      if (this.draggingOnItemPositioned === 'right') {
        toIndex += 1;
      }
      let newItems = this.items;
      newItems = [...newItems.slice(0, fromIndex), ...newItems.slice(fromIndex + 1)];
      newItems = [...newItems.slice(0, toIndex), fromItem, ...newItems.slice(toIndex)];
      this.items = newItems;
      this.onSortingChanged.emit({
        currentIndex: toIndex,
        previousIndex: fromIndex,
        items: {
          before: initialItems,
          after: this.items,
        },
        cancelFn: () => this.items = initialItems,
      });
    }
  }
}
