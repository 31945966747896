import { environment } from "projects/environments/environment";
import { iOSDevice } from "./os-detection";


export function getModuleMenuType(type: string): string {
    switch (true) {
        case (type === 'ANGULAR'):
            return 'link';
        case (type === 'EXTERNAL'):
        case (type === 'ASP'):
        case (type === 'CORE'):
        case (type === 'MESSAGE'):
            return 'function';
        default:
            return '';
    }
}

export function generateModuleMenuURL(type: string, url: string, route: string, params: string): Function | string {
    if (type === 'ANGULAR') {
        return `${route}${params}`;
    }

    else if (type === 'EXTERNAL') {
        url = `${url}${route}?${(Math.round(Math.random() * 1000))}`;
        return () => externalURL(url, params);
    }

    else if (type === 'CORE') {
        url = `${url}${route}?${(Math.round(Math.random() * 1000))}`;
        return () => externalNetcoreURL(url, params);
    }

    else if (type === 'ASP') {
        let paramsObj: any = {};
        let paramsToAppend: string[] = [];
        if (params) {
            paramsToAppend = params.split('&');
            paramsToAppend.forEach((item) => {
                let p = item.split('=');
                paramsObj = { ...paramsObj, [p[0]]: p[1] };
            });
        }
        url = `${url}${route}?${(Math.round(Math.random() * 1000))}`;
        return () => externalCommonAspURL(url, paramsObj);
    }

    else if (type === 'MESSAGE') {
        let paramsObj = JSON.parse(params);
        return () => externalLegacyCodeMessage(route, paramsObj);
    }

    else {
        return () => { return false; }
    }
}

export function generateApplicationURL(languageCode: string, url: string): Function {
    switch (languageCode) {
        case 'C#':
            return () => externalNetcoreURL(url, '');
        case 'TS':
            return () => externalAngularURL(url, '');
        case 'EXTERNAL':
            return () => externalURL(url, '');
        case 'ASP':
            return () => externalCommonAspURL(url);
        default:
            return () => simpleRedirectURL(url);
    }
}

export function simpleRedirectURL(url: string) {
    window.open(url, '_blank');
}

export function externalNetcoreURL(url: string, params: string, newTab: boolean = true) {
    const myRedirectForm = document.createElement('form');
    myRedirectForm.method = 'post';

    if (newTab && !iOSDevice())
        myRedirectForm.target = '_blank';

    myRedirectForm.action = url;
    myRedirectForm.style.display = 'none';

    const myHiddenField = document.createElement('input');
    myHiddenField.type = 'hidden';
    myHiddenField.name = 'url';
    myHiddenField.value = params;
    myRedirectForm.appendChild(myHiddenField);

    document.body.appendChild(myRedirectForm);
    myRedirectForm.submit();
    myRedirectForm.remove();
}

export function externalURL(url: string, params: string) {
    window.open(url + '/' + params, '_blank');
}

export function externalAngularURL(url: string, params: string) {
    window.open(url + '/' + params, '_blank');
}

export function externalURLSamePageWithoutHistory(url: string, params: string){
    window.location.replace(url + '/' + params);
}

export function externalCommonAspURL(url: string, params?: { system?: string, idMenu?: string, redirectId?: string, systemMenu?: string, customMenu?: string, parameters?: string }, newTab: boolean = true) {
    const myRedirectForm = document.createElement('form');
    myRedirectForm.method = 'post';

    if (newTab && !iOSDevice())
        myRedirectForm.target = '_blank';

    myRedirectForm.action = url;
    myRedirectForm.style.display = 'none';

    const myHiddenField1 = document.createElement('input');
    myHiddenField1.type = 'hidden';
    myHiddenField1.name = 'system';
    myHiddenField1.value = params?.system ?? '';
    myRedirectForm.appendChild(myHiddenField1);

    const myHiddenField2 = document.createElement('input');
    myHiddenField2.type = 'hidden';
    myHiddenField2.name = 'redirectId';
    myHiddenField2.value = params?.redirectId ?? '';
    myRedirectForm.appendChild(myHiddenField2);

    const myHiddenField3 = document.createElement('input');
    myHiddenField3.type = 'hidden';
    myHiddenField3.name = 'idMenu';
    myHiddenField3.value = params?.idMenu ?? '';
    myRedirectForm.appendChild(myHiddenField3);

    const myHiddenField4 = document.createElement('input');
    myHiddenField4.type = 'hidden';
    myHiddenField4.name = 'systemMenu';
    myHiddenField4.value = params?.systemMenu ?? '';
    myRedirectForm.appendChild(myHiddenField4);

    const myHiddenField5 = document.createElement('input');
    myHiddenField5.type = 'hidden';
    myHiddenField5.name = 'customMenu';
    myHiddenField5.value = params?.customMenu ?? '';
    myRedirectForm.appendChild(myHiddenField5);

    const myHiddenField6 = document.createElement('input');
    myHiddenField6.type = 'hidden';
    myHiddenField6.name = 'parameters';
    myHiddenField6.value = params?.parameters ?? '';
    myRedirectForm.appendChild(myHiddenField6);

    document.body.appendChild(myRedirectForm);
    myRedirectForm.submit();
    myRedirectForm.remove();
}

export function externalNetcoreLoginURL(url: string, user: any) {
    const myRedirectForm = document.createElement('form');
    myRedirectForm.method = 'post';
    myRedirectForm.action = url;
    myRedirectForm.style.display = 'none';

    const myHiddenField = document.createElement('input');
    myHiddenField.type = 'hidden';
    myHiddenField.name = 'user';
    myHiddenField.value = JSON.stringify(user);
    myRedirectForm.appendChild(myHiddenField);

    document.body.appendChild(myRedirectForm);
    myRedirectForm.submit();
    myRedirectForm.remove();
}

export function externalLegacyCodeMessage(type: string, options?: any) {
    // @ts-ignore
    _sendMessage(type, options);
}

//
/**
 *
 * @param redirectId
 * @param applicationId
 * @example ```openAspRegistryModalByAppId(708, 'F232DDBA-C1C9-4BE1-9B49-FFDE33E63F1B')```
 */
export function openAspRegistryModalByAppId(redirectId: number, applicationId: string, extraParams?: { [key: string]: string }) {
    let functionName: string;
    let urlToOpen: string;

    switch(applicationId?.toUpperCase()) {
        case 'F232DDBA-C1C9-4BE1-9B49-FFDE33E63F1B': { // Kaizen
            urlToOpen = `/systems/melhorias/melhoria.asp?melhoriaId=${redirectId}`;
            break;
        }
        case '5584AE33-331E-4C89-8FE8-569ED7AC3733': { // BOS
            urlToOpen = `/systems/soc/soc_registro.asp?id=${redirectId}`;
            break;
        }
        case 'C7275610-3233-4D97-9829-F8ACB9AC0B3E': { // Alertas
            urlToOpen = `/systems/alertas/alert_register.asp?alertId=${redirectId}`;
            break;
        }
        case '469494BE-C7E2-4DA1-8A43-C9BE6B68B0ED': { // RCA
            urlToOpen = `/systems/sa3/sa3_registro.asp?SA3Id=${redirectId}`;
            break;
        }
        case '6F0234AA-49CE-4FFB-B1A9-AC8F1F507875': { // OPL - LUP
            urlToOpen = `systems/lup/lup_registro.asp?lupId=${redirectId}`;
            break;
        }
        default: return;
    }

    if (extraParams) {
        const paramsString = Object.entries(extraParams)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        urlToOpen += `&${paramsString}`;
    }

    const modalParams = `{
        "functionName": "${functionName ?? 'OpenRegModal'}",
        "urlToOpen": "${urlToOpen}"
    }`;

    if (environment.isLocalhost) return;

    const urlFn = generateModuleMenuURL('MESSAGE', location.origin, 'openModal', modalParams);

    if (typeof urlFn === 'function') urlFn();
}

/**
 *
 * @param modulePathName
 * @param paramsStr
 * @param applicationId
 * @example ```openNetCoreRegistryModalByAppId('/actionplan/', '{controller=Plan||action=Search||variables={redirectId=54}}', null, '85AF27F3-2FBB-46A1-AECE-2460C22EB14A')```
 */
export function openNetCoreRegistryModalByAppId(modulePathName: string, paramsStr: string, customRedirectId: number, applicationId: string) {
    const paramObj: { controller: string, action: string, variables: { redirectId: number } } = (() => {
        paramsStr = paramsStr.replace(/^\{|}$/g, '');
        return paramsStr.split('||').reduce((obj, keyValue) => {
            const key = keyValue.split('=')[0];
            let value = keyValue.split('=').slice(1).join('=');
            if (/^\{|}$/.test(value)) {
                value = value.replace(/^\{|}$/g, '');
                value = value.split('||').reduce((obj, keyValue) => {
                    const key = keyValue.split('=')[0];
                    const value = keyValue.split('=').slice(1).join('=');
                    obj[key] = value;
                    return obj;
                }, {}) as any;
            }
            obj[key] = value;
            return obj;
        }, {}) as any;
    })();

    let functionName: string;
    let modalId: string;
    let flEdit: string;
    let flDraft: string;
    let referenceId: string;

    const id = customRedirectId ?? paramObj.variables.redirectId ?? 0;

    switch(applicationId?.toUpperCase()) {
        case '85AF27F3-2FBB-46A1-AECE-2460C22EB14A': { // PlanoDeAção
            modalId = '#registry-action-plan-modal';
            referenceId = `"planId": ${id},`;
            flEdit = '"flEdit":false,';
            flDraft = '"flDraft":false';
            break;
        }
        case 'EE2159DE-9D30-41DA-A213-18984790C824': { // Etiquetas
            modalId = '#registry-mainregister-modal';
            referenceId = `"dft_id": ${id},`;
            flEdit = '"flEdit":false';
            break;
        }
        case '79F3ECF2-57F7-412E-80A0-7CF04172CBCF': { // CondiçãoInsegura
            modalId = '#registry-mainregister-modal';
            referenceId = `"unc_id": ${id},`;
            flEdit = '"flEdit":true,';
            flDraft = '"flDraft":false';
            break;
        }
        case '1CE147DD-5EF2-498A-841F-D2E1D8AFB242': { // LIL
            modalId = '#registry-inspection-modal';
            referenceId = `"inspectionId": ${id}`;
            break;
        }
        case 'FEC43BCC-A058-4E00-957A-DE04A811DAD3': { // Checklist
            modalId = '#registry-inspection-modal';
            referenceId = `"inspectionId": ${id}`;
            break;
        }
        case '920A3353-2E10-4B30-AD6E-6CF85A1F1357': { // CenterLine
            modalId = '#registry-inspection-modal';
            referenceId = `"inspectionId": ${id}`;
            break;
        }
        default: return;
    }

    const modalParams = `{
        "functionName": "${functionName ?? 'OpenDynamicModal'}",
        "urlToOpen": "${modulePathName}${paramObj.controller}/View",
        "modalData": {
            ${referenceId ?? ('"systemReferenceId":' + paramObj.variables?.redirectId ?? 0)}
            ${flEdit ?? ''}
            ${flDraft ?? ''}
        },
        "modalId": "${modalId}",
        "modalTimeout": 0
    }`;

    if (environment.isLocalhost) return;

    const urlFn = generateModuleMenuURL('MESSAGE', location.origin, 'openModal', modalParams);

    if (typeof urlFn === 'function') urlFn();
}

export function isItValidExternalModuleToOpenInTheModal(applicationId: string) {
    return [
        '85AF27F3-2FBB-46A1-AECE-2460C22EB14A', // --PlanoDeAção
        'EE2159DE-9D30-41DA-A213-18984790C824', // --Etiquetas
        'F232DDBA-C1C9-4BE1-9B49-FFDE33E63F1B', // --Kaizen
        '5584AE33-331E-4C89-8FE8-569ED7AC3733', // --BOS
        '79F3ECF2-57F7-412E-80A0-7CF04172CBCF', // --CondiçãoInsegura
        'C7275610-3233-4D97-9829-F8ACB9AC0B3E', // --Alertas
        '1CE147DD-5EF2-498A-841F-D2E1D8AFB242', // --LIL
        'FEC43BCC-A058-4E00-957A-DE04A811DAD3', // --Checklist
        '920A3353-2E10-4B30-AD6E-6CF85A1F1357', // --CenterLine
    ].includes(applicationId?.toUpperCase());
}
