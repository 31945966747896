import {Directive, Input, OnInit} from "@angular/core";
import {SvcButtonType} from "../models/svc-button-type";
import {ThemePalette} from "@angular/material/core";

@Directive()
export abstract class ButtonBase implements OnInit {
  /**
   * @deprecated Use content projection instead of this property
   */
  @Input() label: string;

  @Input() color: ThemePalette | 'on-primary' = 'primary';

  @Input() type: SvcButtonType = 'button';

  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  @Input() disabled: boolean = false;

  @Input() processing: boolean = false;

  @Input() fullWidth: boolean = false;

  ngOnInit(): void {
  }

}
