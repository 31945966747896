export class SvcDraggingReorderEvent<T> {
    public currentIndex: number;
    public previousIndex: number;
    public items: {
		before: T[],
		after: T[]
	};
	private cancelFn: () => void;

    constructor(obj: SvcDraggingReorderEventPrepare<T>) {
        Object.assign(this, obj);
	}
	
	public cancel(): void {
		this.cancelFn();
	}
}

interface SvcDraggingReorderEventPrepare<T> {
    currentIndex: number;
    previousIndex: number;
    items: {
		before: T[],
		after: T[]
	},
	cancelFn: () => void
}
