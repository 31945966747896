<div
  class="toast-gamification"
  @toastGamifiedEnterBounce
  [class.toast-gamification-top]="toastIntoTop"
  [class.toast-layout-top]="toastLayoutTop"
  [@toastGamifiedSlideToTop]="toastToTop ? 'slideToTop' : 'void'"
  [@toastGamifiedFadeOut]="toastIntoTop ? 'fadeOut' : 'void'"
  (@toastGamifiedEnterBounce.done)="callToastToTopAnimation()"
  (@toastGamifiedSlideToTop.done)="updatePositionToast($event)"
  (@toastGamifiedFadeOut.done)="animationFinished($event)"

>
  <div class="rewards flex items-center justify-center">
    <img src="assets/gamification/rewards-icon.svg" [alt]="'Ícone de recompensa da gamificação' | transloco">
  </div>

  <div class="toast-content flex flex-col gap-1 text-white w-full">
    <div class="points flex items-center justify-between gap-1">
      <p class="font-bold">
        + {{ toastConfig?.points | formatNumberDefault }} {{ 'pontos' | transloco }}
      </p>

      <button mat-icon-button (click)="closeToast()">
        <mat-icon class="text-white icon-size-8" svgIcon="heroicons_solid:x-mark">
        </mat-icon>
      </button>
    </div>

    <p class="message">{{ toastConfig?.message | transloco }}</p>
  </div>
</div>
