import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcProcessComponent } from './svc-process.component';
import {RouterModule} from "@angular/router";
import {processRoutes} from "./svc-process.routing";
import {SvcButtonsModule, SvcDialogsModule} from 'projects/lib-shared-component/src/public-api';
import {TranslocoModule} from "@ngneat/transloco";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    SvcProcessComponent
  ],
  exports: [
    SvcProcessComponent
  ],
  imports: [
    LibSharedCommonModule,
    TranslocoModule,
    SvcDialogsModule,
    RouterModule.forChild(processRoutes),
    MatDialogModule,
    SvcButtonsModule,
  ]
})
export class SvcProcessModule { }
