<div *ngIf="loading">
  <mat-progress-spinner
    [diameter]="18"
    color="primary"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>

<div *ngIf="!loading && text">
  <span class="text-xs text-secondary">{{ 'Texto traduzido' | transloco}}</span>
  <svc-post-mention
    [(ngModel)]="text"
    [readOnly]="true"
    [cursorForce]="true"
    [lineClamp]="false"
  ></svc-post-mention>
</div>

<!-- Traduzir -->
<div *ngIf="!loading">
  <a class="text-primary font-semibold"
    [ngStyle]="{cursor: 'pointer'}"
    (click)="translateText($event)"
     [id]="'post-translate-'+postId"
  >{{'Traduzir' | transloco}}</a>
</div>
