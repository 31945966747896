import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';

import { SvcDatePickerComponent } from './svc-date-picker.component';


@NgModule({
  declarations: [
    SvcDatePickerComponent
  ],
  exports: [
    SvcDatePickerComponent
  ],
  imports: [
    LibSharedCommonModule, 
    LibSharedComponentModule,
    SvcValidationMessageModule
  ]
})
export class SvcDatePickerModule { }
