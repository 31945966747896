import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding, ElementRef } from '@angular/core';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { MatSelectChange } from '@angular/material/select';

export const PAGINATE_OPTIONS = [5, 10, 30, 50, 150, 250, 500];

@Component({
	selector: 'svc-data-paginate',
	templateUrl: './svc-data-paginate.component.html',
	styleUrls: ['./svc-data-paginate.component.scss'],
})
export class SvcDataPaginateComponent implements OnInit, OnChanges {
	@HostBinding('class.svc-data-paginate') public class: boolean = true;
	
	public options: number[] = PAGINATE_OPTIONS;

	@Input() public totalPages: number = 0;
	@Input() public totalRegisters: number = 0;
	@Input() public current: number = 1;
	@Input() public pageSize: number;
	@Input() public canChangePageSize: boolean = true;
	@Input() public disabled: boolean = false;
	@Input() public loading: boolean = false;
	@Output() public onPageChanged = new EventEmitter<number>();
	@Output() public onPageSizeChanged = new EventEmitter<number>();

	public hasPrevPage: boolean;
	public hasNextPage: boolean;
	public startFrom: number;
	public endFrom: number;
	public from: number;

	public parent: SvcDataTableComponent;

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	constructor(private elRef: ElementRef<HTMLElement>) {
	}

	ngOnInit() {}

	public onRender(parent: SvcDataTableComponent) {
		this.parent = parent;
		this.parent.checkMinWidthCanApply();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.prepareProperties();
	}

	private prepareProperties() {
		this.hasPrevPage = this.current > 1;
		this.hasNextPage = this.current < this.totalPages;
		this.from = this.totalRegisters ?? (this.totalPages * this.pageSize);
		this.startFrom = this.totalRegisters > 0 ? ((this.current - 1) * this.pageSize + 1) : 0;
		this.endFrom = Math.min(((this.current - 1) * this.pageSize) + this.pageSize, this.from);
	}

	public optionChanged(event: MatSelectChange) {
		if (!this.disabled && !this.loading && this.current <= this.totalPages) {
			this.onPageSizeChanged.emit(event.value);
		}
	}

	nextPage() {
		if (!this.disabled && !this.loading && this.hasNextPage) {
			this.current += 1;
			this.prepareProperties();
			this.onPageChanged.emit(this.current);
		}
	}
	prevPage() {
		if (!this.disabled && !this.loading && this.hasPrevPage) {
			this.current -= 1;
			this.prepareProperties();
			this.onPageChanged.emit(this.current);
		}
	}
}
