import { Component, OnInit, HostBinding, ContentChildren, QueryList, AfterViewInit, Input, OnChanges, ElementRef, SimpleChanges, EventEmitter, Output, ViewChild, AfterContentChecked, OnDestroy } from '@angular/core';
import { SvcDataHeaderComponent } from '../svc-data-header/svc-data-header.component';
import { SvcDataBodyComponent } from '../svc-data-body/svc-data-body.component';
import { SvcDataChangeAction } from '../enums/svc-data-change-action.enum';
import { SvcDataTableEvent } from '../models/svc-filter-data.model';
import { SvcDataPrepareService } from '../services/svc-data-prepare.service';
import { SvcDataVirtualScrollService } from '../services/svc-data-virtual-scroll.service';
import { SvcDataRenderEvent } from '../events/svc-data-render.event';
import { SvcDataTableSettingsService } from '../services/settings/svc-data-table-settings.service';
import { PAGINATE_OPTIONS, SvcDataPaginateComponent } from '../svc-data-paginate/svc-data-paginate.component';
import { SvcFunctionsHelper, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';

@Component({
	selector: 'svc-data-table',
	templateUrl: './svc-data-table.component.html',
	styleUrls: ['./svc-data-table.component.scss'],
})
export class SvcDataTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterContentChecked {
	@HostBinding('class.svc-data-table') private class: boolean = true;
	@HostBinding('class.svc-no-pagination') public get itDoesntPaginate() {
		return this.noPagination || this.infinite || this.paginate === null;
	}

	@ContentChildren(SvcDataHeaderComponent) private queryHeader: QueryList<SvcDataHeaderComponent>;
	@ContentChildren(SvcDataBodyComponent) private queryBody: QueryList<SvcDataBodyComponent>;

	@ViewChild('headerShadowEl', { static: false }) private headerShadowEl: ElementRef<HTMLElement>;
	@ViewChild(SvcDataPaginateComponent, { static: false }) private paginateComp: SvcDataPaginateComponent;

	/** THAT WILL DEFINE THE DATA TABLE HEIGHT, AND IT WILL HAS SCROLL BAR IN CASE OF OVERFLOW. */
	@Input() public height: string = 'auto';

	/** IF THE ROWS IT WILL HAVE CLICK (JUST ENABLED HOVER EFFECT AND CURSOR AS POINTER) */
	@Input() public clickable: boolean;

  /** IF THE ROWS HAVE BACKGROUND COLOR ON MOUSE OVER */
  @Input() public hoverable: boolean;

	/** THIS DEFINE IF WILL GO BRING DATA BASED ON SCROLLING WHEN TO ARRIVE ON BOTTOM. (BUT USE THIS ONLY WITH SERVER-SIDE) */
	@Input() public infinite: number = 0;

	/** SHOW LOADING SPINNER ABOVE THE DATA TABLE WHEN LOADING IS TRUE. */
	@Input() public loading: boolean = null;

	/** THE TOTAL DATA LENGTH. THIS IS USED FOR MAKE THE PAGINATION. */
	@Input() public dataLength: number = 0;

	/** DETERMINES WHETHER WILL CONSUME DATA FROM SERVER */
	@Input('server-side') public serverSide: boolean = false;

	/** CALL REQUEST DATA ON INITIALIZATION DATA TABLE */
	@Input('get-data-init') public getDataInit: boolean = true;

	/** SET THE CURRENT PAGE WHEN USE PAGINATION */
	@Input() public currentPage: number = 1;

	/** SET IF IT WILL PAGINATE OR NOT */
	@Input() public noPagination: boolean = false;

	/** SET IF CAN CHANGE PAGE SIZE OR NOT */
	@Input() public canChangePageSize: boolean = true;

	/** Notifies the table when the server returned all data. */
	@Input() public dataAreOver: boolean = false;

	/** Sets a minimum width in ag-table. When the minimum width is reached, the side table will scroll horizontally. */
	@Input() public minWidth: string = '';

	/** Sets a minimum height in ag-table. */
	@Input() public minHeight: string = '';

	@Input('items') allItems: any[] = [];

	/** WILL FIND FIRST PARENT ELEMENT THAT HAS SCROLLING AND USE IT TO CONTROL THE VIRTUAL SCROLL (DON'T USE DYNAMIC HEIGHT) */
	@Input() useParentScroll: boolean = false;

	/** CALL/EMIT EVENT TO GET DATA (SERVER-SIDE) */
	@Output() public onGetData = new EventEmitter<SvcDataTableEvent>();

	/** EMIT EVENT WHEN ITEMS HAVEN BEEN RENDERED */
	@Output() public onDataRender = new EventEmitter<SvcDataRenderEvent<any>>();

	/** EMIT EVENT WHEN SB-DATA-TABLE HAS BEEN FULLY RENDERED */
	@Output() public onTableRender = new EventEmitter<void>();

	@Output() public filterActivated = new EventEmitter<boolean>();

	public paginate: number = PAGINATE_OPTIONS[1];

	private get paddingTop() {
		return this.el ? this.el.style.paddingTop : '0px';
	}
	private set paddingTop(value: string) {
		if (this.el) this.el.style.paddingTop = value;
	}

	public set _height(value: string) {
		this.el.style.height = value;

		if (this.header) {
			if (value === '0' || value === '0px') this.header.visible = false;
			else this.header.visible = true;
		}

		this.definePaddingTop();
	}
	public get _height() {
		return this.el && this.el.style.height ? this.el.style.height : 'auto';
	}

	public set _minHeight(value: string) {
		this.el.style.minHeight = value;
		this.definePaddingTop();
	}
	public get _minHeight() {
		return this.el && this.el.style.minHeight ? this.el.style.minHeight : '';
	}

	public items: any[] = [];
	public filteredItems: any[] = [];

	private _filterIsActivated: boolean = false;
	public get filterIsActivated(): boolean {
		return this._filterIsActivated;
	}
	public set filterIsActivated(value: boolean) {
		this._filterIsActivated = value;
		this.filterActivated.emit(value);
	}

	private _parentScrollingElement: HTMLElement = null;
	public get parentScrollingElement(): HTMLElement {
		if (this.useParentScroll) {
			if (!this._parentScrollingElement) {
				let parent = this.el?.parentElement;
				while (parent) {
					if (parent.scrollHeight > parent.clientHeight) {
						this._parentScrollingElement = parent;
						break;
					}
					parent = parent.parentElement;
				}
			}
			return this._parentScrollingElement;
		}
		return null;
	}

	public get header() {
		return this.queryHeader ? this.queryHeader.first : null;
	}
	public get body() {
		return this.queryBody ? this.queryBody.first : null;
	}

	public get topShadow() {
		if (this.height !== 'auto') {
			let body = this.body ? this.body.el : null;
			let header = this.header ? this.header.el : null;
			if (body && header) return body.scrollTop >= header.clientHeight / 2;
		}
		return false;
	}

	public get bottomShadow() {
		if (this.height !== 'auto') {
			let body = this.body ? this.body.el : null;
			let header = this.header ? this.header.el : null;
			if (body && header) return body.scrollTop + body.clientHeight < body.scrollHeight;
		}
		return false;
	}

	public get isPaging() {
		return this.paginate;
	}

	public get isOrdering() {
		return this.header && this.header.colSorting;
	}

	public get isFiltering() {
		return this.header && this.header.cols ? this.header.cols.some(x => x.canFilter) : false;
	}

	public get numPages() {
		if (this.dataLength && this.isPaging) {
			let pages = Math.floor(this.dataLength / this.paginate);
			return pages + (this.dataLength / this.paginate > pages ? 1 : 0);
		} else return 0;
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	public get colSorting() {
		return this.header.colSorting;
	}

	public initialized: boolean = false;

	public isDataEmpty: boolean = false;

	public dataPaginatedLength: number = 0;
	public actionChange: SvcDataChangeAction = SvcDataChangeAction.INITIALIZE;

	private DOMisVisible: boolean = false;
	private DOMcountVisibleChange: number = 0;

	private intervalListenerWidth: any;

	public lastBodyWidth: string = null;
	public lastBodyHeight: string = null;

	constructor(
		public elRef: ElementRef<HTMLElement>,
		private helper: SvcFunctionsHelper,
		private dataPrepareService: SvcDataPrepareService,
		private dataVirtualScrollService: SvcDataVirtualScrollService,
		private settings: SvcDataTableSettingsService
	) {
	}

	ngOnInit() {
		this.ngOnChanges({});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.body.el) {
				this.lastBodyWidth = this.body.el.clientWidth + 'px';
				this.lastBodyHeight = this.body.el.clientHeight + 'px';
			}

			this.body.onRender(this);
			this.header.onRender(this);
			this.paginateComp?.onRender(this);

			this.queryHeader.changes.subscribe(() => this.definePaddingTop());

			this.definePaddingTop();

			setTimeout(() => this.onTableRender.emit());

			if (this.getDataInit) this.emitGetData();

			if (this.infinite && !this.heightIsValid() && !this.useParentScroll)
				console.warn(`The infinite scroll mode not working, because data table height has not been set.`);

			if (!this.infinite && this.body) this.body.backToTheTop();

			this.filterIsActivated = false;
			this.initialized = true;
		});
	}

	ngAfterContentChecked() {
		if (this.elRef && this.elRef.nativeElement) {
			let elem = this.elRef.nativeElement;
			let visible = !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
			if (visible !== this.DOMisVisible) {
				this.DOMisVisible = visible;

				if (this.DOMisVisible && this.DOMcountVisibleChange > 0) this.body.onScroll();

				this.definePaddingTop();
				this.DOMcountVisibleChange++;
			}
		} else this.DOMisVisible = false;

		this.onCheckBodyDimensions();
	}

	ngOnChanges(changes: SimpleChanges) {
		const formOnInit = JSON.stringify(changes) == '{}';
		if ('serverSide' in changes)
			this.serverSide =
				typeof this.serverSide === 'string' && (this.serverSide === '' || this.serverSide === 'true')
					? true
					: this.serverSide;

		if ('clickable' in changes) {
			this.clickable =
				typeof this.clickable === 'string' && (this.clickable === '' || this.clickable === 'true')
					? true
					: this.clickable;
			if (this.body) this.body.clickable = this.clickable;
		}

    if ('hoverable' in changes) {
      this.hoverable =
        typeof this.hoverable === 'string' && (this.hoverable === '' || this.hoverable === 'true')
          ? true
          : this.hoverable;
      if (this.body) this.body.hoverable = this.hoverable;
    }

		if ('useParentScroll' in changes) {
			if (typeof this.useParentScroll === 'string') {
				if (this.useParentScroll === '' || this.useParentScroll === 'true') {
					const contentInner = document.querySelector(this.settings.parentSrollSelector) as HTMLElement;
					this._parentScrollingElement = contentInner ?? document.body;
					this.useParentScroll = true;
				} else {
					this._parentScrollingElement = document.querySelector(this.useParentScroll);
					this.useParentScroll = !!this._parentScrollingElement;
				}
			} else if ((this.useParentScroll as any) instanceof Element) {
				this._parentScrollingElement = (this.useParentScroll as any) as HTMLElement;
				this.useParentScroll = true;
			} else {
				this.useParentScroll = !!this.useParentScroll;
			}
		}

		if ('noPagination' in changes) {
			this.paginate = 999999;
			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
		}

		if ('height' in changes) {
			if (this.height !== 'auto' && !this.heightIsValid()) {
				console.warn(`The height of table is invalid.`);
				this.height = 'auto';
			}

			this._height = this.height;

			this.prepareItemsPagingAndFilter();

			if (!this.infinite && this.body) this.body.backToTheTop();
		}

		if ('minHeight' in changes) {
			if (this.minHeight !== 'auto' && !this.minHeightIsValid()) {
				console.warn(`The minHeight of table is invalid.`);
				this.minHeight = '';
			}

			this._minHeight = this.minHeight;

			this.prepareItemsPagingAndFilter();

			if (!this.infinite && this.body) this.body.backToTheTop();
		}

		if (formOnInit || 'minWidth' in changes) {
			if (!this.minWidthIsValid()) {
				console.warn(`The min-width of table is invalid.`);
				this.minWidth = '';
			}

			if (!this.minWidthIsValid()) {
				this.stopListenerWidth();
			} else {
				this.startListenerWidth();
			}
			this.checkMinWidthCanApply();
		}

		if ('currentPage' in changes) {
			if (typeof this.currentPage === 'string') {
				if (!isNaN(parseInt(this.currentPage))) this.currentPage = parseInt(this.currentPage);
				else {
					console.warn(
						`The current-page value of table is invalid. The current-page value has been defined as 1.`
					);
					this.currentPage = 1;
				}
			}

			if (typeof this.currentPage !== 'number' || this.currentPage <= 0) {
				console.warn(
					`The current-page value of table is invalid. The current-page value has been defined as 1.`
				);
				this.currentPage = 1;
			}

			if (this.currentPage > this.paginate && this.currentPage < 1) this.currentPage = 1;

			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
		}

		if ('infinite' in changes) {
			if (typeof this.infinite === 'string') {
				if (!isNaN(parseInt(this.infinite))) this.infinite = parseInt(this.infinite);
				else {
					console.warn(
						`The [infinite] value of table is invalid. The infinite scroll has not been applied.`
					);
					this.infinite = null;
				}
			}

			if (this.infinite ) {
				this.paginate = null;
			}

			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
			this.definePaddingBottom();
		}

		if ('dataLength' in changes) {
			if (isNullOrUndefined(this.dataLength)) this.dataLength = 0;

			this.definePaddingBottom();
		}

		if ('getDataOnInit' in changes)
			this.getDataInit =
				typeof this.getDataInit === 'string' &&
					(this.getDataInit === '' || this.getDataInit === 'true')
					? true
					: this.getDataInit;

		if ('loading' in changes) {
			setTimeout(() => {
				if (this.body) this.body.scrollEnabled = this.loading;
			});

			if (this._height.replace('px', '') === '0' && this.loading)
				this.elRef.nativeElement.style.height = '50px';

			if (this.header && this.header.frmFilter) {
				if (this.loading) this.header.frmFilter.disable();
				else {
					this.header.enableForm();
				}
			}
		}

		if ('allItems' in changes) {
			if (!this.infinite && this.currentPage !== 1) {
				let maxPages = Math.floor(this.dataLength / this.paginate);
				if (this.dataLength % this.paginate !== 0) maxPages++;

				if (this.currentPage > maxPages || !this.currentPage) {
					this.currentPage = this.currentPage > maxPages ? maxPages : 1;

					if (this.initialized && this.serverSide) {
						this.onPageChange(this.currentPage);
						return;
					}
				}
			}

			let currentItems = changes.allItems.currentValue ?? [];
			let previousItems = changes.allItems.previousValue ?? [];
			let decreasedData = currentItems.length < previousItems.length;

			this.refreshRender({ forceScrollToTop: decreasedData });
		}
	}

	private onCheckBodyDimensions() {
		this.checkBodyWidthChange();
		this.checkBodyHeightChange();
	}

	private checkBodyWidthChange() {
		if (this.el) {
			let currentWidth = '100%';
			if (this.body && this.body.el) currentWidth = this.body.el.clientWidth + 'px';

			if (currentWidth !== this.lastBodyWidth) {
				this.lastBodyWidth = currentWidth;
				this.header.onBodyWidthChange();
				this.definePaddingTop();
			}
		}
	}

	private checkBodyHeightChange() {
		if (this.el && this._height !== 'auto') {
			let currentHeight = this.el.clientHeight + 'px';
			if (currentHeight !== this.lastBodyHeight) {
				this.lastBodyHeight = currentHeight;
				this.refreshRender();
			}
		}
	}

	private definePaddingBottom() {
		if (this.el) {
			let padding = '1px';
			if (!this.itDoesntPaginate) padding = '62px';

			if (padding !== this.el.style.paddingBottom) this.el.style.paddingBottom = padding;
		}
	}

	public definePaddingTop(forceDefine: boolean = false) {
		if (this.el && this.header) {
			let padding = '0px';
			if (this.header && this.header.el && this.header.visible) padding = this.header.height;

			if (forceDefine || padding !== this.elRef.nativeElement.style.paddingTop) {
				this.paddingTop = padding;

				if (this.headerShadowEl && this.headerShadowEl.nativeElement)
					this.headerShadowEl.nativeElement.style.height = padding !== '0px' ? padding : 'auto';
			}
		}
	}

	public heightIsValid() {
		return (
			!isNullOrUndefined(this.height) &&
			typeof this.height === 'string' &&
			this.height !== '' &&
			this.height !== 'auto'
		);
	}

	public minHeightIsValid() {
		return (
			!isNullOrUndefined(this.minHeight) &&
			typeof this.minHeight === 'string' &&
			this.minHeight !== '' &&
			this.minHeight !== 'auto'
		);
	}

	private minWidthIsValid() {
		return (
			!isNullOrUndefined(this.minWidth) &&
			typeof this.minWidth === 'string' &&
			this.minWidth !== '' &&
			this.minWidth !== 'auto'
		);
	}

	private startListenerWidth() {
		this.stopListenerWidth();
		this.intervalListenerWidth = setInterval(() => {
			if (this.elRef && this.minWidthIsValid()) {
				let width = this.el.clientWidth;
				let minWidth = parseInt(this.helper.onlyNumbers(this.minWidth));
				if (width <= minWidth) {
					this.el.style.overflowX = 'auto';
					this.el.style.overflowY = 'hidden';
				} else {
					this.el.style.overflowX = '';
					this.el.style.overflowY = '';
				}
			}
		}, 250);
	}

	private stopListenerWidth() {
		if (this.intervalListenerWidth) {
			clearInterval(this.intervalListenerWidth);
			this.intervalListenerWidth = null;
		}
	}

	public checkMinWidthCanApply(): void {
		if (this.minWidthIsValid()) {
			if (this.el) this.body.el.style.minWidth = this.minWidth;

			if (this.paginateComp && this.paginateComp.el) this.paginateComp.el.style.minWidth = this.minWidth;
		} else {
			if (this.body && this.body.el) this.body.el.style.minWidth = '';

			if (this.paginateComp && this.paginateComp.el) this.paginateComp.el.style.minWidth = '';
		}
	}

	public setDataLength(length: number, paginatedLength: number) {
		this.dataLength = length;
		this.dataPaginatedLength = paginatedLength;
	}

	public onPageChange(currentPage: number, emit: boolean = true) {
		if (this.isPaging) {
			this.notifyChanges(SvcDataChangeAction.PAGINATE);

			if (emit && this.serverSide) {
				this.setCurrentPage(currentPage);
				this.emitGetData();
			} else if (!this.serverSide) {
				this.setCurrentPage(currentPage);
				this.prepareItemsPagingAndFilter()
				this.body.backToTheTop();
			}
		}
	}

	public onPageSizeChange(pageSize: number, emit: boolean = true) {
		if (this.isPaging) {
			this.paginate = pageSize;
			this.onPageChange(1);
		}
	}

	public onSortChange() {
		this.notifyChanges(SvcDataChangeAction.ORDER);

		if (this.serverSide) {
			this.setCurrentPage(1);
			this.emitGetData(this.infinite > 0);
		} else {
			this.prepareItemsPagingAndFilter();
			this.body.backToTheTop();
		}
	}

	public onFilterChange() {
		this.notifyChanges(SvcDataChangeAction.FILTER);

		if (this.header && this.header.cols) {
			this.filterIsActivated = this.header.cols.some(x => x.filterActive);
		}

		if (this.serverSide) {
			this.setCurrentPage(1);
			this.emitGetData(this.infinite > 0);
		} else {
			this.prepareItemsPagingAndFilter();
			this.body.backToTheTop();
		}
	}

	public prepareItemsPagingAndFilter(emit: boolean = true) {
		this.filteredItems = this.dataPrepareService.apply(this.allItems, this);

		if (this.body && this.dataVirtualScrollService.canApplyVirtualScroll(this.body)) {
			this.dataVirtualScrollService.defineItens(this.body);
		} else {
			this.items = this.filteredItems;
			this.isDataEmpty = this.items.length === 0;
			if (emit) {
				this.emitDataRender();
			}
		}
	}

	public emitGetData(resetData: boolean = false) {
		if (resetData) {
			this.currentPage = 1;
			this.dataVirtualScrollService.reset(this.body);
		}

		this.onGetData.emit(
			new SvcDataTableEvent({
				page: this.currentPage,
				pageSize: this.paginate ? this.paginate : this.infinite,
				filters: this.header.getFormDataModel(),
				order: this.colSorting ? { field: this.colSorting.col.field, asc: this.colSorting.asc } : null,
				resetData: resetData,
			})
		);
	}

	public emitDataRender() {
		this.onDataRender.emit(
			new SvcDataRenderEvent<any>({
				items: this.items,
				length: this.items.length,
				startIndex: 0,
				endIndex: this.items.length - 1,
			})
		);
	}

	public setCurrentPage(page: number) {
		this.currentPage = page;
	}

	public notifyChanges(action: SvcDataChangeAction = SvcDataChangeAction.INITIALIZE) {
		this.actionChange = action;
	}

	public resetFilters(getData: boolean = false) {
		for (let ctrlName in this.header.filterCtrls) {
			this.header.filterCtrls[ctrlName].reset();
		}
		if (getData) this.emitGetData(true);
	}

	public refreshRender(options: { forceScrollToTop: boolean } = {} as any) {
		if (this.body) {
			if (!this.body.parent) this.body.parent = this;

			this.dataVirtualScrollService.preparePreviousItemAfterDataChange(this.body);
		}

		this.prepareItemsPagingAndFilter();
		this.definePaddingBottom();

		if (options?.forceScrollToTop && this.body) {
			this.body.backToTheTop();
		}
	}

	public refreshGetData(resetFilters: boolean = true) {
		if (resetFilters) this.resetFilters(true);
		else this.emitGetData(true);
	}

	ngOnDestroy() {
		this.stopListenerWidth();
	}
}
