import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { getAccessTokenPayloadInfo } from 'projects/lib-shared-common/src/public-api';
import { Observable, Subject, catchError, finalize, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AWS_DEFAULT_BACKGROUND_IMAGE, AWS_DEFAULT_LOGO, AWS_DEFAULT_LOGO_NG } from '../../../Constants/AWS-IMAGES';
import { SettingsService } from '../../settings/services/settings.service';
import { IUserAvatar } from "../models/user-avatar";
import { UserHttpClient } from "../user-http-client";
import { User, UserEnvironment } from './user.types';
import { SvcAppSettings } from '../../../settings/svc-app-settings';
import { environment } from 'projects/environments/environment';
import { EnvironmentInfo } from '../../../auth/model/environment.info.model';
import { ParameterService } from '../../apps/services/parameter.service';
import { SvcFeatureToggleService } from '../../../services/svc-feature-toggle/svc-feature-toggle.service';
import { SvcDelimiterType } from '../../../settings/enums/svc-delimiter-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: User;
  private _environment: UserEnvironment;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _translocoService: TranslocoService,
    private _settingsService: SettingsService,
    private _userHttpClient: UserHttpClient,
    private _svcAppSettgins: SvcAppSettings,
    private _parameterService: ParameterService,
    private _featureToggleService: SvcFeatureToggleService,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user = value;
  }

  get user$(): User {
    return this._user;
  }

  /**
 * Setter & getter for user environment
 *
 * @param value
 */
  set environment(value: UserEnvironment) {
    // Store the value
    this._environment = value;
  }

  get environment$(): UserEnvironment {
    return this._environment;
  }

  get userId$(): string {
    return getAccessTokenPayloadInfo('UserId')?.toLocaleLowerCase();
  }

  private _featureToggles: Subject<SvcFeatureToggleService>;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._httpClient
      .get<User>(`${this._appConfig.APIs.apiUrlUsers}/user/session`)
      .pipe(
        tap((user) => {
          this._user = user;
          this._svcAppSettgins.setDateFormat(user.dateFormat);
          this._svcAppSettgins.setDelimiterSymbol(user.delimiter ?? SvcDelimiterType.COMMA);
        }),
        switchMap(user => {
          this._translocoService.setActiveLang(user.languageTag);
          return this._translocoService.load(user.languageTag).pipe(
            switchMap(() => this.getFeatureToggles()),
            map(() => user),
          );
        })
      );
  }

  getFeatureToggles(): Observable<SvcFeatureToggleService> {
    if (!this._featureToggles) {
      this._featureToggles = new Subject<SvcFeatureToggleService>();
      this._parameterService.getGlobalParams(['FEED_ENABLE', 'POST_ENABLE']).pipe(
        tap((res) => {
          const feedIsEnabled = (res.find(p => p.key === 'FEED_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          const postIsEnabled = (res.find(p => p.key === 'POST_ENABLE')?.value ?? 'FALSE').toString().toLocaleLowerCase();
          if (environment.isDEV) this._featureToggleService.setFeedIsEnabled(true);
          else this._featureToggleService.setFeedIsEnabled(typeof feedIsEnabled === 'boolean' ? feedIsEnabled : feedIsEnabled === 'true');
          this._featureToggleService.setPostIsEnabled(typeof postIsEnabled === 'boolean' ? postIsEnabled : postIsEnabled === 'true');
        }),
        catchError(() => {
          this._featureToggleService.setFeedIsEnabled(false);
          this._featureToggleService.setPostIsEnabled(false);
          return of(this._featureToggleService);
        }),
        finalize(() => {
          this._featureToggles.next(this._featureToggleService);
          this._featureToggles.complete();
          this._featureToggles.closed = true;
        }),
      ).subscribe();
    }

    if (this._featureToggles.closed) {
      return of(this._featureToggleService);
    }
    return this._featureToggles.asObservable();
  }

  getEnvironment(): Observable<UserEnvironment> {
    return this._httpClient
      .post<EnvironmentInfo>(`${this._appConfig.APIs.apiUrlAuth}/Environment/info`, {
        environmentUrl: getAccessTokenPayloadInfo('iss'),
      })
      .pipe(
        tap((info) => {
          this._environment = {
            backgroundImage: info.backgroundImage || AWS_DEFAULT_BACKGROUND_IMAGE,
            logoImageNegative: info.logoImageNegative || AWS_DEFAULT_LOGO_NG,
            logoImage: info.logoImage || AWS_DEFAULT_LOGO,
            colorBase: info.colorBase
          };
          this._settingsService.handleThemeChange(`svc-${info.colorBase.replace('#', '').toUpperCase()}`);
        }),
        switchMap(environment => of(environment))
      );
  }

  getCurrencyAcronym() {
    return this._httpClient
    .get<{ currencyName: string, currencySymbol: string }>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/currency`)
    .pipe(
      tap((response) => {
        this._svcAppSettgins.setCurrencyAcronym(response.currencySymbol);
      }),
      catchError(() => {
        return of(this._svcAppSettgins.currencyAcronym);
      }),
    );
  }

  update(user: User): Observable<any> {
    return this._httpClient
      .put<User>(`${this._appConfig.APIs.apiUrlUsers}/UserPreference`, user);
  }

  updateUserImg(userImg: any): Observable<any> {

    let formData: FormData = new FormData();
    formData.append('fileData', userImg)

    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let params = new HttpParams();

    const options = {
      headers: headers,
      params: params,
      reportProgress: true,
    };

    return this._httpClient
      .put(`${this._appConfig.APIs.apiUrlUsers}/userPreference/picture`, formData, options);
  }

  deleteImg(userImg: any) {

    return this._httpClient
      .delete(`${this._appConfig.APIs.apiUrlUsers}/userPreference/picture`, userImg);
  }

  getAvatar(userId: string): Observable<IUserAvatar> {
    return this._userHttpClient.get(`/User/Avatar`, {
      params: {
        userId: userId
      }
    });
  }
  getUsers(active: boolean = true) {
    return this._userHttpClient.get<{
      userId: string,
      name: string,
      active: boolean
    }[]>(
      `/User/names`,
      { params: { active: active } }
    );
  }

  getUsersInfo(params: { pageSize: number, pageIndex: number, name: string, active: boolean }) {
    return this._userHttpClient.get<{
      users: {
        userId: string,
        name: string,
        site: string,
        isGlobal: boolean
      }[],
      page: {
        pageIndex: number,
        pageSize: number,
        pagesTotal: number,
        registersTotal: number
      }
    }>('/User/Info', { params: { ...params } });
  }

  getUsersInfoById(ids: string[]) {
    return this._userHttpClient.get<{
      userId: string,
      name: string,
      site: string,
      isGlobal: boolean
    }[]>('/User/InfoByIds', {
      params: {
        userId: ids
      }
    })
  }

  getTeams(active: boolean = true) {
    return this._userHttpClient.get<{
      groupId: number,
      groupName: string,
      active: boolean
    }[]>('/Groups/names', {
      params: {
        active: active
      }
    }
    );
  }

  getTeamsInfo(params: {
    pageSize: number,
    pageIndex: number,
    name: string,
    active: boolean
  }): Observable<{
    teams: [
      {
        teamId: number,
        name: string,
        siteId: number,
        siteName: string
      }
    ],
    page: {
      pageIndex: number,
      pageSize: number,
      pagesTotal: number,
      registersTotal: number
    }
  }> {
    return this._userHttpClient.get('/Team', { params: { ...params } });
  }

  getTeamInfo(ids: number[]) {
    return this._userHttpClient.get('/Team/InfoByIds', { params: { teamId: ids } })
  }

  getArea(active: boolean = true) {
    return this._httpClient.get<any[]>(
      `${this._appConfig.APIs.apiUrlUsers}/Area/names`,
      { params: { active: active } }
    );
  }

  getEmployeesSite() {
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names`, { params: { active: true } });
  }

  getEmployeesAreas(areaIds: number[]) {
    let params = new HttpParams();
    areaIds.forEach(id => {
      params = params.append('areaIds', id.toString());
    });
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names/areas`, { params });
  }

  getEmployeesTeams(teamIds: number[]) {
    let params = new HttpParams();
    teamIds.forEach(id => {
      params = params.append('teamIds', id.toString());
    });
    return this._httpClient.get<any[]>(`${this._appConfig.APIs.apiUrlUsers}/User/names/teams`, { params });
  }
}
