import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SvcFaqAnswer } from '../svc-faq';
import { SvcFaqService } from '../svc-faq.service';
import { Subscription, catchError, finalize, tap } from 'rxjs';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';

@Component({
  selector: 'svc-faq-answers',
  templateUrl: './svc-faq-answers.component.html',
  styleUrls: ['./svc-faq-answers.component.scss'],
})
export class SvcFaqAnswersComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public answers: SvcFaqAnswer[] = [];

  @ViewChild('audio') public audio: ElementRef<HTMLMediaElement>;

  public current: SvcFaqAnswer;
  public currentIndex: number;
  public currentAudio: {
    base64: string,
    playing?: boolean,
    subscription?: Subscription,
    loading?: boolean,
  };
  public hasNext: boolean;
  public hasPrev: boolean;
  public clientHeight: number = 0;

  public audios: {
    base64: string,
    playing?: boolean,
    subscription?: Subscription,
    loading?: boolean,
  }[] = [];

  private get element(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  constructor(
    private _svcFaqService: SvcFaqService,
    private _httpErrorService: HttpErrorService,
    private _elementRef: ElementRef<HTMLElement>,
  ) { }

  public ngOnInit() {
    if (this.answers.length > 0) {
      this.audios = this.answers.map(() => ({ base64: null }));
      this.current = this.answers.length > 0 ? this.answers[0] : null;
      this.currentAudio = this.audios[0];
      this.currentIndex = this.answers.indexOf(this.current);
      this.current['fileLoading'] = this.current.isImage;
    }
    this.updateComponentProps();
  }

  public ngOnChanges() {
    this.ngOnInit();
  }

  public next() {
    this.currentAudio.subscription?.unsubscribe();
    this.current = this.answers[this.currentIndex + 1];
    this.currentAudio = this.audios[this.currentIndex + 1];
    this.currentIndex = this.answers.indexOf(this.current);
    this.current['fileLoading'] = this.current.isImage;
    this.updateComponentProps();
  }

  public previous() {
    this.currentAudio.subscription?.unsubscribe();
    this.current = this.answers[this.currentIndex - 1];
    this.currentAudio = this.audios[this.currentIndex - 1];
    this.currentIndex = this.answers.indexOf(this.current);
    this.current['fileLoading'] = this.current.isImage;
    this.updateComponentProps();
  }
  
  public updateComponentProps() {
    const currIndex = this.current ? this.answers.indexOf(this.current) : 0;
    this.hasNext = (currIndex + 1)  < this.answers.length;
    this.hasPrev = currIndex > 0;
    setTimeout(() => {
      this.clientHeight = this.element?.clientHeight;
    });
  }

  public getAudio() {
    if (!this.currentAudio.loading) {
      this.currentAudio.loading = true;
      this.currentAudio.subscription = this._svcFaqService.getAudioByAnswerId(this.current.trainingAnswerId).pipe(
        tap((res) => {
          this.currentAudio.base64 = res.base64;
          setTimeout(() => this.playAudio(), 100);
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() => this.currentAudio.loading = false)
      ).subscribe();
    }
  }

  public playAudio() {
    if (this.audio?.nativeElement) {
      this.audio.nativeElement.play();
      this.audio.nativeElement.onended = () => {
        this.stopAudio();
      };
      this.currentAudio.playing = true;
    }
  }

  public pauseAudio() {
    if (this.audio?.nativeElement) {
      this.audio?.nativeElement?.pause();
      this.currentAudio.playing = false;
    }
  }

  public stopAudio() {
    this.pauseAudio();
    if (this.audio?.nativeElement) {
      this.audio.nativeElement.currentTime = 0;
    }
  }

  public ngOnDestroy() {
    this.currentAudio?.subscription?.unsubscribe();
  }
}
