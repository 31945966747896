import { Injectable } from '@angular/core';
import { SvcDataBodyComponent } from '../svc-data-body/svc-data-body.component';
import { SvcDataRenderEvent } from '../events/svc-data-render.event';
import { SvcFunctionsHelper, offsetToParent } from 'projects/lib-shared-common/src/public-api';

@Injectable()
export class SvcDataVirtualScrollService {
	public active: boolean = false;

	constructor(private helper: SvcFunctionsHelper) {}

	public defineItens(body: SvcDataBodyComponent) {
		const vs = body.virtualScroll;
		const table = body.parent;

		const dimensions = this.defineDimensions(body);

		vs.paddingScroll = dimensions.paddingTop;
		vs.totalHeightContent = dimensions.heightAllItems;
		vs.currentStartIndex = dimensions.itemsThatAreGone;
		vs.currentEndIndex = Math.min(vs.currentStartIndex + this.howManyCanAppear(body), table.filteredItems.length);

		table.items = table.filteredItems.slice(vs.currentStartIndex, vs.currentEndIndex);
		table.isDataEmpty = table.items.length === 0;

		table.onDataRender.emit(
			new SvcDataRenderEvent<any>({
				items: table.items,
				length: table.items.length,
				startIndex: vs.currentStartIndex,
				endIndex: vs.currentEndIndex,
			})
		);
	}

	public onScrollChange(body: SvcDataBodyComponent) {
		let vs = body.virtualScroll;
		for (let i = 0; i < body.itemsContainerElRef.nativeElement.children.length; i++) {
			let children = body.itemsContainerElRef.nativeElement.children[i];
			let realIndex = vs.currentStartIndex + i;

			if (!vs.previousItemsHeight[realIndex] || vs.previousItemsHeight[realIndex] !== children.clientHeight) {
				vs.previousItemsHeight[realIndex] = children.getBoundingClientRect().height;
			}
		}

		this.defineItens(body);
	}

	public reset(body: SvcDataBodyComponent) {
		let vs = body.virtualScroll;
		vs.previousItemsHeight = [];
		vs.totalHeightContent = 0;
		vs.paddingScroll = 0;
	}

	defineDimensions(body: SvcDataBodyComponent) {
		let vs = body.virtualScroll;
		let rowHeight = parseInt(this.helper.onlyNumbers(body.rowHeight));
		let firstVisibleRowHeight = rowHeight;

		if (vs.currentStartIndex < vs.previousItemsHeight.length) {
			let height = vs.previousItemsHeight[vs.currentStartIndex];
			firstVisibleRowHeight = height ? height : rowHeight;
		}

		const elementScrolling = vs.containerSrollElRef()?.nativeElement;
		const bodyToParentOffset = body.parent.useParentScroll
			? offsetToParent(body.el, elementScrolling)
			: 0;
		const currentSrollTop = vs.currentSrollTop - bodyToParentOffset;

		let obj = {
			paddingTop: 0,
			itemsThatAreGone: 0,
			heightAllItems: body.parent.filteredItems.reduce((prev, curr, i) => {
				let height = vs.previousItemsHeight[i];
				return prev + (height ? height : rowHeight);
			}, 0),
		};

		if (currentSrollTop >= firstVisibleRowHeight) {
			let paddingTop = 0;
			let itemsThatAreGone = 0;
			let initialScroll = currentSrollTop;

			for (let h of vs.previousItemsHeight) {
				let height = h ? h : rowHeight;
				if (initialScroll >= height) {
					paddingTop += height;
					initialScroll -= height;
					itemsThatAreGone++;
				} else break;
			}

			obj.paddingTop = paddingTop;
			obj.itemsThatAreGone = itemsThatAreGone;
		}

		return obj;
	}

	public preparePreviousItemAfterDataChange(body: SvcDataBodyComponent) {
		let table = body.parent;
		let vs = body.virtualScroll;

		if (!table.infinite) vs.previousItemsHeight = new Array(table.filteredItems.length).fill(null);
		else {
			let anothers = table.filteredItems.length - vs.previousItemsHeight.length;
			if (anothers > 0)
				vs.previousItemsHeight = [...vs.previousItemsHeight, ...new Array<number>(anothers).fill(null)];
		}
	}

	public canApplyVirtualScroll(body: SvcDataBodyComponent) {
		const height = body.parent?._height ?? 'auto';
		let canApply = false;

		if (body.parent) {
			let vs = body.virtualScroll;
			if (height !== 'auto' || body.parent.useParentScroll) {
				canApply = true;
			}
			else if (!body.parent.useParentScroll && vs.height > 0) {
				let rowHeight = parseInt(this.helper.onlyNumbers(body.rowHeight));
				let avaiableScrollHeight = vs.scrollHeight - vs.height;

				if (avaiableScrollHeight > rowHeight) canApply = avaiableScrollHeight / rowHeight >= 1.5;
			}
		}

		this.active = canApply;
		return canApply;
	}

	public howManyCanAppear(body: SvcDataBodyComponent) {
		let vs = body.virtualScroll;
		let rowHeight = parseInt(this.helper.onlyNumbers(body.rowHeight));
		let height = vs.height ? vs.height : parseInt(this.helper.onlyNumbers(body.parent._height));

		let decimalQtty = (isNaN(height) ? 0 : height) / rowHeight;
		let intQtty = Math.floor(decimalQtty);
		return intQtty + (decimalQtty - intQtty > 0 ? 2 : 1);
	}
}
