<div class="flex flex-col text-xs gap-2">
  <div
    *ngFor="let validator of validators"
    class="flex"
  >
    <mat-icon
      *ngIf="control.value && !control.errors?.[validator.name]"
      class="text-green-700 icon-size-5"
      [svgIcon]="'heroicons_solid:check'"
    ></mat-icon>

    <mat-icon
      *ngIf="!control.value || control.errors?.[validator.name]"
      class="text-red-700 icon-size-5"
      [svgIcon]="'heroicons_solid:x-mark'"
    ></mat-icon>

    <span class="pl-2">{{validator.text | transloco}}</span>
  </div>
</div>

