<div
  class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-full"
  *transloco="let t"
>
  <div
    class="relative hidden md:flex flex-auto items-center justify-center h-full overflow-hidden bg-[#21295C]"
  >
  <div
    class="w-full h-full bg-cover"
    [style.background-image]="backgroundImg"
  ></div>
  </div>
  <div
    class="md:flex md:justify-end w-full md:w-1/4 h-full py-8 px-4 sm:p-12 md:p-16 md:pt-24 sm:rounded-2xl rounded-none shadow-none bg-card"
  >
    <div class="w-full max-w-80 mx-auto">

      <!-- Logo -->
      <div class="mt-8 w-60">
        <img class="w-full"
          [src]="solvaceLogo$ | async"
        />
      </div>

      <!-- Title -->
      <div class="mt-8 text-[#21295C] text-4xl font-extrabold tracking-tight leading-tight">
        {{ t("Alteração de Senha") }}
      </div>
      <div class="mt-0.5 mb-8 text-[#21295C] font-medium">
        {{ t("Defina uma nova senha para continuar") }}
      </div>

      <!-- Alert -->
      <svc-alert
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </svc-alert>

      <!-- Reset password form -->
      <form class="flex flex-col mt-6 space-y-6" [formGroup]="resetPasswordForm" #resetPasswordNgForm="ngForm">
        <!-- Verification code field -->
        <div class="flex flex-col mb-3" *ngIf="!changePasswordRequired">
          <mat-label class="flex-row-1 w-full mb-2">
            {{ t("Código de verificação") }}
          </mat-label>
          <div class="flex flex-row -mb-6">
            <mat-form-field class="flex mx-1">
              <input
                #verificator1Input
                (input)="handleFocusNextCode(verificator1Input?.value, verificator2Input)"
                autofocus
                type="text"
                autocomplete="off"
                class="text-center"
                id="verficator1"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator1'"
                (keyup.backspace)="
                  handleBackspace($event, verificator1Input, verificator1Input)
                "
                (paste)="handlePasteCode($event, verificator1Input)"
              />
            </mat-form-field>
            <mat-form-field class="flex mx-1">
              <input
                #verificator2Input
                (input)="handleFocusNextCode(verificator2Input?.value, verificator3Input)"
                type="text"
                autocomplete="off"
                class="text-center"
                id="verficator2"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator2'"
                (keyup.backspace)="
                  handleBackspace($event, verificator2Input, verificator1Input)
                "
                (paste)="handlePasteCode($event, verificator2Input)"
              />
            </mat-form-field>
            <mat-form-field class="flex mx-1">
              <input
                #verificator3Input
                (input)="handleFocusNextCode(verificator3Input?.value, verificator4Input)"
                type="text"
                autocomplete="off"
                class="text-center"
                id="verficator3"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator3'"
                (keyup.backspace)="
                  handleBackspace($event, verificator3Input, verificator2Input)
                "
                (paste)="handlePasteCode($event, verificator3Input)"
              />
            </mat-form-field>
            <mat-form-field class="flex mx-1">
              <input
                #verificator4Input
                (input)="handleFocusNextCode(verificator4Input?.value, i)"
                type="text"
                autocomplete="off"
                class="text-center"
                id="verficator4"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator4'"
                (keyup.backspace)="
                  handleBackspace($event, verificator4Input, verificator3Input)
                "
                (paste)="handlePasteCode($event, verificator4Input)"
              />
            </mat-form-field>
            <mat-form-field class="flex mx-1">
              <input
                #verificator5Input
                (input)="handleFocusNextCode(verificator5Input?.value, verificator6Input)"
                type="text"
                autocomplete="off"
                class="text-center"
                id="verficator5"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator5'"
                (keyup.backspace)="
                  handleBackspace($event, verificator5Input, verificator4Input)
                "
                (paste)="handlePasteCode($event, verificator5Input)"
              />
            </mat-form-field>
            <mat-form-field class="flex mx-1">
              <input
                #verificator6Input
                class="text-center"
                type="text"
                autocomplete="off"
                id="verficator6"
                matInput
                maxlength="1"
                mask="0"
                [formControlName]="'verificator6'"
                (keyup.backspace)="
                  handleBackspace($event, verificator6Input, verificator5Input)
                "
                (paste)="handlePasteCode($event, verificator6Input)"
              />
            </mat-form-field>
          </div>
        </div>

        <!-- Password field -->
        <svc-text class="w-full"
          [label]="t('Nova senha')"
          [formControl]="resetPasswordForm.controls.password"
          [isObscure]="true"
          type="password"
          autocomplete="new-password"
        ></svc-text>

        <!-- Password confirm field -->
        <svc-text class="w-full"
          [label]="t('Confirmação')"
          [formControl]="resetPasswordForm.controls.passwordConfirm"
          [isObscure]="true"
          type="password"
          autocomplete="new-password"
        ></svc-text>

        <user-password-validator
          [control]="resetPasswordForm.controls.password"
        ></user-password-validator>

        <!-- Submit button -->
        <button
          type="button"
          class="w-full text-white"
          mat-flat-button
          [ngClass]="{'bg-zinc-400': resetPasswordForm.invalid, 'bg-[#21295C]': resetPasswordForm.valid}"
          [disabled]="resetPasswordForm.invalid"
          (click)="changePasswordRequired ? changeTemporaryPassword() : resetPassword()"
        >
          <ng-container *ngIf="!resetPasswordForm.disabled"><span class="text-white">{{ t("Confirmar") }}</span></ng-container>
          <mat-progress-spinner
            *ngIf="resetPasswordForm.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>

        <button
          type="button"
          [routerLink]="['/sign-in']"
          class="w-full bg-zinc-100 text-black"
          mat-flat-button
        >
          {{ t("Voltar") }}
        </button>

      </form>
    </div>
  </div>
</div>
