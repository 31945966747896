<svc-dialog-config fullscreen="XS" (onDialogClose)="closeDialog()" *transloco="let t">

  <mat-icon svc-dialog-title-icon svgIcon="heroicons_solid:cog-6-tooth"></mat-icon>

  <div svc-dialog-title>
    {{ t("Configurações") }}
  </div>

  <form [formGroup]="settingsForm" svc-dialog-content>

    <div class="flex-col divide-y">

      <div class="flex p-4">

        <div class="flex flex-col">
          <div class="flex items-center mb-4 text-default">
            <mat-icon class="icon-size-4 mr-1 text-current" svgIcon="heroicons_solid:user-circle"></mat-icon>
            <span class="font-medium text-sm">{{ t('Foto de Usuário') }}</span>
          </div>

          <div class="flex flex-col w-full md:flex-row items-center">
            <div class="relative w-28 h-28 mr-3">
              <img class="w-full h-full rounded-full" *ngIf="urlImg" [src]="urlImg" alt="User avatar" />

              <mat-icon class="icon-size-24" *ngIf="!urlImg" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>

              <div class="absolute bottom-2 -right-1">
                <svc-button-circular size="sm" color="primary" (click)="uploadFile()">
                  <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                  <input type="file" #fileInput (change)="onfileSelected($event)" hidden>
                </svc-button-circular>
              </div>

              <div class="absolute top-2 -right-1">
                <svc-button-circular size="sm" color="primary" (click)="onDeleteImg()">
                  <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                </svc-button-circular>
              </div>

            </div>
            <div class="flex flex-col items-center md:items-start">
              <span class="text-lg font-bold">{{ user.firstLastName }}</span>
              <span class="text-sm text-secondary -mt-1">{{ user.email }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex p-4">
        <svc-select class="w-full" [label]="{ text: t('Idiomas'), icon: 'heroicons_solid:wrench-screwdriver'}"
          formControlName="languageId" [options]="availableLangs">
        </svc-select>
      </div>

      <div class="flex p-4">
        <svc-select class="w-full" [label]="{ text: t('Formato de Data'), icon: 'heroicons_solid:globe-alt' }"
          formControlName="dateFormat" [options]="dateFormatOptions">
        </svc-select>
      </div>

      <div class="flex p-4" *ngIf="env.isDEV">
        <svc-select
          class="w-full"
          [label]="{ text: 'Formato de número' | transloco, icon: 'heroicons_solid:adjustments-vertical' }"
          formControlName="delimiter"
          [options]="delimiterSymbolOptions"
        ></svc-select>
      </div>

      <div class="flex p-4">
        <div class="flex flex-col">
          <div class="mb-2 flex items-center">
            <mat-icon class="icon-size-3 mr-1" [svgIcon]="'heroicons_solid:arrow-path'"></mat-icon>
            <span class="text-sm font-medium">{{ t('Sincronização e compartilhamento') }}</span>
          </div>
          <div class="flex items-center justify-between">
            <svc-slide-toggle
              #outlookSyncToggle
              class="mr-4"
              formControlName="enableOutlookSync"
            ></svc-slide-toggle>
            <div
              class="flex-auto cursor-pointer"
              (click)="!settingsForm.disabled && outlookSyncToggle.toggle()"
            >
              <div class="text-xs font-medium leading-none">{{ t('Ativar Sincronização do Outlook') }}</div>
              <div class="text-xs font-light text-secondary">
                {{ t('Quando ativo, sincroniza todos os seus planos de ação com o Calendário do Outlook por data de previsão') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex p-4">
        <div class="flex flex-col">
          <div class="mb-2 flex items-center">
            <mat-icon class="icon-size-3 mr-1" svgIcon="mat_outline:wifi_off"></mat-icon>
            <span class="text-sm font-medium">{{ t('Modo offline') }}</span>
          </div>
          <div class="flex items-center justify-between">
            <svc-slide-toggle
              #enableOfflineMode
              class="mr-4"
              formControlName="enableOfflineMode"
            ></svc-slide-toggle>
            <div
              class="flex-auto cursor-pointer"
              (click)="!settingsForm.disabled && enableOfflineMode.toggle()"
            >
              <div class="text-xs font-medium leading-none">{{ t('Habilitar modo offlline') }}</div>
              <div class="text-xs font-light text-secondary">
                {{ t('Permite que o usuário faça uso de algumas funções do sistema sem uma conexão com a internet.') }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>

  <div class="space-x-2" svc-dialog-actions>
    <svc-button-neutral [label]="t('Cancelar')" [type]="'button'" [processing]="settingsForm.disabled" (click)="!settingsForm.disabled && closeDialog()"></svc-button-neutral>
    <svc-button-confirm [label]="t('Salvar')" [processing]="settingsForm.disabled" [type]="'button'" (click)="onSubmit()" cdkFocusInitial></svc-button-confirm>
  </div>

</svc-dialog-config>
