<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-full" *transloco="let t">
  <div class="relative hidden md:flex flex-auto items-center justify-center h-full overflow-hidden bg-[#21295C]">
    <div class="w-full h-full bg-cover" [style.background-image]="backgroundImg"></div>
  </div>
  <div class="md:flex md:justify-end w-full md:w-1/4 min-w-96 h-full py-8 px-4 sm:p-12 md:p-16 md:pt-24 rounded-none shadow-none bg-card relative">
    <div class="hidden md:flex absolute top-5 left-5">
      <!-- language -->
      <languages #languages [hasUser]="false" [onlyImage]="false"></languages>
    </div>

    <div class="w-full max-w-80 mx-auto">

      <div class="flex md:hidden">
        <!-- language -->
        <languages [hasUser]="false" [onlyImage]="false"></languages>
      </div>

      <!-- Logo -->
      <div class="mt-8">
        <img class="w-full"
          [src]="solvaceLogo$ | async"
        />
      </div>

      <div *ngIf="languages?.isLoading" class="flex items-center justify-center py-20">
        <mat-spinner diameter="30"></mat-spinner>
      </div>

      <div *ngIf="!languages?.isLoading">
        <!-- Title -->
        <div class="my-8 text-4xl font-extrabold tracking-tight leading-tight text-[#21295C]">
          {{ t("Bem vindo") }}
        </div>

      <!-- Alert -->
      <svc-alert
        class="mb-3"
        *ngIf="showAlert && !loading"
        [appearance]="alert.appearance"
        [showIcon]="passwordExpire"
        [type]="alert.type"
        [@shake]="['error'].includes(alert.type)"
      >
        <span *ngIf="!passwordExpire">{{ alert.message }}</span>
        <div *ngIf="passwordExpire" class="flex items-center">
          <span>{{ alert.message }}</span>
          <a class="underline" [routerLink]="['/expired-password']">Atualizar</a>
        </div>

        </svc-alert>

        <ng-container *ngIf="!privateCurrentUser && !passwordExpire">
          <!-- SSO buttons -->
          <div *ngFor="let sso of environmentInfo?.ssoItems" class="flex items-center space-x-4 pb-3">
            <button
              type="button"
              (click)="loginSSO(sso)"
              class="svc-mat-button-large w-full bg-[#21295C] text-white"
              mat-flat-button
              id="enter-with-button"
            >
              {{ t("Entrar com") }} {{ sso.name }}
            </button>
          </div>

        <!-- Separator -->
        <div class="flex items-center my-3" *ngIf="environmentInfo?.ssoItems?.length??0 > 0">
          <div class="flex-auto mt-px border-t-2 border-gray-300"></div>
          <div class="mx-2 text-secondary font-bold">
            {{ t("ou") }}
          </div>
          <div class="flex-auto mt-px border-t-2 border-gray-300"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="privateCurrentUser">
        <button
          *ngIf="passwordExpire !== 'expired'"
          type="button"
          (click)="signInWithCurrentUser()"
          class="svc-mat-button-large w-full bg-[#21295C] text-white text-ellipsis"
          mat-flat-button
          id="continue-button"
          [disabled]="passwordExpire === 'expired' || loading"
        >
          <ng-container *ngIf="(!quickSignInInProcess || passwordExpire === 'expiring') && !loading">
            {{ t("Continuar como") }} {{ privateCurrentUser.username }}
          </ng-container>
          <mat-progress-spinner *ngIf="quickSignInInProcess && (passwordExpire !== 'expiring' || loading)" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>

        <button
          type="button"
          (click)="onRefresh()"
          class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
          mat-flat-button
          id="other-user-button"
          *ngIf="!loading"
        >
          <ng-container *ngIf="!quickSignInInProcess || passwordExpire">
            {{ t("Entrar com um usuário diferente") }}
          </ng-container>
          <mat-progress-spinner  *ngIf="quickSignInInProcess && !passwordExpire" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>

      </ng-container>

      <div *ngIf="isEnvironmentInfoLoading" class="py-10 text-[#21295C]">
        <mat-spinner class="m-auto text-current" diameter="30"></mat-spinner>
      </div>

      <!-- Sign in form (global)-->
      <form *ngIf="!hasEnviromentInfoByOriginPath && !isEnvironmentInfoLoading" class="flex flex-col space-y-6" [formGroup]="userEnviromentSingInForm">

        <!-- Email field -->
        <svc-text class="w-full"
          [label]="t('Usuário')"
          [formControl]="userEnviromentSingInForm.controls.user"
          [readonly]="environmentUserInfoList"
          inputId="username-field"
        ></svc-text>

        <ng-container *ngIf="environmentUserInfoList">
          <svc-select class="w-full"
            [label]="t('Ambiente')"
            [formControl]="userEnviromentSingInForm.controls.environment"
            [options]="environmentUserInfoList"
            inputId="environment-field"
          ></svc-select>
        </ng-container>

        <ng-container *ngIf="userEnviromentSingInForm.controls.environment.valid && environmentInfo">
          <!-- Password field -->
          <svc-text class="w-full"
            [label]="t('Senha')"
            [formControl]="userEnviromentSingInForm.controls.password"
            [isObscure]="true"
            type="password"
            inputId="password-field"
          ></svc-text>
        </ng-container>

        <!-- Submit button env -->
        <button
          type="button"
          class="w-full mt-6 bg-[#21295C] text-white"
          mat-flat-button
          [disabled]="userEnviromentSingInForm.disabled && passwordExpire !== 'expiring'"
          (click)="signInEnviromentUserInfo()"
          id="signin-button"
        >
          <ng-container *ngIf="!userEnviromentSingInForm.disabled">
            {{ t("Entrar") }}
          </ng-container>
          <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>

        <!-- Submit button env -->

        <ng-container *ngIf="environmentUserInfoList">
          <button
            type="button"
            (click)="onRefresh()"
            class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
            mat-flat-button
            [disabled]="userEnviromentSingInForm.disabled"
            id="other-user-button"
          >
            <ng-container *ngIf="!userEnviromentSingInForm.disabled">
              {{ t("Entrar com um usuário diferente") }}
            </ng-container>
            <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </ng-container>
      </form>

      <!-- Sign in form (local) -->
      <form *ngIf="hasEnviromentInfoByOriginPath && !privateCurrentUser" class="flex flex-col space-y-6" [formGroup]="signInForm">

        <!-- Email field -->
        <svc-text class="w-full"
          [label]="t('Usuário')"
          [formControl]="signInForm.controls.user"
          [readonly]="environmentUserInfoList"
          inputId="username-field"
        ></svc-text>

        <!-- Password field -->
        <svc-text class="w-full"
          [label]="t('Senha')"
          [formControl]="signInForm.controls.password"
          [readonly]="environmentUserInfoList"
          [isObscure]="true"
          type="password"
          inputId="password-field"
        ></svc-text>

        <!-- Submit button -->
        <button
          type="submit"
          class="w-full mt-6 bg-[#21295C] text-white"
          mat-flat-button
          [disabled]="signInForm.disabled && passwordExpire !== 'expiring'"
          (click)="signInWithEnviromentInfo()"
          id="signin-button"
        >
          <ng-container *ngIf="!signInForm.disabled || passwordExpire && !loading">
            {{ t("Entrar") }}
          </ng-container>
          <mat-progress-spinner *ngIf="signInForm.disabled && loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
        </button>

        <ng-container *ngIf="passwordExpire && !loading">
          <button
            type="button"
            (click)="onRefresh()"
            class="svc-mat-button-large w-full mt-6 bg-zinc-100 text-black text-ellipsis"
            mat-flat-button
            [disabled]="userEnviromentSingInForm.disabled"
            id="other-user-button"
          >
            <ng-container *ngIf="!userEnviromentSingInForm.disabled">
              {{ t("Entrar com um usuário diferente") }}
            </ng-container>
            <mat-progress-spinner *ngIf="userEnviromentSingInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </ng-container>
      </form>
      <!-- Actions -->
      <div class="inline-flex items-end justify-end w-full mt-1.5" *ngIf="environmentInfo && !passwordExpire">
        <a class="text-md font-bold text-[#21295C] hover:underline" [routerLink]="['/forgot-password']">
          {{ t("Esqueceu a senha?") }}
        </a>
        </div>
      </div>
    </div>
  </div>

</div>
