<svc-dialog dialogStyle="form" (confirmed)="save()" [textConfirm]="'Adicionar' | transloco" [disabledConfirm]="hashtagForm?.invalid" fullscreen="XS" [ngClass]="{ 'opacity-60': isLoading }">

  <mat-icon svc-dialog-title-icon svgIcon="heroicons_solid:plus-circle">
  </mat-icon>

  <div svc-dialog-title>
    {{'Adicionar Hashtag' | transloco}}
  </div>

  <!--FORM CONTENT-->
  <form [formGroup]="hashtagForm" svc-dialog-content>
    <div class="flex-col">

      <div class="flex flex-col p-4">
        <mat-form-field class="w-full flex-auto svc-mat-no-subscript svc-mat-dense">
          <mat-select formControlName="hashtags" multiple panelClass="add-hashtag-form" class="overflow-x-hidden overflow-y-auto" [style.maxHeight.px]="200">
            <mat-select-trigger>
              <mat-chip-listbox class="mx-0">

                <mat-chip *ngFor="let hashtag of hashtagForm?.get('hashtags')?.value"
                  (removed)="removeHashtag(hashtag)">
                  {{hashtag.name}}
                  <button matChipRemove [attr.aria-label]="'remove option ' + hashtag.name">
                    <mat-icon class="icon-size-4 bg-gray-600 text-white rounded-full" svgIcon="heroicons_solid:x-mark">
                    </mat-icon>
                  </button>
                </mat-chip>

              </mat-chip-listbox>
            </mat-select-trigger>

            <div *ngFor="let siteGroup of availableHashtags">
              <div class="px-3 pt-1" class="font-semibold px-4 py-2 border-b border-gray-300">
                {{siteGroup.siteName | transloco}}
              </div>
              <div *ngFor="let typeGroup of siteGroup.types; let i = index" class="border-gray-300" [ngClass]="{ 'border-t': i > 0 }">
                <div class="pl-6 py-2">
                  {{typeGroup.typeName}}
                </div>
                <mat-option class="pl-10" *ngFor="let hashtag of typeGroup.hashtags" [value]="hashtag">
                  {{ hashtag.name }}
                </mat-option>
              </div>
            </div>

          </mat-select>
        </mat-form-field>

        <svc-validation-message [control]="hashtagForm.get('hashtags')"></svc-validation-message>
      </div>
    </div>
  </form>
</svc-dialog>
<div *ngIf="isLoading" class="absolute flex items-center justify-center inset-0 w-full h-full">
  <mat-spinner diameter="30"></mat-spinner>
</div>
