import { Component, OnInit } from '@angular/core';
import { ToastItem, SvcToastService } from '../svc-toast.service';
import { Observable } from 'rxjs';
import { fadeInUp400ms } from '../../animations/fade-in-up.animation';
import { stagger40ms } from '../../animations/stagger.animation';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

@Component({
  selector: 'svc-toast',
  templateUrl: './svc-toast.component.html',
  styleUrls: ['./svc-toast.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
})
export class SvcToastComponent implements OnInit {

  public toasts: Observable<ToastItem[]>;

  constructor(
    private _toastService: SvcToastService,
  ){
    this.toasts = this._toastService.toasts$;
  }

  ngOnInit(): void {}
}
