<svc-dialog #dialog fullscreen="XS" [hasActions]="false" *screenSizes="let size">
  <div svc-dialog-title>{{ "Elogios recebidos" | transloco }}</div>

  <div svc-dialog-content class="p-4 overflow-y-auto max-h-[860px]" infiniteScroll (scrollEnd)="loadMorePraises()">
    <svc-grid-data [columnsScreenSm]="2" [columnsScreenMd]="2" [columnsScreenLg]="2" [columnsScreenXl]="2" [isLoading]="loadingData">
      <praise-item-card #praiseItemCard
        svc-grid-data
        *ngFor="let item of praises"
        class="h-full"
        [item]="item"
        (onCardClick)="openPraise(praiseItemCard, item.id, $event?.comments ?? false)"
      >
      </praise-item-card>
    </svc-grid-data>
  </div>
</svc-dialog>

