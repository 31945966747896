<ng-container *ngIf="current">
  <div class="relative flex items-center justify-center bg-gray-100 text-gray-400 rounded-t-md" [style.maxHeight.px]="510">
    <img *ngIf="current.isImage" class="object-contain min-h-24 max-h-100 rounded-t-md" [style.maxHeight]="'inherit'" [src]="current.file" (error)="current.file = null; current.fileLoading = false" (load)="updateComponentProps(); current.fileLoading = false"/>
    <video *ngIf="current.isVideo" class="w-full h-60" [src]="current.file" controls></video>
    <mat-icon *ngIf="!current.isImage && !current.isVideo" class="my-10 text-current icon-size-24" svgIcon="mat_solid:image"></mat-icon>
    <div class="carousel-nav absolute left-1.5 top-1/2 -translate-y-1/2 text-white" (click)="hasPrev && previous()" [ngClass]="{ 'cursor-pointer': hasPrev, 'opacity-30': !hasPrev }">
      <mat-icon class="text-current icon-size-7" svgIcon="heroicons_solid:chevron-left"></mat-icon>
    </div>
    <div class="carousel-nav absolute right-1.5 top-1/2 -translate-y-1/2 text-white" (click)="hasNext && next()" [ngClass]="{ 'cursor-pointer': hasNext, 'opacity-30': !hasNext }">
      <mat-icon class="text-current icon-size-7" svgIcon="heroicons_solid:chevron-right"></mat-icon>
    </div>
    <div *ngIf="current.fileLoading" class="absolute inset-0 flex items-center justify-center text-primary">
      <div class="absolute inset-0 bg-white opacity-50"></div>
      <mat-spinner class="relative z-99 text-current" diameter="24"></mat-spinner>
    </div>
  </div>
  <div *ngIf="current.answer" class="text-base text-default pt-2 pb-2.5 px-3">
    <audio #audio *ngIf="currentAudio.base64" [src]="'data:audio/mp3;base64,' + currentAudio.base64" class="hidden"></audio>
    <a *ngIf="false" class="inline-flex align-middle text-primary cursor-pointer mr-2" (click)="currentAudio.base64 ? (currentAudio.playing ? pauseAudio() : playAudio()) : getAudio()">
      <mat-icon *ngIf="!currentAudio.loading" class="text-current icon-size-6" [svgIcon]="currentAudio.playing ? 'heroicons_solid:pause-circle' : 'heroicons_solid:play-circle'"></mat-icon>
      <mat-spinner *ngIf="currentAudio.loading" diameter="16" class="text-current"></mat-spinner>
    </a>
    <span *ngIf="answers?.length > 0" class="inline-flex align-middle mr-1 font-bold">
      {{ 'Passo' | transloco }}  {{ currentIndex + 1 }}/{{ answers.length }} ─
    </span>
    <span [innerHTML]="current.answer"></span>
  </div>
</ng-container>