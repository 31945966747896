import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { OrderByPipe, SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { map, Observable, of } from "rxjs";
import { SvcUserInfo } from "./user-info.model";

@Injectable({
	providedIn: 'root'
})
export class SvcUserAvatarService extends SvcHttpClient {

	#cachedUsersInfo: { users: SvcUserInfo[], teams: SvcUserInfo[], areas: SvcUserInfo[] } = {
		users: [],
		teams: [],
		areas: [],
	};

	constructor(
		appConfig: AppEnvironmentConfig,
		httpClient: HttpClient,
	) {
		super(appConfig.APIs.apiUrlUsers, httpClient);
	}

	public getUserInfoByUserIds(params: { users?: string[], teams?: number[], areas?: number[] }): Observable<SvcUserInfo[]> {
		let filteredParams = { ...params };
		let existingUsersInfo: SvcUserInfo[] = [];
		if (filteredParams.users?.length > 0 && this.#cachedUsersInfo.users.length > 0) {
			filteredParams.users = filteredParams.users.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.users.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.teams?.length > 0 && this.#cachedUsersInfo.teams.length > 0) {
			filteredParams.teams = filteredParams.teams.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.teams.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.areas?.length > 0 && this.#cachedUsersInfo.areas.length > 0) {
			filteredParams.areas = filteredParams.areas.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.areas.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.users?.length > 0 || filteredParams.areas?.length > 0 || filteredParams.teams?.length > 0) {
			return this.post<SvcUserInfo[]>(`/Avatar/ListAvatar`, filteredParams).pipe(
			 	map((response) => {
					response = this.#handleResponse(response);
					this.#cachedUsersInfo.users = [
						...this.#cachedUsersInfo.users,
						...response.filter(x => x.type === 1 && !this.#cachedUsersInfo.users.find(y => y.id === x.id)),
					];
					this.#cachedUsersInfo.areas = [
						...this.#cachedUsersInfo.areas,
						...response.filter(x => x.type === 2 && !this.#cachedUsersInfo.areas.find(y => y.id === x.id)),
					];
					this.#cachedUsersInfo.teams = [
						...this.#cachedUsersInfo.teams,
						...response.filter(x => x.type === 3 && !this.#cachedUsersInfo.teams.find(y => y.id === x.id)),
					];
					const finalResponse = [
						...response,
						...existingUsersInfo,
					];
					return finalResponse.sort((a, b) => a.name.localeCompare(b.name));
			 	})
		 );
		}
		return of(existingUsersInfo);
	}

	#handleResponse(response: SvcUserInfo[]) {
		let availableInfos = [];
		response.forEach((ui => {
			if ((ui.name ?? '') !== '') {
				let type = ui.type;
				if (typeof type === 'string') {
					switch (type) {
						case 'User': type = 1; break;
						case 'Area': type = 2; break;
						case 'Team': type = 3; break;
						default: type = undefined;
					}
				}
				availableInfos.push({
					...ui,
					id: /^\d+$/g.test(ui.id?.toString()) ? parseInt(ui.id as string) : ui.id,
					type: Math.max(type ?? 1, 1) as (1 | 2 | 3),
				});
			}
		}));
		return availableInfos;
	}
}
