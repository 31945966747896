import { Component, ContentChild, ElementRef, HostListener, Inject, Injector, Input, OnChanges, OnInit, Optional, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { ISvcChipGroupedOption, ISvcChipOption } from '../interfaces/svc-chip.interface';
import { SvcControl } from '../../svc-control';
import { MatChipGrid } from '@angular/material/chips';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';

@Component({
  selector: 'svc-chip-grouped',
  templateUrl: './svc-chip-grouped.component.html',
  styleUrls: ['./svc-chip-grouped.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcChipGroupedComponent),
    multi: true
  }]
})
export class SvcChipGroupedComponent extends SvcControl implements OnInit, OnChanges {

  _groups: ISvcChipGroupedOption[];

  filteredGroupedOptions: ISvcChipGroupedOption[];
  selectedOptions: ISvcChipOption[] = [];

  @ViewChild('formField', { read: ElementRef }) formField: ElementRef<HTMLElement>;
  @ViewChild('optionsListTemplate') optionsListTemplate: TemplateRef<any>;
  @ViewChild('readonlyOptionsContainer') readonlyOptionsContainer: ElementRef<HTMLElement>;
  @ViewChild(MatChipGrid) matChipGrid: MatChipGrid;

  @Optional()
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() label: string | { text: string, icon: string };
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() readonlyStyle: 'default' | 'chip' | 'transparent-chip' = 'default';
  @Input() set options(value: any[]) {
    const groups = [...(value ? value : [])];
    this._groups = _.cloneDeep(groups);
  }
  @Input() type: 'single' | 'multiple' = 'multiple';
  @Input() inputId: string;
  @Input() placeholder: string;
  @Input() fallbackTextValue: string;
  @Input() truncateTextValue: boolean = false;
  @Input() collapsedDisplaying: boolean = true;
  @Input() loading: boolean = false;

  valueText: string;
  protected collapsed = true
  protected searchText: string = null;
  protected optionsListIsOpened = false;
  protected undisplayedSelectedOptions = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _isUpdatingControl = false;
  private _overlayRef: OverlayRef;
  private _initialDisableClose = false;

  constructor(
    @Inject(Injector) injector: Injector,
    @Optional() private dialogRef: MatDialogRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.collapsed = this.collapsedDisplaying ?? true;
    this._initialDisableClose = this.dialogRef.disableClose ?? false;

    this.control?.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((values: (string | number)[]) => {
        if (!this._isUpdatingControl) {
          if (this._groups?.length) {
            const selectedOptions = [];
            for (const group of this._groups) {
              for (const option of group.options as any) {
                if (option.options) {
                  for (const optionChild of option.options) {
                    optionChild.selected = values?.includes(optionChild.value) ?? false;
                    if (optionChild.selected) {
                      selectedOptions.push(optionChild);
                    }
                  }
                }
                else {
                  option.selected = values?.includes(option.value) ?? false;
                  if (option.selected) {
                    selectedOptions.push(option);
                  }
                }
              }
            }
            this.selectedOptions = selectedOptions;
          }
          this.searchText = '';
          this.filterOptions(this.searchText);
        }
      }),
    ).subscribe();

    this._checkSelectedOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control) {
      super.ngOnChanges(changes);
      this._checkSelectedOptions();
      this._defineValueText();
      this._update();
    }
    this.filterOptions(this.searchText);
  }

  ngAfterViewChecked(): void {
    this._checkUndisplayedSelectedOptions();
  }

  private _checkUndisplayedSelectedOptions() {
    if (this.collapsed && !this.loading) {
      this.undisplayedSelectedOptions = 0;
      if (this.selectedOptions?.length > 1) {
        if (this.matChipGrid) {
          const length = this.matChipGrid?._chips.length ?? 0;
          for (let i = 0; i < length; i++) {
            const chip = this.matChipGrid?._chips.get(i);
            if (chip._elementRef.nativeElement.parentElement.offsetTop > 0) {
              this.undisplayedSelectedOptions = length - i;
              break;
            }
          }
        }
        else if (this.readonlyOptionsContainer?.nativeElement) {
          const containerEl = this.readonlyOptionsContainer?.nativeElement;
          const length = containerEl?.children.length ?? 0;
          for (let i = 0; i < length; i++) {
            const chipEl = containerEl.children[i] as HTMLElement;
            if (chipEl?.offsetTop > 0) {
              this.undisplayedSelectedOptions = length - i;
              break;
            }
          }
        }
      }
    }
  }

  private _defineValueText() {
    const value: any[] = this.control?.value ?? this.value;
    if (value?.length > 0 && this._groups?.length > 0) {
      let valueText = [];
      value.forEach((value) => {
        for (const group of this._groups) {
          for (const option of group.options as any) {
            if (option.options) {
              for (const optionChild of option.options) {
                if (optionChild.value === value) {
                  valueText.push(optionChild.label);
                }
              }
            }
            else if (option.value === value) {
              valueText.push(option.label);
            }
          }
        }
      });
      this.valueText = valueText.join(', ');
      return;
    }
    this.valueText = '';
  }

  private filterOptions(value: string): void {
    const filterValue = value ? value.toString().toLowerCase() : '';

    this.filteredGroupedOptions = this._groups.map(groupOption => ({
      group: groupOption.group,
      options: (groupOption.options?.map((option: any) => {
        if (!option.options) {
          if (!filterValue || option.label.toString().toLowerCase().includes(filterValue)) {
            return option;
          }
          return null;
        }
        else {
          return {
            group: option.group,
            options: option.options.filter((option: any) => {
              return !filterValue || option.label.toString().toLowerCase().includes(filterValue);
            }),
          };
        }
      }) ?? []).filter(option => option?.options ? option.options.length > 0 : !!(option)),
    })).filter(g => g.options.length > 0);
  }

  private _update() {
    if (this.loading) return;
    this._isUpdatingControl = true;
    this.control?.setValue(this.selectedOptions.map(option =>
      option.selected ? option.value : null
    ));
    this.control?.updateValueAndValidity();
    this._isUpdatingControl = false;
  }

  private _checkSelectedOptions(): void {
    if (!this.loading) {
      const selectedItems: ISvcChipOption[] = [];
      const check = (option: ISvcChipOption) => {
        if ((Array.isArray(this.control?.value) && this.control.value.some(x => x == option.value))) {
          option.selected = true;
          if (this.type == 'single') {
            if (selectedItems.length == 0) {
              selectedItems.push(option);
            } else {
              option.selected = false;
            }
          } else {
            selectedItems.push(option);
          }
        }
      }
      this._groups.forEach(groupOption => groupOption.options?.forEach((option: any) => {
        if (option.options) {
          option.options.forEach((option: any) => check(option));
        }
        else {
          check(option);
        }
      }));

      this.selectedOptions = selectedItems;
    }
  }

  public selected(option: ISvcChipOption, event?: MouseEvent): void {
    event?.stopImmediatePropagation();

    if (this.type == 'single') {
      this.closeOptions();
      option.selected = !(option.selected ?? false);
      this.selectedOptions.forEach(option => option.selected = false);
      this.selectedOptions = option.selected ? [option] : [];
    }
    else {
      if (!option.selected) {
        option.selected = true;
        this.selectedOptions.push(option);
      }
      else {
        option.selected = false;
        this.selectedOptions = this.selectedOptions.filter(x => x.value !== option.value);
      }
      setTimeout(() => this._checkUndisplayedSelectedOptions());
    }

    this._update();
  }

  openOptions() {
    if (this.loading || this.optionsListIsOpened || (this.control?.disabled ?? false)) {
      return;
    }

    const hasOverlayRefInstance = !!(this._overlayRef);

    if (!this._overlayRef && this.formField.nativeElement) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'bg-transparent',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        width: this.formField.nativeElement.clientWidth,
        maxHeight: 256,
        positionStrategy: this._getOptionsPositionStrategy(),
      });

      this._overlayRef.backdropClick().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this._overlayRef.detach();
      });

      this._overlayRef.detachments().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this.formField?.nativeElement?.focus();
        if (this.dialogRef) this.dialogRef.disableClose = this._initialDisableClose;
        setTimeout(() => this.optionsListIsOpened = false);
      });
    }

    if (hasOverlayRefInstance) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef.updatePositionStrategy(this._getOptionsPositionStrategy());
    }

    try {
      const viewRef = this._overlayRef.attach(
        new TemplatePortal(this.optionsListTemplate, this._viewContainerRef)
      );
      this.optionsListIsOpened = true;
      if (this.dialogRef) this.dialogRef.disableClose = true;
      setTimeout(() => {
        if (this.optionsListIsOpened) {
          if (viewRef.rootNodes.length) {
            const element = viewRef.rootNodes[0] as HTMLElement;
            if (element?.querySelector) {
              const input = element.querySelector('input');
              if (input) {
                input?.focus();
              }
              else {
                this.formField?.nativeElement?.focus();
              }
            }
          }
        }
      });
    }
    catch { }
  }

  private _getOptionsPositionStrategy() {
    const fieldWrapper = this.formField.nativeElement.querySelector('.mat-mdc-text-field-wrapper') as HTMLElement;
    return this._overlay
      .position()
      .flexibleConnectedTo(this.formField.nativeElement)
      .withLockedPosition(true)
      .withPush(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: fieldWrapper ? (this.formField.nativeElement.clientHeight - fieldWrapper.offsetHeight) : 0
        },
      ]);
  }

  closeOptions() {
    this._overlayRef?.detach();
  }

  forceFocusItemWhenDownPressed(event: Event) {
    event.preventDefault();
    if (this.filteredGroupedOptions.length > 0 && ((this.type === 'single' && this.selectedOptions.length == 0) || this.type != 'single')) {
      const container = (event.target as HTMLElement).parentElement.parentElement.children[1];
      if (container?.children.length > 0) {
        const item = container?.querySelector('.chip-grouped-option') as HTMLElement;
        item?.focus();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize(event: Event) {
    if (this.optionsListIsOpened) {
      this._overlayRef?.updateSize({
        width: this.formField?.nativeElement?.clientWidth,
      })
      this._overlayRef?.updatePositionStrategy(this._getOptionsPositionStrategy());
    }
  }

  @HostListener('document:keydown.esc')
  private onEsc(event: KeyboardEvent) {
    if (this.optionsListIsOpened) {
      this.closeOptions();
    }
  }

  public ngOnDestroy() {
    this.closeOptions();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
