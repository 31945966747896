import { Component, Input } from '@angular/core';

@Component({
  selector: 'svc-button-confirm',
  templateUrl: './svc-button-confirm.component.html',
  styleUrls: ['./svc-button-confirm.component.scss']
})
export class SvcButtonConfirmComponent {
  @Input() label: string;
  @Input() type: 'button' | 'file' | 'submit' = 'button';
  @Input() processing: boolean = false;
  @Input() disabled: boolean;
}
