import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LibServiceCilAnalyticsService } from './lib-service-cil-analytics.service';
import { ModuleHttpClient } from './module-http-client';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [],
  providers: [
    ModuleHttpClient,
    LibServiceCilAnalyticsService,
  ],
})
export class LibServiceCilAnalyticsModule { }
