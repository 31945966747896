<mat-icon
  (click)="!readonly ? changePriority($event) : null"
  [ngClass]="[iconSizeClass || 'icon-size-6']"
  [class.text-yellow-500]="priority"
  [class.text-gray-300]="!priority"
  [svgIcon]="priority ? 'heroicons_solid:star' : 'heroicons_outline:star-outline'"
  [matTooltip]="priority ? ('Desfavoritar' | transloco ) : ('Favoritar' | transloco )"
  [id]="buttonId"
></mat-icon>

