import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import {
  SvcDialogService,
  SvcToastService,
} from 'projects/lib-shared-component/src/public-api';
import { QuestionTypesEnum } from 'projects/survey/src/app/survey/survey-questions-type/enums/question-types.enum';
import { Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { Question, SurveyModal } from './models/survey-modal';
import { SurveyModalService } from './services/survey-modal.service';

export enum SurveyStatus {
  InElaboration = 1,
  Sended = 2,
  Closed = 3,
}

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
})
export class SurveyModalComponent implements OnInit {
  @ViewChildren('survey') survey: QueryList<any>;

  #modalService = inject(SurveyModalService);
  #translocoService = inject(TranslocoService);
  #toastService = inject(SvcToastService);
  #httpErrorService = inject(HttpErrorService);

  public isLoading = false;
  public questions = [];
  public QuestionTypes = QuestionTypesEnum;
  public title = this.#translocoService.translate('Pesquisa');
  public questionCounter = 0;

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; readonly: true },
    private _dialogRef: MatDialogRef<SurveyModalComponent>,
    private _dialogService: SvcDialogService
  ) {
    this._dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.#modalService.isSave(false);
    this.#getQuestions();
  }

  public openImage(url: string) {
    this._dialogService.openImage(url);
  }

  public isValid(): boolean {
    for (const question of this.survey) {
      if (!question.isValid) {
        return false;
      }
    }

    return true;
  }

  public save(payload?: any, question: Question = null): void {
    if (question) {
      question.metadata = payload;
    }
  }

  public saveAll(): void {
    this.#modalService.isSave(true);

    for (const question of this.survey) {
      question.getQuestionAnswered();
    }

    if (!this.isValid()) {
      return;
    }

    this.#sendSurvey();
  }

  #normalizePayload() {
    return this.questions?.reduce(
      (acc, topic: any) => acc.concat(topic.questions),
      []
    );
  }

  #checkingUnrespondedMetadata(item: any): string {
    const typesToCheck = [
      QuestionTypesEnum.Simple_Choice,
      QuestionTypesEnum.Multiple_Choice,
      QuestionTypesEnum.Text_Box,
      QuestionTypesEnum.Multiple_Text_Boxes,
      QuestionTypesEnum.Evaluation_Bar,
      QuestionTypesEnum.Evaluation_Matrix,
      QuestionTypesEnum.Star_Rating,
      QuestionTypesEnum.Employees_List,
      QuestionTypesEnum.Dropdown_List,
    ];

    if (typesToCheck.includes(item.questionTypeId) && !item?.answered) {
      return '';
    }

    return JSON.stringify(item.metadata);
  }

  #createPayload() {
    const questions = this.#normalizePayload();

    return questions.map((item) => ({
      questionId: item.questionId,
      questionName: item.questionName,
      questionTypeId: item.questionTypeId,
      isRequired: item.isRequired,
      metadata: this.#checkingUnrespondedMetadata(item),
    }));
  }

  #sendSurvey() {
    this.isLoading = true;
    const payload = this.#createPayload();

    this.#modalService
      .sendSurvey(this.data.id, payload)
      .pipe(
        tap(() => {
          const message = this.#translocoService.translate(
            'Pesquisa enviada com sucesso!'
          );
          this.#toastService.success(message);
          this._dialogRef.close();
        }),
        catchError((error) => {
          this.#toastService.error(
            this.#translocoService.translate(
              this.#httpErrorService.getErrorMessage(error)
            )
          );
          return error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  #getQuestions() {
    this.isLoading = true;
    this.#modalService
      .getQuestions(this.data.id)
      .pipe(
        tap((response: SurveyModal) => {
          this.questions = this.#normalizeResponse(response);

          if (this.#isSurveyClosed(response)) {
            this.#handleSurveyClosed();
          }
        }),
        catchError((error) => {
          this.#toastService.error(
            this.#translocoService.translate(
              this.#httpErrorService.getErrorMessage(error)
            )
          );
          return error;
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  #isSurveyClosed(response): boolean {
    return response?.statusId === SurveyStatus.Closed;
  }

  #handleSurveyClosed(): void {
    const message = this.#buildSurveyClosedMessage();
    this._dialogService
      .openConfirm(message, {
        title: 'Atenção',
        cancelText: '',
        hideCloseButton: true
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this._dialogRef.close();
        }
      });
  }

  #buildSurveyClosedMessage(): string {
    const baseMessage = this.#translocoService.translate('Pesquisa encerrada');
    return `${baseMessage}: ${this.title}`;
  }

  #normalizeResponse(response) {
    this.title = response?.title;
    return response.topics.map((topic) => ({
      topicName: topic.topicName,
      questions: topic.questions.map((question) => ({
        ...question,
        metadata: JSON.parse(question.metadata),
        answered: false,
        questionCounter: ++this.questionCounter,
      })),
    }));
  }
}
