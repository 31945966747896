import { Injectable } from '@angular/core';
import { Observable} from "rxjs";
import {SvcUserPreferenceFilterUpdate, SvcUserPreferencesFeatures} from "../models/svc-user-preferences.model";
import { UserHttpClient} from "../user-http-client";

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceFiltersService {

  constructor(
    private http: UserHttpClient
  ) { }

  public getUserPreferencesFilter( params: { featureName: string, applicationId: string }): Observable<SvcUserPreferencesFeatures[]> {
    return this.http.get(`/userpreference/feature/filters`, { params });
  }

  public updateUserPreferencesFilter(filter: SvcUserPreferenceFilterUpdate): Observable<any> {
    return this.http.put(`/userpreference/feature/filters`, filter);
  }

  public removeUserPreferencesFilter(params: { featureName: string, applicationId: string, filterName: string }): Observable<SvcUserPreferencesFeatures[]> {
    return this.http.delete(`/userpreference/feature/filters`, {
      params: params
    });
  }

}
