
import { inject, Injectable, Injector } from '@angular/core';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { SvcPrinterData } from './svc-printer-classes';
import { SvcPrinterModalComponent } from './svc-printer-modal.component';

@Injectable({
	providedIn: 'root'
})
export class SvcPrinterService {

	#svcDialogService = this.injector.get(SvcDialogService);

	constructor(
    private injector: Injector,
	) {
	}

	open(data: SvcPrinterData) {
		this.#svcDialogService.open(SvcPrinterModalComponent, {
			data: data,
			width: '100%',
			maxWidth: '400px',
		}).afterClosed().subscribe((print) => {
			if (print?.elements?.length > 0) {
				this.#print(print.elements);
			}
		});
	}

	#print(elements: HTMLElement[]) {

	}
}
