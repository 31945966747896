import { Injectable } from '@angular/core';
import { SvcDataTableCustomSettings as SvcDataTableCustomSettings } from './svc-data-table-custom-settings';
import { SvcDataTableSettings } from './svc-data-table-settings.model';

@Injectable({
	providedIn: 'root',
})
export class SvcDataTableSettingsService extends SvcDataTableSettings {
	constructor(customSettings: SvcDataTableCustomSettings) {
		super();
		Object.assign(this, customSettings ?? {});
	}
}
