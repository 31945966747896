<svc-dialog dialogStyle="close" fullscreen="XS">

  <mat-icon svc-dialog-title-icon svgIcon="heroicons_solid:eye"></mat-icon>

  <div svc-dialog-title>
    {{'Todas as Hashtags' | transloco}}
  </div>

  <!--FORM CONTENT-->
  <ng-container svc-dialog-content>

    <div class="flex-col">

      <mat-tab-group class="w-full" (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngFor="let hashtagType of hashtagTypeEnum | keyvalue" mat-stretch-tabs="true">
          <ng-template mat-tab-label>
            <ng-container *ngIf="hashtagType.value == 'Local'">
              <mat-icon class="mr-1" [ngClass]="{ 'text-primary': typeActive == hashtagType.value }"
                [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
            </ng-container>

            <ng-container *ngIf="hashtagType.value == 'Global'">
              <mat-icon class="mr-1" [ngClass]="{ 'text-primary': typeActive == hashtagType.value }"
                [svgIcon]="'heroicons_solid:globe-europe-africa'"></mat-icon>
            </ng-container>

            {{ hashtagType.value | transloco }}
          </ng-template>

          <div class="flex grow p-2">
            <mat-form-field class="svc-mat-no-subscript svc-mat-dense grow">
              <input type="search" matInput [(ngModel)]="hashtagSearch" placeholder="{{ 'Procurar' | transloco}}..."
                (ngModelChange)="filter($event)">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            </mat-form-field>
          </div>

          <div class="flex flex-wrap items-center justify-center space-x-2 px-2">
            <div *ngFor="let hashtag of filteredHashtags"
              class="flex rounded-lg bg-primary-200 text-primary-700 items-center mb-2 px-1">
              <a class="text-md font-medium mr-1">{{ hashtag.name }}</a>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>
  </ng-container>

  <div svc-dialog-actions class="w-full">
    <div class="flex w-full">
      <div class="flex flex-auto items-center pr-2 text-xs">
        {{filteredHashtags?.length ?? 0}} {{'Resultados' | transloco}}
      </div>
      <div class="flex flex-row gap-2">
        <svc-button
          mode="flat"
          type="button"
          color="primary"
          (click)="closeDialog()"
          cdkFocusInitial
        >{{ 'Fechar' | transloco }}</svc-button>
      </div>
    </div>
  </div>
</svc-dialog>
