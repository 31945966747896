export enum ReactionTypeEnum {
	LIKE = 'LIKE',
	CELEBRATE = 'CELEBRATE',
	FUNNY = 'FUNNY',
	INSIGHTFUL = 'INSIGHTFUL',
	LOVE = 'LOVE',
}

export interface ReactionType {
	reactionUniqueId: number;
	enumKey: ReactionTypeEnum;
	description: string;
	staticImageUrl: string;
	animatedImageUrl: string;
	active: boolean;
	createDate: string;
}
