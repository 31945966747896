<div class="flex flex-col p-10 items-center" *ngIf="isLoading">
  <mat-spinner color="primary" diameter="35"></mat-spinner>
</div>

<div #viewport *ngIf="!isLoading" class="w-full">
  <angular-d3-cloud 
    [height]="viewport.clientWidth" 
    [width]="viewport.clientWidth" 
    [data]="processedData" 
    [font]="font" 
    [fontWeight]="weight" 
    [fontStyle]="style" 
    [padding]="padding"
    [rotate]="rotate" 
    [autoFill]="autoFill" 
    [fillMapper]="fillMapper" 
    [animations]="animations" 
    [speed]="speed"
    [tooltip]="tooltip" 
    [hover]="hover" 
    [selection]="selection" 
    [theme]="theme" 
    (wordClick)="onWordClick($event)"
    (wordMouseOver)="onWordMouseOver($event)" 
    (wordMouseMove)="onWordMouseMove($event)"
    (wordMouseOut)="onWordMouseOut($event)">
  </angular-d3-cloud>
</div>