import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceChecklistAnalyticsService } from './lib-service-checklist-analytics.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: [

  ],
  providers: [
    LibServiceChecklistAnalyticsService,
    ModuleHttpClient,
  ],
})
export class LibServiceChecklistAnalyticsModule { }
