export enum SvcModule {
    Authentication,
    MyWorkspace,
    Praise,
    Communication,
    Survey,
    Quiz,
    Notification,
    Users,
    Rca,
    BOS,
    Multilingual,
    Alert,
    ExternalFeatures,
    UnsafeCondition,
}
