import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-navigation-images',
  templateUrl: './user-navigation-images.component.html',
  styleUrls: ['./user-navigation-images.component.scss']
})
export class UserNavigationImagesComponent {
  @Input() title: string;
}
