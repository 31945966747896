<div class="flex items-center gap-2" *screenSizes="let size" [ngClass]="{ 'pointer-events-none': readonly }">
  <svc-select
    class="w-full"
    [style.maxWidth]="size.isXS ? '100%' : '400px'"
    [formControl]="control"
    [options]="options"
    [placeholder]="'Selecione' | transloco"
  >
  </svc-select>
</div>

<div class="flex justify-end mt-3]">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
