<section>
  <div *ngIf="parentTree?.templateRef == null" class="entry" [ngClass]="{ 'cursor-pointer': input.children?.length }" (click)="toggleVisibility()">
    {{ input.name }}
  
    <div *ngIf="!showBranches && input.children?.length" class="entry-branches-count">
      {{ input.children?.length > 9 ? '+9' : (input.children?.length ?? 0) }}
    </div>
    <div *ngIf="false" class="entry-options">
      <button class="text-primary" (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="optionsMatMenu">
        <mat-icon svgIcon="mat_solid:add_circle" class="icon-size-5 text-current"></mat-icon>
      </button>
      <mat-menu #optionsMatMenu>
        <button mat-menu-item (click)="edit()" class="text-default">
          <span>{{ 'Edit' | transloco }}</span>
        </button>
        <button mat-menu-item (click)="new()" class="text-default">
          <span>{{ 'Novo' | transloco }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <ng-container *ngIf="parentTree?.templateRef != null" [ngTemplateOutlet]="parentTree.templateRef" [ngTemplateOutletContext]="{ $implicit: entry }"></ng-container>
  <!-- <div *ngIf="input.hasWhy" class="entry-why text-sm">Text</div> -->
</section>
<svc-tree-branch
  *ngIf="input.children?.length"
  [input]="input"
  [orientation]="orientation"
  [ngClass]="{ 'hidden': !showBranches }"
></svc-tree-branch>