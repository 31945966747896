import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  transform(value: any, maxLength: number): string {
    if (!value) return value;
    if (value.length <= maxLength) return value;
    return `${value.substring(0, maxLength)}...`
  }

}
