import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvcImageEditorComponent } from './svc-image-editor.component';
import { SvcModalImageEditorComponent } from './svc-modal-image-editor.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';

@NgModule({
  declarations: [
    SvcImageEditorComponent,
    SvcModalImageEditorComponent,
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    SvcImageEditorComponent,
    SvcModalImageEditorComponent,
  ]
})
export class SvcImageEditorModule { }
