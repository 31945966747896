import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvcWordCloudComponent } from './svc-word-cloud.component';
import { AngularD3CloudModule } from '@talentia/angular-d3-cloud';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [
    SvcWordCloudComponent
  ],
  imports: [
    CommonModule,    
    FormsModule,
    AngularD3CloudModule
  ],
  exports: [SvcWordCloudComponent]
})
export class SvcWordCloudModule { }
