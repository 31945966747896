import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
    selector: '[svcViewPanelContent]'
})
export class SvcViewPanelContentDirective {
    @Input() id: string = '';
    @Input() customClass: string = '';
    
    constructor(public templateRef: TemplateRef<unknown>) {}
}