import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { svcAnimations } from 'projects/lib-shared-component/src/public-api';
import { AuthService } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: svcAnimations,
})
export class AuthForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPasswordNgForm') forgotPasswordNgForm: NgForm;

  alert: { type: String; message: string } = {
    type: 'success',
    message: '',
  };
  forgotPasswordForm: UntypedFormGroup;
  showAlert: boolean = false;
  public solvaceLogo$ = this._authService.solvaceLogo$;

  get backgroundImg() {
    return this._sanitizer.bypassSecurityTrustStyle(
      this._authService.getCoverImage()
    );
  }

  /**
   * Constructor
   */
  constructor(
    private _translocoService: TranslocoService,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _sanitizer: DomSanitizer
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.forgotPasswordForm = this._formBuilder.group({
      user: ['', [Validators.required ]],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send the reset link
   */
  sendResetLink(): void {
    // Return if the form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    // Disable the form
    this.forgotPasswordForm.disable();

    // Hide the alert
    this.showAlert = false;

    // Forgot password
    this._authService
      .forgotPassword(this.forgotPasswordForm.get('user').value)
      .then(() => {
        this._router.navigate(['/reset-password']);
      })
      .catch((error) => {
        this.#customizedErrorMessage(error);
      })
      .finally(() => {
        // Re-enable the form
        this.forgotPasswordForm.enable();

        // Reset the form
        this.forgotPasswordNgForm.resetForm();

        // Show the alert
        this.showAlert = true;
      });
  }

  #customizedErrorMessage(error?: any) {
    const type = 'LimitExceededException'.toLowerCase();
    const message = 'Attempt limit exceeded, please try after some time.'.toLowerCase();
    const errorCode = { ...error };
    if (errorCode?.code?.toLowerCase() === type && error?.message?.toLowerCase() === message) {
      this.alert = { type: 'error', message: this._translocoService.translate('Várias tentativas de senha incorreta. Contate o administrador para tentar recuperá-la.') };
    } else {
      this.alert = { type: 'error', message: this._translocoService.translate('Usuário inválido') };
    }
  }
}
