import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'projects/environments/environment';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { MainTasksService } from 'projects/lib-shared-core/src/lib/features/main-tasks/main-tasks.service';
import { SvcModule } from 'projects/lib-shared-core/src/lib/settings/enums/svc-module.enum';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { MainTasksComponent, SvcFeatureToggleService } from 'projects/lib-shared-core/src/public-api';
import { SvcFeedService } from 'projects/lib-shared-feature/src/lib/general/svc-comments/services/svc-feed.service';
import { Subject, Subscription } from 'rxjs';
import { SvcCommentsFeedComponent } from '../../../../../general/svc-comments/components/svc-comments-feed/svc-comments-feed.component';
import { VerticalNavigationComponent } from '../vertical-navigation/vertical-navigation.component';

export type SidebarModules = 'tasks' | 'feed';

@Component({
  selector: 'sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent extends VerticalNavigationComponent {

  @ViewChild(MainTasksComponent) public mainTasks: MainTasksComponent;
  @ViewChild(SvcCommentsFeedComponent) public commentsFeed: SvcCommentsFeedComponent;

	@Input() public showSideBySide: boolean = false;
  @Output() public onKaizenResumeClicked = new EventEmitter<void>();

  public totalTasks: number = null;
  public totalFeeds: number = null;
  public loadingTasks = true;
  public loadingFeeds = true;
  public selectedModule: number = 0;
  public inWorkspace = false;
  public env = environment;
  public feedIsEnabled = false;
  public hasErrorTasks = false;

  public hasErrorFeed = false;
  public hasErrorTotalUnread = false;

  unsubscribeAll: Subscription;
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected elementRef: ElementRef<HTMLElement>,
    private svcAppSettings: SvcAppSettings,
    private featureToggleService: SvcFeatureToggleService,
    private _mainTasksService: MainTasksService,
    private _svcFeedService: SvcFeedService,
  ) {
    super(elementRef);
    this.inWorkspace = this.svcAppSettings.module === SvcModule.MyWorkspace;
    this.feedIsEnabled = this.featureToggleService.feedIsEnabled;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('mode' in changes) {
      if (this.commentsFeed?.expanded) {
        this.commentsFeed.toggleExpanded();
      }
    }
    super.ngOnChanges(changes);
  }

  open(module: SidebarModules) {
    let openedSomeone = false;
    if (module === 'tasks') {
      this.selectedModule = 0;
      openedSomeone = true;
    }
    else if (module === 'feed' && this.feedIsEnabled) {
      this.selectedModule = 1;
      openedSomeone = true;
    }

    if (openedSomeone && this.mode === 'over') {
      this.toggle();
    }
  }

  getTasks() {
    this._mainTasksService.getUserDrillDowns(true);
  }

  getTotalUnread() {
    this._svcFeedService.getTotalUnread();
    this._svcFeedService.getFeed();
  }

  getFeed() {
    this._svcFeedService.getFeed();
    this._svcFeedService.getTotalUnread();
  }

  protected createOverlay() {
    if (this.overlayEl == null) {
      this.elementRef.nativeElement.insertAdjacentHTML('afterend', '<div class="sidebar-navigation-overlay fadeIn"></div>');
      this.overlayEl = this.elementRef.nativeElement.parentElement.querySelector('.sidebar-navigation-overlay');
      this.overlayEl.addEventListener('click', () => this.toggle());
    }
  }
}
