<ng-content></ng-content>

<div [hidden]="disabled || !showReactions" class="svc-reaction-list" [ngClass]="[ 'to-' + positionX, 'to-' + positionY ]">
	<section class="shadow bg-white" [ngClass]="{ 'rounded-full': (loadingList || loadingCurrent) && !reactionsType.length }">
		<div class="flex" [style.width.px]="reactionsType.length * 30">
			<div *ngFor="let reactionType of reactionsType" class="svc-reaction-item" [ngClass]="{ 'active': reactionType.reactionUniqueId == currentReactionType?.reactionUniqueId }" (click)="makeReaction(reactionType)">
				<img [src]="reactionType.staticImageUrl" />
				<img [src]="reactionType.animatedImageUrl" />
			</div>
		</div>
		<div *ngIf="loadingList || loadingCurrent" class="svc-reaction-loading">
			<mat-spinner diameter="20"></mat-spinner>
		</div>
	</section>
</div>
