import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-evaluation-bar-answer',
  templateUrl: './evaluation-bar-answer.component.html',
  styleUrls: ['./evaluation-bar-answer.component.scss'],
})
export class EvaluationBarAnswerComponent implements OnInit, AfterViewInit {
  @ViewChild('sliderRef') sliderRef: ElementRef;
  @ViewChildren('labels') labels: QueryList<ElementRef>;

  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public levels$ = new BehaviorSubject<ISvcSelectOption[]>(this.getLevels());
  public value = 1;
  public isDuplicateDescription = false;
  public selectedOption = { Level: 1, Description: '' };
  public isSave = false;

  #cdr = inject(ChangeDetectorRef);
  #fb = inject(FormBuilder);
  #modalService = inject(SurveyModalService);
  #renderer = inject(Renderer2);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.initForm();

    if (this.question) {
      this.form.reset();
      this.patchForm(this.question);
    }

    this.formChangesLevel();
    this.formChangesOptions();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  ngAfterViewInit() {
    this.updateSliderLabels(this.form.get('options').value);
    this.#cdr.detectChanges();
  }

  get options() {
    return (this.form.get('options') as FormArray).controls as FormGroup[];
  }

  private patchForm(response: any) {
    let data = null;
    this.question ? (data = response?.metadata) : (data = response);

    const optionsArray = this.form.get('options') as FormArray;
    optionsArray.clear();

    data?.options?.forEach((option) => {
      const optionGroup = this.#fb.group({
        level: [option.level],
        description: [option.description],
      });
      optionsArray.push(optionGroup);
    });

    this.form.get('level').setValue(data?.options?.length);
    this.#cdr.detectChanges();
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    // Quando não é movido o slider e permanece no level inicial
    if (this.value === 1) {
      const options = this.form.get('options').value;
      const selected = options.find((o) => o.level === this.value);
      this.selectedOption = {
        Level: this.value,
        Description: selected?.description,
      };
    }
    this.question.answered = true;
    this.doSave.emit(this.selectedOption);
  }

  public onRatingChange() {
    // this.updateSliderLabels(this.form.get('options').value);
    const sliderValue = this.value - 1; // O índice do slider ajustado para índice base-0
    const optionsArray = this.form.get('options') as FormArray;

    // Certifique-se de que a opção existe antes de tentar acessá-la
    if (optionsArray.at(sliderValue)) {
      this.form.get('level').setValue(sliderValue + 1, { emitEvent: false });

      // Capturando o objeto da opção selecionada
      this.selectedOption = {
        Level: sliderValue + 1,
        Description: optionsArray.at(sliderValue).value.description,
      };

      this.updateSliderLabels(this.form.get('options').value);
      this.question.answered = true;
    }
  }

  private initForm() {
    this.form = this.#fb.group({
      level: [null, Validators.required],
      options: this.#fb.array([]),
    });
  }

  private formChangesLevel() {
    this.form
      ?.get('level')
      ?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((levelQuantity) => {
        this.addOption(levelQuantity);
        this.#cdr.detectChanges();
      });
  }

  private formChangesOptions() {
    // Assine apenas às mudanças de valor do 'options' para atualizar as labels do slider
    this.form
      .get('options')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        // Aqui assumirá que você está atualizando as labels do slider toda vez que
        // qualquer opção dentro do FormArray muda.
        this.updateSliderLabels(values);
        this.#cdr.detectChanges();
      });
  }

  private updateSliderLabels(values: any[]) {
    values.forEach((value, index) => {
      const labelElement = this.labels.find(l => l.nativeElement.id == `label${index + 1}`)?.nativeElement;

      if (labelElement) {
        let leftPercentage: number = (index / (values.length - 1)) * 100;

        if (index === 0) {
          // Primeira label
          this.#renderer.setStyle(labelElement, 'left', '0%');
          this.#renderer.setStyle(labelElement, 'transform', 'translateX(0%)');
        } else if (index === values.length - 1) {
          // Última label
          this.#renderer.setStyle(labelElement, 'left', '100%');
          this.#renderer.setStyle(labelElement, 'transform', 'translateX(-100%)');
        } else {
          // Labels intermediárias
          this.#renderer.setStyle(labelElement, 'left', `${leftPercentage}%`);
          this.#renderer.setStyle(labelElement, 'transform', 'translateX(-50%)');
        }
      }
    });
  }

  private addOption(levelQuantity: number) {
    if (levelQuantity) {
      const optionsArray = this.form.get('options') as FormArray;
      optionsArray.clear();
      this.#cdr.detectChanges();

      for (let index = 0; index < levelQuantity; index++) {
        const optionGroup = this.#fb.group({
          level: ['level' + (index + 1), Validators.required],
          description: ['', [Validators.required]],
        });
        optionsArray.push(optionGroup);
      }

      // Defina o novo valor máximo para o slider baseado no nível selecionado
      const slider = this.sliderRef.nativeElement as HTMLInputElement;
      slider.max = levelQuantity.toString();

      // Reset o valor do slider para o mínimo para evitar problemas com valores fora de alcance
      this.value = parseInt(slider.min, 10);
      this.onRatingChange(); // Atualiza a visualização do slider para refletir o novo valor de ratingValue
    }
  }

  private getLevels(): ISvcSelectOption[] {
    return [
      {
        text: '2',
        value: 2,
      },
      {
        text: '3',
        value: 3,
      },
      {
        text: '4',
        value: 4,
      },
      {
        text: '5',
        value: 5,
      },
    ];
  }
}
