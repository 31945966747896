import { Component, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { SvcDistributionManageService } from '../svc-distribution-manage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject, catchError, finalize, throwError, tap } from "rxjs";
import { TranslocoService } from '@ngneat/transloco';
import { SvcAppSettings, UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { GetUser, SelectUser } from '../interfaces/user-role.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'svc-distribution-manage-form',
  templateUrl: './svc-distribution-manage-form.component.html',
  styleUrls: ['./svc-distribution-manage-form.component.scss']
})
export class SvcDistributionManageFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public getClick;
  @Output() public formSubmitted = new EventEmitter<void>();

  form!: FormGroup;
  submitted = false;
  listPermission: SelectUser[] = [];
  applicationId: string;
  moduleName: string;
  isEditing = false;
  loading = false;
  actionType: 'POST' | 'PUT' = 'POST';

  public siteId: number;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private formBuilder: FormBuilder,
    private _distributionManageService: SvcDistributionManageService,
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _toastService: SvcToastService,
    private _userService: UserService,
  ) {
    this.moduleName = this._translocoService.translate(this._appSettings.applicationName);
    this.applicationId = this._appSettings.applicationId;
    this.siteId = this._userService.user$.originSiteId;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      responsibles: [{ value: '', disabled: true }],
      area: [{ value: '', disabled: true }]
    });
  }

  ngOnChanges(): void {
    if (this.getClick) {
      this.form.controls['area'].setValue(this.getClick.area);
      this.actionType = this.getClick.responsibles.length === 0 ? 'POST' : 'PUT';
      this.loadUsers();
    }
  }

  private transformUsers(users: GetUser[]): SelectUser[] {
    return users.map(user => ({
      label: user.name,
      selected: this.getClick.responsibles.includes(user.userId),
      value: user.userId
    }));
  }

  private loadUsers(): void {
    this.loading = true;
    this._userService.getUsers().subscribe({
      next: (response) => {
        this.listPermission = this.transformUsers(response);
        this.loading = false;
        if (this.listPermission.length) {
          this.form.controls['responsibles'].enable();
          const selectedUsers = this.listPermission.filter(user => user.selected).map(user => user.value);
          this.form.controls['responsibles'].setValue(selectedUsers);
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }

  public onSubmit(): void {
    const formValues = this.form.getRawValue();
    const responsibleUsers = formValues.responsibles;

    const distribution = {
      areaId: this.getClick.areaId,
      applicationId: this.applicationId,
      siteId: this.siteId,
      responsibleUsers: responsibleUsers
    };

    this.loading = true;

    if (this.actionType === 'POST') {
      this._distributionManageService.createDistribution(distribution).pipe(
        untilDestroyed(this),
        tap((response) => {
          this._toastService.success(this._translocoService.translate('Alteração salva com sucesso'));
          this.formSubmitted.emit();
        }),
        catchError((err) => {
          this._toastService.error(this._translocoService.translate('Erro ao criar distribuição'));
          throw err;
        }),
        finalize(() => {
          this.loading = false;
          this.resetForm();
        })
      ).subscribe();
    } else if (this.actionType === 'PUT') {
      const updateDistribution = {
        distributionId: this.getClick.distributionId,
        ...distribution
      };

      this._distributionManageService.updateDistribution(updateDistribution).pipe(
        untilDestroyed(this),
        tap((response) => {
          this._toastService.success(this._translocoService.translate('Alteração salva com sucesso'));
          this.formSubmitted.emit();
        }),
        catchError((err) => {
          this._toastService.error(this._translocoService.translate('Erro ao atualizar distribuição'));
          throw err;
        }),
        finalize(() => {
          this.loading = false;
          this.resetForm();
        })
      ).subscribe();
    }
  }

  public onClear(): void {
    const deletePayload = {
      distributionId: this.getClick.distributionId,
      areaId: this.getClick.areaId,
      applicationId: this.applicationId,
      siteId: this.siteId
    };

    this.loading = true;

    if (!this.getClick.distributionId) {
      this.resetForm();
      this.loading = false;
    } else {
      this._distributionManageService.deleteDistribution(deletePayload).pipe(
        tap(() => {
          this._toastService.success(this._translocoService.translate('Removido com sucesso'));
          this.form.reset();
          this.form.controls['responsibles'].disable();
          this.formSubmitted.emit();
        }),
        catchError((err) => {
          this._toastService.error(this._translocoService.translate('Erro ao remover distribuição'));
          throw err;
        }),
        finalize(() => {
          this.loading = false;
        })
      ).subscribe();
    }
  }

  private resetForm(): void {
    this.form.reset({
      responsibles: { value: '', disabled: true },
      area: { value: '', disabled: true }
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
