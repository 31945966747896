import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from "@ngneat/transloco";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcButtonsModule } from "../svc-buttons/svc-buttons.module";
import { SvcDialogConfigComponent } from './components/svc-dialog-config/svc-dialog-config.component';
import { SvcDialogPrimaryComponent } from './components/svc-dialog-primary/svc-dialog-primary.component';
import { SvcConfirmDialogComponent } from './components/svc-confirm-dialog/svc-confirm-dialog.component';
import {
  SvcDialogActions,
  SvcDialogComponent,
  SvcDialogContent,
  SvcDialogTitle
} from './components/svc-dialog/svc-dialog.component';
import { SvcDialogService } from "./svc-dialog.service";
import { SvcModalModule } from '../modals/svc-modal.module';
import { SvcWarningDialogComponent } from './components/svc-warning-dialog/svc-warning-dialog.component';

@NgModule({
  declarations: [
    SvcDialogConfigComponent,
    SvcDialogPrimaryComponent,
    SvcDialogComponent,
    SvcDialogActions,
    SvcDialogTitle,
    SvcDialogContent,
    SvcConfirmDialogComponent,
    SvcWarningDialogComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedIconsModule,
    SvcModalModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    SvcButtonsModule,
    TranslocoModule,
    MatMenuModule
  ],
  exports: [
    SvcDialogConfigComponent,
    SvcDialogPrimaryComponent,
    SvcDialogComponent,
    SvcDialogActions,
    SvcDialogTitle,
    SvcDialogContent,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    SvcDialogService
  ]
})
export class SvcDialogsModule { }
