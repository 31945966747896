import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-text-box-answer',
  templateUrl: './text-box-answer.component.html',
  styleUrls: ['./text-box-answer.component.scss'],
})
export class TextBoxAnswerComponent implements OnInit {
  @ViewChild('text') text: ElementRef;

  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public isSave = false;

  #fb = inject(FormBuilder);
  #modalService = inject(SurveyModalService);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.form = this.#fb.group({
      text: [this.question?.metadata?.text ?? '', Validators.maxLength(1000)],
    });

    this.changesAnswered();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  private changesAnswered() {
    this.form
      ?.get('text')
      ?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.question.answered = response !== '';
      });
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    this.doSave.emit({ Text: this.form.value.text });
  }
}
