import {NgModule} from "@angular/core";
import { LibSharedCommonModule } from "projects/lib-shared-common/src/public-api";
import {SvcUserAvatarComponent} from "./svc-user-avatar.component";
import { SvcAvatarMultipleComponent } from './svc-avatar-multiple.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [
    LibSharedCommonModule,
    MatTooltipModule,
    MatIconModule,
  ],
  declarations: [
    SvcUserAvatarComponent,
    SvcAvatarMultipleComponent
  ],
  exports: [
    SvcUserAvatarComponent,
    SvcAvatarMultipleComponent
  ],
})
export class SvcUserAvatarModule {}
