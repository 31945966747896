<div class="flex flex-col h-full w-full bg-card rounded-lg" (click)="onCardClick.emit()">
  <div class="table border-b min-h-10">
    <img class="cursor-pointer"
      [class.object-cover]="communication?.attachment?.assignUrl"
      [class.object-none]="!(communication?.attachment?.assignUrl)"
      [src]="communication?.attachment?.assignUrl ? communication.attachment.assignUrl : 'assets/default_card_thumbnail.png'"
      (error)="communication.attachment = null"
    />
  </div>

  <div class="flex flex-col flex-auto m-3 mb-2">
    <div class="flex flex-col h-full cursor-pointer">
      <div class="flex text-base text-default font-semibold leading-none line-clamp-2 break-words pb-2">
        <p #titleEl class="line-clamp-2 break-words" [matTooltip]="titleEl.scrollHeight > (titleEl.clientHeight + 1) ? communication.title : null" matTooltipPosition="above">
          {{ communication.title }}
        </p>
      </div>
      <div class="flex text-sm text-default font-normal leading-tight line-clamp-3 break-words pb-4">
        <p *ngIf="(communication.message ?? '' | removeHtml).length" #messageEl class="line-clamp-3 break-words" [matTooltip]="messageEl.scrollHeight > messageEl.clientHeight ? (communication.message | removeHtml) : null" matTooltipPosition="below">
          {{ communication.message | removeHtml }}
        </p>
      </div>
    </div>

    <svc-totals-status class="h-fit w-fit mb-2"
      [referenceId]="communication.communicationId"
      [applicationId]="applicationId"
      [configTypeId]="configType"
      [featureName]="featureName"
    ></svc-totals-status>

    <div class="flex flex-row flex-wrap border-t items-center pt-2 -mx-3 px-3 gap-2">
      <div class="flex -mx-2">
        <svc-button
          size="sm"
          mode="simple"
          color="primary"
          class="com-button cursor-pointer font-bold"
          buttonId="communication-card-id"
        >
          {{ "COM" | transloco }}-{{ communication.communicationId }}
        </svc-button>
      </div>

      <svc-priority-button
        class="flex cursor-pointer"
        [priority]="communication.isFavorite"
        iconSizeClass="icon-size-4"
        (onPriorityChange)="isFavorite($event)"
        (click)="$event.stopPropagation()"
        buttonId="communication-card-favorite"
      ></svc-priority-button>

      <div *ngIf="communication.isPrivate !== null">
        <svc-lock-button
          class="flex cursor-pointer"
          [iconSizeClass]="'icon-size-5'"
          [readonly]="true"
          [locked]="communication.isPrivate"
          buttonId="communication-card-lock"
        ></svc-lock-button>
      </div>

      <div class="flex flex-auto justify-end text-default">
        <svc-status-badge *ngIf="fromCommunicationMobule && statusBadge" [badgeData]="statusBadge"></svc-status-badge>
        <mat-icon *ngIf="!fromCommunicationMobule"
          [matTooltip]="(communication.isRead ? 'Lido' : 'Não lido') | transloco"
          [svgIcon]="'mat_solid:'+(communication.isRead ? 'visibility' : 'visibility_off')"
          class="text-default icon-size-5"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>
