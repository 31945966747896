import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthUtils } from '../../../../lib-shared-feature/src/lib/auth/auth.utils';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { RequestCollectorService } from 'projects/lib-shared-common/src/public-api';
import { AuthCheckService } from './auth-check.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _appConfig: AppEnvironmentConfig,
    private _requestCollectorService: RequestCollectorService,
    private _authCheckService: AuthCheckService,
  ) { }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    return new Observable((subscriber) => {
      if (this._authCheckService.checkingIsActive && !this._authCheckService.checkSessionState()) {
        subscriber.complete();
        return () => {};
      }

      let newReq = req.clone();
      let headers = new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this._authService.accessToken
      );

      if(!newReq.headers.get('Authorization')){
        try {
          if (
            this._authService.accessToken &&
            !AuthUtils.isTokenExpired(this._authService.accessToken)
          ) {
            newReq = newReq.clone({
              headers: headers,
            });
          }
        }
        catch (_) {}
      }

      // Response
      let subscription = next.handle(newReq).pipe(
        tap({
          next: (res) => subscriber.next(res),
          complete: () => {
            this._authCheckService.interceptRequest();
            subscriber.complete()
          },
          finalize: () => subscriber.complete(),
          error: (error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this._authService.signOut();
              location.reload();
            }
            subscriber.error(error);
          },
        }),
      ).subscribe();

      this._requestCollectorService.add(subscription, newReq);

      return () => subscription.unsubscribe();
    });
  }
}
