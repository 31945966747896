import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import {  AfterViewInit, Component, ElementRef, HostListener, Inject, Injector, Input, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { convertDateStrToSpecificFormat, dateIsValid, dateStringToDate } from 'projects/lib-shared-common/src/public-api';
import { takeUntil, tap } from 'rxjs/operators';
import { DateValidators } from './date-picker-validators';
import { Subject } from 'rxjs';
import { SvcControl } from '../svc-control';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'svc-date-picker',
  templateUrl: './svc-date-picker.component.html',
  styleUrls: ['./svc-date-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SvcDatePickerComponent),
      multi: true
    }
  ],
})
export class SvcDatePickerComponent extends SvcControl implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef<HTMLElement>,
    private _datePipe: DatePipe,
    private _svcAppSettings: SvcAppSettings,
    @Inject(Injector) injector: Injector,
  ) {
    super(injector);
  }

  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input('min') minDate?: Date;
  @Input('max') maxDate?: Date;
  @Input() format: string;
  @Input() inputId: string;
  @Input() showIcon: boolean = true;

  @ViewChild('matCalendarTemplate') matCalendarTemplate: TemplateRef<any>;
  @ViewChild('formFieldContainer') formFieldContainer: ElementRef<HTMLElement>;
  @ViewChild(MatInput) matInput: MatInput;

  public mask: string;
  public currentDate: Date;
  public calendarIsOpened = false;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private inputFocused = false;
  private avoidCloseCalendar = false;
  private internalIsSettingValue = false;
  private initWasCalled = false;
  private prevValidators: ValidatorFn[] = [];
  // private initialValidators: ValidatorFn[] = [];

  public ngOnInit(): void  {
    super.ngOnInit();

    this.format = this.format ?? this._svcAppSettings.dateFormat;
    // this.initialValidators = this.control?.['_rawValidators'] ?? [];
    this._prepareMask();
    this.checkValidators();

    this.control?.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      tap((dateStr) => {
        if (!this.internalIsSettingValue) {
          if (dateIsValid(dateStr, 'yyyy-MM-dd')) {
            const date = dateStringToDate(dateStr, 'yyyy-MM-dd');
            if (this.matInput) this.matInput.value = this._datePipe.transform(date, this.format);
            this.currentDate = date;
          }
          else {
            if (this.matInput) this.matInput.value = null;
            this.currentDate = null;
          }
        }
      }),
    ).subscribe();
    this.initWasCalled = true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._prepareControl());
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.initWasCalled) {
      if ('format' in changes) {
        this.format = this.format ?? this._svcAppSettings.dateFormat;
      }
      if ('minDate' in changes || 'maxDate' in changes) {
        this.checkValidators();
      }
    }
  }

  private _prepareControl() {
    if (!this.control && this.value) {
      const value = this._datePipe.transform(this.value, this.format);
      if (this.matInput) this.matInput.value = value;
      this.currentDate = dateStringToDate(value, this.format);
    }
    if (this.control && this.control.value) {
      const value = this._datePipe.transform(this.control.value, this.format);
      if (this.matInput) this.matInput.value = value;
      this.currentDate = dateStringToDate(value, this.format);
    }
  }

  private checkValidators() {
    if (this.control) {
      const rawValidators = this.control?.['_rawValidators'] ?? [];
      const initialValidators = (typeof rawValidators === 'function' ? [rawValidators] : rawValidators).filter(x => !this.prevValidators.includes(x));
      let validators = [DateValidators.isValid(this.format)];

      if (this.minDate) {
        validators.push(DateValidators.min(this.minDate, this.format));
      }
      if (this.maxDate) {
        validators.push(DateValidators.max(this.maxDate, this.format));
      }

      this.prevValidators = validators;
      validators = [
        ...initialValidators,
        ...validators
      ];

      this.control.setValidators(validators);
      if (this.initWasCalled) {
        this.control.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  private _prepareMask() {
    const separatorSymbol = this.format.replace(/[aA-zZ]/g, '')[0] ?? '';
    this.mask = this.format?.split('').map((char) => char !== separatorSymbol ? '0' : separatorSymbol).join('');
  }

  protected showMatCalendar(): void {
    if (this.control?.disabled ?? false) {
      return;
    }

    if (!this._overlayRef && this.formFieldContainer.nativeElement) {
      // Create the overlay
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'svc-backdrop-on-mobile',
        scrollStrategy: this._overlay.scrollStrategies.block(),
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(
            this.formFieldContainer.nativeElement,
          )
          .withLockedPosition(true)
          .withPush(true)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
            },
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            },
            {
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom',
            },
          ]),
      });

      // Detach the overlay from the portal on backdrop click
      this._overlayRef.backdropClick().subscribe(() => {
        this._overlayRef.detach();
      });

      this._overlayRef.detachments().subscribe(() => {
        this.calendarIsOpened = false;
      });
    }

    if (!this.calendarIsOpened) {
      try {
        this._overlayRef.attach(
          new TemplatePortal(this.matCalendarTemplate, this._viewContainerRef)
        );
        this.calendarIsOpened = true;
      }
      catch{}
    }
  }

  @HostListener('document:mousedown', ['$event'])
  private onClick(event: MouseEvent) {
    if (this.calendarIsOpened && this.inputFocused) {
      if (document.querySelector('mat-calendar')?.contains(event.target as any)) {
        this.avoidCloseCalendar = true;
      }
    }
  }

  @HostListener('document:keydown.esc')
  private onEsc() {
    this.closeMatCalendar();
  }

  protected closeMatCalendar(): void {
    if (this.calendarIsOpened) {
      setTimeout(() => {
        if (!this.avoidCloseCalendar) {
          this._overlayRef.detach();
        }
        this.avoidCloseCalendar = false;
      }, this.inputFocused ? 100 : 0);
    }
  }

  protected onInternalValueChanged() {
    this.internalIsSettingValue = true;
    const dateStr = this.matInput?.value ?? '';
    if (dateStr.length === this.format.length) {
      const formattedDate = convertDateStrToSpecificFormat(dateStr, this.format, 'yyyy-MM-dd');
      this.control?.setValue(formattedDate?.date ?? null);
      if (dateIsValid(dateStr, this.format)) {
        const date = dateStringToDate(dateStr, this.format);
        this.currentDate = date;
      }
      else {
        this.currentDate = null;
      }
    }
    else {
      this.control?.setValue(null);
      this.currentDate = null;
    }
    this.internalIsSettingValue = false;
  }

  protected onSelect(event: Date): void  {
    this._overlayRef.detach();
    if (this.matInput) {
      this.matInput.value = this._datePipe.transform(event, this.format);
      this.onInternalValueChanged();
    }
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
