import { Component } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'svc-date-picker-calendar-header',
  templateUrl: './svc-date-picker-calendar-header.component.html',
  styleUrls: ['./svc-date-picker-calendar-header.component.scss']
})
export class SvcDatePickerCalendarHeaderComponent {

  constructor(public calendar: MatCalendar<Date>) { }

  public previousClicked(): void {
    this.calendar.activeDate = new Date(
      this.calendar.activeDate.getFullYear(),
      this.calendar.activeDate.getMonth() - 1,
      1
    );
  }

  public nextClicked(): void {
    this.calendar.activeDate = new Date(
      this.calendar.activeDate.getFullYear(),
      this.calendar.activeDate.getMonth() + 1,
      1
    );
  }
}
