import { Injectable, OnDestroy} from '@angular/core';
import { cookieExist, getCookie, setCookie } from 'projects/lib-shared-common/src/public-api';
import { debounceTime, fromEvent, interval, Subscription} from 'rxjs';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { TranslocoService } from "@ngneat/transloco";
import { AuthService} from './auth.service';
import { debounce } from 'lodash';
import { SvcAppSettings } from '../settings/svc-app-settings';
import { SvcModule } from '../settings/enums/svc-module.enum';
import { Router } from '@angular/router';
import { environment } from 'projects/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthCheckService implements OnDestroy {

  #checkingIsActive = false;
  #authenticated = false;
  #waitingConfirm = false;
  #userLastMoveDate: Date = new Date();
  #intervalSubscription: Subscription;
  #mouseSubscription: Subscription;
  #checkInterval = interval(60000);
  #cookieLegacyIntegrationApi = '2906AC24C7324A2EA1BC2FF27CB08F9A';
  #cookieLastAccess = 'A9673308967047B883713587A240F0DF';
  #cookieTimeout = '399E3DB191D941ACA3B84EBD5B69F167';
  #cookieActive = '5E32D867A42C48358F909F85F78056CE';
  #translationTimeoutSessionMessage = "Sua sessão irá expirar em 2 minutos. Deseja continuar logado?";
  #translationExpiredSessionMessage = "Sua sessão não está mais ativa. Você será redirecionado para a tela de login.";
  #debouncedRefreshSessionState = debounce(this.refreshSessionState.bind(this), 500);

  public get checkingIsActive(){ return this.#checkingIsActive; }

  constructor(
    private _dialog: SvcDialogService,
    private _transloco: TranslocoService,
    private _authService: AuthService,
    private _appSettings: SvcAppSettings,
    private _router: Router,
  ) {
  }

  ngOnDestroy(): void {
    this.stopCheckSessionState();
  }

  public startCheckSessionState() {

    if (environment.isLocalhost || [SvcModule.Notification, SvcModule.ExternalFeatures].includes(this._appSettings.module))
      return;

    this._authService.check().subscribe(authenticated => {
      this.#authenticated = authenticated;
      if (this.#authenticated) {
        this.stopCheckSessionState();
        this.#checkingIsActive = true;
        this.#intervalSubscription = this.#checkInterval.subscribe(interval => {
          this.checkSessionState()
        });
        this.#mouseSubscription = fromEvent(document.body, 'mousemove')
          .pipe(debounceTime(500))
          .subscribe((e) => {
            this.#userLastMoveDate = new Date();
          });
      }
    })
  }

  public stopCheckSessionState() {
    this.#checkingIsActive = false;
    this.#intervalSubscription?.unsubscribe();
    this.#mouseSubscription?.unsubscribe();
  }

  public interceptRequest() {
    if (!this.#authenticated) return;
    const dif = new Date().getTime() - this.#userLastMoveDate.getTime();
    if (dif <= 60000) this.#debouncedRefreshSessionState();
  }

  public refreshSessionState() {
    setCookie(this.#cookieLastAccess, this.getNowDatetime());
  }

  public checkSessionState(): boolean {

    const accessToken = this._authService.accessToken;
    const legacyToken = getCookie(this.#cookieLegacyIntegrationApi);
    const lastAccess = getCookie(this.#cookieLastAccess);
    const idleTimeout = getCookie(this.#cookieTimeout) || '60';

    if (!legacyToken || !accessToken || (cookieExist(this.#cookieLastAccess) && !this.isValidUrlEncodedDate(lastAccess)) || !this.isConvertibleToNumber(idleTimeout)) {
      this._dialog.closeAll();
      this.stopCheckSessionState();
      setTimeout(() => {
        alert(this._transloco.translate(this.#translationExpiredSessionMessage));
        this.killSessionState();
      })
      return false;
    }

    if (!cookieExist(this.#cookieLastAccess)) return true;
    const remaining = this.getRemainingTime(Number(idleTimeout), lastAccess);

    if (remaining > 0 && remaining <= 2 && !this.#waitingConfirm) {
      this.#waitingConfirm = true;
      this._dialog.openConfirm(this._transloco.translate(this.#translationTimeoutSessionMessage), {
        okText: this._transloco.translate("Sim"),
        cancelText: this._transloco.translate("Não"),
        title: this._transloco.translate("Confirmação"),
      }).subscribe(confirm => {
        this.#waitingConfirm = false;
        if (confirm) {
          this.stopCheckSessionState();
          this.refreshSessionState();
          this.startCheckSessionState();
        } else {
          this.killSessionState();
        }
      });
      return true;
    }

    if (remaining <= 0) {
      this._dialog.closeAll();
      this.stopCheckSessionState();
      setTimeout(() => {
        alert(this._transloco.translate(this.#translationExpiredSessionMessage));
        this.killSessionState();
      })
      return false;
    }

    return true;
  }

  private killSessionState() {
    this.stopCheckSessionState();
    this._authService.signOut(true).subscribe(() => {
      this._router.navigate(['sign-in']);
    });
  }

  private isValidUrlEncodedDate(dateStr: string): boolean {
    const regex = /^\d{2}%2F\d{2}%2F\d{4}(%2B|%20)\d{2}%3A\d{2}%3A\d{2}$/;
    return regex.test(dateStr);
  }

  private isConvertibleToNumber(value: string): boolean {
    const number = parseFloat(value);
    return !isNaN(number) && isFinite(number);
  }

  private getRemainingTime(timeout: number, lastAccess: string) {
    const dateStr = decodeURIComponent(lastAccess).replace('+', ' ');
    const differenceInMs = (new Date().getTime() - this.parseDate(dateStr).getTime());
    return timeout - (Math.round(differenceInMs) / (1000 * 60));
  }

  private parseDate(dateStr: string): Date {
    const [day, month, yearAndTime] = dateStr.split('/');
    const [year, time] = yearAndTime.split(' ');
    const [hour, minute, second] = time.split(':');
    return new Date(Date.UTC(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hour, 10),
      parseInt(minute, 10),
      parseInt(second, 10)
    ));
  }

  private getNowDatetime() {
    const now = new Date();
    return encodeURIComponent(`${now.getUTCDate().toString().padStart(2, '0')}/${(now.getUTCMonth() + 1).toString().padStart(2, '0')}/${now.getUTCFullYear()}+${now.getUTCHours().toString().padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')}:${now.getUTCSeconds().toString().padStart(2, '0')}`)
  }

}
