import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SvcProgressBarComponent } from './svc-progress-bar/svc-progress-bar.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcMultiColorProgressBarComponent } from './svc-multicolor-progress-bar/svc-multicolor-progress-bar.component';

@NgModule({
  imports: [
    LibSharedCommonModule
  ],
  declarations: [SvcProgressBarComponent, SvcMultiColorProgressBarComponent],
  exports: [SvcProgressBarComponent, SvcMultiColorProgressBarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SvcProgressBarModule {}
