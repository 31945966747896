<form [formGroup]="form" [ngClass]="{ 'pointer-events-none': readonly }">
  <svc-text-area
    class="w-full"
    formControlName="text"
    [minRows]="2"
    [maxRows]="3"
    [autosize]="true"
    charCount
    [maxLength]="1000"
    [placeholder]="'Digite aqui' | transloco"
  ></svc-text-area>
</form>

<div class="flex justify-end mt-3">
  <mat-icon
    *ngIf="isAnswered"
    class="icon-size-7 text-green-500"
    svgIcon="heroicons_outline:check-circle"
  ></mat-icon>

  <span class="text-sm text-red-600" *ngIf="isSave && !isValid">
    {{ "Resposta é obrigatória" | transloco }}*
  </span>
</div>
