import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  ISvcChartIndicatorDefinitions,
  ISvcChartIndicatorInfo,
  ISvcChartIndicatorOrientation,
} from './interfaces/svc-chart-indicator.interface';
import { ECharts, EChartsOption } from 'echarts';
import {LegendComponent} from "echarts/components";
import {formatNumber} from "@angular/common";
import { formatNumberDefault } from 'projects/lib-shared-common/src/public-api';

@Component({
  selector: 'svc-chart-indicator',
  templateUrl: './svc-chart-indicator.component.html',
  styleUrls: ['./svc-chart-indicator.component.scss'],
})
export class SvcChartIndicatorComponent implements OnChanges {
  instance: ECharts;

  @Input() height: string = '200px';
  @Input() orientation: ISvcChartIndicatorOrientation = ISvcChartIndicatorOrientation.HORIZONTAL;
  @Input() chartInfo: ISvcChartIndicatorInfo = {} as ISvcChartIndicatorInfo;
  @Input() chartDefinitions: ISvcChartIndicatorDefinitions | null = null;
  @Input() errorMsg: string;
  @Output() chartClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();

  chartOptions: Partial<EChartsOption> = {
    tooltip: {
      borderWidth: 0,
      backgroundColor: '#334155',
      textStyle: {
        color: '#fff',
        fontSize: 12,
      },
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (chartInfo: any) {
        const params = chartInfo.filter((p: any) => p.value != null && p.value > 0);
        if (params.length == 0) return null;
        const text = params.map((p: any) => (
          `<div class="flex flex-row space-x-3 items-center">
            <div class="grow">${p.marker}${p.seriesName}</div>
            <div>${formatNumberDefault(p.value)}</div>
          </div>`
        ));
        return `<div class="flex flex-col min-w-[130px]">
          <div>${params[0]?.axisValue}</div>
          <hr class="mt-1 mb-2"/>
          <div class="flex flex-col gap-1">${text.join('')}</div>
        </div>`;
      },
    },
    legend: {
      show: true,
      orient: 'horizontal',
      left: 'left',
      top: 'bottom',
      icon: 'circle',
      padding: [0, 7],
      itemGap: 5,
      itemHeight: 10,
      itemWidth: 10,
      textStyle: {
        color: '#898989',
        fontSize: 11,
      },
    },
    grid: {
      top: '0',
      left: '10',
      right: '10',
      bottom: '20',
      containLabel: true,
    },
    series: [],
  };

  onChartInit(evt: ECharts) {
    this.instance = evt;
  }

  onChartClick(evt: any){
    this.chartClick.emit({
      name: evt.name,
      serieName: evt.seriesName,
      color: evt.color,
      value: evt.value
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartDefinitions) {
      this.updateOptions();
      this.generateCategories();
      this.generateSeries();
      if(this.instance)
        this.instance.setOption(this.chartOptions);
    }
  }

  private updateOptions(){
    // @ts-ignore
    (<LegendComponent>this.chartOptions.legend).show = this.chartDefinitions?.showLegend ?? true;
  }

  private updateCategories(definitions: Partial<EChartsOption>) {
    this.chartOptions.yAxis = definitions.yAxis;
    this.chartOptions.xAxis = definitions.xAxis;
  }

  private updateSeries(definitions: Partial<EChartsOption>) {
    this.chartOptions.series = definitions.series;
  }

  private generateCategories() {
    if (this.orientation == ISvcChartIndicatorOrientation.HORIZONTAL) {
      this.updateCategories({
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.chartDefinitions.categories.map(c => c.label),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 0,
            fontSize: 11,
            color: '#898989',
            align: 'right',
            margin: 20
          },
        },
      });
    } else {
      this.updateCategories({
        xAxis: {
          type: 'category',
          data: this.chartDefinitions.categories.map(c => c.label),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 90,
            fontSize: 11,
            color: (v, i) => {
              return this.chartDefinitions?.categories[i]?.error
                ? 'red'
                : '#898989';
            },
            align: 'right',
            margin: 5
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
        },
      });
    }
  }

  private generateSeries() {
    const items: any[] = [];


    this.chartDefinitions.series.forEach((serie, i) => {

      items.push({
        name: serie.name,
        type: 'bar',
        stack: this.chartDefinitions.stacked ?? false ? 'stacked' : i.toString(),
        barMaxWidth: 25,
        barMinHeight: serie.minHeight ?? 25,
        label: {
          distance: 10,
          rotate: this.orientation == ISvcChartIndicatorOrientation.VERTICAL ? 90 : 0,
          align: this.orientation == ISvcChartIndicatorOrientation.VERTICAL ? (this.chartDefinitions.stacked ?? false ? 'left' : 'right') : 'right',
          verticalAlign: 'middle',
          position: this.orientation == ISvcChartIndicatorOrientation.VERTICAL ? (this.chartDefinitions.stacked ?? false ? 'insideBottom' : 'insideTop') : `insideRight`,
          fontSize: 11,
          show: true,
          color: '#ffffff',
          formatter: (p) => (formatNumberDefault(p?.value))
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: serie.color,
          borderColor: 'transparent',
          borderWidth: 5,
          borderRadius: 50,
        },
        data: serie.values.map((x) => x == 0 ? null : x),
      });
    });

    this.updateSeries({ series: items });
  }

  public refresh(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.onRefresh.emit();
  }
}
