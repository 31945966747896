<div *screenSizes="let size" class="flex" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)" (mouseleave)="onMouseUp($event)">
  <div class="flex items-center px-2 text-primary" [ngClass]="{ 'invisible': scrollerContainer.scrollWidth <= scrollerContainer.clientWidth, 'text-gray-400': scrollerContainer.scrollLeft <= 0 }">
    <mat-icon
      class="custom-icon flex h-full cursor-pointer icon-size-4 -mr-2 text-current"
      svgIcon="heroicons_solid:chevron-left"
      (click)="scrollerContainer.scrollTo({left: scrollerContainer.scrollLeft - 50, behavior: 'smooth'})"
    ></mat-icon>
  </div>

  <div #scrollerContainer [ngClass]="{'dragging': isDragging, 'grabbable': !isDragging, 'overflow-x-auto': size.isXS, 'overflow-x-hidden': !size.isXS }" class="flex-auto flex" [class.justify-center]="scrollerContainer.scrollWidth <= scrollerContainer.clientWidth">
    <mat-stepper linear #stepper>
      <mat-step
        *ngFor="let step of questions; let i = index"
        [state]="step?.answered ? 'done' : undefined"
        [aria-labelledby]="(step?.answered && (i + 1) < questions.length && questions[i + 1].answered) ? 'next-was-answered' : 'next-wasnt-answered'"
        [editable]="false"
      ></mat-step>
      <ng-template matStepperIcon="done">
        <mat-icon
          class="icon-size-7"
          svgIcon="heroicons_outline:check-circle"
        ></mat-icon>
      </ng-template>
    </mat-stepper>
  </div>

  <div class="flex items-center px-2 text-primary" [ngClass]="{ 'invisible': scrollerContainer.scrollWidth <= scrollerContainer.clientWidth, 'text-gray-400': (scrollerContainer.clientWidth + scrollerContainer.scrollLeft) >= scrollerContainer.scrollWidth }">
    <mat-icon
      class="custom-icon flex h-full cursor-pointer icon-size-4 -ml-2 text-current"
      svgIcon="heroicons_solid:chevron-right"
      (click)="scrollerContainer.scrollTo({left: scrollerContainer.scrollLeft + 50, behavior: 'smooth'})"
    ></mat-icon>
  </div>
</div>
