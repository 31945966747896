import {getCookie} from "./cookie-reader";
import {SvcHttpClient} from "../classes/svc-http-client";
import {HttpClient, HttpHeaders} from "@angular/common/http";

export function getLegacyApiUrl(): string{
  return decodeURIComponent(getCookie('F446E9D07E364203B3DCD9590D559A02'));
}

export function getLegacyApiToken(): string {
  return getCookie('2906AC24C7324A2EA1BC2FF27CB08F9A');
}

export function changeSiteCallback(httpClient: HttpClient, siteId: number) {
  let svcHttpClient = new SvcHttpClient(getLegacyApiUrl(), httpClient);
  let headers = new HttpHeaders().set(
    'Authorization',
    'Bearer ' + getLegacyApiToken()
  );

  return svcHttpClient.post('/api/user/update-site-user',
    {
    site_id: siteId
  }, { headers: headers});
}

export function signinLegacyApi(httpClient: HttpClient, baseUrl: string, ssoId: string) {
  let svcHttpClient = new SvcHttpClient(getLegacyApiUrl(), httpClient);

  return svcHttpClient.post<{ token: string}>('/api/auth/sub',
    {
      environmentUrl: baseUrl,
      sub: ssoId
    });
}
