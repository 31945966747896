<ng-container *screenSizes="let size">
  <div *ngIf="_post && !!_post.id" class="flex flex-col relative bg-white rounded-lg" [ngClass]="{ 'p-4 shadow': size.isAboveSM, 'px-3 py-1': size.isBelowMD }">

    <svc-post-item-title [post]="_post" [avatarSize]="_avatarSize"></svc-post-item-title>
  
    <!-- TEXT -->
    <div *ngIf="text?.length" class="mt-3 text-justify font-light cursor-force" (click)="viewPost()">
      <svc-post-mention #mentionComponent [(ngModel)]="text" [readOnly]="true" [cursorForce]="true" [lineClamp]="true" [post]="_post"></svc-post-mention>

      <svc-post-translate [text]="textTranslated" [loading]="translatingText" (translate)="translatePost()"></svc-post-translate>
    </div>

    <!-- PICTURE -->
    <div *ngIf="_mediasCarousel && _mediasCarousel.length > 0" class="cursor-pointer bg-gray-50 mt-3" (click)="viewPost()" [id]="'post-open-photo'+_post.id">
      <svc-carousel
        [slides]="_mediasCarousel"
        fitContent="contain"
        [height]="400"
        [video-controls]="true"
        [imageViewer]="false"
        (click)="this.currentSlide?.type === 'video' ? $event.stopPropagation() : null"
        (currentSlide)="setCurrentSlide($event)"
      ></svc-carousel>
    </div>

    <svc-post-item-footer #postItemFooterComponent [post]="_post"></svc-post-item-footer>

  </div>

</ng-container>