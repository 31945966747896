import { Injectable, Injector } from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { fromEvent, Subject, takeUntil, tap } from 'rxjs';

import { SvcDrilldownComponent } from '../../svc-drilldown.component';
import { SvcDrilldownConfig } from '../interfaces/svc-drilldown-config.interface';
import { SvcDataPrepareService, SvcDataTableEvent, SvcDataVirtualScrollService } from '../../../svc-data-table/public-api';
import { SVC_DRILLDOWN_CONFIG_DATA } from '../utils/svc-drilldown-config-data';

@Injectable({
  providedIn: 'root'
})
export class SvcDrilldownService {

  public isLoading$ = new Subject<boolean>();
  public items$ = new Subject<any>();
  public dataLength$ = new Subject<number>();
  public closeDrilldown$ = new Subject<boolean>();
  public clickItemTypeId$ = new Subject<any>();
  public onGetData$ = new Subject<SvcDataTableEvent>();

  private _overlayRef: OverlayRef;
  private _containerRef: HTMLElement;
  private _resizeUnsubscribe$ = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
  ) { }

  public openDrilldown(containerRef: HTMLElement, drilldownConfig: SvcDrilldownConfig): void {
    if (this._overlayRef) this.closeDrilldown();

    this._containerRef = containerRef;

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'svc-drilldown-transparent-backdrop',
      positionStrategy: this._getPositionStrategy(),
      scrollStrategy: this._overlay.scrollStrategies.noop(),
    });

    this._overlayRef = this._overlay.create(config);

    this._overlayRef.hostElement.classList.add('svc-drilldown-overlay-scroll');

    const injector: Injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: SVC_DRILLDOWN_CONFIG_DATA, useValue: drilldownConfig },
        { provide: SvcDataPrepareService },
        { provide: SvcDataVirtualScrollService },
      ]
    });

    this._overlayRef.attach(new ComponentPortal(SvcDrilldownComponent, null, injector));

    this._overlayRef.backdropClick().subscribe(() => this.closeDrilldown(true));
    this._onWindowResize();
  }

  private _isMobile(): boolean {
    return window.innerWidth < 400 || window.innerHeight < 500;
  }

  private _isElementVisible(): boolean {
    return this._containerRef.getBoundingClientRect().top >= 0;
  }

  private _getPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this._overlay.position()
      .flexibleConnectedTo(this._isMobile() || !this._isElementVisible() ? document.body : this._containerRef)
      .withPush(this._isMobile() ? true : false)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);
  }

  private _onWindowResize = () => {
    if (this._overlayRef) {
      fromEvent(window, 'resize').pipe(
        tap(() => {
          if (this._overlayRef) {
            this._overlayRef.updatePositionStrategy(this._getPositionStrategy());
            this._overlayRef.updatePosition();
          }
          else
            this._resizeUnsubscribe();
        }),
        takeUntil(this._resizeUnsubscribe$)
      ).subscribe();
    }
  }

  private _resizeUnsubscribe(): void {
    this._resizeUnsubscribe$.next();
    this._resizeUnsubscribe$.complete();
  }

  public closeDrilldown(updateValues = false): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this.closeDrilldown$.next(updateValues);
      this._resizeUnsubscribe();
    }
  }
}
