import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { catchError, finalize, forkJoin, Subject, tap } from 'rxjs';
import { SvcNotificationManagementService } from './svc-notification-management.service';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { RuleConfiguration, RuleConfigurationType } from './models/rule-configuration.model';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { FormControl } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { ConfigurationTypePipe } from './pipes/configuration-type.pipe';

@Component({
  selector: 'svc-notification-management',
  templateUrl: './svc-notification-management.component.html',
  styleUrls: ['./svc-notification-management.component.scss'],
})
export class SvcNotificationManagementComponent implements OnInit {
  protected moduleName: string;
  protected loading: boolean = true;
  protected rules: RuleConfiguration[];
  protected columns: string[] = [];
  protected typesNeedToUpdate: { id: number, value: boolean, slideToggle?: MatSlideToggle }[] = [];
  protected initialRuleConfigTypes: RuleConfigurationType[] = [];
  protected configTypePipe = new ConfigurationTypePipe();

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _httpErrorService: HttpErrorService,
    private _toastService: SvcToastService,
    private _notificationManagementService: SvcNotificationManagementService,
  ) {
    this.moduleName = _translocoService.translate(_appSettings.applicationName);
  }

  ngOnInit(): void {
    this._getNotificationManagement();
  }

  onCheckboxValueChanged(rule: RuleConfiguration, type: RuleConfigurationType, selected: boolean, slideToggle?: MatSlideToggle) {
    const value = rule.control?.value ?? [];
    if (selected) {
      rule.control.setValue([...new Set([type.configurationId, ...value])]);
    }
    else {
      rule.control.setValue(value.filter(id => id !== type.configurationId));
    }

    type.active = selected;
    this.typesNeedToUpdate = [
      ...(this.typesNeedToUpdate.filter(x => x.id !== type.configurationId)),
      { id: type.configurationId, value: selected, slideToggle: slideToggle },
    ];
  }

  private _getNotificationManagement() {
    this.loading = true;
    this._notificationManagementService.getRulesConfig().pipe(
      tap((response) => {
        response.forEach((rule) => {
          rule.control = new FormControl(rule.types?.filter((t) => t.active ?? false).map((t) => t.configurationId) ?? []);
          rule.options = rule.types?.map((t) => ({ label: t.typeName, value: t.configurationId })) ?? []
          rule.types.forEach((type) => {
            if (!this.columns.some(column => column == type.typeName)) {
              this.columns.push(type.typeName);
            }
            this.initialRuleConfigTypes.push({ ...type });
          });
        });
        this.rules = response;
      }),
      finalize(() => this.loading = false),
      catchError((err) => {
        this.rules = this.rules ?? [];
        this._httpErrorService.showErrorInToast(err);
        return err;
      }),
    ).subscribe();
  }

  public cancel() {
    for (const rule of this.rules) {
      rule.types = rule.types.map((t) => ({
        ...t,
        active: this.initialRuleConfigTypes.find(init => init.configurationId === t.configurationId)?.active ?? t.active,
      }));
      rule.control.setValue(rule.types?.filter((t) => t.active ?? false).map((t) => t.configurationId) ?? []);
    }
    for(const type of this.typesNeedToUpdate) {
      if (type?.slideToggle) {
        type.slideToggle.checked = this.initialRuleConfigTypes.find(init => init.configurationId === type.id)?.active ?? !type.slideToggle.checked;
      }
    }
    this.typesNeedToUpdate = [];
  }

  public save() {
    this.loading = true;
    forkJoin(this.typesNeedToUpdate.map(x => this._notificationManagementService.updateConfigTypeActivation(
      x.id,
      x.value
    ))).pipe(
      tap(() => {
        this.initialRuleConfigTypes = this.rules.flatMap(x => x.types);
        this.typesNeedToUpdate.forEach((x) => {
          this.initialRuleConfigTypes.find(y => y.configurationId === x.id).active = x.value;
        });
        this.typesNeedToUpdate = [];
        this._toastService.success(this._translocoService.translate('Configurações de notificação alteradas com sucesso'));
      }),
      catchError((err) => {
        this._httpErrorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.loading = false)
    ).subscribe();
  }
}
