import { NgModule } from '@angular/core';
import { LayoutModule } from './layout/layout.module';
import { TranslocoCoreModule } from 'projects/lib-shared-core/src/public-api';
import { AuthModule } from '../auth/auth.module';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    LayoutModule,
    TranslocoCoreModule,
    AuthModule,
  ],
})
export class SvcUiModule {}
