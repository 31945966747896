<svc-dialog #dialog [hasActions]="false" [hideCloseButton]="hideCloseButton">
  <div svc-dialog-title>
    {{ title | transloco }}
  </div>

  <div svc-dialog-content class="m-4">
    <p class="text-sm px-2">
      {{ text | transloco }}
    </p>

    <div mat-dialog-actions class="flex justify-end p-2 mt-5">
      <div class="flex gap-2">
        <svc-button mode="flat" color="primary" type="button" cdkFocusInitial (click)="ok()">
          {{ okText | transloco }}
        </svc-button>
        <svc-button *ngIf="cancelText !== ''" mode="stroked" type="button" (click)="cancel()">
          {{ cancelText | transloco }}
        </svc-button>
      </div>
    </div>
  </div>
</svc-dialog>
