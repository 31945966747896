import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcWarappedLayoutComponent, SvcWrappedLayoutItem } from './svc-wrapped-layout.component';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
  ],
  declarations: [
    SvcWarappedLayoutComponent,
    SvcWrappedLayoutItem,
  ],
  exports: [
    SvcWarappedLayoutComponent,
    SvcWrappedLayoutItem,
  ],
})
export class SvcWrappedLayoutModule {}
