import { Component, EventEmitter, forwardRef, HostBinding, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService, SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { ISvcChipOption, SvcFilterEvent, SvcFilterField, SvcFiltersComponent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { catchError, debounceTime, finalize, Subject, takeUntil, tap } from 'rxjs';
import { SidebarNavigationComponent } from '../../../../ui/layout/layouts/components/sidebar-navigation/sidebar-navigation.component';
import { Application } from '../../models/applications.model';
import { FeedItem } from '../../models/feed-item.model';
import { SvcFeedService } from '../../services/svc-feed.service';
import { SvcCommentsViewerComponent } from '../svc-comments-viewer/svc-comments-viewer.component';

@Component({
  selector: 'svc-comments-feed',
  templateUrl: './svc-comments-feed.component.html',
  styleUrls: ['./svc-comments-feed.component.scss']
})
export class SvcCommentsFeedComponent implements OnInit {
  @HostBinding('class.expanded') expanded: boolean = false;

  @ViewChild(SvcFiltersComponent)
  public svcFilters: SvcFiltersComponent;

  @ViewChild(SvcCommentsViewerComponent)
  public svcCommentsViewer: SvcCommentsViewerComponent;

  @Output() public onTotalChanged = new EventEmitter<number>();
  @Output() public onLoadingChanged = new EventEmitter<boolean>();
  @Output() public onExpanded = new EventEmitter<boolean>();
  @Output() public onHasErrorFeed = new EventEmitter<boolean>();
  @Output() public onHasErrorTotalUnread = new EventEmitter<boolean>();

  public feedAreOver = false;
  public searchCtrl = new FormControl('');
  public feeds: FeedItem[] = [];
  public total: number = 0;
  public selectedFeed: FeedItem;
  public isLoading: boolean = true;
  public isTotalLoading: boolean = true;
  public isFiltersLoading: boolean = true;
  public isCheckingFeedCanBeLoaded: boolean = false;
  public showFilters: boolean = false;
  public lastFilters: {
    filter?: string,
    applicationIds?: string[],
    startDate?: string,
    endDate?: string,
  } = {
      filter: '',
      applicationIds: [],
      startDate: null,
      endDate: null,
    };
  public hasFiltersDefined = false;
  public hasFiltersPending = false;
  public filtersField: SvcFilterField[] = [];

  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Optional() @Inject(forwardRef(() => SidebarNavigationComponent))
    private _sidebarNavigation: SidebarNavigationComponent,
    private _svcMediaQuery: SvcMediaQuery,
    private _svcFeedService: SvcFeedService,
    private _translocoService: TranslocoService,
    private _svcToastService: SvcToastService,
    private _httpErrorService: HttpErrorService,
  ) {
    this._listenMediaQuery();
    this._listenFeedList();
    this._listenTotaUnread();
    this._listenSearchControl();
    this._listenSignalR();
    this._getFilters();
    this._svcFeedService.getFeed();
    this._svcFeedService.getTotalUnread();
  }

  ngOnInit(): void {
    this._svcFeedService.hasErrorFeed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.onHasErrorFeed.emit(value);
      });

    this._svcFeedService.hasErrorTotalUnread$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.onHasErrorTotalUnread.emit(value);
      });
  }

  public toggleExpanded(options?: { dontClearSelectedFeed: boolean }) {
    this.expanded = !this.expanded;
    if (this.expanded && this.feeds.length > 0 && !this.selectedFeed) {
      const firstFeed = this.feeds[0];
      this.onFeedClick(firstFeed)
    }
    else if (!this.expanded && this.selectedFeed && !(options?.dontClearSelectedFeed)) {
      this.selectedFeed = null;
    }
    this.onExpanded.emit(this.expanded);
  }

  public onFeedClick(feed: FeedItem) {
    this.isCheckingFeedCanBeLoaded = true;
    this._svcFeedService.getUserHasPermission({
      commentHeaderId: feed.commentHeaderId,
      registryId: feed.registryId || feed.registryUid,
    }).pipe(
      tap((hasPermission) => {
        if (hasPermission) {
          this.selectedFeed = feed;
          if (feed.totalNoRead > 0) {
            this._svcFeedService.markAsReadByCommentHeaderId(feed.commentHeaderId).pipe(
              tap(() => {
                feed.totalNoRead = 0;
                if (!this.isTotalLoading) {
                  this.total = Math.max(this.total - 1, 0);
                  this.onTotalChanged.emit(this.total);
                }
              }),
              finalize(() => this.isCheckingFeedCanBeLoaded = false),
            ).subscribe();
            return;
          }
        }
        else {
          const message = this._translocoService.translate('Você não possui permissão acessar as mensagens deste feed.');
          this._svcToastService.error(message);
        }
        this.isCheckingFeedCanBeLoaded = false;
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        this.isCheckingFeedCanBeLoaded = false
        return error;
      }),
    ).subscribe();
  }

  public nextPage() {
    if (!this.feedAreOver && !this.isLoading) {
      this._svcFeedService.feedNextPage();
    }
  }

  public clearFilters() {
    if (!this.isFiltersLoading && !this.svcFilters?.isEmpty) {
      this.svcFilters.clearFiltersField({ preventEmit: true });
      this.hasFiltersDefined = false;
      this.hasFiltersPending = false;
      this.doFilter({ fields: this.svcFilters.fields });
    }
  }

  public checkIfHasFiltersToDo(event: SvcFilterEvent) {
    const newFilters = {
      filter: this.searchCtrl.value ?? '',
      applicationIds: event.fields.find(f => f.name === 'applicationIds')?.value ?? null,
      startDate: event.fields.find(f => f.name === 'startDate')?.value ?? null,
      endDate: event.fields.find(f => f.name === 'endDate')?.value ?? null,
    };
    this.hasFiltersPending = JSON.stringify(newFilters) != JSON.stringify(this.lastFilters);
  }

  public doFilter(event: SvcFilterEvent) {
    const newFilters = {
      filter: this.searchCtrl.value ?? '',
      applicationIds: event.fields.find(f => f.name === 'applicationIds')?.value ?? null,
      startDate: event.fields.find(f => f.name === 'startDate')?.value ?? null,
      endDate: event.fields.find(f => f.name === 'endDate')?.value ?? null,
    };

    if (JSON.stringify(this.lastFilters ?? {}) !== JSON.stringify(newFilters)) {
      this.lastFilters = newFilters;
      this.hasFiltersPending = false;
      this._checkIfHasFilters();
      this._svcFeedService.getFeed(this.lastFilters);
    }
    this.closeFilters({ fromSuccess: true });
  }

  public openFilters() {
    this.showFilters = true;
  }

  public closeFilters(options?: { fromSuccess: boolean }) {
    this.showFilters = false;
    if (this.hasFiltersPending && !(options?.fromSuccess)) {
      this.svcFilters.patchValues(this.lastFilters);
      this.hasFiltersPending = false;
    }
  }

  private _listenMediaQuery() {
    this._svcMediaQuery.size$.pipe(
      takeUntil(this.destroy$),
      tap((size) => {
        if (size.isBelowMD) {
          this.expanded = false;
        }
      }),
    ).subscribe();
  }

  private _listenFeedList() {
    this._svcFeedService.feedIsLoading$.pipe(
      takeUntil(this.destroy$),
      tap((isLoading) => {
        this.isLoading = isLoading;
        this.onLoadingChanged.emit(isLoading);
      }),
    ).subscribe();

    this._svcFeedService.feed$.pipe(
      takeUntil(this.destroy$),
      tap((response) => {
        this.feeds = response.feeds;
        if (this.selectedFeed) {
          const feed = this.feeds.find(f => f.commentHeaderId === this.selectedFeed.commentHeaderId);
          if (feed && feed !== this.selectedFeed) {
            this.selectedFeed = feed;
          }
        }
      }),
    ).subscribe();
  }

  private _listenTotaUnread() {
    this._svcFeedService.totalUnreadIsLoading$.pipe(
      takeUntil(this.destroy$),
      tap((isLoading) => {
        this.isTotalLoading = isLoading;
        if (isLoading) {
          this.onTotalChanged.emit(null);
        }
      }),
    ).subscribe();

    this._svcFeedService.totalUnread$.pipe(
      takeUntil(this.destroy$),
      tap((response) => {
        this.total = response ?? 0;
        if (response != null) {
          this.onTotalChanged.emit(response);
        }
      }),
    ).subscribe();
  }

  private _listenSignalR() {
    this._svcFeedService.prepareListenSignalR();
    this._svcFeedService.startListenSignalR();
    this._svcFeedService.newFeedReceived$.pipe(
      takeUntil(this.destroy$),
      tap((feed) => {
        if (!this.selectedFeed || !feed.commentId) return;
        if ((feed.registryId > 0 && feed.registryId === this.selectedFeed.registryId) || (feed.registryUid === this.selectedFeed.registryUid)) {
          feed.totalNoRead = 0;
          this.svcCommentsViewer?.tryToRefreshChatByCommentId(feed.commentId);
        }
      }),
    ).subscribe();
  }

  private _getFilters() {
    this.isFiltersLoading = true;
    this._svcFeedService.getFilters().pipe(
      tap((response) => {
        this._mountFiters(response);
      }),
      catchError((error) => {
        this._mountFiters();
        return error;
      }),
      finalize(() => this.isFiltersLoading = false)
    ).subscribe();
  }

  private _mountFiters(options?: { applications?: Application[] }) {
    this.filtersField = [
      {
        name: 'applicationIds',
        label: this._translocoService.translate('Módulo'),
        initialValue: [],
        type: 'chip',
        config: {
          options: options?.applications?.map((item) => <ISvcChipOption>({
            value: item.id,
            label: item.description,
          })) ?? [],
        },
      },
      {
        name: 'startDate',
        label: this._translocoService.translate('Período'),
        type: 'date',
        dependsAnother: 'endDate',
        config: {
          maxDate: new Date()
        },
      },
      {
        name: 'endDate',
        label: '',
        type: 'date',
        dependsAnother: 'startDate',
        config: {
          maxDate: new Date()
        },
      },
    ];
  }

  private _listenSearchControl() {
    this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      tap((value) => {
        this.lastFilters = {
          ...this.lastFilters ?? {},
          filter: value,
        };
        this._svcFeedService.getFeed(this.lastFilters);
      }),
    ).subscribe();
  }

  private _checkIfHasFilters() {
    const lastFilters = { ...this.lastFilters };
    delete lastFilters.filter;
    this.hasFiltersDefined = Object.keys(lastFilters).some((key) => {
      const value = lastFilters[key];
      if (!!(value) || (Array.isArray(value) && value.length > 0)) {
        return true;
      }
      return false;
    });
  }

  public closeSidebarIfOpened() {
    if (this._sidebarNavigation.mode === 'over' && this._sidebarNavigation.opened) {
      this._sidebarNavigation.toggle();
    }
  }
}
