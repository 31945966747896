<svc-dialog #svcDialog fullscreen="XS" [scrollInsideContent]="true" class="h-full">
  <div svc-dialog-title class="title">
    <span class="text-default text-base font-bold">{{ title }}</span>
  </div>

  <div svc-dialog-content class="relative flex flex-col overflow-x-hidden overflow-y-auto" [ngClass]="{ 'min-h-30': isLoading, 'mt-8': isLoading && !(questions?.length) }">

    <div *ngIf="questions?.length > 0" class="w-full p-4 bg-primary-200">
      <div class="rounded-lg z-99999 overflow-hidden bg-primary-100">
        <app-stepper [questions]="questions"></app-stepper>
      </div>
    </div>

    <div *ngIf="questions?.length > 0" class="pt-0 pb-3 px-4 bg-primary-200">
      <div class="flex flex-col gap-y-3">
        <ng-container *ngFor="let topic of questions; let i = index">
          <div class="flex items-center py-3 pl-0.5 pr-3 bg-white rounded-lg">
            <label class="text-base text-default font-medium leading-tight">
              {{ topic.topicName }}
            </label>
          </div>

          <ng-container *ngFor="let question of topic.questions">
            <div class="py-3 pl-0.5 pr-3 rounded-b-lg bg-primary-100">
              <label class="text-base text-default font-medium">
                {{ question.questionCounter }}- {{ question.questionName }}
                <span *ngIf="question.isRequired" class="text-red-500">*</span>
              </label>

              <div *ngIf="question.attachmentSigned" class="flex items-center justify-center object-top mt-4 -ml-0.5 -mr-3 bg-primary-50">
                <img #image
                  class="w-full h-auto max-h-44 object-cover object-top rounded-t-lg"
                  [ngClass]="{ 'cursor-pointer': image.src }"
                  [src]="question.attachmentSigned"
                  (error)="$event.target.src = null"
                  (click)="image.src && openImage(question.attachmentSigned)"
                />
              </div>

              <div class="flex flex-col my-3 pl-2.5">
                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Simple_Choice">
                  <app-simple-choice-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-simple-choice-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Multiple_Choice">
                  <app-multiple-choice-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-multiple-choice-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Text_Box">
                  <app-text-box-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-text-box-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Multiple_Text_Boxes">
                  <app-multiple-text-boxes-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-multiple-text-boxes-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Evaluation_Bar">
                  <app-evaluation-bar-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-evaluation-bar-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Evaluation_Matrix">
                  <app-evaluation-matrix-answer
                    class="-mx-3"
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-evaluation-matrix-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Star_Rating">
                  <app-start-rating-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-start-rating-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Employees_List">
                  <app-list-of-employees-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-list-of-employees-answer>
                </ng-container>

                <ng-container *ngIf="question.questionTypeId === QuestionTypes.Dropdown_List">
                  <app-dropdown-list-answer
                    #survey
                    [questionTemplateId]="question.questionTemplateParentId"
                    [question]="question"
                    [readonly]="data?.readonly"
                    (doSave)="save($event, question)"
                  ></app-dropdown-list-answer>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isLoading" class="absolute inset-0 flex items-center justify-center text-primary">
      <div class="absolute inset-0 bg-white opacity-50"></div>
      <mat-spinner class="fixed left-1/2 top-1/2 z-99 text-current" diameter="30" [style.transform]="'translate(-50%, -50%)'"></mat-spinner>
    </div>
  </div>

  <div svc-dialog-actions class="mr-2 mb-2">
    <div  class="flex flex-row gap-2 w-full justify-end">
      <svc-button mode="flat" color="on-primary" [disabled]="isLoading" (click)="!isLoading && svcDialog.closeDialog()">
        {{ !data?.readonly ? 'Cancelar' : 'Fechar' | transloco}}
        </svc-button>
      <div *ngIf="!data?.readonly">
        <svc-button mode="flat" color="primary" [disabled]="isLoading" (click)="!isLoading && saveAll()">
          {{ 'Enviar' | transloco}}
        </svc-button>
      </div>
    </div>
  </div>
</svc-dialog>
