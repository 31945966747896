import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';
import { SvcDatePickerRangeComponent } from './svc-date-picker-range.component';
import { SvcDatePickerCalendarHeaderModule } from './svc-date-picker-calendar-header/svc-date-picker-calendar-header.module';
import { SvcDatePickerCalendarModule } from './svc-date-picker-calendar/svc-date-picker-calendar.module';
import { LibSharedIconsModule } from '../../../../../lib-shared-icons/src/public-api';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    SvcDatePickerRangeComponent,
  ],
  exports: [
    SvcDatePickerRangeComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcValidationMessageModule,
    TranslocoModule,
    SvcDatePickerCalendarHeaderModule,
    SvcDatePickerCalendarModule,
    LibSharedIconsModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (translocoService: TranslocoService) => translocoService.getActiveLang(),
      deps: [ TranslocoService ]
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
  ]
})
export class SvcDatePickerRangeModule { }
