import { Injectable } from '@angular/core';
import { VerticalNavigationComponent } from './layouts/components/vertical-navigation/vertical-navigation.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutService {

  private _verticalNavigationComp: VerticalNavigationComponent;
  public get menuRectracting$(): BehaviorSubject<boolean> {
    return this._verticalNavigationComp?.rectracting$;
  }

  constructor() { }

  public setVerticalNavigationComponent(component: VerticalNavigationComponent) {
    this._verticalNavigationComp = component;
    this._verticalNavigationComp.rectracting$.subscribe();
  }

  public retractMenu() {
    if (!this._verticalNavigationComp?.isRetracted) {
      this._verticalNavigationComp.toggleRetraction();
    }
  }

  public expandMenu() {
    if (this._verticalNavigationComp?.isRetracted) {
      this._verticalNavigationComp.toggleRetraction();
    }
  }
}