export type IconLibs = 'heroIcons' | 'material' | 'fontawesome';

export type IconType = 'solid' | 'outline';

export type IconName =
  'arrow-top-right-on-square' |
  'bars-3' |
  'check-circle' |
  'exclamation' |
  'x-circle' |
  'chevron-right'
;

export interface IconItem {
  name: IconName
  lib: IconLibs
  libIconName: {
    solid: string,
    outline: string
  };
}

export const IconList: IconItem[] = [
  {
    name: 'check-circle',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:check-circle',
      outline: 'heroicons_outline:check-circle'
    }
  },
  {
    name: 'x-circle',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:x-circle',
      outline: 'heroicons_outline:x-circle'
    }
  },
  {
    name: 'exclamation',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:exclamation',
      outline: 'heroicons_outline:exclamation'
    }
  },
  {
    name: 'bars-3',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:bars-3',
      outline: 'heroicons_outline:bars-3'
    }
  },
  {
    name: 'chevron-right',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:chevron-right',
      outline: 'heroicons_outline:chevron-right'
    }
  },
  {
    name: 'arrow-top-right-on-square',
    lib: "heroIcons",
    libIconName: {
      solid: 'heroicons_solid:arrow-top-right-on-square',
      outline: 'heroicons_outline:arrow-top-right-on-square'
    }
  },

];
