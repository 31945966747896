import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { PraiseItemCardComponent } from './praise-item-card.component';
import { SvcCommentsModule } from 'projects/lib-shared-feature/src/lib/general/svc-comments/svc-comments.module';
import { SvcReactionModule } from 'projects/lib-shared-feature/src/lib/general/svc-reaction/svc-reaction.module';
import { SvcTotalsStatusModule } from 'projects/lib-shared-feature/src/lib/general/svc-totals-status/svc-totals-status.module';

@NgModule({
  declarations: [PraiseItemCardComponent],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcUserAvatarModule,
    SvcCommentsModule,
    SvcReactionModule,
    SvcTotalsStatusModule,
    TranslocoModule,
  ],
  exports: [
    PraiseItemCardComponent
  ]
})
export class PraiseItemCardModule { }
