import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-start-rating-answer',
  templateUrl: './start-rating-answer.component.html',
  styleUrls: ['./start-rating-answer.component.scss'],
})
export class StartRatingAnswerComponent implements OnInit {
  @Input() questionTemplateId: number;
  @Input() rating: number = 0;
  @Input() starCount: number = 5;
  @Input() iconSizeClass: string;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();
  @Output() ratingChange = new EventEmitter<number>();

  public ratingArr = [];
  public isSave = false;

  #modalService = inject(SurveyModalService);

  ngOnInit() {
    this.initRatingArr();

    if (this.question) {
      this.starCount = this.question.metadata?.amount;
      this.initRatingArr();
    }

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  public onStarClick(index: number) {
    this.rating = index + 1;
    this.ratingChange.emit(this.rating);
    this.initRatingArr();
    this.question.answered = true;
  }

  private initRatingArr() {
    this.ratingArr = Array.from(
      { length: this.starCount },
      (_, index) => index < this.rating
    );
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }

    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }

    return true;
  }

  public getQuestionAnswered(): void {
    this.doSave.emit({ Rating: this.rating });
  }
}
