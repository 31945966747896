import {Component, Input, OnInit} from '@angular/core';
import { SvcViewPanelExportInterface } from "../../interfaces/svc-view-panel-export.interface";
import { HttpClient} from "@angular/common/http";

@Component({
  selector: 'svc-view-panel-export',
  template: `
  <ng-container>
    <button *ngIf="showExportMenu" mat-button class="text-primary font-bold min-w-7 w-7 h-7 min-h-7" [matMenuTriggerFor]="exportMatMenu" [disabled]="exporting" id="view-panel-export-menu">
      <mat-icon *ngIf="!exporting" class="text-current icon-size-6" svgIcon="fontawesome_solid:ellipsis-vertical"></mat-icon>
      <mat-spinner *ngIf="exporting" [diameter]="24"></mat-spinner>
    </button>

    <mat-menu #exportMatMenu>

      <button
        *ngIf="config?.xls && config?.xls.active"
        mat-menu-item
        (click)="exportAsXls()"
        class="text-default"
        id="view-panel-export-xls"
      >
        <mat-icon class="text-current"
          svgIcon="heroicons_solid:document-text"
        ></mat-icon>
        <span>Export XLS</span>
      </button>

      <button
        *ngIf="config?.pdf && config?.pdf.active"
        mat-menu-item
        (click)="exportAsPdf()"
        class="text-default"
        id="view-panel-export-pdf"
      >
        <mat-icon  class="text-current"
          svgIcon="mat_solid:picture_as_pdf"
        ></mat-icon>
        <span>Export PDF</span>
      </button>

      <button
        *ngIf="config?.print"
        mat-menu-item
        class="text-default"
        id="view-panel-export-print"
      >
        <mat-icon class="text-current"
          svgIcon="heroicons_solid:printer"
        ></mat-icon>
        <span>Print</span>
      </button>

    </mat-menu>
  </ng-container>
  `,
  styles: [`
    :host {
      display: inline-block;
    }
  `]
})
export class SvcViewPanelExportComponent implements OnInit {

  @Input() config: SvcViewPanelExportInterface;

  exporting: boolean;
  showExportMenu: boolean;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.showExportMenu = this.config
      && (
        this.config.pdf.active === true
        || this.config.xls.active === true
        || this.config.print
      )
  }

  exportAsXls() {

    if(!this.config.xls.url)  {
      if (this.config.xls.onClick)
        this.config.xls.onClick.emit();
      return;
    }

    this.http.get(this.config.xls.url,{ observe:'response', responseType:'blob'}).subscribe( response => {
      let fileName = response.headers.get('content-disposition')?.split(';')[1].split('=')[1];
      let blob:Blob = response.body as Blob
      let a = document.createElement('a');
      a.download = this.config.xls.fileName;
      a.href = URL.createObjectURL(blob);
      a.click();
    })
  }

  exportAsPdf(){
    if(!this.config.pdf.url)  {
      this.config.pdf.onClick.emit();
      return;
    }

    this.http.get(this.config.pdf.url,{ observe:'response', responseType:'blob'}).subscribe( response => {
      let fileName = response.headers.get('content-disposition')?.split(';')[1].split('=')[1];
      let blob:Blob = response.body as Blob
      let a = document.createElement('a');
      a.download = this.config.pdf.fileName;
      a.href = URL.createObjectURL(blob);
      a.click();
    })
  }

}
