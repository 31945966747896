import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root',
})
export class ParameterService {
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
  ) {}

  getSiteParam(paramName: string): Observable<string> {
    return this._httpClient.get(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/site/value/${paramName}`).pipe(
      map((res: any) => {
        if (typeof res === 'object') {
          return res.value;
        }
        return res;
      })
    );
  }

  getGlobalParam(paramName: string): Observable<string> {
    return this._httpClient.get(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/global/value/${paramName}`).pipe(
      map((res: any) => {
        if (typeof res === 'object') {
          return res.value;
        }
        return res;
      })
    );
  }

  getGlobalParams(paramsName: string[]): Observable<{ key: string, value: string }[]> {
    return this._httpClient.get<{ key: string, value: string }[]>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/global/values`, {
      params: { paramKeys: paramsName }
    });
  }
  
  getParamFromOldApi(paramName: string): Observable<{ name: string, value: string }> {
    return this._httpClient
      .get<{ paramValue: string }>(`${this._appConfig.APIs.apiUrlAuth}/Parameter/${paramName}`)
      .pipe(
        map((response) => ({
          name: paramName,
          value: response.paramValue
        })),
      );
  }
}
