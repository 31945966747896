export enum SvcSelectPeriodDefaultIds {
  Yesterday = 'yesterday',
  Today = 'today',
  Tomorrow = 'tomorrow',
  ThisWeek = 'this-week',
  ThisMonth = 'this-month',
  PastSevenDays = 'past-seven-days',
  PastFourteenDays = 'past-fourteen-days',
  PastThirtyDays = 'past-thirty-days',
  PastNinetyDays = 'past-ninety-days',
  NextSevenDays = 'next-seven-days',
  NextFourteenDays = 'next-fourteen-days',
  NextThirtyDays = 'next-thirty-days',
  NextNinetyDays = 'next-ninety-days',
  Customize = 'customize',
}
