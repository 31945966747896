<button
  mat-mini-fab
  [ngClass]="[size]"
  [color]="color"
  [type]="type"
  [disabled]="disabled || processing"
>
  <span *ngIf="!processing">{{ label }}</span>

  <mat-progress-spinner
    *ngIf="processing"
    [diameter]="24"
    mode="indeterminate"
  ></mat-progress-spinner>

  <ng-container *ngIf="!processing">
    <ng-content></ng-content>
  </ng-container>
</button>
