import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcSmartUserAvatarComponent } from './svc-smart-user-avatar.component';
import { SvcDialogsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
  declarations: [
    SvcSmartUserAvatarComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatIconModule,
    SvcDialogsModule,
    SvcUserAvatarModule,
    TranslocoModule,
  ],
  exports: [
    SvcSmartUserAvatarComponent,
  ],
})
export class SvcSmartUserAvatarModule { }
