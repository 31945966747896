import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';
import { SvcTextComponent } from './svc-text.component';

@NgModule({
  declarations: [
    SvcTextComponent
  ],
  imports: [
    LibSharedCommonModule, 
    LibSharedComponentModule,
    SvcValidationMessageModule
  ],
  exports: [
    SvcTextComponent
  ]
})
export class SvcTextModule { }
