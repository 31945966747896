import {Component, OnInit} from '@angular/core';
import {UserHashtagsService} from "../../services/user-hastags.service";
import { Hashtag, HashtagTypeEnum} from "../../services/user-hashtags.types";
import { MatDialogRef} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
  selector: 'all-hashtags',
  templateUrl: './all-hashtag.component.html',
  styleUrls: ['./all-hashtag.component.scss']
})
export class AllHashtagComponent implements OnInit {

  typeActive: HashtagTypeEnum = HashtagTypeEnum.Local;

  constructor(
    private _dialogRef: MatDialogRef<AllHashtagComponent>,
    private _userHashtagService: UserHashtagsService
  ) { }

  hashtags: Hashtag[] = [];
  filteredHashtags: Hashtag[];
  hashtagSearch: string;
  hashtagTypeEnum = [
    HashtagTypeEnum.Local,
    HashtagTypeEnum.Global,
  ];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  ngOnInit() {
    this._userHashtagService.getUserHashtags().subscribe();

    this._userHashtagService.hashtags$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.hashtags = res;
        this.filter(this.hashtagSearch);
      });
  }

  ngOnDestroy(): void {
    //Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  tabChanged = (event: MatTabChangeEvent): void => {
    this.typeActive = event.index === 0 ? HashtagTypeEnum.Local : HashtagTypeEnum.Global
    this.filter(this.hashtagSearch)
  }

  filter(value: string) {
    if(!value?.trim()) {
      this.filteredHashtags = this.hashtags.filter(res => res.isGlobal == (this.typeActive == HashtagTypeEnum.Global));
    } else {
      this.filteredHashtags = this.hashtags.filter(x =>
        x.name.trim().toLowerCase().includes(value.trim().toLowerCase()) && x.isGlobal == (this.typeActive == HashtagTypeEnum.Global)
      );
    }
  }

  public closeDialog() {
    this._dialogRef.close();
  }
}
