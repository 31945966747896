import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'svc-lock-button',
  templateUrl: './svc-lock-button.component.html',
  styleUrls: ['./svc-lock-button.component.scss'],
})
export class SvcLockButtonComponent implements OnInit {
  @Input() locked = true;
  @Input() readonly = false;
  @Input() iconSizeClass: string;
  @Input() buttonId: string;
  @Output() onLockChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  async changeLock() {
    this.locked = !this.locked;
    this.onLockChanged.emit(this.locked);
  }
}
