import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcDialogsModule, SvcGridDataModule } from 'projects/lib-shared-component/src/public-api';
import { SearchService } from './services/search.service';
import { ModalPraiseReceivedComponent } from './modal-praise-received.component';
import { PraiseItemCardModule } from '../../praise-item-card/praise-item-card.module';
import { ModalPraiseDetailModule } from '../../modals/modal-praise-item-detail/modal-praise-item-detail.module';

@NgModule({
  declarations: [ModalPraiseReceivedComponent],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    SvcGridDataModule,
    TranslocoModule,
    PraiseItemCardModule,
    ModalPraiseDetailModule,
  ],
  exports: [ModalPraiseReceivedComponent],
  providers: [
    SearchService,
  ]
})
export class ModalPraiseReceivedModule { }
