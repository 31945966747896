declare global {
    interface Date {
        isSame(date: Date): boolean;
        isAfter(date: Date): boolean;
        isBefore(date: Date): boolean;
        addDays(days: number): Date;
        addMonths(months: number): Date;
        addYears(years: number): Date;
        withoutTimespan(): Date;
    }
}
Date.prototype.isSame = function(this: Date, date: Date): boolean {
    return this.getFullYear() == date.getFullYear() && this.getMonth() == date.getMonth() && this.getDate() == date.getDate();
}
Date.prototype.isAfter = function(this: Date, date: Date): boolean {
    return this.getFullYear() > date.getFullYear() ||
        (this.getFullYear() == date.getFullYear() && this.getMonth() > date.getMonth()) ||
        (this.getFullYear() == date.getFullYear() && this.getMonth() == date.getMonth() && this.getDate() > date.getDate());
}
Date.prototype.isBefore = function(this: Date, date: Date): boolean {
    return this.getFullYear() < date.getFullYear() ||
        (this.getFullYear() == date.getFullYear() && this.getMonth() < date.getMonth()) ||
        (this.getFullYear() == date.getFullYear() && this.getMonth() == date.getMonth() && this.getDate() < date.getDate());
}
Date.prototype.addDays = function(this: Date, days: number): Date {
    const date = new Date(this);
    return new Date(date.setDate(date.getDate() + days));
}
Date.prototype.addMonths = function(this: Date, months: number): Date {
    const date = new Date(this);
    return new Date(date.setMonth(date.getMonth() + months));
}
Date.prototype.addYears = function(this: Date, years: number): Date {
    const date = new Date(this);
    return new Date(date.setFullYear(date.getFullYear() + years));
}
Date.prototype.withoutTimespan = function(): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0, 0);
}

export {}