export interface CommonIndicator {
    sumTotal: number;
    indicators?: CommonIndicatorCategory[];
}

export interface CommonIndicatorCategory{
    indicatorEnum: number;
    indicatorItems: CommonIndicatorStatusItem[];
}

export interface CommonIndicatorStatusItem{
    total: number;
    indicatorStatusEnum: number;
}

export interface CommonIndicatorChart {

  data: CommonIndicator;
  chartInfo: CommonIndicatorChartInfo;
  chartOrder: number[];
  seriesOrder: number[];

  generateChartInfo(): CommonIndicatorChartInfo;
}

export interface CommonIndicatorChartInfo {
    categories: string[];
    series: { name: string, color: string, values: number[] }[];
}

interface Enum { [key: string|number]: number|string; }

export interface BaseCommonIndicatorChartConfig {
  categories: Enum;
  categoriesNames: Enum;
  statuses: Enum;
  statusesNames: Enum;
  colors: Enum;
}

export abstract class BaseCommonIndicatorChart implements CommonIndicatorChart {

  data: CommonIndicator;
  chartInfo: CommonIndicatorChartInfo;
  chartOrder: number[];
  seriesOrder: number[];

  protected constructor(
    private config: BaseCommonIndicatorChartConfig
  ) {}

  generateChartInfo(): CommonIndicatorChartInfo {

    const categories: string[] = Object.keys(this.config.categories)
      .filter((i) => Number(i))
      .map(i => parseInt(i))
      .sort((a,b) => this.chartOrder.indexOf(a) - this.chartOrder.indexOf(b))
      .reduce((acc, key) => {
        acc.push(this.config.categoriesNames[key])
        return acc;
      }, []);

    const series: { name: string, color: string, values: number[] }[] = Object.keys(this.config.statuses)
      .filter(i => Number(i))
      .map(i => parseInt(i))
      .sort((a, b) => this.seriesOrder.indexOf(a) - this.seriesOrder.indexOf(b))
      .reduce<{ name: string; color: string; values: number[] }[]>((acc, key) => {
        acc.push({
          name: this.config.statusesNames[key].toString(),
          color: this.config.colors[key].toString(),
          values: Object.keys(this.config.categories)
            .filter((i) => Number(i))
            .map(i => parseInt(i))
            .sort((a,b) => this.chartOrder.indexOf(a) - this.chartOrder.indexOf(b))
            .reduce((a, b) => {
              const indicator = this.data.indicators
                .find(i => i.indicatorEnum === b)
                ?.indicatorItems
                ?.find(i => i.indicatorStatusEnum === key);
              a.push(indicator?.total || 0)
              return a;
            }, [])
        });
        return acc;
      }, []);
    return { categories: categories, series: series };
  }

}
