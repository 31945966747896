import { NgModule } from '@angular/core';
import { provideNgxMask } from 'ngx-mask';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { SvcChipModule } from './svc-chip/svc-chip.module';
import { SvcDatePickerRangeModule } from './svc-date-picker-range/svc-date-picker-range.module';
import { SvcDatePickerModule } from './svc-date-picker/svc-date-picker.module';
import { SvcSelectPeriodModule } from './svc-select-period/svc-select-period.module';
import { SvcSelectModule } from './svc-select/svc-select.module';
import { SvcTextAreaModule } from './svc-text-area/svc-text-area.module';
import { SvcTextModule } from './svc-text/svc-text.module';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    SvcTextModule,
    SvcTextAreaModule,
    SvcDatePickerModule,
    SvcChipModule,
    SvcSelectModule,
    SvcDatePickerRangeModule,
    SvcSelectPeriodModule,
  ],
  declarations: [],
  exports: [
    SvcTextModule,
    SvcTextAreaModule,
    SvcDatePickerModule,
    SvcChipModule,
    SvcSelectModule,
    SvcDatePickerRangeModule,
    SvcSelectPeriodModule,
  ],
  providers: [provideNgxMask()],
})
export class SvcControlsModule {}
