import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { of, merge, switchMap, Observable } from 'rxjs';

import { fadeInUp400ms } from '../animations/fade-in-up.animation';
import { stagger40ms } from '../animations/stagger.animation';
import { ISvcTable } from './interfaces/svc-table.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonPaginationRequest } from 'projects/lib-shared-model/src/public-api';

@Component({
  selector: 'svc-table',
  templateUrl: './svc-table.component.html',
  styleUrls: ['./svc-table.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
})
export class SvcTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input()
  dataContent: any;

  @Input()
  dataDefs: ISvcTable<any>[] = [];

  @Input()
  showSelections = false;

  @Input()
  serverSide = false;

  @Input()
  searchTerm = '';

  @Output()
  onRequestData = new EventEmitter<CommonPaginationRequest>();

  @Output()
  onRowClicked: EventEmitter<{ index: number }> = new EventEmitter<any>();

  @Output()
  onViewEventClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onPriorityChanged: EventEmitter<{ prioritized: boolean; data: any }> = new EventEmitter<{ prioritized: boolean; data: any }>();

  @Output()
  onLockedChanged: EventEmitter<{ locked: boolean; data: any }> = new EventEmitter<{ locked: boolean; data: any }>();

  selectedRows = new SelectionModel<any>(true, []);

  pageIndex = 0;
  pageSizeOptions = [5, 10, 30, 50, 150, 250, 500];
  pageSize = this.pageSizeOptions[1];
  totalResults = 0;

  loadingData: boolean = false;
  availableItems: Observable<any>;
  dataSource: MatTableDataSource<any> | null = new MatTableDataSource();

  private sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {

    this.sort = ms;
    this.setDataSourceAttributes();
  }

  private paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  get pagination() {
    return this.dataContent?.pagination;
  }

  get columns(): ISvcTable<any>[] {
    const columns: ISvcTable<any>[] = [];

    if (this.showSelections)
      columns.push({
        label: 'Select',
        property: 'checkbox',
        type: 'checkbox',
        visible: true
      });

    columns.push(...this.dataDefs);

    return columns;
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  ngOnInit(){
    if(!this.serverSide)
      this.availableItems = this.dataSource.connect();
  }

  ngAfterViewInit(): void {
    if(this.serverSide) {
      this.loadingData = true;

      merge(
        this.sort.sortChange,
        this.paginator.page
      ).pipe(
        switchMap(() => {
          this.loadingData = true;
          return of({ sortColumn: this.sort?.active??null, sortDirection: this.sort?.direction??null, pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize})
        })
      ).subscribe((response) => this.onRequestData.next(response));

      this.onRequestData.next({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
    this.updatePagination();
  }

  ngOnDestroy(): void {
    if(this.dataSource && !this.serverSide)
      this.dataSource.disconnect();
  }

  public reloadData() {
    this.onRequestData.next({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize});
  }

  private updateData() {
    if (this.dataContent) {
      if(this.serverSide){
        this.loadingData = false;
        this.availableItems = of(this.dataContent.data);
      }else{
        this.dataSource.data = this.dataContent.data;
      }
    }
  }

  private updatePagination() {
    if(this.serverSide) {
      if (this.pagination) {
        this.pageIndex = this.pagination.pageIndex;
        this.totalResults = this.pagination.total;
      }
    }
  }

  trackByProperty<T>(index: number, column: ISvcTable<T>) {
    return column.property;
  }

  isAllSelected() {
    return this.selectedRows.selected.length === this.dataSource.data.length;
  }

  toggleAll() {
    this.isAllSelected()
      ? this.selectedRows.clear()
      : this.dataSource.data.forEach((row) => this.selectedRows.select(row));
  }

  changeLock(event: boolean, row: any) {
    const locked = { locked: event, data: row };
    this.onLockedChanged.emit(locked);
  }

  changePriority(event: boolean, row: any) {
    const prioritized = { prioritized: event, data: row };
    this.onPriorityChanged.emit(prioritized);
  }

  viewItem(id: any) {
    this.onViewEventClicked.emit(id);
  }

  public rowClicked(row: any) {
    const index = this.dataContent.data.indexOf(row);
    this.onRowClicked.emit({ index });
  }
}
