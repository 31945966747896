export function generateStringFromDate(date: Date, dateFormat: string): string | null {
    if(date){
        if(dateFormat == 'DDMMYYYY'){
            return date.getDate().toString() + '/' + (date.getMonth() + 1).toString() + '/' + date.getFullYear().toString();
        }
        else if (dateFormat == 'MMDDYYYY'){
            return (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString();
        }
    }
    return null;
}

export function generateStringFromDateTime(date: Date, dateFormat: string): string | null {
    if(date){
        if(dateFormat == 'DDMMYYYY'){
            return date.getDate().toString() + '/' + (date.getMonth() + 1).toString() + '/' + date.getFullYear().toString() + ' ' + date.getHours() + ':' + date.getMinutes();
        }
        else if (dateFormat == 'MMDDYYYY'){
            return (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString() + ' ' + date.getHours() + ':' + date.getMinutes();
        }
    }
    return null;
}