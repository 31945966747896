import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface UserWireframeData {
  user: UserWireframe,
  logoImageNegative: string;
  showClose: boolean;
}

export interface UserWireframe {
  active: boolean;
  originSiteId: number;
  email: string;
  registryNumber: string;
  departmentName: string;
  areaName: string;
  employeePositionName: string;
  firstLastName: string;
  pictureFile: string;
  initialColor: string;
  siteName: string;
}

@Component({
  selector: 'svc-user-wireframe',
  templateUrl: './svc-user-wireframe.component.html',
  styleUrls: ['./svc-user-wireframe.component.scss']
})
export class SvcUserWireframeComponent implements OnInit {

  @Input() config: UserWireframeData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserWireframeData,
  ) { }

  ngOnInit(): void {
    if (this.data && Object.keys(this.data).length > 0)
      this.config = this.data;
  }


}
