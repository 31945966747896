import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  private _datePipe = new DatePipe('en-US');

  constructor(
    private _translocoService: TranslocoService,
  ) {}

  transform(value: Date | string): string {
    if (typeof value == 'string') {
      value = new Date(value?.replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{1,2}.*Z)/g, (term) => term.replace('Z', '')));
    }
    const unit = this.getUnitBySecondsPassed(value);
    if (unit.unit === TimeUnity.second && unit.value < 60) {
      return (this._datePipe.transform(value, 'hh:mm a')).toUpperCase();
    }

    const term = this.getTermByValue(unit.unit, unit.value);
    return term.replace('{0}', unit.value.toString());
  }

  private getUnitBySecondsPassed(date: Date): { unit: TimeUnity , value: number } {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 60) {
      return { unit: TimeUnity.second, value: seconds };
    } else if (seconds < 120) { // Até 2 minutos
      return { unit: TimeUnity.minute, value: 1 };
    } else if (seconds < 3600) { // Até 1 hora
      return { unit: TimeUnity.minute, value: Math.floor(seconds / 60) };
    } else if (seconds < 7200) { // Até 2 horas
      return { unit: TimeUnity.hour, value: 1 };
    } else if (seconds < 86400) { // Até 24hrs
      return { unit: TimeUnity.hour, value: Math.floor(seconds / 3600)};
    }else if (seconds < 172800) { // Até 47 hrs
      return { unit: TimeUnity.day, value: 1};
    }else if (seconds < 604800) { // Até 7 dias
      return { unit: TimeUnity.day, value: Math.floor(seconds / 86400)};
    } else if (seconds < 1209600) { // Até 14 dias
      return { unit: TimeUnity.week, value: 1 };
    } else if (seconds < 2419200) { // Até 28 dias
      return { unit: TimeUnity.week, value: Math.floor(seconds / 604800) };
    } else {
      const monthsAndYears = this.getMonthsAndYearsDifference(date, now);
      if (monthsAndYears.years > 0) {
        return { unit: TimeUnity.year, value: monthsAndYears.years };
      } else if (monthsAndYears.months > 0) {
        return { unit: TimeUnity.month, value: monthsAndYears.months };
      } else {
        return { unit: TimeUnity.year, value: monthsAndYears.years };
      }
    }
  }

  private getTermByValue(timeUnity: TimeUnity, value: number) {
    switch (timeUnity) {
      case TimeUnity.second:
        return this._translocoService.translate(`Há {0} ${value > 1 ? 'segundos' : 'segundo'}`);
      case TimeUnity.minute:
        return this._translocoService.translate(`Há {0} ${value > 1 ? 'minutos' : 'minuto'}`);
      case TimeUnity.hour:
        return this._translocoService.translate(`Há {0} ${value > 1 ? 'horas' : 'hora'}`);
      case TimeUnity.day:
        return this._translocoService.translate(`Há {0} ${value > 1 ? 'dias' : 'dia'}`);
      case TimeUnity.week:
        return this._translocoService.translate(`Há {0} ${value > 1 ? 'semanas' : 'semana'}`);
      case TimeUnity.month:
        return this._translocoService.translate(`'Há {0} ${value > 1 ? 'meses' : 'mês'}`);
      case TimeUnity.year:
        return this._translocoService.translate(`'Há {0} ${value > 1 ? 'anos' : 'ano'}`);
    }

  }

  private getMonthsAndYearsDifference(date1: Date, date2: Date): { months: number, years: number } {
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    let years = year2 - year1;
    let months = month2 - month1;

    if (months < 0) {
      years--;
      months += 12;
    }

    if (date1.getDate() < date2.getDate()) {
      months--;
      if (months < 0) {
        months += 12;
        years--;
      }
    }

    return { months, years };
  }
}

enum TimeUnity {
  second,
  minute,
  hour,
  day,
  week,
  month,
  year,
}