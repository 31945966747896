import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials'
})
export class UserInitialsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) return '';
    const names = value.replace(/\([^)]*\)|[^a-zA-Z ]/g, '').trim().split(' ');
    const firstName = names ? names[0].substring(0, 1) : '';
    const lastName = names && names.length > 1 ? names[names.length - 1].substring(0, 1) : '';
    return `${firstName}${lastName}`.toUpperCase();
  }
}
