import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcTotalsStatusService } from './svc-totals-status.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvcTotalsStatusComponent } from './svc-totals-status.component';
import { LibSharedComponentModule } from 'projects/lib-shared-component/src/public-api';

@NgModule({
  declarations: [
    SvcTotalsStatusComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslocoModule,
  ],
  providers: [
    SvcTotalsStatusService,
  ],
  exports: [
    SvcTotalsStatusComponent
  ],
})
export class SvcTotalsStatusModule { }
