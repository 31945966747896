<svc-dialog #dialog>

  <div svc-dialog-title class="text-xl font-bold">
    {{ 'Compartilhamentos' | transloco }}
  </div>

  <div svc-dialog-content class="flex flex-col overflow-y-auto gap-1.5 px-2 py-4" [style.maxHeight.px]="300">

    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let item of items"
        [ngTemplateOutlet]="userTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <div class="flex flex-col items-center justify-center py-15">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </ng-container>
    
  </div>

  <div svc-dialog-actions class="flex flex-row">
    <svc-button mode="flat" color="primary" (click)="close()" [buttonId]="'post-share-close'">
      {{'Fechar' | transloco}}
    </svc-button>
  </div>
</svc-dialog>

<ng-template #userTemplate let-item>
  <div class="flex flex-row items-center gap-2 w-full pl-1 pr-2">

    <div class="flex">
      <svc-user-avatar [info]="item.avatar"></svc-user-avatar>
    </div>

    <div class="flex-auto flex flex-col">
      <div class="font-bold leading-tight">{{ item.userName }}</div>
      <div *ngIf="item.userRole?.length">{{ item.userRole }}</div>
    </div>

    <div class="flex">
      <div class="relative text-default">
        <mat-icon class="text-current icon-size-7" svgIcon="heroicons_solid:share"></mat-icon>
        <div class="absolute flex items-center justify-center text-xxs bg-primary text-white -right-0.5 bottom-1.5 rounded-full" [ngClass]="{ 'w-4 h-4': item.sharedTimes < 100, 'w-5 h-5': item.sharedTimes > 99 }">
          {{ item.sharedTimes > 99 ? '+99' : item.sharedTimes }}
        </div>
      </div>
    </div>

  </div>
</ng-template>
