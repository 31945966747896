import { EnvironmentInfo } from './environment.info.model';

export class AWSAmplifyAuthInfo {
  Auth: AWSAmplifyAuth;
  oauth: AWSAmplifyOAuth;

  constructor(env: EnvironmentInfo, app: string) {
    this.Auth = new AWSAmplifyAuth(env.poolId, env.clientId);

    if (document.location.origin.startsWith('http://localhost:4200')) {
      this.oauth = new AWSAmplifyOAuth(document.location.origin, '', env.urlCognito);
    } else {
      this.oauth = new AWSAmplifyOAuth(env.url, app, env.urlCognito);
    }
  }
}

export class AWSAmplifyAuth {
  identityPoolId: string = '';
  region: string = 'us-east-1';
  userPoolId: string;
  userPoolWebClientId: string;
  constructor(poolId: string, clientId: string) {
    this.userPoolId = poolId;
    this.userPoolWebClientId = clientId;
  }
}

export class AWSAmplifyOAuth {
  domain: string;
  scope: [
    'aws.cognito.signin.user.admin',
    'email',
    'openid',
    'phone',
    'profile'
  ];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string = 'code';
  constructor(url: string, app: string, urlCognito: string) {
    this.redirectSignIn = `${url}${app ? '/' + app : ''}/sign-in`;
    this.redirectSignOut = `${url}/user/User/Logoff`;
    this.domain = urlCognito;
  }
}

export interface AWSAmplifyLoginResponse {
  result: AWSAmplifyAuthResult;
  currentUser: any;
  oAuth: AWSAmplifyOAuth;
}

export enum AWSAmplifyAuthResult {
  NewPasswordRequired,
  Success
}
