export interface ISvcChartIndicatorInfo {
  title: string;
  total: number;
  description: string;
  loading: boolean;
  error: any;
}

export interface ISvcChartIndicatorDefinitions {
  categories: { label: string, error?: boolean }[];
  series: {
    name: string;
    color: string;
    values: number[];
    minHeight?: number;
  }[];
  stacked?: boolean;
  showLegend?: boolean;
}

export enum ISvcChartIndicatorOrientation {
  HORIZONTAL,
  VERTICAL
}
