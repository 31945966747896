import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'svc-warning-dialog',
  templateUrl: './svc-warning-dialog.component.html',
  styleUrls: ['./svc-warning-dialog.component.scss']
})
export class SvcWarningDialogComponent {

  public title: string;
  public text: string;
  public buttonText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef?: MatDialogRef<SvcWarningDialogComponent>
  ) {
    this.title = data?.title ?? 'Aviso';
    this.text = data?.text;
    this.buttonText = data?.buttonText ?? 'OK';
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }
}
