<div [matDialogFullscreen]="'XS'">
	<div class="tui-image-editor-controls flex-row py-3">
		<div class="menu top-menu">
			<div class="menu-item disabled" id="btn-undo" [matTooltip]="'Defazer' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:undo"></mat-icon>
			</div>
			<div class="menu-item disabled" id="btn-redo" [matTooltip]="'Refazer' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:redo"></mat-icon>
			</div>
		</div>
		<div class="menu top-menu flex-auto justify-end">
			<div class="menu-item" (click)="save()">
				{{ 'Salvar' | transloco }}
			</div>
			<div class="menu-item" (click)="cancel()">
				{{ 'Cancelar' | transloco }}
			</div>
		</div>
	</div>
	<div *ngIf="isLoading" class="flex justify-center py-10">
		<mat-spinner diameter="30"></mat-spinner>
	</div>
	<div class="tui-image-editor" [ngClass]="{ 'hidden': isLoading }"></div>
	<div class="tui-image-editor-controls py-3">
		<div class="menu">
			<div class="menu-item" id="btn-crop" [matTooltip]="'Recortar' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:crop"></mat-icon>
			</div>
			<div class="menu-item" id="btn-flip" [matTooltip]="'Espelhar' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:flip"></mat-icon>
			</div>
			<div class="menu-item" id="btn-rotation" [matTooltip]="'Girar' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:refresh"></mat-icon>
			</div>
			<div class="menu-item" id="btn-draw-line" [matTooltip]="'Desenhar' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:brush"></mat-icon>
			</div>
			<div class="menu-item hidden" id="btn-draw-shape" [matTooltip]="'Formas' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:extension"></mat-icon>
			</div>
			<div class="menu-item" id="btn-text" [matTooltip]="'Texto' | transloco">
				<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:title"></mat-icon>
			</div>
		</div>
		<div class="sub-menu-container hidden" id="crop-sub-menu">
			<div class="menu flep-auto">
				<div class="menu-item" id="btn-apply-crop">{{ 'Aplicar' | transloco }}</div>
				<div class="menu-item close">{{ 'Cancelar' | transloco }}</div>
			</div>
		</div>
		<div class="sub-menu-container hidden" id="flip-sub-menu">
			<div class="menu">
				<div class="menu-item" id="btn-flip-x">{{ 'Espelhar em X' | transloco }}</div>
				<div class="menu-item" id="btn-flip-y">{{ 'Espelhar em Y' | transloco }}</div>
			</div>
			<div class="menu">
				<div class="menu-item" id="btn-reset-flip">{{ 'Resetar' | transloco }}</div>
				<div class="menu-item close">{{ 'Fechar' | transloco }}</div>
			</div>
		</div>
		<div class="sub-menu-container hidden" id="rotation-sub-menu">
			<div class="menu">
				<div class="menu-item" id="btn-rotate-counter-clockwise">
					<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:rotate_left"></mat-icon>
				</div>
				<div class="menu-item" id="btn-rotate-clockwise">
					<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:rotate_right"></mat-icon>
				</div>
				<div class="menu-item close">{{ 'Fechar' | transloco }}</div>
			</div>
		</div>
		<div class="sub-menu-container hidden" id="draw-line-sub-menu">
			<div class="menu flex-col sm:flex-row">
				<div class="menu-item">
					<div id="tui-brush-color-picker">{{'Color' | transloco}}</div>
				</div>
				<div class="menu-item">
					<label class="menu-item flex-col no-pointer">
						{{'Espessura' | transloco}}
						<input id="input-brush-width-range" type="range" min="5" max="30" value="12" />
					</label>
				</div>
			</div>
			<div class="menu">
				<div class="menu-item close">{{ 'Fechar' | transloco }}</div>
			</div>
		</div>
		<div class="sub-menu-container hidden" id="text-sub-menu">
			<div class="menu flex-col">
				<div class="menu flex-col p-0">
					{{ 'Formatação' | transloco }}
					<div class="menu p-0">
						<button class="menu-item btn-text-style" data-style-type="b">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_bold"></mat-icon>
						</button>
						<button class="menu-item btn-text-style" data-style-type="i">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_italic"></mat-icon>
						</button>
						<button class="menu-item btn-text-style" data-style-type="u">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_underlined"></mat-icon>
						</button>
						<button class="menu-item btn-text-style" data-style-type="l">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_align_left"></mat-icon>
						</button>
						<button class="menu-item btn-text-style" data-style-type="c">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_align_center"></mat-icon>
						</button>
						<button class="menu-item btn-text-style" data-style-type="r">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:format_align_right"></mat-icon>
						</button>
					</div>
				</div>
				<div class="menu-item">
					<label class="no-pointer">
						{{ 'Tamanho' | transloco }}<br/>
						<input id="input-font-size-range" type="range" min="10" max="100" value="10" />
					</label>
				</div>
				<div class="menu-item">
					<div id="tui-text-color-picker">{{ 'Cor' | transloco }}</div>
				</div>
				<div class="menu-item close">{{ 'Fechar' | transloco }}</div>
			</div>
		</div>
	</div>
</div>