import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from "projects/environments/environment";
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { SvcAppSettings, SvcFeatureToggleService, SvcModule } from 'projects/lib-shared-core/src/public-api';
import { Subject, tap } from 'rxjs';

export type MobileMenuType = 'menu' | 'modules' | 'tasks' | 'feed';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {

  @Input() totalTasks: number = null;
  @Input() loadingTasks = true;
  @Input() totalFeeds: number = null;
  @Input() loadingFeeds = true;
  @Output() menuClick = new EventEmitter<MobileMenuType>();
  @Output() onShown = new EventEmitter<boolean>();

  public env = environment;
  public inWorkspace = false;
  public disableFeed = false;
  public feedIsVisible = false;
  public isMenuReadQrCodeCall: boolean;
  public isMenuSyncCall: boolean;
  private appOnlyFeatureMessage = 'Funcionalidade disponível apenas pelo aplicativo';

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _svcMediaQuery: SvcMediaQuery,
    private svcAppSettings: SvcAppSettings,
    private featureToggleService: SvcFeatureToggleService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
  ) {
    this._svcMediaQuery.size$.pipe(
      tap(() => this.checkIfWillBeShown())
    ).subscribe()
    this.inWorkspace = this.svcAppSettings.module === SvcModule.MyWorkspace;
    this.feedIsVisible = this.featureToggleService.feedIsEnabled;
  }

  public ngOnInit() {
    this.checkIfWillBeShown();
    this.isMenuReadQrCodeCall = typeof (window as any).MenuReadQrCodeCall != 'undefined';
    this.isMenuSyncCall = typeof (window as any).MenuSyncCall != 'undefined';
  }

  private checkIfWillBeShown() {
    const isShowing = this._svcMediaQuery.currentSize.isBelowLG;
    this.onShown.emit(isShowing);
  }

  onClickMenu(type: MobileMenuType) {
    if (type === 'feed' && !this.feedIsVisible) {
      return;
    }
    this.menuClick.emit(type);
  }

  qrCodeCall() {
    if (this.isMenuReadQrCodeCall) {
      (window as any).MenuReadQrCodeCall.postMessage("");
    } else {
      this._toastService.error(this._translocoService.translate(this.appOnlyFeatureMessage));
    }
  }

  offlineCall() {
    if (this.isMenuSyncCall) {
      (window as any).MenuSyncCall.postMessage("");
    } else {
      this._toastService.error(this._translocoService.translate(this.appOnlyFeatureMessage));
    }
  }
}
