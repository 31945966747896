<div class="flex flex-col-reverse">
	<div *ngFor="let toast of toasts | async">
		<svc-toast-item
			[type]="toast.type"
			[message]="toast.message"
			[title]="toast.title"
			[lifespan]="toast.lifespan"
			[hideIcon]="toast.hideIcon"
			(onDismissClicked)="toast.onDismissClicked()"
			@fadeInUp
			@stagger
		></svc-toast-item>
	</div>
</div>