import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcPivotTableRoutingModule } from './svc-pivot-table-routing.module';
import { SvcPivotTableComponent } from './svc-pivot-table.component';
import { SvcBreadcrumbModule, SvcDataTableModule } from 'projects/lib-shared-component/src/public-api';
import { SvcPivotTableService } from './shared/services/svc-pivot-table.service';
import { SvcPivotTableHttpService } from './shared/services/svc-pivot-table-http.service';
import { SvcPivotTableErrorService } from './shared/services/svc-pivot-table-error.service';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/lib/lib-shared-common.module';

@NgModule({
  declarations: [
    SvcPivotTableComponent
  ],
  imports: [
    LibSharedCommonModule,
    SvcPivotTableRoutingModule,
    SvcBreadcrumbModule,
    TranslocoModule,
    RouterModule,
    SvcDataTableModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    SvcPivotTableComponent,
  ],
  providers: [
    SvcPivotTableService,
    SvcPivotTableHttpService,
    SvcPivotTableErrorService,
  ]
})
export class SvcPivotTableModule { }
