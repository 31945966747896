<ng-container *ngFor="let item of navigation">
  <a href="javascript:void(0)" class="text-primary text-sm leading-tight font-normal" (click)="goTo(item)">
    <div class="flex p-1.5 h-[38px] items-center justify-between gap-4 bg-primary-100 rounded-lg">

      <div class="flex gap-2 items-center">
        <div *ngIf="item !== itemLoading">
          <app-user-navigation-images [title]="item?.title"></app-user-navigation-images>
        </div>

        <span *ngIf="item !== itemLoading">{{ item.title | transloco }}</span>
        <mat-spinner *ngIf="item === itemLoading" diameter="18"></mat-spinner>
      </div>

      <div *ngIf="item?.title !== 'Meu Personal Book'">
        <div *ngIf="item?.title === 'Minhas Qualificações'">
          <ng-container *ngIf="!loadingState.myQualifications; else loadingMyQualifications">
            <div *ngIf="accessibilityState.myQualifications; else withoutQualifications"
              [ngClass]="{'relative flex items-center justify-center bg-primary rounded-full p-1 min-w-[28px] min-h-[27px]': counters.qualifications !== null && counters.qualifications >= 0 }">
              <div class="text-white text-base font-bold flex items-center justify-center">
                <ng-container *ngIf="counters.qualifications !== null && counters.qualifications >= 0; else refresh">
                  {{ counters.qualifications + '%' }}
                </ng-container>
                <ng-template #refresh>
                  <div class="flex items-center font-normal">
                    <mat-icon *ngIf="!loadingState.myQualifications" class="icon-size-5 text-primary cursor-pointer"
                      svgIcon="heroicons_solid:arrow-path" [matTooltip]="'Atualizar' | transloco"
                      (click)="getCounterMyQualifications()">
                    </mat-icon>

                    <mat-spinner diameter="18" *ngIf="loadingState.myQualifications"></mat-spinner>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-template #withoutQualifications>
            <div class="pr-3 text-primary text-2xl font-bold">
              {{ '-' }}
            </div>
          </ng-template>
          <ng-template #loadingMyQualifications>
            <mat-spinner diameter="18"></mat-spinner>
          </ng-template>
        </div>

        <div *ngIf="item?.title === 'Elogios'">
          <ng-container *ngIf="!loadingState.praiseReceived; else loadingPraiseReceived">
            <div *ngIf="accessibilityState.praiseReceived; else withoutPraiseReceived"
              [ngClass]="{'relative flex items-center justify-center bg-primary rounded-full p-1 min-w-[28px] min-h-[27px]': counters.compliments !== null && counters.compliments >= 0 }">
              <div class="text-white text-base font-bold flex items-center justify-center">
                <ng-container *ngIf="counters.compliments !== null && counters.compliments >= 0; else refresh">
                  {{ counters.compliments | formatNumberDefault }}
                </ng-container>
                <ng-template #refresh>
                  <div class="flex items-center font-normal">
                    <mat-icon *ngIf="!loadingState.praiseReceived" class="icon-size-5 text-primary cursor-pointer"
                      svgIcon="heroicons_solid:arrow-path" [matTooltip]="'Atualizar' | transloco"
                      (click)="getPraiseReceivedCounter()">
                    </mat-icon>

                    <mat-spinner diameter="18" *ngIf="loadingState.praiseReceived"></mat-spinner>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-template #withoutPraiseReceived>
            <div class="pr-3 text-primary text-2xl font-bold">
              {{ '-' }}
            </div>
          </ng-template>
          <ng-template #loadingPraiseReceived>
            <mat-spinner diameter="18"></mat-spinner>
          </ng-template>
        </div>
      </div>

      <mat-spinner *ngIf="loadingData && item?.title !== 'Meu Personal Book'" diameter="18"></mat-spinner>
    </div>
  </a>
</ng-container>
