import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcButtonsModule } from '../svc-buttons/svc-buttons.module';
import { SvcControlsModule } from '../svc-controls/svc-controls.module';
import { SvcDataTableExampleComponent } from './example/svc-data-table-example.component';
import { SvcDataPrepareService } from './services/svc-data-prepare.service';
import { SvcDataVirtualScrollService } from './services/svc-data-virtual-scroll.service';
import { SvcDataBodyComponent } from './svc-data-body/svc-data-body.component';
import { SvcDataCellComponent } from './svc-data-cell/svc-data-cell.component';
import { SvcDataColComponent } from './svc-data-col/svc-data-col.component';
import { SvcDataFilterComponent } from './svc-data-filter/svc-data-filter.component';
import { SvcDataHeaderComponent } from './svc-data-header/svc-data-header.component';
import { SvcDataPaginateComponent } from './svc-data-paginate/svc-data-paginate.component';
import { SvcDataRowComponent } from './svc-data-row/svc-data-row.component';
import { SvcDataTableComponent } from './svc-data-table/svc-data-table.component';

@NgModule({
	declarations: [
		SvcDataBodyComponent,
		SvcDataCellComponent,
		SvcDataColComponent,
		SvcDataFilterComponent,
		SvcDataHeaderComponent,
		SvcDataPaginateComponent,
		SvcDataRowComponent,
		SvcDataTableComponent,
		SvcDataTableExampleComponent,
	],
	imports: [
		CommonModule,
		SvcButtonsModule,
		MatTooltipModule,
		ReactiveFormsModule,
		FormsModule,
		MatIconModule,
		MatSlideToggleModule,
		MatFormFieldModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		SvcButtonsModule,
		SvcControlsModule,
    TranslocoModule
	],
	exports: [
		SvcDataBodyComponent,
		SvcDataCellComponent,
		SvcDataColComponent,
		SvcDataFilterComponent,
		SvcDataHeaderComponent,
		SvcDataPaginateComponent,
		SvcDataRowComponent,
		SvcDataTableComponent,
		SvcDataTableExampleComponent,
	],
	providers: [
		DatePipe,
    SvcDataPrepareService,
		SvcDataVirtualScrollService,
	],
})
export class SvcDataTableModule {}
