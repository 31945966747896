import { TranslocoService } from '@ngneat/transloco';

import { SvcSelectPeriodDefaultIds } from '../enums/svc-select-period-default-ids.enum';
import { SvcSelectPeriodOption } from '../interfaces/svc-select-period-option.interface';


const formatDateToPattern = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const getDateByDifferenceDays = (days: number, isPast: boolean): string => {
  const today = new Date();
  const date = new Date(today);
  date.setDate(isPast ? today.getDate() - days : today.getDate() + days);
  return formatDateToPattern(date);
}

export function getDefaultSelectPeriodOptions(translocoService: TranslocoService): SvcSelectPeriodOption[] {
  const today = formatDateToPattern(new Date());
  const tomorrow = getDateByDifferenceDays(1, false);
  const yesterday = getDateByDifferenceDays(1, true)

  const _today = new Date();

  const _weekStart = new Date(today);
  _weekStart.setDate(_today.getDate() - _today.getDay() + 1);
  const weekStart = formatDateToPattern(_weekStart);
  const _weekEnd = new Date(_weekStart);
  _weekEnd.setDate(_weekStart.getDate() + 6);
  const weekEnd = formatDateToPattern(_weekEnd);

  const monthStart = formatDateToPattern(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const monthEnd = formatDateToPattern(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

  const lastWeekStart = getDateByDifferenceDays(7, true);
  const lastTwoWeeksStart = getDateByDifferenceDays(14, true);
  const lastMonthStart = getDateByDifferenceDays(30, true);
  const lastThreeMonthsStart = getDateByDifferenceDays(90, true);

  const nextWeekEnd = getDateByDifferenceDays(7, false);
  const nextTwoWeeksEnd = getDateByDifferenceDays(14, false);
  const nextMonthEnd = getDateByDifferenceDays(30, false);
  const nextThreeMonthsEnd = getDateByDifferenceDays(90, false);

  return [
    {
      text: translocoService.translate('Ontem'),
      startDate: yesterday,
      endDate: yesterday,
      id: SvcSelectPeriodDefaultIds.Yesterday
    },
    {
      text: translocoService.translate('Hoje'),
      startDate: today,
      endDate: today,
      id: SvcSelectPeriodDefaultIds.Today
    },
    {
      text: translocoService.translate('Amanhã'),
      startDate: tomorrow,
      endDate: tomorrow,
      id: SvcSelectPeriodDefaultIds.Tomorrow
    },
    {
      text: translocoService.translate('Essa semana'),
      startDate: weekStart,
      endDate: weekEnd,
      id: SvcSelectPeriodDefaultIds.ThisWeek
    },
    {
      text: translocoService.translate('Esse mês'),
      startDate: monthStart,
      endDate: monthEnd,
      id: SvcSelectPeriodDefaultIds.ThisMonth
    },
    {
      text: translocoService.translate('Últimos 7 dias'),
      startDate: lastWeekStart,
      endDate: today,
      id: SvcSelectPeriodDefaultIds.PastSevenDays
    },
    {
      text: translocoService.translate('Últimos 14 dias'),
      startDate: lastTwoWeeksStart,
      endDate: today,
      id: SvcSelectPeriodDefaultIds.PastFourteenDays
    },
    {
      text: translocoService.translate('Últimos 30 dias'),
      startDate: lastMonthStart,
      endDate: today,
      id: SvcSelectPeriodDefaultIds.PastThirtyDays
    },
    {
      text: translocoService.translate('Últimos 90 dias'),
      startDate: lastThreeMonthsStart,
      endDate: today,
      id: SvcSelectPeriodDefaultIds.PastNinetyDays
    },
    {
      text: translocoService.translate('Próximos 7 dias'),
      startDate: today,
      endDate: nextWeekEnd,
      id: SvcSelectPeriodDefaultIds.NextSevenDays
    },
    {
      text: translocoService.translate('Próximos 14 dias'),
      startDate: today,
      endDate: nextTwoWeeksEnd,
      id: SvcSelectPeriodDefaultIds.NextFourteenDays
    },
    {
      text: translocoService.translate('Próximos 30 dias'),
      startDate: today,
      endDate: nextMonthEnd,
      id: SvcSelectPeriodDefaultIds.NextThirtyDays
    },
    {
      text: translocoService.translate('Próximos 90 dias'),
      startDate: today,
      endDate: nextThreeMonthsEnd,
      id: SvcSelectPeriodDefaultIds.NextNinetyDays
    },
    {
      text: translocoService.translate('Customizado'),
      startDate: null,
      endDate: null,
      id: SvcSelectPeriodDefaultIds.Customize
    }
  ];
}
