import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'svc-quill-text-editor',
  templateUrl: './svc-quill-text-editor.component.html',
  styleUrls: ['./svc-quill-text-editor.component.scss'],
})
export class SvcQuillTextEditorComponent {
  @Input() inputId: string;
  @Input() placeholder: string;
  @Input() modules: unknown;
  @Input() format: string;
  @Input() styles: unknown;
  @Input() dataEditor: unknown;
  @Input() control: FormControl;
}
