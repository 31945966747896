import { Component } from '@angular/core';
import {ButtonBase} from "../svc-button-base";

@Component({
  selector: 'svc-button-circular',
  templateUrl: './svc-button-circular.component.html',
  styleUrls: ['./svc-button-circular.component.scss']
})
export class SvcButtonCircularComponent extends ButtonBase {
}
