import { Component } from "@angular/core";

@Component({
  selector: 'svc-router-outlet',
  template: '<router-outlet></router-outlet>',
  styles: [`:host {
    display: block;
    height: 100%;
  }`],
})
export class SvcRouterOutletComponent {
}