import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageCantBeLoadComponent } from './page-cant-be-load.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcButtonsModule } from 'projects/lib-shared-component/src/public-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        PageCantBeLoadComponent
    ],
    imports: [
        RouterModule,
        LibSharedCommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        SvcButtonsModule,
        TranslocoModule,
    ],
    exports: [
        PageCantBeLoadComponent
    ]
})
export class PageCantBeLoadModule {}
