import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-embed-html',
  templateUrl: './modal-embed-html.component.html',
  styleUrls: ['./modal-embed-html.component.scss'],
})
export class ModalEmbedHtmlComponent implements OnInit {

  public html: string;
  public copied: boolean;

  @ViewChild('code') public codeElementRef: ElementRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private _dialogRef?: MatDialogRef<ModalEmbedHtmlComponent>
  ) {
    this.html = data.html;
  }

  public closeDialog() {
    this._dialogRef.close();
  }

  public copyToClipboard() {
    if (!this.copied) {
      const element = this.codeElementRef.nativeElement;
      var textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.opacity = '0';
      textArea.textContent = element.textContent;
      document.body.append(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      this.copied = true;

      setTimeout(() => textArea.remove());
      setTimeout(() => this.copied = false, 4000);
    }
  }

  public ngOnInit(): void {
  }
}
