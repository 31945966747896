import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { SvcToastService } from "projects/lib-shared-component/src/public-api";

@Injectable({
	providedIn: 'root'
})
export class HttpErrorService {
	constructor(
		private _translocoService: TranslocoService,
		private _toastService: SvcToastService,
	) {
	}

	public showErrorInToast(error: HttpErrorResponse): void {
		const message = this.getErrorMessage(error);
		this._toastService.error(message);
	}

	public getErrorMessage(error: HttpErrorResponse): string {
		// Stopped to get error message from http request response
		// if (error.error?.errors?.Server) {
		// 	return error.error.errors.Server;
		// }
		return this.getDefaulfErrorMessage();
	}

	public getDefaulfErrorMessage(): string {
		return this._translocoService.translate('Ocorreu um erro, tente novamente em outro momento');
	}
}