import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Eligibility } from 'projects/communication/src/app/communication/models/user-eligibility';
import { SvcDialogStyles } from '../../models/svc-dialog-styles';

@Component({
  selector: 'svc-dialog-primary',
  templateUrl: './svc-dialog-primary.component.html',
  styleUrls: ['./svc-dialog-primary.component.scss'],
})
export class SvcDialogPrimaryComponent implements AfterContentInit {
  @ViewChild('modal') modal: any;
  @ViewChild('titleContainer') dialogTitleContainer: ElementRef<HTMLElement>;

  @Input() textConfirm: string;
  @Input() textCancel: string;
  @Input() dialogStyle: SvcDialogStyles = 'form';
  @Input() disableConfirm = false;
  @Input() disableCancel = false;
  @Input() showMatMenu: Eligibility;
  @Input() processing = false;
  @Input() fullscreen: string | string[] = 'XS';
  @Input() scrollInsideContent: boolean = true;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @Output() actionRemove = new EventEmitter();
  @Output() actionReschedule = new EventEmitter();
  @Output() actionResend = new EventEmitter();
  @Output() actionSetEditMode = new EventEmitter();
  @Output() actionView = new EventEmitter();
  @Output() actionClose = new EventEmitter();

  footerContentIsEmpty: boolean = true;

  hasTitle: boolean = false;
  hasActions: boolean = false;

  constructor(private _dialogRef?: MatDialogRef<SvcDialogPrimaryComponent>) { }

  ngOnInit() { }

  ngAfterContentInit() {
    this.opened.emit();
  }

  ngOnDestroy() {
    this.closed.emit();
  }

  closeDialog() {
    this._dialogRef.close();
  }

  remove() {
    this.actionRemove.emit();
  }

  resend() {
    this.actionResend.emit();
  }

  reschedule() {
    this.actionReschedule.emit();
  }

  close() {
    this.actionClose.emit();
  }

  setEditMode() {
    this.actionSetEditMode.emit();
  }

  view() {
    this.actionView.emit();
  }
}
