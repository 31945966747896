<div class="container">

  <div class="flex w-full">

    <!-- SLIDE EMPTY VIEW (ADD MORE) -->
    <section *ngIf="isInEmptyView" class="m-auto">
      <img
        class="self-center w-auto object-none"
        src="assets/default_card_thumbnail.png"
        [style.height.auto]="!height"
        [style.minHeight.px]="minHeight ? minHeight : 200"
        [style.maxHeight.px]="height ? height : null"
      />
      <!-- ADD BUTTON -->
      <div *ngIf="!readOnly && onAddSlide.observed" class="absolute top-3 right-3 flex w-6 h-6 items-center justify-center rounded-full bg-primary text-white shadow cursor-pointer" (click)="emitAddSlide()" [matTooltip]="'Adicionar' | transloco">
        <mat-icon class="icon-size-4 text-current" svgIcon="fontawesome_solid:plus"></mat-icon>
      </div>
    </section>

    <!-- CURRENT SLIDE -->
    <section *ngIf="currentSlide" class="w-full">
      <ng-container *ngIf="slides.length > 0">
        <div *ngIf="!currentSlide?.type || currentSlide?.type === 'image'" class="relative">
          <img
            class="self-center w-full"
            [src]="currentSlide?.url"
            [style.height.auto]="!height"
            [style.minHeight.px]="minHeight ? minHeight : null"
            [style.maxHeight.px]="height ? height : null"
            [style.object-fit]="fitContent"
            [class.cursor-pointer]="imageViewer"
            (error)="currentSlide.fileLoading = false"
            (load)="currentSlide.fileLoading = false"
            (click)="openInViewer()"
          />
          <div *ngIf="currentSlide.fileLoading" class="absolute inset-0 flex items-center justify-center text-primary">
            <div class="absolute inset-0 bg-white opacity-50"></div>
            <mat-spinner class="relative z-99 text-current" diameter="24"></mat-spinner>
          </div>
        </div>
        <div *ngIf="currentSlide?.type === 'video'" class="flex slide">
          <video
            class="grow"
            [controls]="videoControls"
            [style.minHeight.px]="height ? height : null"
            [style.maxHeight.px]="height ? height : null"
            [src]="currentSlide.url"
          ></video>
        </div>
      </ng-container>
      
      <!-- REMOVE BUTTON -->
      <div *ngIf="!readOnly && onRemoveSlide.observed" class="absolute top-3 right-3 flex w-6 h-6 items-center justify-center rounded-full bg-primary text-white shadow cursor-pointer" (click)="removeSlide()" [matTooltip]="'Remover' | transloco">
        <mat-icon class="icon-size-4.5 text-current" svgIcon="heroicons_solid:trash"></mat-icon>
      </div>
    </section>
  </div>

  <!-- NAV-BUTTON PREVIOUS -->
  <ng-container *ngIf="currentIndex > 0 || (!readOnly && isAddAvailable && !isInEmptyView)">
    <button class="navigation-button bg-transparent w-10 h-10 min-h-10 top-1/2 left-2 text-white shadow-none" type="button" mat-fab (click)="goToPrevious($event)">
      <mat-icon class="icon-size-8" svgIcon="heroicons_solid:chevron-left"></mat-icon>
    </button>
  </ng-container>

  <!-- NAV-BUTTON NEXT -->
  <ng-container *ngIf="slides.length > 0 && (currentIndex < (slides.length - 1) || isInEmptyView)">
    <button class="bg-transparent w-10 h-10 min-h-10 top-1/2 right-2 text-white shadow-none navigation-button" type="button" mat-fab (click)="goToNext($event)">
      <mat-icon class="icon-size-8" svgIcon="heroicons_solid:chevron-right"></mat-icon>
    </button>
  </ng-container>
</div>
