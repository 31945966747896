import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-multiple-text-boxes-answer',
  templateUrl: './multiple-text-boxes-answer.component.html',
  styleUrls: ['./multiple-text-boxes-answer.component.scss'],
})
export class MultipleTextBoxesAnswerComponent implements OnInit {
  @ViewChild('text') text: ElementRef;

  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public isSave = false;

  #fb = inject(FormBuilder);
  #modalService = inject(SurveyModalService);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.form = this.#fb.group({
      options: this.#fb.array(this.question?.metadata?.options?.map(({ title, text }) => this.#fb.group({
        title: [title],
        text: [text, [Validators.maxLength(1000)]],
      })) ?? []),
    });

    this.changesAnswered();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  private changesAnswered() {
    const optionsControls = (this.form.get('options') as FormArray<FormGroup>).controls;
    optionsControls.forEach((optionGroup: FormGroup) => {
      optionGroup.get('text')?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(() => {
          this.question.answered = optionsControls.every((c) => (c.controls?.text?.value ?? '') !== '');
        }),
      ).subscribe();
    });
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    const valueForm = this.form.value.options;
    const payload = {
      Options: valueForm.map((option) => ({
        Title: option.title,
        Text: option.text,
      })),
    };

    this.doSave.emit(payload);
  }
}
