<div class="fixed top-0 bottom-0 left-full w-full sm:w-96 sm:h-screen sm:shadow" *screenSizes="let size">
  <div class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card" [ngClass]="{ '-translate-x-full shadow': opened, 'translate-x-0': !opened }">
    <!-- Header -->
    <div class="apps-panel-header flex flex-0 items-center justify-start cursor-pointer" (click)="toggle()">
      <!-- Toggle -->
      <ng-container *ngIf="opened">
        <div class="flex flex-auto items-center justify-center overflow-hidden pl-4">
          <div class="flex flex-0 items-center justify-center text-default">
            <mat-icon class="text-current" [ngClass]="size.isBelowMD ? 'icon-size-8' : 'icon-size-6'" svgIcon="mat_solid:apps"></mat-icon>
          </div>
          <div #panelTitle class="text-default font-bold truncate pl-2" [ngClass]="size.isBelowMD ? 'text-2xl' : 'text-lg'" [title]="showTitle ? 'Apps ('+ ('Caixa de Ferramentas' | transloco) + ')' : ''">
            Apps ({{ 'Caixa de Ferramentas' | transloco }})
          </div>
          <button class="ml-auto mr-4 w-6 min-w-6 h-6 min-h-6" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="text-default icon-size-5"></mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <!-- Content -->
    <div *ngIf="!isLoading" class="border-t py-4 px-2 space-y-2 overflow-y-auto" [ngClass]="{ 'opacity-50': isPanelItemLoading }">

      <mat-form-field class="svc-mat-no-subscript svc-mat-dense w-full">
        <input
          matInput
          [formControl]="searchCtrl"
          [placeholder]="'Pesquisar' | transloco"
          type="search"
        />
        <mat-icon class="icon-size-4" svgIcon="heroicons_solid:magnifying-glass"></mat-icon>
      </mat-form-field>

      <!-- Highlight Apps -->
      <div class="grid grid-cols-1 gap-2" *ngIf="(filteredHighlightApps.length > 0)">
        <div class="flex flex-col">
          <app-panel-item *ngFor="let app of filteredHighlightApps" [app]="app" [disabled]="isPanelItemLoading" [expanded]="app.expanded"></app-panel-item>
        </div>
      </div>

      <!-- Common Apps -->
      <div class="flex space-x-2" *ngIf="filteredAppsColumn1.length > 0 || filteredAppsColumn2.length > 0">
        <div class="flex flex-col" *ngIf="filteredAppsColumn1.length > 0">
          <app-panel-item *ngFor="let app of filteredAppsColumn1" [app]="app" [disabled]="isPanelItemLoading" [expanded]="app.expanded"></app-panel-item>
        </div>
        <div class="flex flex-col" *ngIf="filteredAppsColumn2.length > 0">
          <app-panel-item *ngFor="let app of filteredAppsColumn2" [app]="app" [disabled]="isPanelItemLoading" [expanded]="app.expanded"></app-panel-item>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading" class="flex justify-center border-t">
      <mat-spinner class="mt-10" diameter="30"></mat-spinner>
    </div>
  </div>
</div>
