<!-- <button mat-icon-button (click)="settingsDrawer.toggle()"> -->
<button mat-icon-button>
  <mat-icon class="text-current" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
</button>

<div class="flex flex-col w-full overflow-auto bg-card">
  <div
    class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary"
  >
    <mat-icon
      class="icon-size-7 text-current"
      [svgIcon]="'heroicons_solid:cog-6-toothcog-6-toothcog-6-toothcog-6-tooth'"
    ></mat-icon>
    <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
    <!-- <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()"> -->
    <button class="ml-auto" mat-icon-button>
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_solid:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <div class="flex flex-col p-6">
    <!-- Theme -->
    <div class="text-md font-semibold text-secondary">Theme</div>
    <div class="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-6">
      <ng-container *ngFor="let theme of themes">
        <div
          class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="selectedTheme === theme"
          [ngClass]="getThemeName(theme)"
          (click)="setTheme(theme)"
        >
          <div class="flex-0 w-3 h-3 rounded-full bg-primary"></div>
          <div
            class="ml-2.5 font-medium leading-5 truncate"
            [class.text-secondary]="selectedTheme !== theme"
          >
            {{ theme }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
