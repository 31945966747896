import { ISvcStatusBadge } from "projects/lib-shared-component/src/public-api";
import { CategorizedDrillDown } from "projects/lib-shared-model/src/public-api";

export interface InspetionDrillDownItem {
    checkListId: number;
    checkListName: string;
    checkListCreateDate: Date;
    checkListStatus: number;
    isPrivate: boolean;
    inspectionItemsNotOk: number;
    inspectionItemsOk: number;
    inspectionItemsPending: number;
    inspectionRegistryNumber: number;
    owners?: any;
    deadline: string;
}

export class InspetionCategorizedDrillDown implements CategorizedDrillDown<InspetionDrillDownItem> {
    indicatorEnum: number;
    indicatorItems: InspetionDrillDownItem[];
}

export enum InspectionDrillDownStatus {
    OPEN = 10,
    IN_APPROVAL = 20,
    COMPLETED = 30
  }

export const InspectionDrillDownStatusBadge = <InspectionDrillDownStatus>(
    enumDefinition: InspectionDrillDownStatus
  ): ISvcStatusBadge  => {
    switch (enumDefinition) {
      case InspectionDrillDownStatus.OPEN:
        return { description: 'Aberta', type: 'opened' };
      case InspectionDrillDownStatus.IN_APPROVAL:
        return { description: 'Em Aprovação', type: 'approval' };
      case InspectionDrillDownStatus.COMPLETED:
        return { description: 'Concluída', type: 'completed' };
    }
}
