<section #draggingArea>
  <div #leftLimit class="left-limit" [style.left]="leftLimitControl.position + '%'">
    <p
      id="left-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && leftLimitControl.currentDate && (!isOverlapping || isDraggingLeft)"
    >
      {{ leftLimitControl.currentDate.name }}
    </p>
  </div>

  <div #rightLimit class="right-limit" [style.right]="rightLimitControl.position + '%'">
    <p
      id="right-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && rightLimitControl.currentDate && (!isOverlapping || isDraggingRight)"
    >
      {{ rightLimitControl.currentDate.name }}
    </p>
  </div>
</section>
<div class="flex">
  <p class="flex-auto text-base">{{minDate | date:'yyyy'}}</p>
  <p class="flex-auto text-base text-right">{{maxDate | date:'yyyy'}}</p>
</div>
