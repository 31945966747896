import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

@Component({
  selector: 'svc-toast-item',
  templateUrl: './svc-toast-item.component.html',
  styleUrls: ['./svc-toast-item.component.scss'],
})
export class SvcToastItemComponent implements OnInit {

  @HostBinding('class.success') private isSuccess: boolean;
  @HostBinding('class.error') private isError: boolean;
  @HostBinding('class.warning') private isWarning: boolean;
  @HostBinding('class.info') private isInfo: boolean;

  @Input() public type: ToastType;
  @Input() public title?: string;
  @Input() public message: string;
  @Input() public lifespan: number;
  @Input() public hideIcon: boolean;

  @Output() public onDismissClicked = new EventEmitter<void>();

  constructor(){
  }

  ngOnInit(): void {
    this.isSuccess = this.type == ToastType.SUCCESS;
    this.isError = this.type == ToastType.ERROR;
    this.isWarning = this.type == ToastType.WARNING;
    this.isInfo = this.type == ToastType.INFO;
  }
}
