<div *ngIf="selectedFeed" class="p-3 pb-4">
  <div class="text-primary font-bold line-clamp-3 break-words" [ngClass]="parent.expanded ? 'text-xl' : 'text-sm'">
    <p class="line-clamp-3 break-words leading-tight">
      {{ selectedFeed.title | removeHtml }}
    </p>
  </div> 
  <p class="text-primary-300 font-bold truncate" [ngClass]="parent.expanded ? 'text-base' : 'text-xs'">
    {{ selectedFeed.subtitle | removeHtml }}
  </p> 
</div>

<svc-comments-chat *ngIf="selectedFeed"
  class="block pb-2"
  [ngClass]="parent.expanded ? 'px-4' : 'px-2'"
  [smallSize]="!parent.expanded"
  [referenceId]="selectedFeed.registryId > 0 ? selectedFeed.registryId : selectedFeed.registryUid"
  [configTypeId]="selectedFeed.configTypeId"
  [siteId]="selectedFeed.siteId"
  [external]="selectedFeed.isGlobalModule ?? false"
></svc-comments-chat>

<div *ngIf="!selectedFeed && !parent.isLoading && !parent.isCheckingFeedCanBeLoaded" class="flex flex-col flex-auto items-center justify-center h-full py-3 px-4">
  <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
    <mat-icon class="text-primary-700" svgIcon="heroicons_outline:chat-bubble-left-right"></mat-icon>
  </div>
  <div class="mt-5 text-2xl font-semibold tracking-tight">
    {{ 'Sem mensagens' | transloco }}
  </div>
  <div class="w-full max-w-60 mt-1 text-md text-center text-default">
    {{ 'Quando você selecionar uma conversa, as mensagens aparecerão aqui.' | transloco }}
  </div>
</div>