import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { TranslocoModule } from '@ngneat/transloco';
import { AgVirtualScrollModule } from 'ag-virtual-scroll';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcNotificationsComponent } from './svc-notifications.component';

@NgModule({
	declarations: [
		SvcNotificationsComponent
	],
	imports: [
		RouterModule,
		OverlayModule,
		PortalModule,
		LibSharedCommonModule,
		LibSharedComponentModule,
		TranslocoModule,
		SvcButtonsModule,
		SvcUserAvatarModule,
		AgVirtualScrollModule,
	],
	exports: [
		SvcNotificationsComponent
	]
})
export class SvcNotificationsModule {
}
