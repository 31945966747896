import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { TranslocoModule } from "@ngneat/transloco";
import { SvcModalImageViewerComponent } from './svc-modal-image-viewer/svc-modal-image-viewer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';

@NgModule({
  declarations: [
    SvcModalImageViewerComponent,
  ],
  imports: [
    LibSharedCommonModule, 
    MatTooltipModule,
    MatRippleModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    TranslocoModule,
  ],
  exports: [
    SvcModalImageViewerComponent
  ],
})
export class SvcModalModule { }
