import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'merge'
})
export class MergePipe implements PipeTransform {

  transform(value: any[] | { [key: string]: any }, source: any[] | { [key: string]: any }): any[] | { [key: string]: any } {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return Array.isArray(source) ? [...value, ...source] : value;
      }
      return typeof source === 'object' ? { ...value, ...source } : value;
    }
    return value ?? source;
  }
}
