import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvcUserWireframeComponent } from './svc-user-wireframe.component';
import { TranslocoModule} from "@ngneat/transloco";
import { MatDialogModule} from "@angular/material/dialog";
import { SvcUserWireframeService} from "./svc-user-wireframe.service";
import { MatButtonModule} from "@angular/material/button";
import { MatIconModule} from "@angular/material/icon";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SvcUserWireframeComponent
  ],
	imports: [
		CommonModule,
		TranslocoModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		LibSharedCommonModule,
		MatTooltipModule
	],
  exports: [
    SvcUserWireframeComponent
  ],
  providers: [
    SvcUserWireframeService
  ]
})
export class SvcUserWireframeModule { }
