
import { Injectable } from '@angular/core';
import { ToastType } from './svc-toast-item/svc-toast-item.component';
import { uuid } from 'projects/lib-shared-common/src/public-api';
import { Subject } from 'rxjs';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { SvcModule } from 'projects/lib-shared-core/src/lib/settings/enums/svc-module.enum';

export interface ToastItem {
  id: string;
  type: ToastType;
  message: string;
  title?: string;
  timeout: NodeJS.Timeout;
  hideIcon: boolean;
  lifespan: number;
  onDismissClicked: () => void;
}

export interface SvcToastConfig {
  timeout?: number;
  hideIcon?: boolean;
}

const TOAST_LIFESPAN = 5000;

@Injectable({
  providedIn: 'root',
})
export class SvcToastService {

  private _toastsList: ToastItem[] = [];
  private _toasts = new Subject<ToastItem[]>;
  public get toasts$() { return this._toasts.asObservable(); }

  private get isInsideIframe() {
    return this._svcAppSettings.module === SvcModule.Notification &&
      (top || parent) !== window;
  }

  constructor(
    private _svcAppSettings: SvcAppSettings,
  ) {
  }

  public success(message: string, title?: string, config?: SvcToastConfig): string {
    return this._show({ type: ToastType.SUCCESS, message, title}, config)?.id;
  }

  public error(message: string, title?: string, config?: SvcToastConfig): string {
    return this._show({ type: ToastType.ERROR, message, title}, config)?.id;
  }

  public warning(message: string, title?: string, config?: SvcToastConfig): string {
    return this._show({ type: ToastType.WARNING, message, title}, config)?.id;
  }

  public info(message: string, title?: string, config?: SvcToastConfig): string {
    return this._show({ type: ToastType.INFO, message, title }, config)?.id;
  }

  public clear() {
    if (this.isInsideIframe && 'angularNotificationClearToasts' in (top || parent)?.window) {
      return (top || parent)?.window['angularNotificationClearToasts']();
    }
    else {
      this._toastsList = [];
      this._toasts.next(this._toastsList);
    }
  }

  public close(toastId: string) {
    if (this.isInsideIframe && 'angularNotificationCloseToast' in (top || parent)?.window) {
      return (top || parent)?.window['angularNotificationCloseToast'](toastId);
    }
    else {
      this._toastsList = this._toastsList.filter(x => x.id !== toastId);
      this._toasts.next(this._toastsList);
    }
  }

  public showByConfig(data: { type: ToastType, message: string, title?: string }, config?: SvcToastConfig): ToastItem {
    return this._show(data, config);
  }

  private _show(data: { type: ToastType, message: string, title?: string }, config?: SvcToastConfig): ToastItem {
    if (this.isInsideIframe && 'angularNotificationShowToast' in (top || parent)?.window) {
      return (top || parent)?.window['angularNotificationShowToast'](data, config);
    }
    else {
      const toastItem: ToastItem = {
        id: uuid(),
        type: data.type,
        message: data.message,
        title: data.title,
        hideIcon: config?.hideIcon ?? false,
        lifespan: config?.timeout ?? TOAST_LIFESPAN,
        onDismissClicked: () => {
          clearTimeout(toastItem.timeout);
          this.close(toastItem.id);
        },
        timeout: setTimeout(() => {
          this.close(toastItem.id);
        }, config?.timeout ?? TOAST_LIFESPAN),
      };
      this._toastsList.push(toastItem);
      this._toasts.next(this._toastsList);
      return toastItem;
    }
  }
}
