import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcSelectPeriodComponent } from './svc-select-period.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';
import { SvcDatePickerRangeModule } from "../svc-date-picker-range/svc-date-picker-range.module";
import { SvcSelectModule } from '../svc-select/svc-select.module';


@NgModule({
  declarations: [
    SvcSelectPeriodComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcValidationMessageModule,
    TranslocoModule,
    SvcSelectModule,
    SvcDatePickerRangeModule,
],
  exports: [
    SvcSelectPeriodComponent
  ]
})
export class SvcSelectPeriodModule { }
