import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { SvcDialogSizes } from "../../models/svc-dialog-sizes";
import { SvcDialogStyles } from "../../models/svc-dialog-styles";
import { SvcDialogService } from "../../svc-dialog.service";

@Directive({ selector: '[svc-dialog-title]' })
export class SvcDialogTitle { }
@Directive({ selector: '[svc-dialog-content]' })
export class SvcDialogContent { }

@Directive({ selector: '[svc-dialog-actions]' })
export class SvcDialogActions { }

@Component({
  selector: 'svc-dialog',
  templateUrl: './svc-dialog.component.html',
  styleUrls: ['./svc-dialog.component.scss']
})
export class SvcDialogComponent implements AfterContentInit {

  @ContentChild(SvcDialogTitle, { read: ElementRef }) dialogTitle: ElementRef;
  @ContentChild(SvcDialogActions, { read: ElementRef }) dialogActions: ElementRef;
  @ViewChild('titleContainer') dialogTitleContainer: ElementRef;
  @ViewChild('defaultActions') defaultActions: ElementRef;

  @ViewChild('modal') modal: any;

  @Input() fullscreen: string | string[] = null;
  @Input() dialogStyle: SvcDialogStyles = "form";
  @Input() size: SvcDialogSizes;
  @Input() hasActions: boolean = true;
  @Input() hideCloseButton = false;
  @Input() textConfirm: string;
  @Input() public disabledConfirm: boolean;
  @Input() textCancel: string;
  @Input() scrollInsideContent: boolean = false;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  footerContentIsEmpty: boolean = true;

  hasTitle: boolean = false;
  manualActions: boolean = false;

  constructor(
    private dialogService: SvcDialogService,
    private _dialogRef?: MatDialogRef<SvcDialogComponent>
  ) {
  }

  ngOnInit() {
    if (!this.size) {
      this.size = this._dialogRef?._containerInstance?._config['size'] ?? 'md';
    }
  }
  ngAfterContentInit() {
    this.hasTitle = !!this.dialogTitle && this.dialogTitle.nativeElement.innerHTML.trim() !== '';
    this.manualActions = !!this.dialogActions && this.dialogActions.nativeElement.innerHTML.trim() !== '';

    this.footerContentIsEmpty = !this.dialogActions || this.dialogActions.nativeElement.innerHTML.trim() === '';
    this.opened.emit();
  }

  ngOnDestroy() {
    this.closed.emit();
  }

  closeDialog(configClose?: {}) {
    if (configClose)
      this._dialogRef.close(configClose);
    else
     this._dialogRef.close();
  }

}
