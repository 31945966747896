import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Question } from 'projects/lib-shared-feature/src/lib/modals/survey-modal/models/survey-modal';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SurveyModalService } from '../../services/survey-modal.service';

@Component({
  selector: 'app-evaluation-matrix-answer',
  templateUrl: './evaluation-matrix-answer.component.html',
  styleUrls: ['./evaluation-matrix-answer.component.scss'],
})
export class EvaluationMatrixAnswerComponent implements OnInit {
  @Input() questionTemplateId: number;
  @Input() question: Question;
  @Input() readonly: boolean;
  @Output() doSave = new EventEmitter<unknown>();

  public form: FormGroup;
  public selectedOption = 1;
  public MAX_INSTRUCTIONS = 10;
  public MAX_OPTIONS = 5;
  public isSave = false;

  #cdr = inject(ChangeDetectorRef);
  #fb = inject(FormBuilder);
  #modalService = inject(SurveyModalService);

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.initForm();

    if (this.question) {
      this.form.reset();
      this.patchForm(this.question);
    }

    this.changesAnswered();

    this.#modalService.save$.subscribe((isSave) => {
      if (isSave) this.isSave = true;
    });
  }

  private changesAnswered() {
    this.form
      ?.get('instructions')
      ?.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.allRadiosChecked()) {
          this.question.answered = true;
        }
      });
  }

  private allRadiosChecked(): boolean {
    return this.instructions.controls.every(
      (instruction) => instruction.get('option').value != null
    );
  }

  get isValid() {
    if (this.question?.isRequired && !this.question?.answered) {
      return false;
    }
    return true;
  }

  get isAnswered() {
    if (!this.question?.answered) {
      return false;
    }
    return true;
  }

  public getQuestionAnswered(): void {
    const instructionsArray = this.instructions.value;
    // Mapeia cada instrução para um objeto que inclui a descrição e a opção selecionada (se houver)
    const selectedOptions = instructionsArray
      .map((instruction) => {
        const selectedOptionIndex = instruction.option;
        // Certifica-se de que a opção selecionada é válida antes de acessar o cabeçalho da opção correspondente
        const selectedOptionHeader =
          selectedOptionIndex != null && selectedOptionIndex >= 0
            ? this.optionHeaders.at(selectedOptionIndex).value
            : null;

        return {
          Description: instruction.instructionLabel,
          Column: selectedOptionHeader,
        };
      })
      .filter((instruction) => instruction.Column != null);

    this.doSave.emit({ Rows: selectedOptions });
  }

  get instructions(): FormArray {
    return this.form.get('instructions') as FormArray;
  }

  get optionHeaders(): FormArray {
    return this.form.get('optionHeaders') as FormArray;
  }

  private initForm() {
    this.form = this.#fb.group({
      optionHeaders: this.#fb.array([]),
      instructions: this.#fb.array([]),
    });
  }

  private patchForm(response: any): void {
    let data = null;
    this.question ? (data = response?.metadata) : (data = response);

    // Obter as descrições das opções a partir da primeira linha (assumindo que todas as linhas têm o mesmo número de colunas)
    const rows = data.Rows;
    const optionDescriptions =
      rows?.length > 0
        ? rows[0].Columns?.map((col: any) => col.Description)
        : [];

    // Atualizar o FormArray de optionHeaders
    this.optionHeaders.clear();
    optionDescriptions?.forEach((desc: string) => {
      this.optionHeaders.push(this.#fb.control(desc));
    });

    // Atualizar o número de opções selecionadas
    this.selectedOption = optionDescriptions?.length;

    // Resetar as instruções atuais
    this.instructions.clear();

    // Iterar sobre cada linha de Rows e reconstruir as instruções
    rows?.forEach((row: any) => {
      const instructionIndex = row.Columns?.findIndex((column) => column.Checked);
      const newInstructionGroup = this.#fb.group({
        option: this.#fb.control(instructionIndex >= 0 ? instructionIndex : null),
        instructionLabel: this.#fb.control(row.Description),
      });
      this.instructions.push(newInstructionGroup);
    });

    this.#cdr.detectChanges();
  }
}
