import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Subject, debounceTime, startWith, takeUntil, tap } from 'rxjs';
import { App } from './services/apps.types';
import { AppsService } from './services/apps.service';
import { SitesService } from '../sites/services/sites.service';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'apps-panel',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appsPanel',
})
export class AppsComponent implements OnInit, OnDestroy {

  @ViewChild('panelTitle') panelTitle: ElementRef;
  @ViewChild(MatInput) matInput: MatInput;

  highlightApps: App[];
  appsColumn1: App[];
  appsColumn2: App[];
  filteredHighlightApps: App[] = [];
  filteredAppsColumn1: App[] = [];
  filteredAppsColumn2: App[] = [];
  opened: boolean = false;
  showTitle: boolean = false;
  searchCtrl = new FormControl('');
  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public isPanelItemLoading = false;

  get isLoading(): boolean {
    return this._appsService.isLoading;
  }


  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _appsService: AppsService,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private _sitesService: SitesService,
    private _translocoService: TranslocoService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'apps-panel-opened': this.opened,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to apps changes
    this._appsService.apps$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((apps: App[]) => {
        // Load the apps
        this.highlightApps = apps.filter(x => x.highlight);
        const commonApps = apps.filter(x => !x.highlight);
        this.appsColumn1 = commonApps.slice(0, commonApps.length / 2);
        this.appsColumn2 = commonApps.slice(commonApps.length / 2);
        this.searchCtrl.valueChanges.pipe(
          startWith(''),
          debounceTime(100),
          tap((value: string) => {
            value = (value ?? '').trim().toLowerCase();
            this.filteredHighlightApps = value ? this.highlightApps.filter(x => {
              const hasAnyOne = this._translocoService.translate(x.name).toLowerCase().includes(value)
                || x.items.some(y => this._translocoService.translate(y.name).toLowerCase().includes(value));
              x.expanded = hasAnyOne;
              return hasAnyOne;
            }).map(x => {
              return {
                ...x,
                items: x.items.filter(x => this._translocoService.translate(x.name).toLowerCase().includes(value)),
              };
            }) : this.highlightApps;
            this.filteredAppsColumn1 = value ? this.appsColumn1.filter(x => {
              const hasAnyOne = this._translocoService.translate(x.name).toLowerCase().includes(value)
                || x.items.some(y => this._translocoService.translate(y.name).toLowerCase().includes(value));
              x.expanded = hasAnyOne;
              return hasAnyOne;
            }).map(x => {
              return {
                ...x,
                items: x.items.filter(x => this._translocoService.translate(x.name).toLowerCase().includes(value)),
              };
            }) : this.appsColumn1;
            this.filteredAppsColumn2 = value ? this.appsColumn2.filter(x => {
              const hasAnyOne = this._translocoService.translate(x.name).toLowerCase().includes(value)
                || x.items.some(y => this._translocoService.translate(y.name).toLowerCase().includes(value));
              x.expanded = hasAnyOne;
              return hasAnyOne;
            }).map(x => {
              return {
                ...x,
                items: x.items.filter(x => this._translocoService.translate(x.name).toLowerCase().includes(value)),
              };
            }) : this.appsColumn2;
          }),
        ).subscribe();
      });

    this._sitesService.onSiteChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async () => {
        this._appsService
          .getAll()
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the panel
   */
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement('div');

    // Return if overlay couldn't be create for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add('apps-panel-overlay');

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(
      this._elementRef.nativeElement.parentElement,
      this._overlay
    );

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener('click', () => {
      this.close();
    });

    setTimeout(() => {
      this.showTitle = this.panelTitle?.nativeElement.offsetWidth < this.panelTitle?.nativeElement.scrollWidth;
    })
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;

    // If the panel opens, show the overlay
    if (open) {
      this._showOverlay();
      setTimeout(() => this.matInput?.focus());
    }
    // Otherwise, hide the overlay
    else {
      this._hideOverlay();
    }
  }
}
