<svc-dialog dialogStyle="empty">
  <div class="flex gap-2 items-center" svc-dialog-title>

    <mat-icon
      class="icon-size-4 text-default"
      svgIcon="heroicons_solid:funnel"
    ></mat-icon>
    <div class="text-base font-semibold">
      {{ config?.title | transloco }}
    </div>
  </div>

  <div class="flex-auto p-5" svc-dialog-content>
    <svc-filters #svcFilters
      *ngIf="!config?.loading"
      class="grid gap-4"
      [ngStyle]="{ 'grid-template-columns': gridTemplateColumnsStyleValue }"
      [class.hidden]="config?.loading"
      [filters]="config?.filtersField"
      (onFilterChanged)="_fieldsChanged()"
      (onValueChanged)="_valueChanged($event)"
      (onInit)="_fieldsChanged()"
    ></svc-filters>

    <div *ngIf="config?.loading" class="flex flex-col p-10 items-center">
      <mat-spinner diameter="35"></mat-spinner>
    </div>
  </div>

  <div class="flex flex-1 justify-between items-center gap-2" svc-dialog-actions>
    <svc-button
      color="primary"
      mode="simple"
      [disabled]="isFormNotFilled || config.loading"
      (click)="!isFormNotFilled && !config.loading ? clearFilters() : null"
    >
      <p class="underline">{{ 'Limpar filtros' | transloco }}</p>
    </svc-button>

    <div class="flex gap-2 items-center">
      <svc-button color="primary" mode="stroked" (click)="cancelFilter()" buttonId="filter-cancel">
        {{ 'Cancelar' | transloco }}
      </svc-button>

      <svc-button
        color="primary"
        mode="flat"
        [disabled]="isFormNotFilled || config.loading"
        (click)="!isFormNotFilled && !config.loading && defineFilter()"
        buttonId="filter-search"
      >
        {{ 'Buscar' | transloco }}
      </svc-button>
    </div>
  </div>

</svc-dialog>
